import React from 'react';
import withRouter from '../../components/Common/withRouter';
import useHomeData from './hooks/useHomeData';
import SeoDetails from '../../seo/SeoDetails';
import Banner from './components/Banner';
import Event from './components/Event';
import Artist from './components/Artist';
import ChipFilter from './components/ChipFilter';
import AppDetails from './components/AppDetails';

const Home = (props) => {
  const {
    tradingData,
    featuredData,
    exclusiveOfferData,
    eventData,
    artistData,
    seoData,
    cityName
  } = useHomeData(props.router);

  const sectionTitles = [
    { key: 'featured', label: 'Popular Events Near You', data: featuredData },
    {
      key: 'exclusive_offers',
      label: 'Events With Exclusive Offers',
      data: exclusiveOfferData,
    },
    { key: 'events', label: 'Upcoming Events Near You', data: eventData },
  ];

  return (
    <>
      <SeoDetails
          title={seoData?.seo_title}
          description={seoData?.short_description}
          ogImage={seoData?.seo_image}
      />
      <Banner events={tradingData} props={props} cityName={cityName} />
      <ChipFilter props={props} />

      <section className="pt-10 pb-16 md:pt-16">
        <div className="container">
          {sectionTitles.map(({ key, label, data }) => (
            <Event
              key={key}
              events={data}
              props={props}
              section_title={label}
            />
          ))}

          <Artist artists={artistData} props={props} pageTitle="Home" />
          <AppDetails />
        </div>
      </section>
    </>
  );
};

export default withRouter(Home);
