/* EVENTS */
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';
export const CLEAR_EVENT_DATA = 'CLEAR_EVENT_DATA';

/* EVENTS DETAIL*/
export const GET_EVENT_DETAIL = 'GET_EVENT_DETAIL';
export const GET_EVENT_DETAIL_SUCCESS = 'GET_EVENT_DETAIL_SUCCESS';
export const GET_EVENT_DETAIL_FAIL = 'GET_EVENT_DETAIL_FAIL';
export const CLEAR_EVENT_DETAIL_DATA = 'CLEAR_EVENT_DETAIL_DATA';

/* EVENTS SPONSOR */
export const GET_EVENT_SPONSOR = 'GET_EVENT_SPONSOR';
export const GET_EVENT_SPONSOR_SUCCESS = 'GET_EVENT_SPONSOR_SUCCESS';
export const GET_EVENT_SPONSOR_FAIL = 'GET_EVENT_SPONSOR_FAIL';
export const CLEAR_EVENT_SPONSOR = 'CLEAR_EVENT_SPONSOR';

/* EVENTS ARTIST */
export const GET_EVENT_ARTIST = 'GET_EVENT_ARTIST';
export const GET_EVENT_ARTIST_SUCCESS = 'GET_EVENT_ARTIST_SUCCESS';
export const GET_EVENT_ARTIST_FAIL = 'GET_EVENT_ARTIST_FAIL';
export const CLEAR_EVENT_ARTIST = 'CLEAR_EVENT_ARTIST';

/* EVENTS NEAREST EVENT */
export const GET_NEAREST_EVENT = 'GET_NEAREST_EVENT';
export const GET_NEAREST_EVENT_SUCCESS = 'GET_NEAREST_EVENT_SUCCESS';
export const GET_NEAREST_EVENT_FAIL = 'GET_NEAREST_EVENT_FAIL';
export const CLEAR_NEAREST_EVENT = 'CLEAR_NEAREST_EVENT';
