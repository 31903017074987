import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import holiBg from '../../../assets/light_theme_images/bg-holi.svg';
import holiIcon from '../../../assets/light_theme_images/holi-icon.svg';
import holiIcon2 from '../../../assets/light_theme_images/holi-icon2.svg';
import holiIcon3 from '../../../assets/light_theme_images/holi-icon3.svg';
import holiIcon4 from '../../../assets/light_theme_images/holi-icon4.svg';
import holiinvi from '../../../assets/light_theme_images/holiinvi.svg';
import logo from '../../../assets/light_theme_images/logo.svg';
import PriceDetail from '../../QrCode/PriceDetail';
import SponserList from '../../QrCode/SponserList';
import PassDetails from '../../QrCode/PassDetails';
import HoliSeasonMePass from './HoliSeasonMePass';
import DeletedImage from '../../../assets/images/default/Deleted-image.png';
import ExpiredImage from '../../../assets/images/default/Expired-image.png';
import ScannedImage from '../../../assets/images/default/Scanned-image.png';
import RFIDImage from '../../../assets/images/default/RFID-image.png';
import SharedImage from '../../../assets/images/default/Shared-image.png';
import BlurImage from '../../../assets/images/default/BlurImage.png';
import RegFormImage from '../../../assets/images/default/RegFormImage.png';
import locationIcon from '../../../assets/light_theme_images/location-red.svg';
import shareYourExperience from '../../../assets/light_theme_images/share-your-experience.svg';
import ReviewPopup from '../../../components/QrCode/Index';
import img from '../../../assets/images/App-Store.png';
import img1 from '../../../assets/images/google-play.png';
import { convertToAmPm, convertToDate } from '../../../common/commonFunction';

const HoliPass = ({
  passDetails,
  seasonArray,
  pricingDetails,
  sponsorList,
  textColor,
  primaryColor,
  secondaryColor,
  backGroundColor,
  toggleSplitModal,
  setShowTermsModal,
  setUrlString,
  setPassAvailableQuantity,
  distributionType,
  meetLink,
  bannerImage,
  ratingDetail,
  eventDetail,
  artistDetail,
  showReviewModal,
  toggleReviewModal,
  props,
  EventDetail,
  circles,
  openMeetLink,
  map,
}) => {
  const [isThings, setIsThings] = useState(false);
  return (
    <>
      <div className={`bg-[#f4f4f4] h-full pb-4`}>
        <div
          className="bg-white px-2 py-6 mb-6 "
          style={{
            boxShadow: '0px 2px 12px 0px #00000014',
          }}
        >
          <div className="m-auto max-w-[160px]">
            <img src={logo} alt="mepass" />
          </div>
        </div>
        <div className="w-full md:w-[398px] m-auto px-[46px]">
          {passDetails && passDetails.pass_type_slug == 'season-mepass' ? (
            <HoliSeasonMePass
              seasonArray={seasonArray}
              passDetails={passDetails}
              pricingDetails={pricingDetails}
              sponsorList={sponsorList}
              textColor={textColor}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              backGroundColor={backGroundColor}
              RFIDImage={RFIDImage}
              ExpiredImage={ExpiredImage}
              DeletedImage={DeletedImage}
              ScannedImage={ScannedImage}
              SharedImage={SharedImage}
              BlurImage={BlurImage}
              RegFormImage={RegFormImage}
              map={map}
              toggleSplitModal={toggleSplitModal}
              setShowTermsModal={setShowTermsModal}
              setUrlString={setUrlString}
              setPassAvailableQuantity={setPassAvailableQuantity}
              distributionType={distributionType}
              meetLink={meetLink}
              openMeetLink={openMeetLink}
              bannerImage={bannerImage}
              artistDetail={artistDetail}
              eventDetail={EventDetail}
              ratingDetail={ratingDetail}
            />
          ) : (
            <div
              className={`rounded-[10px] border border-[#CB2129]`}
              style={{ backgroundColor: backGroundColor }}
            >
              <PassDetails
                passDetails={passDetails}
                textColor={textColor}
                backGroundColor={backGroundColor}
                bannerImage={bannerImage}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              />
              <div className="relative">
                <div
                  className="border-b-2 border-dashed my-3 mx-4 relative"
                  style={{ borderColor: primaryColor }}
                >
                  <span
                    style={{ borderColor: primaryColor }}
                    className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#CB2129] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-left-[12px] box-border flex items-center p-1`}
                  ></span>
                  <span
                    style={{ borderColor: primaryColor }}
                    className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-right-[12px] box-border flex items-center p-1`}
                  ></span>
                </div>
                <img
                  src={holiIcon}
                  alt="holi-icon"
                  className="absolute -left-[27px] md:-left-[32px] -top-[55px] md:-top-[61px] w-[79%]"
                />
                <img
                  src={holiIcon2}
                  alt="holi-icon"
                  className="absolute -right-[27px] md:-right-[32px] -top-[55px] md:-top-[61px] w-[79%]"
                />
              </div>
              <div className="p-[15px] relative">
                <img
                  src={holiIcon3}
                  alt="holi-icon"
                  className="absolute left-0 top-[242px] w-[18%]"
                />
                <img
                  src={holiIcon4}
                  alt="holi-icon"
                  className="absolute right-0 top-[242px] w-[18%]"
                />
                <div>
                  {passDetails && passDetails.user_name && (
                    <h2
                      style={{
                        color: textColor,
                      }}
                      className={`text-xl text-center font-bold mb-4 capitalize text-[${textColor}]`}
                    >
                      {passDetails.user_name}
                    </h2>
                  )}
                  <div className="flex mb-5 relative z-10">
                    <div className="flex-1">
                      {passDetails &&
                        passDetails.event_pass &&
                        passDetails.scan_date &&
                        passDetails.pass_type_slug != 'flexible-mepass' && (
                          <div className="flex gap-1.5 mb-2">
                            <i
                              style={{ color: primaryColor }}
                              class="text-[12px] fa-solid fa-calendar-days mt-[2px]"
                            ></i>
                            <p
                              style={{ color: textColor }}
                              className=" text-[12px] font-normal"
                            >
                              {convertToDate(passDetails.scan_date, 'pass')}
                            </p>
                          </div>
                        )}
                      {passDetails &&
                        passDetails.event_pass &&
                        passDetails.event_pass.pass_date &&
                        passDetails.event_date &&
                        passDetails.event_date.start_time && (
                          <div className="flex gap-2.5">
                            <p
                              style={{ color: textColor }}
                              className="text-[12px] font-normal"
                            >
                              <i
                                style={{ color: primaryColor }}
                                className="fa-solid fa-clock mr-[5px]"
                              ></i>
                              {convertToAmPm(passDetails.event_date.start_time)}
                            </p>
                            {/* <span style={{ color: textColor }} className="font-bold"> <i class="fa-solid fa-credit-card mr-[2px]"></i> 500</span> */}
                          </div>
                        )}
                    </div>
                    <div className="flex-none">
                      {passDetails.available_quantity > 0 && (
                        <>
                          <div
                            style={{
                              border: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                              color: textColor,
                            }}
                            className={`font-bold text-xl w-[80px] h-[29px] flex items-center justify-center text-[${textColor}] uppercase relative break-all qty-border-liner-gradient bg overflow-hidden bg-transparent`}
                          >
                            {passDetails.available_quantity}
                            <sub className="ml-1 text-[8px] font-normal capitalize -bottom-[5px]">
                              Qty.
                            </sub>
                            <span
                              className="absolute -top-[5px] -right-[6px] size-[14px] rounded-full z-10 border"
                              style={{
                                backgroundColor: backGroundColor,
                                borderColor: primaryColor,
                              }}
                            ></span>
                            <span
                              className="absolute -bottom-[5px] -right-[6px] size-[14px] rounded-full z-10 border"
                              style={{
                                backgroundColor: backGroundColor,
                                borderColor: primaryColor,
                              }}
                            ></span>
                            <span
                              className="absolute -top-[5px] -left-[6px] size-[14px] rounded-full z-10 border"
                              style={{
                                backgroundColor: backGroundColor,
                                borderColor: primaryColor,
                              }}
                            ></span>
                            <span
                              className="absolute -bottom-[5px] -left-[6px] size-[14px] rounded-full z-10 border"
                              style={{
                                backgroundColor: backGroundColor,
                                borderColor: primaryColor,
                              }}
                            ></span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="relative">
                    <img
                      src={holiBg}
                      alt="holi-bg"
                      className="w-full absolute -top-[46px] bottom-0 left-0 right-0"
                    />
                    <div
                      style={{ backgroundColor: primaryColor }}
                      className="max-w-[70%] m-auto p-1.5 rounded-[8px] mb-4 relative z-10"
                    >
                      {passDetails &&
                      passDetails.status !== null &&
                      (passDetails.status === 'Expired' ||
                        passDetails.status === 'Deleted' ||
                        passDetails.status === 'Scann' ||
                        passDetails.status === 'Scann' ||
                        passDetails.available_quantity === 0) ? (
                        <>
                          {passDetails.status === 'Expired' ? (
                            <img
                              src={ExpiredImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : passDetails.status === 'Deleted' ? (
                            <img
                              src={DeletedImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : passDetails.status === 'Scann' ? (
                            <img
                              src={ScannedImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : passDetails.available_quantity === 0 ? (
                            <img
                              src={SharedImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : passDetails &&
                            passDetails.event_pass &&
                            passDetails.event_pass.is_rfid == 'Yes' ? (
                            <img
                              src={RFIDImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : (
                            ''
                          )}
                        </>
                      ) : passDetails &&
                        passDetails.event_pass &&
                        passDetails.event_pass.is_rfid == 'Yes' ? (
                        <img
                          src={RFIDImage}
                          alt="img"
                          className="inline-block rounded-[8px]"
                        />
                      ) : passDetails &&
                        passDetails.new_registration_form_id > 0 &&
                        passDetails.registration_form_status != 'Approved' ? (
                        <img
                          src={RegFormImage}
                          alt="img"
                          className="inline-block rounded-[8px] w-full"
                        />
                      ) : passDetails &&
                        passDetails.is_payment_link == 'Yes' &&
                        passDetails.payment_link_status != 'paid' ? (
                        <img
                          src={BlurImage}
                          alt="img"
                          className="inline-block rounded-[8px] w-full"
                        />
                      ) : (
                        <img
                          src={passDetails && passDetails.qr_image}
                          alt="img"
                          className="inline-block rounded-[8px]"
                        />
                      )}
                    </div>
                  </div>
                  {passDetails.seat_label && (
                    <div className="flex gap-3 mb-[21px]">
                      <div>
                        <p className="text-[#101010] text-[12px] font-medium">
                          Theater
                        </p>
                        <h4 className="text-[#CB2129] text-[12px] font-semibold">
                          {passDetails.venue_name}
                        </h4>
                      </div>

                      <div>
                        <p className="text-[#101010] text-[12px] font-medium">
                          Seats
                        </p>
                        <h4 className="text-[#CB2129] text-[12px] font-semibold uppercase">
                          {passDetails.event_pass &&
                            passDetails.event_pass.name}
                          - {passDetails.seat_label}
                        </h4>
                      </div>
                    </div>
                  )}
                  {(passDetails &&
                    passDetails.event_pass &&
                    passDetails.event_pass.is_rfid == 'Yes') ||
                  (passDetails &&
                    passDetails.status !== null &&
                    (passDetails.status === 'Expired' ||
                      passDetails.status === 'Deleted' ||
                      passDetails.status === 'Scann' ||
                      passDetails.available_quantity === 0)) ? (
                    <>
                      <div
                        className={
                          'flex items-center justify-center relative z-10'
                        }
                      >
                        {passDetails &&
                        passDetails.event_date &&
                        !isEmpty(passDetails.event_date.gmap_link) ? (
                          <div className="flex-none">
                            <Link
                              to={
                                passDetails &&
                                passDetails.event_date &&
                                passDetails.event_date.gmap_link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location  text-[#CB2129] font-medium w-[100px]"
                            >
                              <img
                                src={locationIcon}
                                alt="img"
                                className="inline-block mr-1"
                              />
                              Location
                            </Link>
                          </div>
                        ) : (
                          <>
                            {passDetails &&
                              passDetails.event &&
                              !isEmpty(passDetails.event.gmap_link) && (
                                <div className="flex-none">
                                  <Link
                                    to={
                                      passDetails &&
                                      passDetails.event &&
                                      passDetails.event.gmap_link
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location text-[#CB2129] font-medium w-[100px]"
                                  >
                                    <img
                                      src={locationIcon}
                                      alt="img"
                                      className="inline-block mr-1"
                                    />
                                    Location
                                  </Link>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {distributionType !== 'Online' ? (
                        <div
                          className={
                            passDetails && passDetails.is_transferable === 'No'
                              ? 'flex items-center justify-center relative z-10'
                              : 'flex items-center justify-center gap-4 relative z-10'
                          }
                        >
                          {passDetails &&
                            passDetails.is_transferable === 'Yes' && (
                              <div className="none">
                                {passDetails.is_payment_link == 'Yes' &&
                                passDetails.payment_link_status != 'paid' ? (
                                  <button
                                    onClick={() => {
                                      window.open(passDetails.payment_link);
                                    }}
                                    style={{
                                      background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                    }}
                                    className={`rounded-[4px] flex items-center justify-center text-[12px] text-white font-medium px-4 py-2`}
                                  >
                                    Make Payment
                                  </button>
                                ) : passDetails.new_registration_form_id > 0 &&
                                  (passDetails.registration_form_data_id ==
                                    '' ||
                                    passDetails.registration_form_data_id ==
                                      null) ? (
                                  <button
                                    onClick={() => {
                                      props.router.navigate(
                                        `/qr/${passDetails.url_string}/registration/${passDetails.event_pass.registration_form_slug}`,
                                      );
                                    }}
                                    style={{
                                      background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                    }}
                                    className={`rounded-[4px] flex items-center justify-center text-[12px] text-white font-medium px-4 py-2`}
                                  >
                                    Fill the form
                                  </button>
                                ) : (
                                  <button
                                    onClick={toggleSplitModal}
                                    style={{
                                      background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                    }}
                                    className={`rounded-[4px] flex items-center justify-center text-[12px] text-white font-medium px-4 py-2`}
                                  >
                                    Split & Share
                                  </button>
                                )}
                              </div>
                            )}
                          {passDetails &&
                          passDetails.event_date &&
                          passDetails.event_date.gmap_link ? (
                            <div className="flex-none relative z-10">
                              <Link
                                to={
                                  passDetails &&
                                  passDetails.event_date &&
                                  passDetails.event_date.gmap_link
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location  font-medium text-[#CB2129] w-[100px]`}
                              >
                                <img
                                  src={locationIcon}
                                  alt="img"
                                  className="inline-block mr-1"
                                />
                                Location
                              </Link>
                            </div>
                          ) : (
                            <>
                              {passDetails &&
                              passDetails.event &&
                              !isEmpty(passDetails.event.gmap_link) ? (
                                <div className="flex-none">
                                  <Link
                                    to={
                                      passDetails &&
                                      passDetails.event &&
                                      passDetails.event.gmap_link
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ borderColor: primaryColor }}
                                    className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location text-[#CB2129] font-medium w-[100px]"
                                  >
                                    <img
                                      src={locationIcon}
                                      alt="img"
                                      className="inline-block mr-1"
                                    />
                                    Location
                                  </Link>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="flex justify-center relative z-10">
                          <button
                            onClick={() => openMeetLink(meetLink)}
                            style={{
                              background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                            }}
                            className={`rounded-[4px] flex items-center justify-center text-base text-white font-semibold w-[140px] py-3 `}
                          >
                            {!isEmpty(meetLink)
                              ? 'Join Now'
                              : 'Link Will Be Shared Soon'}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  <div className="mb-8"></div>
                  {passDetails.is_payment_link == 'Yes' &&
                  passDetails.payment_link_status != 'paid' ? (
                    <p
                      style={{ color: textColor }}
                      className={`text-[12px] text-[${textColor}]  font-semibold`}
                    >
                      Excited to host you! Complete your payment now to secure
                      your Mepass experience
                    </p>
                  ) : (
                    ''
                  )}
                  {passDetails.new_registration_form_id > 0 &&
                  (passDetails.registration_form_data_id == '' ||
                    passDetails.registration_form_data_id == null) ? (
                    <p
                      style={{ color: textColor }}
                      className={`text-[12px] text-[${textColor}]  font-semibold`}
                    >
                      Excited to host you! Complete your
                      <span
                        style={{ color: primaryColor }}
                        className="cursor-pointer"
                        onClick={() => {
                          props.router.navigate(
                            `/qr/${passDetails.url_string}/registration/${passDetails.event_pass.registration_form_slug}`,
                          );
                        }}
                      >
                        {' '}
                        registration form
                      </span>{' '}
                      to secure your Mepass experience
                    </p>
                  ) : (
                    ''
                  )}

                  {/* Image For Holi  */}
                  <img src={holiinvi} alt="holi" className="w-full" />
                  {passDetails &&
                    passDetails.event_pass &&
                    passDetails.event_pass.notes && (
                      <div className="group bg-[#F9F9F9] rounded-[10px] p-3 mt-4">
                        <button
                          onClick={() => setIsThings(!isThings)}
                          className="w-full flex justify-between items-center cursor-pointer select-none"
                        >
                          <p className="text-base text-[#101010] font-semibold">
                            Things to know
                          </p>
                          <i
                            className={`fas text-[#000000] ${
                              isThings ? 'fa-chevron-up' : 'fa-chevron-down'
                            }`}
                          ></i>
                        </button>
                        {isThings && (
                          <div>
                            <div
                              style={{ borderColor: secondaryColor }}
                              className="border-b-2 border my-3"
                            ></div>
                            <div
                              className={`${
                                passDetails &&
                                passDetails.event_pass &&
                                passDetails.event_pass.notes &&
                                passDetails.event_pass.notes.includes('<ul>')
                                  ? 'bullet-point'
                                  : passDetails &&
                                    passDetails.event_pass &&
                                    passDetails.event_pass.notes &&
                                    passDetails.event_pass.notes.includes(
                                      '<ol>',
                                    )
                                  ? 'number-point'
                                  : ''
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: passDetails.event_pass.notes,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}

                  {localStorage.getItem('publicMePassUser') &&
                    (ratingDetail.event == 'No' ||
                      ratingDetail.artist == 'No') && (
                      <>
                        <div
                          className="bg-[#F9F9F9] rounded-[10px] p-3 cursor-pointer mt-4"
                          onClick={() => {
                            toggleReviewModal();
                          }}
                        >
                          <div className="flex gap-3">
                            <div className="flex-1">
                              <p className="text-[15px] text-left text-[#101010] font-semibold">
                                Share your experience
                              </p>
                            </div>
                            <div className="flex-none">
                              <img
                                src={shareYourExperience}
                                alt="img"
                                className="inline-block"
                              />
                            </div>
                          </div>
                        </div>
                        <ReviewPopup
                          isVisible={showReviewModal}
                          toggleReviewModal={toggleReviewModal}
                          props={props}
                          EventDetail={EventDetail}
                          ArtistDetail={artistDetail}
                          ratingDetail={ratingDetail}
                        />
                      </>
                    )}
                  <>
                    <PriceDetail
                      passDetails={passDetails}
                      pricingDetails={pricingDetails}
                      textColor={textColor}
                      secondaryColor={secondaryColor}
                      primaryColor={primaryColor}
                    />
                  </>
                </div>
              </div>

              {!isEmpty(sponsorList) && (
                <>
                  <div
                    className="border-b-2 border-dashed my-3 mx-4 relative"
                    style={{ borderColor: primaryColor }}
                  >
                    <span
                      style={{ borderColor: primaryColor }}
                      className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#CB2129] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-left-[12px] box-border flex items-center p-1`}
                    ></span>
                    <span
                      style={{ borderColor: primaryColor }}
                      className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-right-[12px] box-border flex items-center p-1`}
                    ></span>
                  </div>
                  <SponserList
                    sponsorList={sponsorList}
                    textColor={textColor}
                  />
                </>
              )}
              <div
                className="border-b-2 border-dashed my-3 mx-4 relative"
                style={{ borderColor: primaryColor }}
              >
                <span
                  style={{ borderColor: primaryColor }}
                  className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#CB2129] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-left-[12px] box-border flex items-center p-1`}
                ></span>
                <span
                  style={{ borderColor: primaryColor }}
                  className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-right-[12px] box-border flex items-center p-1`}
                ></span>
              </div>
              <div className="px-[15px] py-[20px]">
                <h3
                  style={{ color: textColor }}
                  className="text-base text-center font-semibold mb-3"
                >
                  Download App
                </h3>
                <div className="grid grid-cols-2 gap-5">
                  {process.env.REACT_APP_PLAY_STORE_URL ? (
                    <Link
                      to={process.env.REACT_APP_PLAY_STORE_URL}
                      target="_blank"
                    >
                      <img
                        src={img1}
                        alt="img"
                        className="inline-block w-full"
                      />
                    </Link>
                  ) : (
                    <img src={img1} alt="img" className="inline-block w-full" />
                  )}
                  {process.env.REACT_APP_APP_STORE_URL ? (
                    <Link
                      to={process.env.REACT_APP_APP_STORE_URL}
                      target="_blank"
                    >
                      <img
                        src={img}
                        alt="img"
                        className="inline-block w-full"
                      />
                    </Link>
                  ) : (
                    <img src={img} alt="img" className="inline-block w-full" />
                  )}
                </div>
              </div>
              <div className="relative w-full mt-6 flex justify-center">
                <div className="flex justify-between absolute w-[98%] top-[50%] -translate-y-[50%] mt-[1px]">
                  {circles.map((_, index) => (
                    <span
                      key={index}
                      style={{ borderColor: primaryColor }}
                      className={`w-[30px] h-[30px] rounded-full bg-[#f4f4f4] border flex items-center justify-center p-1 -rotate-90 relative after:absolute after:w-[17px] after:md:h-[30px] after:h-[36px] after:bg-[#f4f4f4] after:-left-[3px] after:top-[50%] after:-translate-y-[50%] after:content-[''] ${
                        index === 0 ? 'pl-0' : ''
                      } ${index === circles.length - 1 ? 'pr-0' : ''}`}
                    ></span>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* <div className="mt-4 text-center">
                  <p
                    className={`text-sm text-[#1A1A1A] font-body font-normal text-center`}
                  >
                    Technology Partner :{" "}
                    <Link
                      to="https://www.bsptechno.com/"
                      target="blank"
                      className=" ml-1"
                    >
                      BSP Technologies
                    </Link>
                  </p>
                </div> */}
        </div>
      </div>
    </>
  );
};

export default HoliPass;
