import React, { useEffect, useState } from 'react';
import banner from '../../assets/light_theme_images/blog-detail.png';
import blogItmes from '../../assets/light_theme_images/blog-detail-items.png';
import blogItem from '../../assets/light_theme_images/blog-item.png';
import blogItem1 from '../../assets/light_theme_images/blog-item1.png';
import blogItem2 from '../../assets/light_theme_images/blog-item2.png';
import blogItem3 from '../../assets/light_theme_images/blog-item3.png';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import withRouter from '../../components/Common/withRouter';
import { isEmpty, set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getData } from '../../store/AppMaster/actions';
import api from '../../constants/api_constants';
import moment from 'moment/moment';
import { convertToDate } from '../../common/commonFunction';
import whatsapp from '../../assets/light_theme_images/whatsapp.png';
import instagram from '../../assets/light_theme_images/instagram.svg';
import twitter from '../../assets/light_theme_images/twitter.svg';
import facebook from '../../assets/light_theme_images/facebook.svg';
import { Link } from 'react-router-dom';

const OurBlogDetails = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const dispatch = useDispatch();
  const [isApiCalled, setIsApiCalled] = useState(0);
  const [blogDetails, setBlogDetails] = useState([]);
  const [recentBlog, setRecentBlog] = useState([]);
  const [filterData, setFilterData] = useState({
    slug: props.router.params.blog_slug,
  });
  const domain = window.location.hostname;
  const [whatsappShareUrl, setWhatsappShareUrl] = useState('');
  const [twitterShareUrl, setTwitterShareUrl] = useState('');
  const [facebookShareUrl, setFacebookShareUrl] = useState('');
  const [instagramShareUrl, setInstagramShareUrl] = useState('');
  const [locationHash, setLocationHash] = useState('#introduction');

  useEffect(() => {
    setRecentBlog([]);
    const requestData = {
      start: 0,
      limit: 3,
      sort_order: 'desc',
      slug: props.router.params.blog_slug,
      status: 'Active',
    };
    dispatch(getData(requestData, props.router.navigate, api.BLOG_LIST));
    getBlogDetail();
  }, [props.router.params.blog_slug]);

  if (!isEmpty(data) && !isEmpty(data.blogList) && isEmpty(recentBlog)) {
    setRecentBlog(data.blogList);
  }

  const getBlogDetail = () => {
    setBlogDetails([]);
    const requestData = {
      slug: props.router.params.blog_slug,
    };
    dispatch(getData(requestData, props.router.navigate, api.BLOG_DETAIL));
  };

  if (!isEmpty(data) && !isEmpty(data.blogViewPublic) && isEmpty(blogDetails)) {
    setBlogDetails(data.blogViewPublic);
    let storyUrl = '';
    if (domain === 'www.mepass.in' || domain === 'mepass.in') {
      storyUrl = 'https://www.mepass.in/our-blog/' + data.blogViewPublic.slug;
    } else {
      storyUrl =
        'https://stage-frontendpublic.mepass.co.in/our-blog/' +
        data.blogViewPublic.slug;
    }
    const blogUrl = encodeURIComponent(storyUrl);
    const blogTitle = encodeURIComponent(data.blogViewPublic.blog_name);
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${blogTitle}&url=${blogUrl}&hashtags=Event,Fun`;
    setTwitterShareUrl(twitterShareUrl);
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${blogUrl}`;
    setFacebookShareUrl(facebookShareUrl);
    const whatsAppShareUrl =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
        ? `https://wa.me/?text=${blogUrl}` // Mobile version
        : `https://web.whatsapp.com/send?text=${blogUrl}`;
    setWhatsappShareUrl(whatsAppShareUrl);
    if (data.blogViewPublic.introduction) {
      setLocationHash('#introduction');
    } else {
      setLocationHash('#detailed_description');
    }
  }
  useEffect(() => {
    if (location.hash == '#introduction') {
      const element = document.getElementById('introduction');
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 80,
          behavior: 'smooth',
        });
      }
      setLocationHash('#introduction');
    } else if (location.hash == '#detailed_description') {
      const element = document.getElementById('detailed_description');
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 80,
          behavior: 'smooth',
        });
      }
      setLocationHash('#detailed_description');
    } else if (location.hash === '#conclusion') {
      const element = document.getElementById('conclusion');
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 80,
          behavior: 'smooth',
        });
      }
      setLocationHash('#conclusion');
    }
  }, [location, location.hash]);

  return (
    <>
      <section className="bg-white pt-14 pb-24">
        <div className="container">
          <div className="flex flex-col text-center gap-6">
            <h2 className="text-[#101010] text-4xl font-semibold">
              {blogDetails.blog_name}
            </h2>
            <div className="flex items-center justify-center gap-4">
              <p className="text-[#CB2129] text-base font-medium">
                {convertToDate(blogDetails.created_at, 'blog')}
              </p>
              {blogDetails.reading_time && (
                <>
                  <i className="fas fa-circle text-[#FFC0C0] text-[10px]"></i>
                  <p className="text-[#CB2129] text-base font-medium">
                    {blogDetails.reading_time} min read
                  </p>
                </>
              )}
            </div>
            <img
              src={blogDetails.primary_banners_path}
              alt="img"
              className="rounded-2xl"
            />
          </div>
          <div className="grid grid-cols-3 gap-10 mt-10">
            <div className="col-span-2">
              {blogDetails.conclusion && (
                <p id="introduction">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blogDetails.introduction,
                    }}
                  />
                </p>
              )}
              <div className="mt-5" id="detailed_description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: blogDetails.body,
                  }}
                />
              </div>
              {blogDetails.secondary_banners_path && (
                <img
                  src={blogDetails.secondary_banners_path}
                  alt="img"
                  className="rounded-[12px] mt-8"
                />
              )}
              {blogDetails.conclusion && (
                <div className="mt-5" id="conclusion">
                  <h3 className="text-[#101010] text-2xl font-semibold">
                    Conclusion
                  </h3>
                  <p className="mt-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blogDetails.conclusion,
                      }}
                    />
                  </p>
                </div>
              )}
            </div>
            <div>
              <div>
                <div class="flex flex-col gap-5">
                  <h4 class="text-xl lg:text-3xl font-semibold text-[#101010]">
                    In this article
                  </h4>
                  <ul class="flex flex-col gap-3">
                    {blogDetails.introduction && (
                      <li>
                        <a
                          href="#introduction"
                          className={`py-[10px] pl-[20px] text-[${
                            locationHash == '#introduction'
                              ? '#CB2129'
                              : '#101010'
                          }]  text-sm lg:text-base font-semibold relative 
                        after:absolute after:h-full after:w-[3px]
                         
                        ${
                          locationHash == '#introduction'
                            ? 'after:bg-[#CB2129]'
                            : ''
                        } 
                         after:top-0 after:bottom-0 after:left-0`}
                        >
                          Introduction
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        href="#detailed_description"
                        className={`py-[10px] pl-[20px]  text-[${
                          locationHash == '#detailed_description'
                            ? '#CB2129'
                            : '#101010'
                        }]  text-sm lg:text-base font-semibold relative after:absolute after:h-full 
                        after:w-[3px] after:bg-[${
                          locationHash == '#detailed_description'
                            ? '#CB2129'
                            : '#101010'
                        }] after:top-0 after:bottom-0 after:left-0`}
                      >
                        Detailed Description
                      </a>
                    </li>
                    {blogDetails.body && (
                      <li>
                        <a
                          href="#conclusion"
                          className={`py-[10px] pl-[20px]  text-[${
                            locationHash == '#conclusion'
                              ? '#CB2129'
                              : '#101010'
                          }]  text-sm lg:text-base font-semibold relative after:absolute after:h-full 
                        after:w-[3px] after:bg-[${
                          locationHash == '#conclusion' ? '#CB2129' : '#101010'
                        }] after:top-0 after:bottom-0 after:left-0`}
                        >
                          Conclusion
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <div class="flex flex-col gap-4 mt-10">
                  <h4 class="text-xl lg:text-3xl font-semibold text-[#101010]">
                    Share with friends
                  </h4>
                  <ul class="flex gap-5">
                    <li>
                      <a
                        className="cursor-pointer"
                        href={whatsappShareUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={whatsapp} />
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="https://www.instagram.com/mepassbharat/"
                        target="_blank"
                      >
                        <img src={instagram} />
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="cursor-pointer"
                        href={twitterShareUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={twitter} />
                      </a>
                    </li>
                    <li>
                      <a
                        className="cursor-pointer"
                        href={facebookShareUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebook} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {recentBlog && recentBlog.length > 0 && (
            <div className="mt-10">
              <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
                Other Blogs
              </h2>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={3}
                spaceBetween={40}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider blog-slider !pb-14"
              >
                {recentBlog &&
                  recentBlog.map((singleBlog, index) => (
                    <SwiperSlide key={singleBlog.id + 'recent'}>
                      <Link to={`/our-blog/${singleBlog.slug}`}>
                        <img
                          src={singleBlog.primary_banners_path}
                          alt="img"
                          className="rounded-xl"
                        />
                        <div className="flex flex-col gap-2.5 mt-3">
                          <h2 className="text-[#101010] text-2xl font-semibold">
                            {singleBlog.blog_name}
                          </h2>
                          <p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: singleBlog.introduction
                                  ? singleBlog.introduction.slice(0, 500) +
                                    '...'
                                  : '',
                              }}
                            />
                          </p>
                          <div className="flex items-center gap-4">
                            <p className="text-[#CB2129]  text-[16px]">
                              {convertToDate(singleBlog.created_at)}
                            </p>
                            {singleBlog.reading_time && (
                              <>
                                <i className="fas fa-circle text-[#CB2129] text-[10px]"></i>
                                <p className="text-[#CB2129] text-[16px]">
                                  {singleBlog.reading_time} min read
                                </p>
                              </>
                            )}
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                props.router.navigate(
                                  `/our-blog/${singleBlog.slug}`,
                                );
                              }}
                              className="border border-[#CB2129]  text-[#CB2129] text-base md:text-lg font-normal py-1 md:py-2 px-3 rounded-lg w-[97px]"
                            >
                              Read
                            </button>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default withRouter(OurBlogDetails);
