import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';

const Setting = ({ props, isApiCall, setIsApiCall }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [isUpdateFormData, setIsUpdateFormData] = useState(true);
  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      const requestData = {};
      dispatch(
        getData(requestData, props.router.navigate, api.VIEW_USER_PROFILE),
      );

      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate]);

  if (!isEmpty(data) && !isEmpty(data.profileView) && isUpdateData) {
    setUserDetails(data.profileView);
    setIsUpdateData(false);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      notification: userDetails?.notification || 'No',
      send_email: userDetails?.send_email || 'No',
      send_whatsapp: userDetails?.send_whatsapp || 'No',
      send_facebook: userDetails?.send_facebook || 'No',
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setIsUpdateFormData(true);
      const requestData = {
        notification: values.notification,
        send_email: values.send_email,
        send_whatsapp: values.send_whatsapp,
        send_facebook: values.send_facebook,
      };
      dispatch(
        addUpdateNewData(requestData, props.router.navigate, api.UPDATE_SETTING),
      );
    },
  });
  if (
    !isEmpty(data) &&
    !isEmpty(data.notificationSetting) &&
    isUpdateFormData
  ) {
    setIsUpdateFormData(false);
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
      }}
    >
      <div className="bg-[#F9F9F9] p-[20px] md:p-[40px] rounded-[2px]">
        {/* <div className="hidden md:block">
                    <div className="flex items-center gap-8 justify-end">
                        <button
                            type="submit"
                            className="text-black bg-secondary text-base font-body font-normal border border-secondary w-[214px] h-[41px] flex items-center justify-center rounded-md tracking-[0.2em] md:tracking-[0.3em] uppercase">
                            Save changes
                        </button>
                    </div>
                </div> */}
        <div className="flex items-center justify-between pb-4">
          <h4 className="text-xl font-bold text-[#101010]">All ON</h4>
          <div>
            <label for="toggle" className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle"
                  className="sr-only peer"
                  checked={
                    validation.values['notification'] === 'Yes' &&
                    validation.values['send_email'] === 'Yes' &&
                    validation.values['send_whatsapp'] === 'Yes' &&
                    validation.values['send_facebook'] === 'Yes'
                  }
                  onChange={(e) => {
                    validation.setFieldValue(
                      'notification',
                      e.target.checked ? 'Yes' : 'No',
                    );
                    validation.setFieldValue(
                      'send_email',
                      e.target.checked ? 'Yes' : 'No',
                    );
                    validation.setFieldValue(
                      'send_whatsapp',
                      e.target.checked ? 'Yes' : 'No',
                    );
                    validation.setFieldValue(
                      'send_facebook',
                      e.target.checked ? 'Yes' : 'No',
                    );
                  }}
                />
                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
              </div>
            </label>
          </div>
        </div>
        <div className="border-b border-[#E7E7E7] my-2"></div>
        <div className="flex items-center justify-between py-4">
          <h4 className="text-xl font-medium text-[#101010]">Notification</h4>
          <div>
            <label for="toggle1" className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle1"
                  className="sr-only peer"
                  checked={validation.values['notification'] === 'Yes'}
                  onChange={(e) => {
                    validation.setFieldValue(
                      'notification',
                      e.target.checked ? 'Yes' : 'No',
                    );
                  }}
                />
                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
              </div>
            </label>
          </div>
        </div>
        <div className="flex items-center justify-between py-4">
          <h4 className="text-xl font-medium text-[#101010]">Whatsapp</h4>
          <div>
            <label for="toggle2" className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle2"
                  className="sr-only peer"
                  checked={validation.values['send_whatsapp'] === 'Yes'}
                  onChange={(e) => {
                    validation.setFieldValue(
                      'send_whatsapp',
                      e.target.checked ? 'Yes' : 'No',
                    );
                  }}
                />
                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
              </div>
            </label>
          </div>
        </div>
        <div className="flex items-center justify-between py-4">
          <h4 className="text-xl font-medium text-[#101010]">Email</h4>
          <div>
            <label for="toggle3" className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle3"
                  className="sr-only peer"
                  checked={validation.values['send_email'] === 'Yes'}
                  onChange={(e) => {
                    validation.setFieldValue(
                      'send_email',
                      e.target.checked ? 'Yes' : 'No',
                    );
                  }}
                />
                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
              </div>
            </label>
          </div>
        </div>
        <div className="flex items-center justify-between pt-4">
          <h4 className="text-xl font-medium text-[#101010]">Facebook</h4>
          <div>
            <label for="toggle4" className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle4"
                  className="sr-only peer"
                  checked={validation.values['send_facebook'] === 'Yes'}
                  onChange={(e) => {
                    validation.setFieldValue(
                      'send_facebook',
                      e.target.checked ? 'Yes' : 'No',
                    );
                  }}
                />
                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-center md:justify-end gap-4">
          <button
            type="submit"
            className="text-white btn-liner-gradient text-base font-semibold rounded-lg py-2 px-4 inline-block"
          >
            Save changes
          </button>
        </div>
      </div>
      {/* <div className="md:hidden block p-4">
                <button
                    type="submit"
                    className="text-black bg-secondary text-base font-body font-normal border border-secondary uppercase rounded-lg w-full h-[56px] flex items-center justify-center">
                    Save changes
                </button>
            </div> */}
    </form>
  );
};
Setting.propTypes = {
  props: PropTypes.object,
};
export default Setting;
