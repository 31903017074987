import React from "react"
import footer from '../../assets/images/footer-logo.png'
import { Link } from "react-router-dom"
const ComingSoonFooter = () => {
    return (
      <React.Fragment>
        <div className="bg-black pt-10 pb-10 bg-center bg-blend-multiply">
          <div className="container mx-auto ">
            <div className="block md:flex gap-8">
              <div className="grow">
                <img src={footer} alt="footer" />
                <p className="text-sm md:text-base font-normal text-white font-body mt-3">
                  Revolutionizing Ticketing with Tech for Entire
                  <br />
                  Entertainment Ecosystem
                </p>
              </div>
              <div className="flex-none w-52 mt-4 mt-md-0">
                <ul className="text-white">
                  <li className="text-base font-normal font-body mb-2 hover:text-secondary duration-1000 ease-in-out">
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li className="text-base font-normal font-body mb-2 hover:text-secondary duration-1000 ease-in-out">
                    <Link to="/terms-condition">Terms & Condition</Link>
                  </li>
                  <li className="text-base font-normal font-body mb-2 hover:text-secondary duration-1000 ease-in-out">
                    <Link to="/our-blog">Blogs</Link>
                  </li>
                  {/* <li className='text-base font-normal font-body hover:text-secondary duration-1000 ease-in-out'>
                                    <Link to="/artiest">
                                        Articles
                                    </Link>
                                </li> */}
                </ul>
              </div>
              <div className="flex-none w-52 mt-2 mt-md-0">
                <ul className="text-white">
                  <li className="text-base font-normal font-body mb-2 hover:text-secondary duration-1000 ease-in-out">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="text-sm font-normal font-body mb-2 hover:text-secondary duration-1000 ease-in-out">
                    <Link to="/refund-cancellation-policy">Refund policy</Link>
                  </li>
                  {/* <li className='text-base font-normal font-body mb-2 hover:text-secondary duration-1000 ease-in-out'>
                                    <Link to="/coming-soon">
                                        Support
                                    </Link>
                                </li> */}
                  <li className="text-base font-normal font-body mb-2 hover:text-secondary duration-1000 ease-in-out">
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li className="text-base font-normal font-body hover:text-secondary duration-1000 ease-in-out">
                    <Link to="/business">Business</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="block md:flex gap-8 items-center mt-4">
              <div className="grow">
                <div className="flex items-center justify-start mt-5">
                  <Link
                    to="https://www.facebook.com/mepassbharat"
                    target="_blank"
                  >
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                      <i className="fab fa-facebook-f text-black text-base"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://x.com/i/flow/login?redirect_after_login=%2FMepassbharat"
                    target="_blank"
                  >
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                      <i className="fa-brands fa-x-twitter text-black text-base"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/mepassbharat/"
                    target="_blank"
                  >
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                      <i className="fab fa-linkedin text-black text-base"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://www.instagram.com/mepassbharat/"
                    target="_blank"
                  >
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center">
                      <i className="fab fa-instagram text-black text-base"></i>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* copyright */}
            <div className="border-t border-[#5A5A5A] mt-8 pt-6">
              <p className="text-base font-normal font-body text-[#CBD5E1]">
                © Copyright and all rights reserved by Memorialeye event
                management private limited
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default ComingSoonFooter
