import React, { useState } from 'react';
import { useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import message from '../../constants/message';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import { Link, useParams } from 'react-router-dom';
import gifUrlReview from '../../assets/light_theme_images/Review-rating.gif';

const ReviewPopup = ({
  isVisible,
  toggleReviewModal,
  EventDetail,
  ArtistDetail,
  ratingDetail,
}) => {
  if (!isVisible) return null;
  const dispatch = useDispatch();
  const [eventActiveIndex, setEventActiveIndex] = useState(0);
  const [artistActiveIndex, setArtistActiveIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);

  const closeModal = () => {
    toggleReviewModal(false);
    window.location.reload();
  };

  // const toggleReviewModal2 = () => {
  //   setShowFeedback(!showFeedback);
  //   // if (toggleReviewModal) {
  //   //   toggleReviewModal(false);
  //   // }
  // };
  const validationSchema = Yup.object({
    artistRating: Yup.number().test(
      'rating-required-if-comment-provided',
      'Artist Rating is required if a comment is provided',
      function (value) {
        const { artistComment } = this.parent || {};
        //const { artistRating } = this.parent;
        // If comment is provided, rating must be provided
        console.log(artistComment);
        if (value == 0 && artistComment !== '' && artistComment) {
          return this.createError({
            message: 'Artist Rating is required if a comment is provided',
          });
        }
        return true;
      },
    ),
    artistComment: Yup.string().test(
      'comment-required-if-rating-provided',
      'Artist Comment is required if a rating is provided',
      function (value) {
        const { artistRating } = this.parent;
        // If rating is provided, comment must be provided
        if (artistRating > 0 && !value) {
          return this.createError({
            message: 'Artist Comment is required if a rating is provided',
          });
        }
        return true;
      },
    ),
    eventRating: Yup.number().test(
      'rating-required-if-comment-provided',
      'Event Rating is required if a comment is provided',
      function (value) {
        const { eventComment } = this.parent;
        // If comment is provided, rating must be provided
        if (eventComment && value === 0) {
          return this.createError({
            message: 'Event Rating is required if a comment is provided',
          });
        }
        return true;
      },
    ),

    eventComment: Yup.string().test(
      'comment-required-if-rating-provided',
      'Event Comment is required if a rating is provided',
      function (value) {
        const { eventRating } = this.parent;
        // If rating is provided, comment must be provided
        if (eventRating > 0 && !value) {
          return this.createError({
            message: 'Event Comment is required if a rating is provided',
          });
        }
        return true;
      },
    ),

    // Ensure at least one rating and one comment is provided

    atLeastOneRatingAndComment: Yup.boolean()
      .test(
        'at-least-one-rating-comment',
        'At least one rating and one comment are required.',
        function () {
          const { artistRating, eventRating, artistComment, eventComment } =
            this.parent;
          return (
            (artistRating > 0 && artistComment != '') ||
            (eventRating > 0 && eventComment != '')
          );
        },
      )
      .oneOf([true], 'At least one rating and one comment are required.'),
  });

  const formik = useFormik({
    initialValues: {
      artistComment: '',
      artistRating: 0,
      eventComment: '',
      eventRating: 0,
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const artistRequestData = {
        type: 'Artist',
        rating_id: ArtistDetail?.id,
        comments: values.artistComment,
        rating: values.artistRating,
      };

      const eventRequestData = {
        type: 'Event',
        rating_id: EventDetail?.id,
        comments: values.eventComment,
        rating: values.eventRating,
      };

      console.log('Artist Data:', artistRequestData);
      console.log('Event Data:', eventRequestData);
      if (artistRequestData.rating !== 0) {
        dispatch(addUpdateNewData(artistRequestData, '', api.SAVE_RATING));
      }
      if (eventRequestData.rating !== 0) {
        dispatch(addUpdateNewData(eventRequestData, '', api.SAVE_RATING));
      }
      setShowFeedback(true);
    },
  });

  const handleArtistStarClick = (index) => {
    setArtistActiveIndex(index);
    formik.setFieldValue('artistRating', index); // Setting the artist rating (1-based index)
  };

  const handleEventStarClick = (index) => {
    setEventActiveIndex(index);
    formik.setFieldValue('eventRating', index); // Setting the event rating (1-based index)
  };
  console.log(ratingDetail);
  const validationFeedback = useFormik({
    enableReinitialize: true,

    initialValues: {
      send_artist_feedback: ratingDetail.artist,
      send_event_feedback: ratingDetail.event,
      customParam: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      const requestEventData = {
        type: 'Event',
        favorite_id: EventDetail?.id,
        send_event_feedback: values.send_event_feedback,
      };
      const requestArtistData = {
        type: 'Artist',
        favorite_id: ArtistDetail?.id,
      };
      console.log(values.customParam);
      if (values.customParam == 'Event-add') {
        dispatch(addUpdateNewData(requestEventData, '', api.SAVE_FEEDBACK));
      }
      if (values.customParam == 'Event-del') {
        dispatch(addUpdateNewData(requestEventData, '', api.DELETE_FEEDBACK));
      }
      if (values.customParam == 'Artist-add') {
        dispatch(addUpdateNewData(requestArtistData, '', api.SAVE_FEEDBACK));
      }
      if (values.customParam == 'Artist-del') {
        dispatch(addUpdateNewData(requestArtistData, '', api.DELETE_FEEDBACK));
      }
    },
  });

  return (
    <>
      {!showFeedback ? (
        <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block max-h-[95vh] align-center bg-white border border-[#4D4444] rounded-xl text-left overflow-auto shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[50%] 2xl:w-[30%]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 md:px-10 py-8 relative">
                <button
                  type="button"
                  className="py-2 px-2 absolute top-1 md:top-3 right-2 md:right-5"
                  onClick={closeModal}
                >
                  <i className="fas fa-times text-2xl"></i>
                </button>
                <div className="text-center">
                  <h2 className="text-[#101010] text-xl md:text-2xl font-semibold">
                    Share Your Experience
                  </h2>
                </div>
                {formik.errors.atLeastOneRatingAndComment &&
                  formik.errors.atLeastOneRatingAndComment && (
                    <p className="text-red-500 text-sm">
                      {formik.errors.atLeastOneRatingAndComment}
                    </p>
                  )}
                <div className="border-b-2 border-[#F0BA0E] my-4"></div>

                {/* Artist Review */}
                {ArtistDetail?.id && ratingDetail.artist == 'No' && (
                  <>
                    <p className="text-xl font-normal text-[#101010] mt-5">
                      How would you like to review the{' '}
                      <span className="font-semibold text-[#CB2129]">
                        {ArtistDetail && ArtistDetail.name}
                      </span>
                      ?
                    </p>
                    <div className="rating-box my-5">
                      <div className="stars flex items-center justify-center gap-4">
                        {[...Array(5)].map((_, index) => (
                          <i
                            key={index}
                            className={`fa-solid fa-star text-3xl cursor-pointer ${
                              index + 1 <= artistActiveIndex
                                ? 'star-gradient-color'
                                : 'text-[#D8D8D8]'
                            }`}
                            onClick={() => handleArtistStarClick(index + 1)}
                          />
                        ))}
                      </div>
                      {formik.touched.artistRating &&
                        formik.errors.artistRating && (
                          <p className="text-red-500 text-sm">
                            {formik.errors.artistRating}
                          </p>
                        )}
                    </div>
                    <textarea
                      className="bg-[#F9F9F9] rounded-[4px] p-[11px] w-full placeholder:text-[#616161]"
                      id="artistComment"
                      rows="5"
                      placeholder="Add a note..."
                      value={formik.values.artistComment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.artistComment &&
                      formik.errors.artistComment && (
                        <p className="text-red-500 text-sm">
                          {formik.errors.artistComment}
                        </p>
                      )}
                  </>
                )}
                {ratingDetail.event == 'No' && (
                  <>
                    {/* Event Review */}
                    <p className="text-xl font-normal text-[#101010] mt-5">
                      How would you like to review the{' '}
                      <span className="font-semibold text-[#CB2129]">
                        {EventDetail?.name}
                      </span>
                      ?
                    </p>
                    <div className="rating-box my-5">
                      <div className="stars flex items-center justify-center gap-4">
                        {[...Array(5)].map((_, index) => (
                          <i
                            key={index}
                            className={`fa-solid fa-star text-3xl cursor-pointer ${
                              index + 1 <= eventActiveIndex
                                ? 'star-gradient-color'
                                : 'text-[#D8D8D8]'
                            }`}
                            onClick={() => handleEventStarClick(index + 1)}
                          />
                        ))}
                      </div>
                      {formik.touched.eventRating &&
                        formik.errors.eventRating && (
                          <p className="text-red-500 text-sm">
                            {formik.errors.eventRating}
                          </p>
                        )}
                    </div>
                    <textarea
                      className="bg-[#F9F9F9] rounded-[4px] p-[11px] w-full placeholder:text-[#616161]"
                      id="eventComment"
                      rows="5"
                      placeholder="Add a note..."
                      value={formik.values.eventComment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.eventComment &&
                      formik.errors.eventComment && (
                        <p className="text-red-500 text-sm">
                          {formik.errors.eventComment}
                        </p>
                      )}
                  </>
                )}
                {/* Action Buttons */}
                <div className="grid grid-cols-2 gap-3 md:gap-6 mt-3">
                  <button
                    className="text-[#CB2129] border border-[#CB2129] bg-white text-base md:text-lgtext-center rounded-[4px] font-semibold py-2.5 px-3 md:px-4"
                    onClick={closeModal}
                  >
                    Back
                  </button>
                  <button
                    className="text-[#FFFFFF] btn-liner-gradient text-base md:text-lg text-center rounded-[4px] font-semibold py-2.5 px-3 md:px-4"
                    type="button"
                    onClick={formik.handleSubmit}
                  >
                    Submit Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div
                className="inline-block align-center bg-white border border-[#4D4444] rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[50%] 2xl:w-[30%]"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 md:px-10 py-10  relative">
                  <button
                    type="button"
                    className="py-2 px-2 absolute top-3 right-5"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <i className="fas fa-times text-2xl"></i>
                  </button>
                  <div className="w-[240px] h-[240px] flex justify-center m-auto">
                    <img src={gifUrlReview} alt="rating review" />
                  </div>
                  <div className="mt-3">
                    <h2 className="text-3xl text-[#101010] font-semibold text-center">
                      Thank You For Sharing Your Experience
                    </h2>
                    <div className="border-b-2 border-[#F0BA0E] my-7"></div>
                    <p className="text-xl text-[#101010] font-normal mb-7">
                      Subscribe for future updates
                    </p>
                    {ArtistDetail.name && (
                      <div className="flex mb-6">
                        <div className="flex-1">
                          <h3 className="text-2xl text-[#101010] font-semibold">
                            {ArtistDetail.name}
                          </h3>
                        </div>
                        <div className="flex-none">
                          <div>
                            <label
                              for="toggle4"
                              className="flex items-center cursor-pointer"
                            >
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id="toggle4"
                                  className="sr-only peer"
                                  checked={
                                    validationFeedback.values[
                                      'send_artist_feedback'
                                    ] === 'Yes'
                                  }
                                  onChange={async (e) => {
                                    const isChecked = e.target.checked;

                                    // Update the Formik value
                                    validationFeedback.setFieldValue(
                                      'send_artist_feedback',
                                      isChecked ? 'Yes' : 'No',
                                    );

                                    // Submit the form after the value is updated
                                    try {
                                      validationFeedback.setFieldValue(
                                        'customParam',
                                        e.target.checked
                                          ? 'Artist-add'
                                          : 'Artist-del',
                                      );
                                      await validationFeedback.submitForm(); // `submitForm` ensures Formik state updates are respected
                                    } catch (error) {
                                      console.error(
                                        'Error during form submission:',
                                        error,
                                      );
                                    }
                                  }}
                                />
                                <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                                <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex mb-7">
                      <div className="flex-1">
                        <h3 className="text-2xl text-[#101010] font-semibold">
                          Event Updates
                        </h3>
                      </div>
                      <div className="flex-none">
                        <div>
                          <label
                            for="toggle5"
                            className="flex items-center cursor-pointer"
                          >
                            <div className="relative">
                              <input
                                type="checkbox"
                                id="toggle5"
                                className="sr-only peer"
                                checked={
                                  validationFeedback.values[
                                    'send_event_feedback'
                                  ] === 'Yes'
                                }
                                onChange={(e) => {
                                  validationFeedback.setFieldValue(
                                    'send_event_feedback',
                                    e.target.checked ? 'Yes' : 'No',
                                  );
                                  validationFeedback.setFieldValue(
                                    'customParam',
                                    e.target.checked
                                      ? 'Event-add'
                                      : 'Event-del',
                                  );
                                  validationFeedback.submitForm();
                                }}
                              />
                              <div className="block switch-inner-shadow w-14 h-7 rounded-full bg-gray-300 transition-all duration-500 peer-checked:bg-primary"></div>
                              <div className="absolute top-1 left-1 h-5 w-5 bg-white rounded-full transition-all duration-500 peer-checked:left-8"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        onClick={closeModal}
                        className="text-[#FFFFFF] btn-liner-gradient text-lg text-center rounded-[4px] font-semibold py-2.5 px-4 w-[128px]"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReviewPopup;
