import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import withRouter from '../../components/Common/withRouter';
import { isEmpty, set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getData } from '../../store/AppMaster/actions';
import api from '../../constants/api_constants';
import moment from 'moment/moment';
import { convertToDate } from '../../common/commonFunction';

const OurBlog = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const dispatch = useDispatch();
  const [isApiCalled, setIsApiCalled] = useState(0);
  const [recentBlog, setRecentBlog] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [blogCount, setBlogCount] = useState(0);
  const [getBlogList, setGetBlogList] = useState(false);
  const [filterData, setFilterData] = useState({
    start: 3,
    limit: 12,
    search: '',
    sort_by: '',
    sort_order: 'desc',
    status: 'Active',
    category_id: '',
  });

  useEffect(() => {
    if (isApiCalled == 0) {
      setIsApiCalled(true);
      const requestData = {
        start: 0,
        limit: 3,
        sort_order: 'desc',
        status: 'Active',
      };
      dispatch(getData(requestData, props.router.navigate, api.BLOG_LIST));
    }
  }, [dispatch, isApiCalled]);

  const getAllBlogList = () => {
    setGetBlogList(true);
    dispatch(getData(filterData, props.router.navigate, api.BLOG_LIST));
  };

  if (!isEmpty(data) && !isEmpty(data.blogList) && isEmpty(recentBlog)) {
    setRecentBlog(data.blogList);
    if (data.filter_count > 3) {
      getAllBlogList();
    }
  }

  if (!isEmpty(data) && !isEmpty(data.blogList) && getBlogList) {
    setBlogList((prevBlogs) => [...prevBlogs, ...data.blogList]);
    setBlogCount(data.filter_count > 0 ? data.filter_count - 3 : 0);
    setGetBlogList(false);
  }

  const loadMoreBlog = () => {
    const newStart = filterData.start + 12;
    filterData.start = newStart;
    setFilterData({ ...filterData, start: newStart });
    getAllBlogList();
  };

  return (
    <>
      {recentBlog.length > 0 ? (
        <>
          <section className="bg-white py-14">
            <div className="container">
              <div>
                <h1 className="text-xl lg:text-3xl font-semibold text-[#101010] mb-4 lg:mb-8">
                  Recent Blog
                </h1>
                <Swiper
                  cssMode={true}
                  mousewheel={true}
                  keyboard={true}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                  className="mySwiper gallary-slider blog-slider !pb-10"
                >
                  {recentBlog.map((singleBlog, index) => (
                    <SwiperSlide key={index}>
                      <div className="grid gird-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                        <div>
                          <Link to={`/our-blog/${singleBlog.slug}`}>
                            <img
                              src={singleBlog.primary_banners_path}
                              alt="img"
                              className="aspect-[1.55/1] rounded-xl"
                            />
                          </Link>
                        </div>
                        <div>
                          <div className="flex items-center gap-2 md:gap-4 mb-2 lg:mb-3">
                            <p className="text-[#CB2129] text-[10px] md:text-sm lg:text-base font-medium">
                              {convertToDate(singleBlog.created_at, 'blog')}
                            </p>
                            {singleBlog.reading_time && (
                              <>
                                <i className="fas fa-circle text-[#FFC0C0] text-[8px]"></i>
                                <p className="text-[#CB2129] text-[10px] md:text-sm lg:text-base font-medium">
                                  {singleBlog.reading_time} min read
                                </p>
                              </>
                            )}
                          </div>
                          <Link to={`/our-blog/${singleBlog.slug}`}>
                            <h2 className="text-[#101010] text-base lg:text-3xl font-semibold lg:leading-[48px]">
                              {singleBlog.blog_name}
                            </h2>
                          </Link>
                          <p className="mt-[8px] text-[12px] md:text-base lg:text-xl font-normal leading-[25px]">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: singleBlog.introduction,
                              }}
                            />
                          </p>
                          <div className="mt-4 lg:mt-8">
                            <button
                              onClick={() => {
                                props.router.navigate(
                                  `/our-blog/${singleBlog.slug}`,
                                );
                              }}
                              className="border border-[#CB2129]  text-[#CB2129] text-base md:text-lg font-normal py-1 md:py-2 px-3 rounded-lg w-[97px]"
                            >
                              Read
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
          {blogCount > 0 && (
            <section className="bg-white pt-10 pb-14">
              <div className="container">
                <h2 className="text-xl lg:text-3xl font-semibold text-[#101010] mb-4 lg:mb-8">
                  Other Blogs
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                  {blogList.map((singleBlog, index) => (
                    <div key={singleBlog.id}>
                      <Link to={`/our-blog/${singleBlog.slug}`}>
                        <img
                          src={singleBlog.primary_banners_path}
                          alt="img"
                          className="rounded-xl"
                        />
                        <div className="mt-2 md:mt-3">
                          <h3 className="text-[#101010] text-sm md:text-base lg:text-2xl font-semibold">
                            {singleBlog.blog_name}
                          </h3>
                          <p className="text-[#101010] text-[10px] md:text-base font-normal mt-1">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: singleBlog.introduction,
                              }}
                            />
                          </p>
                          <div className="flex items-center gap-2 md:gap-4 mt-1 md:mt-4">
                            <p className="text-[#CB2129] text-[10px] md:text-sm lg:text-base font-medium">
                              {convertToDate(singleBlog.created_at, 'blog')}
                            </p>
                            {singleBlog.reading_time && (
                              <>
                                <i className="fas fa-circle text-[#FFC0C0] text-[8px]"></i>
                                <p className="text-[#CB2129] text-[10px] md:text-sm lg:text-base font-medium">
                                  {singleBlog.reading_time} min read
                                </p>
                              </>
                            )}
                          </div>
                          <div className="mt-2 md:mt-4">
                            <button
                              onClick={() => {
                                props.router.navigate(
                                  `/our-blog/${singleBlog.slug}`,
                                );
                              }}
                              className="border border-[#CB2129]  text-[#CB2129] text-base md:text-lg font-normal py-1 md:py-2 px-3 rounded-lg w-[97px]"
                            >
                              Read
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
                {blogCount > filterData.limit && (
                  <div className="text-center mt-10">
                    <button
                      onClick={() => {
                        loadMoreBlog();
                      }}
                      className="border border-[#CB2027] text-[#CB2027] me-2 font-body font-medium px-4 py-2 rounded-[5px]"
                    >
                      View more
                    </button>
                  </div>
                )}
              </div>
            </section>
          )}
        </>
      ) : (
        <div className="text-center text-black h-[180px] md:h-[290px] flex items-center justify-center">
          <h2 className="text-2xl font-body font-normal text-center">
            No Blog Found
          </h2>
        </div>
      )}
    </>
  );
};

export default withRouter(OurBlog);
