import {
  GET_CMS_FAIL,
  GET_CMS_SUCCESS,
  CLEAR_CMS_DATA,
  GET_POPULAR_CITY_FAIL,
  GET_POPULAR_CITY_SUCCESS,
  CLEAR_POPULAR_CITY_DATA,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  CLEAR_CATEGORIES_DATA,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAIL,
  CLEAR_LOCATION_DATA,
} from './actionTypes';
import { isEmpty } from 'lodash';
const INIT_STATE = {
  tradingData: [],
  featuredData: [],
  exclusiveOfferData: [],
  eventData: [],
  artistData: [],
  popularCityData: [],
  categoriesWithCity: [],
  categoriesWithoutCity: [],
  seoData: {},
  locationDetails: {},
  error: {},
  loading: false,
};

const home = (state = INIT_STATE, action) => {
  switch (action?.type) {
    case GET_CMS_SUCCESS:
      if (action?.payload?.data?.responseFor && action.payload.data.responseFor == 'trending') {
        return {
          ...state,
          loading: false,
          tradingData: action?.payload?.data?.cmsPage?.trending,
          seoData: {
            seo_title: action.payload.data.cmsPage.seo_title,
            short_description: action.payload.data.cmsPage.short_description,
            seo_image: action.payload.data.cmsPage.seo_image,
          }
        }
      } else if (action?.payload?.data?.responseFor && action.payload.data.responseFor == 'feature') {
        return {
          ...state,
          loading: false,
          featuredData: action?.payload?.data?.cmsPage?.feature
        }
      } else if (action?.payload?.data?.responseFor && action.payload.data.responseFor == 'exclusive_offers') {
        return {
          ...state,
          loading: false,
          exclusiveOfferData: action?.payload?.data?.cmsPage?.exclusive_offers
        }
      } else if (action?.payload?.data?.responseFor && action.payload.data.responseFor == 'events') {
        return {
          ...state,
          loading: false,
          eventData: action?.payload?.data?.cmsPage?.events
        }
      } else if (action?.payload?.data?.responseFor && action.payload.data.responseFor == 'artists') {
        return {
          ...state,
          loading: false,
          artistData: action?.payload?.data?.cmsPage?.artists
        }
      }

    case GET_CMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_CMS_DATA:
      return {
        ...state,
        trending: [],
        featuredData: [],
        exclusiveOfferData: [],
        eventData: [],
        artistData: [],
      };

    case GET_POPULAR_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        popularCityData: action?.payload?.data?.allCities,
      };

    case GET_POPULAR_CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_POPULAR_CITY_DATA:
      return {
        ...state,
        popularCityData: [],
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriesWithCity: action?.payload?.data?.categoriesWithCity,
        categoriesWithoutCity: action?.payload?.data?.categoriesWithoutCity,
      };

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_CATEGORIES_DATA:
      return {
        ...state,
        categoriesWithCity: [],
        categoriesWithoutCity: [],
      };

    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locationDetails: action?.payload?.data?.locationDetails,
      };

    case GET_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_LOCATION_DATA:
      return {
        ...state,
        locationDetails: [],
      };
    default:
      return state;
  }
};

export default home;
