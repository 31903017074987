import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { clearCmsData, getCms } from '../../../store/home/actions';
import { clearEventData, getEvents } from '../../../store/events/actions';

const useEventCategory = (router) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const swiperRef = useRef(null);

  const { events, totalEvents } = useSelector((state) => ({
    events: state.events.events,
    totalEvents: state.events.totalEvents,
  }));

  const { trending, seoData } = useSelector((state) => ({
    trending: state.home.trending,
    seoData: state.home.seoData,
  }));

  let filterData = {
    start: 0,
    limit: 12,
    search: '',
    sort_by: '',
    sort_order: 'desc',
    status: '',
    category_id: '',
  };

  const getListData = useCallback(
    (status, search, start, loadMoreData) => {
      filterData.status = status || '';
      filterData.search = search || filterData.search;
      filterData.start = start || 0;
      filterData.category_slug = router.params.category_name || '';
      filterData.city = router.params.city_name || '';

      dispatch(getEvents(filterData, router.navigate));
    },
    [dispatch, router.navigate],
  );

  useEffect(() => {
    let searchParam = '';
    if (location.search) {
      dispatch(clearEventData());
      const queryParams = new URLSearchParams(location.search);
      searchParam = queryParams.get('search');
      filterData.search = searchParam;
      getListData('', searchParam, '', false);
    } else if (router.params.city_name) {
      dispatch(clearEventData());
      getListData('', searchParam, '', false);
      dispatch(clearCmsData());
      CmsPage();
    } else if (isEmpty(events)) {
      getListData('', searchParam, '', false);
      CmsPage();
    }
  }, [location?.search, router.params.city_name]);

  const CmsPage = useCallback(() => {
    let requestCmsData = {
      section_titles: 'trending',
      is_page: 'Event',
    };
    if (router.params.city_name) {
      requestCmsData.city = router.params.city_name;
    }
    if (router.params.category_name) {
      requestCmsData.category = router.params.category_name;
    }
    dispatch(getCms(requestCmsData, router.navigate));
  }, [dispatch, router.navigate]);

  const loadMore = () => {
    const start = filterData.start + filterData.limit;
    filterData.start = start;
    getListData(filterData.status, filterData.search, start, true);
  };

  const handlePageClick = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  return {
    events,
    totalEvents,
    trending,
    swiperRef,
    loadMore,
    handlePageClick,
    seoData,
  };
};

export default useEventCategory;
