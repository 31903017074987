import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import banner from '../../assets/images/sign-up.png';
import eventDefaultBanner from '../../assets/images/default/event-image.jpg';
import cardImg from '../../assets/light_theme_images/event-category-banner-mg.png';
import googleIcon from '../../assets/light_theme_images/google.svg';
import instagramIcon from '../../assets/light_theme_images/instagram-icon.svg';
import logo from '../../assets/light_theme_images/logo.svg';
import labelConstant from '../../constants/label';
import regex from '../../constants/regex';
import OTP from '../Common/OTP';
import Text from '../Common/Text';
import api from '../../constants/api_constants';
import {
  addUpdateNewData,
  getData,
  loginUser,
  clearData,
} from '../../store/AppMaster/actions';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
// import required modules
import { EffectFade, Autoplay } from 'swiper/modules';
import { useGoogleLogin } from '@react-oauth/google';
const Login = ({
  validation,
  isOtpSent,
  validationOtp,
  resendOtp,
  recaptchaResponse,
  props,
  cmsData,
  loading,
}) => {
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes = 120 seconds
  const [showButton, setShowButton] = useState(false);
  const dispatch = useDispatch();
  const googleLoginCall = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log('Login Success:', tokenResponse);
      // Use tokenResponse.access_token to make API requests
      const requestData = {
        access_token: tokenResponse.access_token,
      };
      dispatch(
        loginUser(requestData, props.router.navigate, api.GOOGLE_SIGNUP),
      );
      //fetchUserProfile(tokenResponse.access_token);
    },
    onError: (error) => {
      console.error('Login Failed:', error);
    },
  });

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setShowButton(true);
    }
  }, [timeLeft]);

  const handleResendOtp = (e) => {
    e.preventDefault();
    resendOtp(); // Call the resendOtp function
    setShowButton(false); // Hide the button
    setTimeLeft(120); // Reset the timer to 2 minutes
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };
  return (
    <>
      <div>
        <div className="block lg:flex items-center justify-center md:h-screen pt-24 pb-28 xl:pt-0 xl:pb-0">
          <div className="relative block lg:flex items-center justify-center px-5 md:px-7 lg:px-0">
            <div className="w-full lg:w-[450px] flex-none md:mr-14">
              <div className="text-center flex flex-col items-center">
                <div className="w-[163.63px]">
                  <img
                    src={logo}
                    alt=""
                    className="m-auto mb-14 inline-block lg:hidden"
                  />
                </div>
              </div>
              <div>
                <Swiper
                  spaceBetween={30}
                  effect={'fade'}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  modules={[EffectFade, Autoplay]}
                  className="mySwiper lg:h-[567px] 2xl:h-[650px]"
                >
                  {!isEmpty(cmsData) &&
                    cmsData.map((singleCms, index) => (
                      <>
                        {index < 3 && !isEmpty(singleCms.event) ? (
                          <SwiperSlide>
                            <div className="relative w-full h-full aspect-[9/16] rounded-3xl overflow-hidden flex items-center justify-center px-[90px] py-[70px] md:py-[160px]">
                              <div
                                className="absolute inset-0 -z-10"
                                style={{
                                  backgroundImage: `url(${
                                    singleCms.event.banner_image
                                      ? singleCms.event.banner_image
                                      : eventDefaultBanner
                                  })`,
                                  filter: 'blur(30px)',
                                  opacity: '0.5px',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                }}
                              ></div>
                              <div className="absolute inset-0 bg-[#00000033] -z-10"></div>
                              <img
                                src={
                                  singleCms.event.banner_image
                                    ? singleCms.event.banner_image
                                    : eventDefaultBanner
                                }
                                alt="img"
                                className="aspect-[9/16] rounded-xl"
                              />
                            </div>
                          </SwiperSlide>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                </Swiper>
              </div>
            </div>
            <div className="w-full lg:w-[500px] flex-none mt-14 lg:mt-0">
              <div>
                <div className="text-center flex flex-col items-center">
                  <div className="w-[163.63px]">
                    <img
                      src={logo}
                      alt=""
                      className="m-auto mb-14 lg:inline-block hidden"
                    />
                  </div>
                </div>
                {isOtpSent ? (
                  <>
                    <div className="text-center">
                      <h2 className="text-base md:text-4xl text-[#101010] font-semibold">
                        OTP Verification
                      </h2>
                      <p className="text-sm md:text-base font-normal text-[#101010] mt-2">
                        Enter the OTP sent to your mobile to verify and continue
                        your journey with us!
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <h2 className="text-base md:text-4xl text-[#101010] font-semibold">
                        Welcome back to Mepass!
                      </h2>
                      <p className="text-sm md:text-base font-normal text-[#101010] mt-2">
                        Join our vibrant community and turn every moment into an
                        unforgettable memory
                      </p>
                    </div>
                  </>
                )}

                <div className="mt-6">
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        isOtpSent
                          ? validationOtp.handleSubmit()
                          : validation.handleSubmit();
                      }}
                    >
                      {isOtpSent ? (
                        <>
                          <div>
                            <div className="flex items-start justify-between">
                              <OTP
                                validation={validationOtp}
                                name="otp"
                                label={labelConstant.OTP}
                                isRequire={true}
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            disabled={validationOtp.isSubmitting ? true : false}
                            className="btn-liner-gradient  text-white text-base font-medium md:py-4 py-2 px-8 rounded-[5px] md:rounded-[10px] w-full mt-5"
                          >
                            {' '}
                            Verify
                          </button>
                          <div className="text-center mt-5">
                            {showButton ? (
                              <button
                                onClick={handleResendOtp}
                                className="text-[#101010] text-base font-medium"
                              >
                                Didn’t receive OTP?{' '}
                                <span className="font-bold text-[#CB2129]">
                                  Resend OTP
                                </span>
                              </button>
                            ) : (
                              <p className="text-[#101010] text-base font-body font-medium">
                                Resend OTP in {formatTime(timeLeft)}
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <Text
                            validation={validation}
                            name="mobile"
                            label="Mobile Number"
                            isRequire={true}
                            placeholder="Mobile Number"
                            regexCompare={regex.MOBILE}
                            maxLength={10}
                            className="bg-[#F9F9F9] text-[#CB2129] text-base font-semibold rounded-[4px] placeholder:text-[#616161] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-4 px-5"
                          />

                          <button
                            type="submit"
                            disabled={validation.isSubmitting ? true : false}
                            className="btn-liner-gradient  text-white text-base font-medium py-2 md:py-4 px-4 md:px-8 rounded-[8px] font-body w-full mt-5"
                          >
                            Request OTP
                          </button>
                        </>
                      )}
                    </form>
                  </div>
                  {isOtpSent ? (
                    <></>
                  ) : (
                    <>
                      <div className="mt-8 text-center">
                        {props.router.location.state &&
                        props.router.location.state.is_login_page == 'Yes' ? (
                          <p className="text-[#101010] text-lg font-normal font-body">
                            Don't have an account?{' '}
                            <Link
                              to="/sign-up"
                              className="text-[#CB2129] font-bold"
                            >
                              Sign Up
                            </Link>
                          </p>
                        ) : (
                          <p className="text-[#101010] text-sm md:text-lg font-normal font-body">
                            Don't have an account?{' '}
                            <Link
                              to="/sign-up"
                              state={{ previousPage: 'Login' }}
                              className="text-[#CB2129] font-bold"
                            >
                              Sign Up
                            </Link>
                          </p>
                        )}
                      </div>
                      <div className="mt-8 border-b border-[#E0E0E0] relative">
                        <div className="bg-white px-[10px] text-[#616161] text-sm font-light absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 -mt-[1px]">
                          Or
                        </div>
                      </div>
                      <div className="flex justify-center gap-1.5 md:gap-6 mt-10">
                        <button
                          onClick={() => googleLoginCall()}
                          className="socail-border-liner-gradient text-[#101010] text-[12px] md:text-base font-normal py-1 md:py-3 px-2 xl:px-4"
                        >
                          <img
                            src={googleIcon}
                            alt="icon"
                            className="inline-block mr-1 md:mr-2"
                          />
                          Log In with Google
                        </button>
                        {/* <button className="socail-border-liner-gradient text-[#101010] text-[12px] md:text-base font-normal py-1 md:py-3 px-1 xl:px-4">
                          <img
                            src={instagramIcon}
                            alt="icon"
                            className="inline-block mr-1 md:mr-2"
                          />
                          Log In with Instagram
                        </button> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Login.propTypes = {
  validation: PropTypes.object,
  recaptchaRef: PropTypes.object,
  isOtpSent: PropTypes.bool,
  validationOtp: PropTypes.object,
  resendOtp: PropTypes.func,
};
export default Login;
