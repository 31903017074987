import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import withRouter from '../Common/withRouter';
import logo from '../../assets/light_theme_images/logo.svg';
import notification from '../../assets/images/small/notification.png';
import user from '../../assets/images/default/user-image.png';
import locationIcon from '../../assets/light_theme_images/location.svg';
import downArrow from '../../assets/light_theme_images/down-arrow.svg';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getData } from '../../store/AppMaster/actions';
import {
  getCategories,
  getCms,
  getLocation,
  getPopularCity,
} from '../../store/home/actions';
import api from '../../constants/api_constants';
import SelectCityModal from './SelectCityModal';
import SidebarMenu from './SidebarMenu';
import ProfileMenu from './ProfileMenu';
import searchIcon from '../../assets/light_theme_images/search.svg';
import calendarGradient from '../../assets/light_theme_images/calendar-gradient.svg';
import { pushClickEvent } from '../../seo/gtevents';
import SearchModal from './SearchModal';

const Header = (props) => {
  const authUserDetails = JSON.parse(
    localStorage.getItem('publicMePassUserDetails'),
  );

  const [activeItem, setActiveItem] = useState('/home');
  const fullUrl = window.location.href;
  const appUrl = fullUrl.split('/');
  const lastSegment = appUrl[appUrl.length - 1];
  const dispatch = useDispatch();
  const [searchEvent, setSearchEvent] = useState('');

  const { popularCityData, categoriesWithCity, trending, locationDetails } =
    useSelector((state) => ({
      popularCityData: state.home.popularCityData,
      categoriesWithCity: state.home.categoriesWithCity,
      trending: state.home.trending,
      locationDetails: state.home.locationDetails,
    }));

  const [sidebarArray, setSidebarArray] = useState([]);
  const currentLocation = useLocation();
  const currentRoute = currentLocation.pathname;

  const CmsPage = () => {
    let requestCmsData = {
      section_titles: 'trending',
      is_page: 'Event',
    };
    if (props.router.params.city_name) {
      requestCmsData.city = props.router.params.city_name;
    }
    dispatch(clearData());
    dispatch(getCms(requestCmsData, props.router.navigate));
  };

  const getCityCategory = (city) => {
    setSidebarArray([]);
    const requestData = {
      city: city,
    };
    dispatch(clearData());
    dispatch(getCategories(requestData, props.router.navigate));
  };
  const popularCityList = () => {
    const requestData = {};
    dispatch(clearData());
    dispatch(getPopularCity(requestData, props.router.navigate));
  };

  useEffect(() => {
    if (
      props.router.params.city_name !=
      localStorage.getItem('publicUserSelectMePassCity')
    ) {
      getCityCategory(props.router.params.city_name);
      CmsPage();
    }
    if (isEmpty(popularCityData)) {
      popularCityList();
    }
    if (currentRoute == '/home' || currentRoute == '/') {
      setShowCityModal(true);
    }
  }, [props.router.params.city_name]);

  useEffect(() => {
    if (!isEmpty(popularCityData)) {
      const categoryArray = [];
      sidebarArray.push({ name: 'Home', link: '/home', submenu: [] });
      let link = '/events';
      if (props.router.params.category_name && props.router.params.city_name) {
        link = `/city/${props.router.params.city_name}/category/${props.router.params.category_name}/events`;
      } else if (props.router.params.category_name) {
        link = `/category/${props.router.params.category_name}/events`;
      } else if (props.router.params.city_name) {
        link = `/city/${props.router.params.city_name}/events`;
      }
      sidebarArray.push({ name: 'Events', link: link, submenu: [] });

      if (!isEmpty(categoriesWithCity)) {
        categoriesWithCity.map((item) => {
          let link = '';
          if (props.router.params.city_name) {
            link = `/city/${props.router.params.city_name}/category/${item.slug}`;
          } else {
            link = `/category/${item.slug}`;
          }
          item.link = link;
          categoryArray.push(item);
        });
      }

      if (!isEmpty(categoryArray)) {
        categoryArray.map((item, itemIndex) => {
          let categoryName =
            item.name.charAt(0).toUpperCase() + item.name.slice(1);
          if (itemIndex < 2) {
            sidebarArray.push({
              name: categoryName,
              link: item.link,
              submenu: [],
            });
          } else if (itemIndex == 2) {
            sidebarArray.push({
              name: 'More',
              link: '#',
              submenu: [{ name: categoryName, link: item.link }],
            });
          } else if (itemIndex > 2) {
            sidebarArray[4] &&
              sidebarArray[4].submenu &&
              sidebarArray[4].submenu.push({
                name: categoryName,
                link: item.link,
              });
          }
        });
      }
    }
  }, [popularCityData]);

  const [sidebarToggle, setSidebarToggle] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarToggle(true);
  };

  const handleNavLinkClick = () => {
    setSidebarToggle(false); // Close sidebar when a link is clicked
  };

  const [isShowCityModal, setShowCityModal] = useState(false);
  const [isShowSearchModal, setShowSearchModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loaderLocation, setLoaderLocation] = useState(false);

  const handleCityModal = () => {
    setShowCityModal(!isShowCityModal);
  };

  const handleOnSelect = (item) => {
    setSelectedItem(item.name); // Set the selected item as active
    getCityCategory(item.name);
    localStorage.setItem('publicUserSelectMePassCity', item.name);
    setShowCityModal(false);
    if (lastSegment === 'events') {
      let link = '/events';
      if (props.router.params.category_name) {
        link = `/city/${item.name}/category/${props.router.params.category_name}/events`;
      } else {
        link = `/city/${item.name}/events`;
      }
      props.router.navigate(link);
    } else {
      props.router.navigate(`/city/${item.name}`);
    }
  };

  // State to track which submenu is open
  const [openSubmenu, setOpenSubmenu] = useState(null);

  // Function to toggle submenu visibility
  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  // Function to set active menu or submenu
  const handleActiveItem = (path) => {
    setActiveItem(path);
  };

  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  // Function to toggle submenu
  const toggleSubmenuIndex = (index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };
  const navigate = useNavigate();
  const handleEventSearchChange = () => {
    setShowSearchModal(false);
    if (searchEvent) {
      navigate(`/events?search=${encodeURIComponent(searchEvent)}`);
    } else {
      window.location.href = '/events';
    }
  };

  const [location, setLocation] = useState({
    city: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    postal_code: '',
  });
  const [errorLocation, setErrorLocation] = useState('');
  const detectYourLocation = () => {
    // setLoaderLocation(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const requestData = {
            latitude: latitude,
            longitude: longitude,
            user_id: authUserDetails ? authUserDetails.id : '',
          };
          dispatch(getLocation(requestData, props.router.navigate));
        },
        (err) => {
          setLoaderLocation(false);
          //setErrorLocation("Failed to fetch city data")
        },
      );
    } else {
      setLoaderLocation(false);
      setError('Geolocation is not supported by your browser.');
    }
  };

  useEffect(() => {
    if (locationDetails && locationDetails.city) {
      //setLoaderLocation(false);
      setLocation({
        city: locationDetails.city,
        state: locationDetails.state,
        country: locationDetails.country,
        postal_code: locationDetails.postal_code,
      });
      setSelectedItem(locationDetails.city); // Set the selected item as active
      getCityCategory(locationDetails.city);
      localStorage.setItem('publicUserSelectMePassCity', locationDetails.city);
      if (locationDetails.city) {
        if (popularCityData.length > 0) {
          //filter popular city
          const popularCity = popularCityData.filter(
            (item) => item.name === locationDetails.city,
          );
          if (popularCity.length > 0) {
            if (popularCity[0].cmspage == 'Yes') {
              setSelectedItem(locationDetails.city); // Set the selected item as active
              getCityCategory(locationDetails.city);
              props.router.navigate(`/city/${locationDetails.city}`);
            } else {
              setSelectedItem(locationDetails.city); // Set the selected item as active
              getCityCategory(locationDetails.city);
              props.router.navigate(`/city/${locationDetails.city}/events`);
            }
          }
        }
      }
      setShowCityModal(false);
    }
  }, [locationDetails]);

  return (
    <React.Fragment>
      <div className="relative z-10">
        <nav className="bg-white w-full header-border-liner-gradient">
          <div className="container mx-auto py-[15px]">
            <div className="relative flex md:h-16 items-center justify-between gap-3 md:gap-14">
              <div className="w-[163.63px]">
                <Link to="/home">
                  <img className="w-auto" src={logo} alt="Your Company name" />
                </Link>
              </div>
              <div className="flex-auto items-center w-full">
                <div className="hidden lg:block">
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <div className="flex gap-6 justify-between">
                        <div className="w-[350px] xl:w-[480px] border border-[#CB2129] rounded-lg px-3 py-[10px]">
                          <div className="flex items-center">
                            <i class="fa-solid fa-magnifying-glass text-[#737373] mr-1"></i>
                            <input
                              onClick={() => {
                                setShowSearchModal(true),
                                  setShowCityModal(false);
                              }}
                              type="text"
                              className="text-[#101010] text-base font-medium border border-white bg-transparent rounded-lg  placeholder:text-[#737373] focus-visible:border-white focus-visible:outline-none w-full"
                              placeholder="search events/concerts/gigs"
                            />
                          </div>
                        </div>
                        <div className="relative">
                          <div className="select-location-border-liner-gradient px-3 py-3 relative">
                            <div
                              className="flex items-center cursor-pointer relative z-10"
                              onClick={() => {
                                setShowCityModal(true);
                                setShowSearchModal(false);
                              }}
                            >
                              <div
                                className="flex-1"
                                onClick={() => {
                                  pushClickEvent(
                                    'Location Select',
                                    'location_select',
                                    'Select your City',
                                    {},
                                  );
                                }}
                              >
                                <div className="flex items-center">
                                  <img
                                    src={locationIcon}
                                    alt="img"
                                    className="inline-block mr-1"
                                  />
                                  <p className="text-sm md:text-base text-black font-medium">
                                    {props.router.params.city_name
                                      ? props.router.params.city_name
                                      : localStorage.getItem(
                                          'publicUserSelectMePassCity',
                                        )
                                      ? localStorage.getItem(
                                          'publicUserSelectMePassCity',
                                        )
                                      : 'Select your City'}
                                  </p>
                                </div>
                              </div>
                              <div className="flex-none">
                                <img
                                  src={downArrow}
                                  className="inline-block ml-3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-none">
                      <div className="flex gap-2">
                        <ProfileMenu
                          notification={notification}
                          authUserDetails={authUserDetails}
                          user={user}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-end">
                    <div className="block lg:hidden">
                      <div className="flex">
                        <div className="relative mr-2">
                          <div
                            onClick={() => setShowSearchModal(true)}
                            className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                          >
                            <i className="fa-solid fa-magnifying-glass text-[#101010]"></i>
                          </div>
                        </div>
                        <div className="relative mr-2">
                          <div
                            className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                            onClick={() => {
                              setShowCityModal(true);
                            }}
                          >
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => {
                                setShowCityModal(true);
                              }}
                            >
                              <i className="fa-solid fa-location-dot text-[#101010]"></i>
                              {/* <p className="text-sm md:text-lg text-black font-normal">
                                {props.router.params.city_name
                                  ? props.router.params.city_name
                                  : ""}
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <ProfileMenu
                          notification={notification}
                          authUserDetails={authUserDetails}
                          user={user}
                        />
                      </div>
                    </div>
                    {/* {sidebarToggle && ( */}
                    <SidebarMenu
                      sidebarArray={sidebarArray}
                      toggleSubmenu={toggleSubmenu}
                      handleActiveItem={handleActiveItem}
                      activeItem={activeItem}
                      openSubmenu={openSubmenu}
                      isResponsive={true}
                      handleSidebarToggle={handleSidebarToggle}
                      sidebarToggle={sidebarToggle}
                      handleNavLinkClick={handleNavLinkClick}
                      toggleSubmenuIndex={toggleSubmenuIndex}
                      openSubmenuIndex={openSubmenuIndex}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* modal city */}
      {isShowCityModal && !isEmpty(popularCityData) ? (
        <SelectCityModal
          isShowCityModal={isShowCityModal}
          setShowCityModal={setShowCityModal}
          cityList={popularCityData}
          handleOnSelect={handleOnSelect}
          props={props}
          detectYourLocation={detectYourLocation}
          errorLocation={errorLocation}
          loaderLocation={loaderLocation}
          setShowSearchModal={setShowSearchModal}
          authUserDetails={authUserDetails}
        />
      ) : null}

      {/* Search modal */}
      {isShowSearchModal ? (
        <SearchModal
          logo={logo}
          calendarGradient={calendarGradient}
          cmsData={trending}
          notification={notification}
          authUserDetails={authUserDetails}
          user={user}
          setShowSearchModal={setShowSearchModal}
          setShowCityModal={setShowCityModal}
          searchEvent={searchEvent}
          setSearchEvent={setSearchEvent}
          handleEventSearchChange={handleEventSearchChange}
        />
      ) : null}
    </React.Fragment>
  );
};

export default withRouter(Header);
