import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import { Link } from 'react-router-dom';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import { convertToAmPm } from '../../common/commonFunction';

const PassDetails = ({ passDetails, textColor, backGroundColor }) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-6 p-[15px]">
        <div>
          <img
            src={
              passDetails &&
              passDetails.event_pass &&
              passDetails.event_pass.image
                ? passDetails.event_pass.image
                : passDetails &&
                  passDetails.event &&
                  passDetails.event.banner_image
            }
            alt="img"
            className="inline-block aspect-[9/16] object-cover rounded-lg"
          />
        </div>
        <div className="col-span-2">
          <div className="grid grid-flow-row-dense grid-row-5 h-full content-stretch	">
            <div className="row-span-4">
              <h2
                style={{ color: textColor }}
                className={`text-lg text-[${textColor}] font-body font-semibold capitalize mb-2`}
              >
                {passDetails && passDetails.event && passDetails.event.name}
              </h2>
              <p
                style={{ color: textColor }}
                className={`text-base font-normal mb-[2px] text-[${textColor}]`}
              >
                <i class="fas fa-calendar-minus text-[#5A5A5A] mr-2"></i>
                {passDetails &&
                  passDetails.event_pass &&
                  passDetails.event_pass.pass_date}
              </p>
              <p
                style={{ color: textColor }}
                className={`text-base font-normal text-[${textColor}]`}
              >
                <i class="fas fa-clock text-[#5A5A5A] mr-2"></i>
                {passDetails &&
                passDetails.event_date &&
                passDetails.event_date.start_time
                  ? convertToAmPm(passDetails.event_date.start_time)
                  : ''}
              </p>
            </div>
            <div className="row-span-1">
              <div className="flex items-center mb-1">
                <div className="flex-1">
                  <h2
                    className={`text-lg text-primary font-body font-semibold capitalize`}
                  >
                    Gold
                  </h2>
                </div>
                <div className="flex-none">
                  <div className="flex items-center gap-1">
                    <p className="text-sm text-[#5A5A5A]">Qty.</p>
                    <p className="text-lg text-primary font-normal text-center">
                      10
                    </p>
                  </div>
                </div>
              </div>
              <Link
                to=""
                className="text-base font-normal text-[#5A5A5A] text-center underline decoration-1"
              >
                View more details<i class="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
            {/* <button style={{ color: textColor }} className={`bg-[${backGroundColor}] text-[${textColor}] px-[15px] mt-2 py-[8px] rounded-full border border-[#FFD1D3] font-body font-medium text-sm`}>
                        {passDetails && passDetails.event_pass.pass_type_name}
                    </button> */}
          </div>
        </div>
      </div>
    </>
  );
};
PassDetails.propTypes = {};
export default PassDetails;
