import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import { convertToAmPm } from '../../common/commonFunction';
import { pushClickEvent } from '../../seo/gtevents';

const SelectDateTime = ({
  dropdownRef,
  toggleDropdown,
  isDropdownOpen,
  optionGroupDate,
  selectedDate,
  handleDateDropdown,
  sessionPassCount,
  selectedTime,
  handleTimeDropdown,
  optionGroupTime,
}) => {
  return (
    <>
      <div className="bg-[#F9F9F9] p-4 md:p-7 rounded-lg mt-7">
        <h3 className="text-[22px] lg:text-3xl text-[#101010] font-semibold">
          Pick Your Date
        </h3>
        <div className="flex flex-wrap gap-6 mt-4">
          {optionGroupDate &&
            optionGroupDate.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  handleDateDropdown(item);
                  pushClickEvent(
                    'Selected Date',
                    'PassPurchase',
                    item.label,
                    {},
                  );
                }}
                className={`${
                  selectedDate && selectedDate.value === item.value
                    ? 'btn-liner-gradient text-white text-lg text-center font-semibold py-2 px-4 rounded-lg'
                    : 'border border-[#101010] text-[#101010] text-lg text-center font-semibold py-2 px-4 rounded-lg'
                }`}
              >
                {item.label}
              </button>
            ))}
          {optionGroupDate.length == 0 && (
            <p className="text-[#101010] text-lg text-center font-semibold py-2 px-4 rounded-lg">
              No Date Available
            </p>
          )}
        </div>
      </div>
      {optionGroupTime.length > 0 && (
        <div className="bg-[#F9F9F9] p-4 md:p-7 rounded-lg mt-7">
          <h3 className="text-[22px] lg:text-3xl text-[#101010] font-semibold">
            Pick Your Time
          </h3>
          <div className="flex flex-wrap gap-6 mt-4">
            {optionGroupTime &&
              optionGroupTime.map((items, indexs) => (
                <button
                  key={indexs}
                  onClick={() => {
                    handleTimeDropdown(items);
                  }}
                  className={`${
                    selectedTime && selectedTime.value === items.value
                      ? 'btn-liner-gradient text-white text-lg text-center font-semibold py-2 px-4 rounded-lg'
                      : 'border border-[#101010] text-[#101010] text-lg text-center font-semibold py-2 px-4 rounded-lg'
                  }`}
                >
                  {convertToAmPm(items.label)}
                </button>
              ))}
            {optionGroupTime.length == 0 && (
              <p className="text-[#101010] text-lg text-center font-semibold py-2 px-4 rounded-lg">
                No Time Available
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
SelectDateTime.propTypes = {};
export default SelectDateTime;
