import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import messageConstant from '../../constants/message';
import labelConstants from '../../constants/label';
import regex from '../../constants/regex';
import Text from '../../components/Common/Text';
import icon from '../../assets/images/small/info-light.png';
import icon2 from '../../assets/images/small/phone-pay.png';
import img from '../../assets/light_theme_images/event-details-items.png';
import offerIcon from '../../assets/light_theme_images/apply-offer-icon.svg';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import bannerImageDefault from '../../assets/images/default/event-image.jpg';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUpdateNewDataMultipleForm,
  getData,
} from '../../store/AppMaster/actions';
import api from '../../constants/api_constants';
import { pushMakePayment } from '../../seo/gtevents';
import { Link } from 'react-router-dom';

const PaymentOption = ({
  event,
  paymentDetails,
  passList,
  processedToPay,
  setIsProcessedToPay,
  passPaymentCalculation,
  passOffers,
  handlePromoCodeApply,
  removePromoCode,
  promoCode,
  setPromoCode,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const [isUpdateFormData, setIsUpdateFormData] = useState(false);
  const gstAmount = passList.reduce(
    (total, pass) => (total + pass.quantity > 0 ? pass.igst : 0),
    0,
  );
  const gstAmountQty =
    gstAmount * passList.reduce((total, pass) => total + pass.quantity, 0);
  const [currentStep, setCurrentStep] = useState(1);
  const authData = JSON.parse(localStorage.getItem('publicMePassUserDetails'));
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: authData && authData.name,
      mobile: authData && authData.mobile,
      email: authData && authData.email,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(messageConstant.FULL_NAME_REQUIRED)
        .min(regex.NAME_MIN, messageConstant.FULL_NAME_MIN_LENGTH)
        .max(regex.NAME_MAX, messageConstant.FULL_NAME_MAX_LENGTH),
      mobile: Yup.string()
        .required(messageConstant.MOBILE_REQUIRED)
        .matches(/^[0-9]+$/, messageConstant.MOBILE_INVALID)
        .min(10, messageConstant.MOBILE_MIN_LENGTH)
        .max(10, messageConstant.MOBILE_MAX_LENGTH),
      email: Yup.string()
        .required(messageConstant.EMAIL_REQUIRED)
        .email(messageConstant.EMAIL_INVALID),
    }),
    onSubmit: (values) => {
      setIsUpdateFormData(true);
      const requestData = {
        name: values.name,
        mobile: values.mobile,
        email: values.email,
      };
      dispatch(
        addUpdateNewDataMultipleForm(requestData, '', api.UPDATE_USER_PROFILE),
      );
    },
  });

  if (!isEmpty(data) && !isEmpty(data.profileUpdate) && isUpdateFormData) {
    let publicMePassUserDetails = JSON.parse(
      localStorage.getItem('publicMePassUserDetails'),
    );
    if (data.profileUpdate.id == publicMePassUserDetails.id) {
      publicMePassUserDetails.name = data.profileUpdate.name;
      publicMePassUserDetails.email = data.profileUpdate.email;
      publicMePassUserDetails.mobile = data.profileUpdate.mobile;
      localStorage.setItem(
        'publicMePassUserDetails',
        JSON.stringify(publicMePassUserDetails),
      );
    }
    setIsUpdateFormData(false);
    if (currentStep < 4) setCurrentStep(currentStep + 1);
  }
  const [isShowCancelModal, setShowCancelModal] = useState(false);
  const [isShowCancelHomeModal, setShowCancelHomeModal] = useState(false);
  const handleCancelModal = () => {
    setShowCancelModal(!isShowCancelModal);
  };
  const handleCancelHomeModal = () => {
    setShowCancelHomeModal(!isShowCancelHomeModal);
    setShowCancelModal(false);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
  });
  const handleNext = () => {
    if (currentStep < 4) setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    alert('Form Submitted: ' + JSON.stringify(formData, null, 2));
  };

  const steps = [
    { label: 'Information', id: 1 },
    { label: 'Order Summary', id: 2 },
    { label: 'Offers & Payment', id: 3 },
  ];

  return (
    <>
      <div className="bg-white pt-6 md:pt-14 pb-14">
        <div className="container">
          <div className="flex items-center justify-between mb-[40px]">
            <h2 className="text-2xl md:text-3xl text-[#101010] font-semibold text-center">
              Checkout
            </h2>
            <button
              onClick={() => setIsProcessedToPay(false)}
              className="text-end btn-liner-gradient text-white text-base font-normal tracking-[.25em] py-3 px-8 rounded font-body  hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase mt-3 md:mt-0"
            >
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="w-full">
              {/* Step Indicator */}
              <div className="flex justify-between items-center mt-8">
                {steps.map((step, index) => (
                  <div key={step.id} className="flex-1">
                    <div className="flex h-[75px] md:h-auto relative">
                      <div className="flex flex-col items-center text-center w-full relative z-10">
                        {/* Step Circle */}
                        <div
                          className={`flex items-center justify-center w-[24px] md:w-10 h-[24px] md:h-10 rounded-full border-2 ${
                            currentStep >= step.id
                              ? 'bg-[#CB2129] border-[#CB2129] text-white active-step-shadow'
                              : 'bg-white border-white steps-shadow'
                          }`}
                        >
                          {currentStep > step.id ? (
                            <i className="fas fa-check text-white"></i>
                          ) : (
                            <>
                              {currentStep === step.id ? (
                                <i className="fas fa-check text-white"></i>
                              ) : (
                                <i className="fas fa-circle text-[#CB2129]"></i>
                              )}
                            </>
                          )}
                        </div>
                        {/* Step Label */}
                        <p
                          className={`mt-2 text-[12px] md:text-xl font-medium ${
                            currentStep === step.id
                              ? 'text-[#101010]'
                              : 'text-[#9C9C9C]'
                          }`}
                        >
                          {step.label}
                        </p>
                      </div>
                      {index < steps.length - 1 && (
                        <div
                          className={`w-full h-1 absolute left-1/2 top-[16%] md:top-[25%] ${
                            currentStep > step.id
                              ? 'bg-[#CB2129]'
                              : 'bg-[#F9F9F9]'
                          }`}
                        ></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* Form Content */}
              <div className="p-[12px] md:p-[40px] bg-[#F9F9F9] rounded-[12px] mt-[40px]">
                {currentStep === 1 && (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-10">
                        <Text
                          validation={validation}
                          name="name"
                          label={labelConstants.FULL_NAME}
                          isRequire={true}
                          regexCompare={regex.NAME}
                          className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
                        />

                        <Text
                          validation={validation}
                          name="mobile"
                          label={labelConstants.MOBILE}
                          isRequire={true}
                          regexCompare={regex.MOBILE}
                          maxLength={10}
                          readOnly={authData && authData.mobile ? true : false}
                          className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
                        />

                        <Text
                          className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
                          validation={validation}
                          name="email"
                          label="Email"
                          isRequire={true}
                        />
                      </div>
                      <p className="text-[#5D5D5D] text-[12px] font-normal mt-5">
                        Please note your pass will be sent to the above given
                        mobile number.
                      </p>
                      <div className="flex justify-center gap-3 mt-[40px]">
                        <button
                          onClick={handleCancelModal}
                          className="bg-white  text-[#CB2129] border border-[#CB2129] text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg "
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn-liner-gradient  text-white text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    {passList.length > 0 && (
                      <>
                        {passList.map((pass, index) => (
                          <>
                            {pass.quantity > 0 && (
                              <div
                                key={index}
                                className="bg-white rounded-lg p-5 mb-4"
                              >
                                <div className="flex">
                                  <div className="flex-none mr-5">
                                    <div className="w-[80px] md:w-[120px] h-[80px] md:h-[120px]">
                                      {pass.image ? (
                                        <img
                                          src={pass.image}
                                          alt="img"
                                          className="rounded-[4px] aspect-[1/1]"
                                        />
                                      ) : event.banner_image ? (
                                        <img
                                          src={event.banner_image}
                                          alt="img"
                                          className="rounded-[4px] aspect-[1/1]"
                                        />
                                      ) : (
                                        <img
                                          src={bannerImageDefault}
                                          alt="img"
                                          className="rounded-[4px] aspect-[1/1]"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-1">
                                    <div className="block md:flex h-full">
                                      <div className="flex-1">
                                        <h3 className="text-base md:text-2xl text-[#101010] font-semibold">
                                          {event.name ? event.name : ''}
                                          {event.tagline
                                            ? ': ' + event.tagline
                                            : ''}
                                        </h3>
                                        <h4 className="text-base md:text-xl text-[#CB2129] font-semibold mt-1">
                                          {pass.name ? pass.name : ''}
                                        </h4>
                                        <p className="text-base text-[#101010] font-body font-normal mt-2">
                                          {pass.quantity}{' '}
                                          {event.event_type_id == 4
                                            ? 'Ticket'
                                            : 'Pass'}
                                        </p>
                                      </div>
                                      <div className="flex-none">
                                        <div className="hidden md:block h-full">
                                          <div className="flex flex-col justify-between h-full">
                                            <div className="flex">
                                              <p className="text-xl md:text-3xl text-[#101010] font-semibold mr-[2px]">
                                                ₹
                                              </p>
                                              <h3 className="text-lg md:text-3xl text-[#101010] font-body font-semibold">
                                                {pass.amount_after_discount}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="block md:hidden mt-3">
                                  <div className="flex md:flex-col justify-between h-full">
                                    <div className="flex">
                                      <p className="text-xl md:text-3xl text-[#101010] font-semibold mr-[2px]">
                                        ₹
                                      </p>
                                      <h3 className="text-xl md:text-3xl text-[#101010] font-body font-semibold">
                                        {pass.amount_after_discount}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    )}
                    <div className="flex justify-center gap-3 mt-[40px]">
                      {currentStep > 1 && (
                        <button
                          onClick={handleCancelModal}
                          className="bg-white  text-[#CB2129] border border-[#CB2129] text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg "
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        onClick={handleNext}
                        className="btn-liner-gradient  text-white text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg"
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <div>
                      <div className="flex items-end gap-3">
                        <div className="flex-1">
                          <div>
                            <label className="mb-[10px] block text-base font-medium text-[#101010]">
                              Discount Code
                              <span className="text-[#CB2027]">*</span>
                            </label>
                            <div className="relative">
                              <input
                                name="promoCode"
                                type="text"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                                className={
                                  'bg-white border border-[#E7E7E7] text-[#CB2129] text-base font-semibold font-body  rounded-lg placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 md:py-4 px-4 md:px-5 h-[56px]'
                                }
                                placeholder={'Enter promo code'}
                              />
                              {passPaymentCalculation.discount > 0 && (
                                <span onClick={() => removePromoCode()}>
                                  <i className="cursor-pointer fas fa-times absolute right-[12px] top-[19px]"></i>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex-none md:ml-5">
                          <button
                            onClick={() => handlePromoCodeApply()}
                            type="button"
                            className="btn-liner-gradient h-[56px] text-white text-lg font-semibold py-2 md:py-[10px] px-4 md:px-7 rounded-lg "
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      <div className="w-full md:w-[80%] xl:w-[90%] ">
                        {passPaymentCalculation.discount_status ===
                          'success' && (
                          <p className="text-green-500 text-sm font-body font-normal mt-2">
                            {passPaymentCalculation.discount_message}
                          </p>
                        )}
                        {passPaymentCalculation.discount_status === 'error' && (
                          <p className="text-red-500 text-sm font-body font-normal mt-2">
                            {passPaymentCalculation.discount_message}
                          </p>
                        )}
                      </div>
                      <Swiper
                        slidesPerView={3.4}
                        spaceBetween={30}
                        freeMode={true}
                        // autoplay={{
                        //   delay: 2500,
                        //   disableOnInteraction: false,
                        // }}
                        breakpoints={{
                          320: {
                            slidesPerView: 1.2,
                            spaceBetween: 20,
                          },
                          640: {
                            slidesPerView: 1.7,
                            spaceBetween: 20,
                          },
                          1024: {
                            slidesPerView: 2.3,
                            spaceBetween: 20,
                          },
                          1200: {
                            slidesPerView: 3.4,
                            spaceBetween: 30,
                          },
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Autoplay, FreeMode, Pagination]}
                        className="mySwiper mt-7 gallary-slider !pb-10"
                      >
                        {passOffers.map((offer, index) => (
                          <>
                            {offer.visible_to_public == 'Yes' && (
                              <SwiperSlide key={index}>
                                <div className="bg-white border border-[#D2D2D2] rounded-lg min-h-[130px]">
                                  <div className="p-3">
                                    <div className="flex">
                                      <div className="flex-none">
                                        <img
                                          src={offerIcon}
                                          alt="icon"
                                          className="inline-block mr-2"
                                        />
                                      </div>
                                      <div className="flex-1">
                                        <div className="flex">
                                          <div className="flex-1">
                                            <h3 className="text-[#101010] text-lg font-semibold break-all">
                                              {offer.name}
                                            </h3>
                                          </div>
                                          <div className="flex-none">
                                            <button
                                              onClick={() =>
                                                setPromoCode(offer.offer_code)
                                              }
                                              className="bg-[#CB2129] border border-[#CB2129] text-white text-[12px] md:text-sm py-[1px] md:py-[4px] px-2 md:px-4 rounded-lg font-medium md:inline-block hidden"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>

                                        <div className="flex items-center">
                                          <div className="flex-1">
                                            <p className="text-[#0F993E] text-[12px] font-normal">
                                              {offer.offer_code}
                                            </p>
                                          </div>
                                          <div className="flex-none">
                                            <button
                                              onClick={() =>
                                                setPromoCode(offer.offer_code)
                                              }
                                              className="bg-[#CB2129] border border-[#CB2129] text-white text-[13px] md:text-sm py-[1px] md:py-[4px] px-2 md:px-4 rounded-lg font-medium inline-block md:hidden"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border-b-2 border-dashed border-[#E7E7E7] mx-4 relative flex-none">
                                    <span
                                      className={`w-[24px] h-[22.43px] rounded-full absolute -bottom-[13px] -left-[29px] bg-[#F9F9F9] border border-[#D2D2D2] box-border flex items-center p-1 after:absolute after:bg-[#F9F9F9] after:w-[12px] after:h-[23px] after:-left-[1px]`}
                                    ></span>
                                    <span
                                      className={`w-[24px] h-[22.43px] rounded-full absolute -bottom-[13px] -right-[29px] bg-[#F9F9F9] border border-[#D2D2D2] box-border flex items-center p-1 after:absolute after:bg-[#F9F9F9] after:w-[12px] after:h-[23px] after:-right-[1px]`}
                                    ></span>
                                  </div>
                                  <div className="p-3">
                                    <p className="text-[#9C9C9C] text-base font-normal">
                                      {offer.description}
                                    </p>
                                  </div>
                                </div>
                              </SwiperSlide>
                            )}
                          </>
                        ))}
                      </Swiper>
                    </div>
                    <div className="bg-white rounded-lg p-5">
                      <div className="flex mb-3">
                        <div className="flex-1">
                          <h3 className="text-xl text-[#101010] font-semibold">
                            Order Amount (
                            {passList.reduce(
                              (total, pass) => total + pass.quantity,
                              0,
                            )}
                            )
                          </h3>
                        </div>
                        <div className="flex-none">
                          <div className="flex">
                            <p className="text-lg md:text-2xl text-[#CB2129] font-normal mr-[2px]">
                              ₹
                            </p>
                            <h3 className="text-lg md:text-2xl text-[#CB2129] font-body font-semibold">
                              {paymentDetails.total_amount}
                            </h3>
                          </div>
                        </div>
                      </div>

                      <div className="border-b-2 border-[#E7E7E7] my-3"></div>
                      <div className="flex">
                        <div className="flex-1">
                          <h3 className="text-base text-[#101010] font-normal">
                            Total {event.event_type_id == 4 ? 'Ticket' : 'Pass'}{' '}
                            Quantity
                          </h3>
                        </div>
                        <div className="flex-none">
                          <h3 className="text-base text-[#101010] font-normal">
                            {passList.reduce(
                              (total, pass) => total + pass.quantity,
                              0,
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="flex mt-1">
                        <div className="flex-1">
                          <h3 className="text-base text-[#101010] font-normal">
                            Amount Before Tax
                          </h3>
                        </div>
                        <div className="flex-none">
                          <div className="flex">
                            <p className="text-base text-[#101010] font-normal mr-[2px]">
                              ₹
                            </p>
                            <h3 className="text-base text-[#101010] font-normal">
                              {paymentDetails.amount}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-1">
                        <div className="flex-1">
                          <h3 className="text-base text-[#101010] font-normal">
                            GST
                          </h3>
                        </div>
                        <div className="flex-none">
                          <div className="flex">
                            <p className="text-base text-[#101010] font-normal mr-[2px]">
                              ₹
                            </p>
                            <h3 className="text-base text-[#101010] font-normal">
                              {paymentDetails.tax_amount}
                            </h3>
                          </div>
                        </div>
                      </div>
                      {paymentDetails.convenience_charge > 0 && (
                        <div className="flex mt-1">
                          <div className="flex-1">
                            <h3 className="text-base text-[#101010] font-normal">
                              Convenience Charge
                            </h3>
                          </div>
                          <div className="flex-none">
                            <div className="flex">
                              <p className="text-base text-[#101010] font-normal mr-[2px]">
                                ₹
                              </p>
                              <h3 className="text-base text-[#101010] font-normal">
                                {paymentDetails.convenience_charge}
                              </h3>
                            </div>
                          </div>
                        </div>
                      )}
                      {paymentDetails.discount > 0 && (
                        <div className="flex mt-1">
                          <div className="flex-1">
                            <h3 className="text-base text-[#101010] font-normal">
                              Discount applied
                            </h3>
                          </div>
                          <div className="flex-none">
                            <div className="flex">
                              <p className="text-base text-[#101010] font-normal mr-[2px]">
                                - ₹
                              </p>
                              <h3 className="text-base text-[#101010] font-normal">
                                {paymentDetails.discount}
                              </h3>
                            </div>
                          </div>
                        </div>
                      )}
                      {paymentDetails.get_y &&
                        paymentDetails.get_y.length > 0 && (
                          <div className="flex mt-3">
                            <div className="flex-1">
                              <h3 className="text-xl text-[#101010] font-semibold">
                                {paymentDetails.get_y[0]['pass_detail']['name']}
                              </h3>
                            </div>
                            <div className="flex-none">
                              <h3 className="text-base text-[#101010] font-normal">
                                {paymentDetails.get_y[0]['quantity']} MEPASS
                                FREE
                              </h3>
                            </div>
                          </div>
                        )}
                      <div className="border-b border-[#E7E7E7] my-3"></div>
                      <div className="flex">
                        <div className="flex-1">
                          <h3 className="text-xl text-[#101010] font-semibold">
                            Order Total
                          </h3>
                        </div>
                        <div className="flex-none">
                          <div className="flex">
                            <p className="text-lg md:text-2xl text-[#101010] font-bold mr-[2px]">
                              ₹
                            </p>
                            <h3 className="text-lg md:text-2xl text-[#101010] font-bold">
                              {paymentDetails.net_amount}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Navigation Buttons */}
                    <div className="flex justify-center gap-3 mt-[40px]">
                      {currentStep > 1 && (
                        <button
                          onClick={handleCancelModal}
                          className="bg-white  text-[#CB2129] border border-[#CB2129] text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg "
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        onClick={() => {
                          processedToPay();
                          pushMakePayment(
                            'Proceed to pay',
                            'Payment',
                            'Proceed to pay',
                            passList,
                            '',
                            paymentDetails.net_amount,
                          );
                        }}
                        className="btn-liner-gradient  text-white text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg"
                      >
                        Proceed to pay
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cancel Popup modal */}
      {isShowCancelModal ? (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div
            className="bg-white rounded-[12px] md:rounded-[32px] text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-[85%] md:w-[80%] lg:w-[800px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white py-[56px] md:py-[138px] px-[32px] md:px-[48px] rounded-[32px]">
              <p className="text-base md:text-xl font-semibold text-[#101010] text-center">
                Are You sure to Leave your choice incomplete?
              </p>
              <div className="flex gap-6 justify-center mt-[40px]">
                <button
                  onClick={handleCancelModal}
                  className="bg-white text-[#CB2129] border border-[#CB2129] text-[12px] md:text-base font-medium py-2 md:py-[10px] px-4 md:px-7 rounded-[4px] md:rounded-lg"
                >
                  No
                </button>
                <button
                  onClick={handleCancelHomeModal}
                  className="btn-liner-gradient text-white text-[12px] md:text-base font-medium py-2 md:py-[10px] px-4 md:px-7 rounded-[4px] md:rounded-lg"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Cancel Popup modal */}
      {isShowCancelHomeModal ? (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div
            className="bg-white rounded-[12px] md:rounded-[32px] text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-[85%] md:w-[80%] lg:w-[800px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white py-[56px] md:py-[138px] px-[32px] md:px-[48px] rounded-[32px]">
              <p className="text-base md:text-xl font-semibold text-[#101010] text-center">
                Don't worry! We'll remind you just before the last chance, so
                you won't miss out
              </p>
              <div className="flex gap-6 justify-center mt-[40px]">
                <Link
                  to="/"
                  className="btn-liner-gradient text-white text-[12px] md:text-base font-medium py-2 md:py-[10px] px-4 md:px-7 rounded-[4px] md:rounded-lg"
                >
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PaymentOption;
