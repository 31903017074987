import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { isEmpty } from 'lodash';
import React from 'react';
import hBannerImageDefault from '../../assets/images/default/h-event-image.png';
import calendarIcon from '../../assets/light_theme_images/calender-dark.svg';
import carbonDirection from '../../assets/light_theme_images/carbon-direction-bear.svg';
import clockIcon from '../../assets/light_theme_images/clock-dark.svg';
import durartionIcon from '../../assets/light_theme_images/duration-hours.svg';
import locationIcon from '../../assets/light_theme_images/location-dark.svg';
import { convertToAmPm } from '../../common/commonFunction';
import withRouter from '../../components/Common/withRouter';
import Artist from '../../components/Event/Details/Artist';
import EventUpComing from '../../components/Event/Details/EventUpComing';
import Sponsor from '../../components/Event/Details/Sponsor';
import ShareButtons from '../../components/Event/ShareButtons';
import { pushClickEvent } from '../../seo/gtevents';
import SeoDetails from '../../seo/SeoDetails';
import useEventDetails from './hooks/useEventDetails'; // Import the custom hook
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Keyboard, Mousewheel, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const EventDetails = (props) => {
  const {
    eventDetail,
    artists,
    sponsors,
    upcomingEventDetail,
    bannerImage,
    videoUrl,
    embedUrl,
    isVideoPlaying,
    mapCenter,
    addToFavorite,
    removeToFavorite,
    isAddData,
    isRemoveData,
    eventRating,
    eventDateDisplay,
    getFullAddress,
    mapContainerStyle,
    handleMarkerClick,
    openIndex,
    handleToggle,
    swiperInstance,
    handleReachEnd,
    setSwiperInstance,
    swiperRef
  } = useEventDetails(props);

  return (
    <>
      <SeoDetails
        title={eventDetail.seo_title}
        description={eventDetail.tagline}
        keywords={eventDetail.keywords}
        ogImage={eventDetail.banner_image}
        schemaData={eventDetail} // pass the event data to generate schema
        artist={artists} // pass the artist data to generate schema
      />

      {((!isEmpty(bannerImage) && bannerImage.length > 0) || embedUrl) && (
        <section className="pt-6 md:pt-10">
          <div className="container">
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={1.1}
              centeredSlides={false}
              spaceBetween={40}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 1.1,
                  spaceBetween: 40,
                },
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper event-details-slider banner-slider !pb-10"
              onSwiper={setSwiperInstance} // Store Swiper instance
              onReachEnd={handleReachEnd} // Trigger when the last slide is reached// Trigger when the last slide is reached
            >
              {bannerImage && (
                <>
                  {bannerImage.map((item, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={item ? item : hBannerImageDefault}
                        alt=""
                        className="rounded-xl aspect-[99/40] w-full"
                      />
                    </SwiperSlide>
                  ))}
                  {/* {videoUrl &&
                    <SwiperSlide>
                      <ReactPlayer
                        className='react-player rounded-xl aspect-[99/40] w-full'
                        url={videoUrl}
                        playing={isVideoPlaying}
                        onPlay={handleVideoPlay}
                        onEnded={handleVideoEnd}
                      />
                    </SwiperSlide>
                  } */}
                  {embedUrl && (
                    <SwiperSlide>
                      <iframe
                        className="rounded-xl aspect-[99/40] w-full"
                        src={embedUrl}
                        allow="fullscreen"
                        allowfullscreen
                      ></iframe>
                    </SwiperSlide>
                  )}
                </>
              )}
            </Swiper>
          </div>
        </section>
      )}

      <section className="pt-2 md:pt-10">
        <div className="container">
          <div className="block md:flex gap-10">
            <div className="flex-1">
              <h1 className="text-[#101010] text-3xl md:text-[40px] font-bold">
                {eventDetail.name}
              </h1>
              <div className="block lg:flex gap-4 mt-4 md:mt-2.5">
                <div className="flex-none">
                  <div className="flex gap-1.5 mb-2 lg:mb-0">
                    <img
                      src={calendarIcon}
                      alt="img"
                      className="inline-block"
                    />
                    <p className="text-[#101010] text-base md:text-xl font-normal">
                      {eventDateDisplay()}
                    </p>
                  </div>
                </div>
                <div className="flex-none">
                  <div className="flex gap-4">
                  {eventDetail &&
                          eventDetail.dates &&
                          eventDetail.dates[0] &&
                          eventDetail.dates[0]['start_time']
                            &&
                    <div className="flex-none">
                      <div className="flex gap-1.5 mb-2 lg:mb-0">
                        <img
                          src={clockIcon}
                          alt="img"
                          className="inline-block"
                        />
                        <p className="text-[#101010] text-base md:text-xl font-normal">
                        {convertToAmPm(eventDetail.dates[0]['start_time'])}
                        </p>
                      </div>
                    </div>
                    }
                    {eventDetail.duration_hours && (
                      <div className="flex-none">
                        <div className="flex gap-1.5 mb-2 lg:mb-0">
                          <img
                            src={durartionIcon}
                            alt="img"
                            className="inline-block"
                          />
                          <p className="text-[#101010] text-base md:text-xl font-normal">
                            {eventDetail.duration_hours} Hours
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  {eventDetail.dates &&
                  eventDetail.dates[0] &&
                  eventDetail.dates[0]['address'] ? (
                    <div className="flex items-start gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block mt-[3px]"
                      />
                      <p className="text-[#101010] text-base md:text-xl font-normal">
                        {getFullAddress(eventDetail.dates[0])}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="flex-none mt-7 md:mt-0">
              <div className="flex md:flex-col items-center md:items-end">
                {eventDetail.pass_available === true ? (
                  <Link
                    to={`/events/${eventDetail.slug}/book-tickets`}
                    onClick={() =>
                      pushClickEvent(
                        'Event Click',
                        'click_bookNow',
                        'Book Now',
                        eventDetail,
                      )
                    }
                  >
                    <button className="btn-liner-gradient text-white text-base text-center font-semibold py-[10px] px-4 md:px-8 rounded-lg font-body duration-1000 ease-in-out h-[54px] flex items-center justify-center">
                      Book Now
                    </button>
                  </Link>
                ) : (
                  ''
                )}
                {eventDetail.original_price > 0 && (
                  <p className="text-[#101010] text-base md:text-xl font-bold md:mt-2 ml-3 md:ml-0">
                    ₹{eventDetail.original_price}{' '}
                    <span className="font-medium">onwards</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white pt-4">
        <div className="container">
          <div className="flex flex-wrap gap-y-2 md:gap-y-0 gap-2 md:gap-x-6">
            {eventDetail.keywords && !isEmpty(eventDetail.keywords) ? (
              <>
                {eventDetail.keywords &&
                  eventDetail.keywords.split(',').map((item, index) => (
                    // <button
                    //   key={index}
                    //   className="text-white text-[16px] font-normal tracking-[.125em] py-2 px-[18px] rounded-full font-body bg-black  hover:text-secondary border border-black hover:border hover:border-secondary duration-1000 ease-in-out"
                    // >
                    //   #{item}
                    // </button>
                    <p
                      key={index}
                      className="text-[#CB2129] text-base md:text-lg font-medium py-1 md:py-2 px-2 md:px-4 "
                    >
                      #{item}
                    </p>
                  ))}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
      <section className="bg-white pt-10 pb-24">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              {eventDetail.tagline && (
                <h2 className="text-xl md:text-3xl font-medium text-[#101010] mb-8 md:leading-[48px] italic">
                  {eventDetail.tagline}
                </h2>
              )}
              {eventDetail.short_description && (
                <div className="mb-7">
                  <div className="flex gap-3">
                    <div className="flex-1">
                      <h2 className="text-xl md:text-3xl font-semibold text-[#101010] mb-4">
                        Why should you attend?
                      </h2>
                    </div>
                    <div className="flex-none">
                      <div className="hidden md:block">
                        {eventDetail.rating > 0 && (
                          <div className="bg-[#F0BA0E] rounded-full py-1 px-3 text-[#101010] flex items-center justify-center">
                            <i className="fas fa-star text-lg mr-1"></i>
                            <p className="text-xl md:text-2xl font-semibold">
                              {eventDetail.rating > 0 ? eventDetail.rating : ''}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`break-all ${
                      eventDetail.short_description &&
                      eventDetail.short_description.includes('<ul>')
                        ? 'bullet-point'
                        : eventDetail.short_description &&
                          eventDetail.short_description.includes('<ol>')
                        ? 'number-point'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.short_description,
                    }}
                  />
                </div>
              )}
              {eventDetail.description && (
                <div className="mb-7">
                  <h2 className="text-xl md:text-3xl font-semibold text-[#101010] mb-4">
                    Key takeaways...
                  </h2>
                  <div
                    className={`${
                      eventDetail.description &&
                      eventDetail.description.includes('<ul>')
                        ? 'bullet-point'
                        : eventDetail.description &&
                          eventDetail.description.includes('<ol>')
                        ? 'number-point'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.description,
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              <div className="flex flex-col gap-10">
                {eventDetail.distribution_type != 'Online' && (
                  <div>
                    <h3 className="text-xl lg:text-[32px] font-semibold text-[#101010] ">
                      {' '}
                      Where it’s happening?{' '}
                    </h3>
                    {eventDetail.dates &&
                    eventDetail.dates[0] &&
                    eventDetail.dates[0]['address'] ? (
                      <div className="flex gap-1.5 pt-2 md:pt-4 mb-3">
                        <p className="text-[#101010] text-xl font-normal">
                          {getFullAddress(eventDetail.dates[0])}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {eventDetail &&
                    eventDetail.dates &&
                    eventDetail.dates[0].gmap_link ? (
                      <>
                        <div className="relative">
                          <LoadScript
                            googleMapsApiKey={
                              process.env.REACT_APP_PLACE_API_KEY
                            }
                          >
                            <GoogleMap
                              mapContainerStyle={mapContainerStyle}
                              center={mapCenter}
                              zoom={14}
                              options={{
                                zoomControl: false, // Disables the + and - zoom controls
                                disableDefaultUI: true, // Disables all default UI controls (optional)
                              }}
                            >
                              <Marker
                                position={mapCenter}
                                onClick={handleMarkerClick}
                              />
                            </GoogleMap>
                          </LoadScript>
                        </div>
                        <Link
                          to={eventDetail.dates[0].gmap_link}
                          className="rounded-lg p-2 px-10 bg-white border border-[#E2E2E2] w-full mt-4 block text-center text-2xl font-normal"
                          target="_blank"
                        >
                          <img
                            src={carbonDirection}
                            alt="img"
                            className="mr-2 inline-block size-6"
                          />
                          Check Direction
                        </Link>
                      </>
                    ) : eventDetail.gmap_link ? (
                      <>
                        <div className="relative">
                          <LoadScript
                            googleMapsApiKey={
                              process.env.REACT_APP_PLACE_API_KEY
                            }
                          >
                            <GoogleMap
                              mapContainerStyle={mapContainerStyle}
                              center={mapCenter}
                              zoom={14}
                              options={{
                                zoomControl: false, // Disables the + and - zoom controls
                                disableDefaultUI: true, // Disables all default UI controls (optional)
                              }}
                            >
                              <Marker
                                position={mapCenter}
                                onClick={handleMarkerClick}
                              />
                            </GoogleMap>
                          </LoadScript>
                        </div>
                        <Link
                          to={eventDetail.gmap_link}
                          className="rounded-lg p-2 px-10 bg-white border border-[#E2E2E2] w-full mt-4 block text-center text-2xl font-normal"
                          target="_blank"
                        >
                          <img
                            src={carbonDirection}
                            alt="img"
                            className="mr-2 inline-block size-6"
                          />
                          Check Direction
                        </Link>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                )}

                <div className="flex flex-col gap-5">
                  <h4 className="text-base md:text-2xl font-semibold text-[#101010] ">
                    Invite your tribe
                  </h4>
                  <ul className="flex gap-5">
                    <ShareButtons eventDetail={eventDetail} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* ----Artists-Performing---- */}
          <div className="mt-8">
            <Artist artists={artists} props={props} />
          </div>

          {/* ----People say about us---- */}
          {!isEmpty(eventRating) && (
            <div className="mt-8">
              <h4 className="text-xl md:text-[32px] font-semibold text-[#101010] mb-5">
                What people say about us
              </h4>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 2.1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {eventRating.map((item, index) => (
                  <SwiperSlide>
                    <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-[16px] p-3 md:p-6 duration-1000 ease-in-out flex flex-col justify-between h-full">
                      <div className="flex-1">
                        <p className="text-[#101010] font-normal text-[10px] md:text-lg">
                          {item.comments}
                        </p>
                      </div>
                      <div className="flex-none">
                        <div className="flex items-center mt-4">
                          <div className="flex-none">
                            {item.profile_image ? (
                              <div className="w-[16px] md:w-[40px] h-[16px] md:h-[40px] rounded-full overflow-hidden">
                                <img
                                  src={item.profile_image}
                                  alt=""
                                  className="aspect-[1/1] rounded-full object-cover"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="flex-1 ml-3">
                            <h4 className="text-[#101010] font-semibold text-[10px] md:text-xl">
                              {item.user_name}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          {/* ----Event-Sponsors---- */}
          <div className="mt-8">
            <Sponsor sponsors={sponsors} props={props} />
          </div>

          {/* ----Terms & Conditions---- */}
          {!isEmpty(eventDetail.terms_and_conditions) ? (
            <>
              <div className="pt-4">
                <div
                  className="accordion-header flex items-start gap-2 cursor-pointer"
                  onClick={() => handleToggle(0)}
                >
                  <h4 className="text-base md:text-2xl font-semibold text-[#101010] flex-1">
                    Terms & Conditions
                  </h4>
                  <div className="flex-none">
                    <button className="flex items-center justify-center text-black text-base md:text-xl mt-2.5 md:mt-0">
                      <i
                        className={`fas ${
                          openIndex === 0 ? 'fa-chevron-up' : 'fa-chevron-down'
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
                {openIndex === 0 && (
                  <div className="mt-3 px-3">
                    <p className="text-sm md:text-base text-[#101010] font-normal mt-3">
                      <span
                        className={`${
                          eventDetail.terms_and_conditions &&
                          eventDetail.terms_and_conditions.includes('<ul>')
                            ? 'bullet-point'
                            : eventDetail.terms_and_conditions &&
                              eventDetail.terms_and_conditions.includes('<ol>')
                            ? 'number-point'
                            : ''
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: eventDetail.terms_and_conditions,
                        }}
                      ></span>
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            ''
          )}
          {/* ----You-may-love-these-too---- */}
          <div className="mt-8">
            <EventUpComing events={upcomingEventDetail} props={props} />
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(EventDetails);
