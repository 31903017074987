import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import map from '../../assets/images/small/map.png';
import passesSuccessImage from '../../assets/light_theme_images/SplitShare.gif';
import withRouter from '../../components/Common/withRouter';
import api from '../../constants/api_constants';
import message from '../../constants/message';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import img from '../../assets/images/App-Store.png';
import img1 from '../../assets/images/google-play.png';
import logo from '../../assets/light_theme_images/logo.svg';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
import DeletedImage from '../../assets/images/default/Deleted-image.png';
import ExpiredImage from '../../assets/images/default/Expired-image.png';
import ScannedImage from '../../assets/images/default/Scanned-image.png';
import RFIDImage from '../../assets/images/default/RFID-image.png';
import SharedImage from '../../assets/images/default/Shared-image.png';
import BlurImage from '../../assets/images/default/BlurImage.png';
import RegFormImage from '../../assets/images/default/RegFormImage.png';
import PassDetails from './PassDetails';
import SponserList from './SponserList';
import PriceDetail from './PriceDetail';
import SeasonMePass from './SeasonMePass';
import Navratri2024 from './PassTemplate/Navratri2024';
import locationIcon from '../../assets/light_theme_images/location-red.svg';
import ReviewPopup from '../../components/QrCode/Index';
import shareYourExperience from '../../assets/light_theme_images/share-your-experience.svg';
import holiBg from '../../assets/light_theme_images/bg-holi.svg';
import holiIcon from '../../assets/light_theme_images/holi-icon.svg';
import holiIcon2 from '../../assets/light_theme_images/holi-icon2.svg';
import holiIcon3 from '../../assets/light_theme_images/holi-icon3.svg';
import holiIcon4 from '../../assets/light_theme_images/holi-icon4.svg';
import holiinvi from '../../assets/light_theme_images/holiinvi.svg';
import HoliPass from './PassTemplate/Holi';
const QrCode = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [passDetails, setPassDetails] = useState('');
  const [sponsorList, setSponsorList] = useState('');
  const [distributionType, setDistributionType] = useState('Offline');
  const [meetLink, setMeetLink] = useState('');
  const [isSponsorData, setIsSponsorData] = useState(false);
  const [backGroundColor, setBackGroundColor] = useState('');
  const [textColor, setTextColor] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [ticketPrice, setTicketPrice] = useState(0);
  const [pricingDetails, setPricingDetails] = useState('');
  const [otherFees, setOtherFees] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [popupList, setPopupList] = useState('');
  const [isPopupData, setIsPopupData] = useState(false);
  const [showPopupModal, setIsShowPopupModal] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [bannerImage, setBannerImage] = useState([]);

  const [showSplitModal, setShowSplitModal] = useState(false);
  const [showSuccessfullyModal, setShowSuccessfullyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [seasonArray, setSeasonArray] = useState([]);
  const [urlString, setUrlString] = useState('');
  const [passAvailableQuantity, setPassAvailableQuantity] = useState(0);

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [EventDetail, setEventDetail] = useState('');
  const [artistDetail, setArtistDetail] = useState('');
  const [ratingDetail, setRatingDetail] = useState('');
  const [isPassViewShow, setIsPassViewShow] = useState(false);
  // Get the current location object
  const location = useLocation();
  const [isThings, setIsThings] = useState(false);

  //Submit form
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobile: '',
      confirm_mobile: '',
      name: '',
      count: 1,
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required(message.MOBILE_REQUIRED)
        .matches(/^[0-9]+$/, message.MOBILE_INVALID)
        .min(10, message.MOBILE_MIN_LENGTH)
        .max(10, message.MOBILE_MAX_LENGTH),
      /**Mobile and Conformation mobile same or not*/
      confirm_mobile: Yup.string()
        .required(message.CONFIRM_MOBILE_REQUIRED)
        .oneOf([Yup.ref('mobile'), null], message.MOBILE_NOT_MATCH),
      /**If count greater then available count */
      count: Yup.number()
        .required(message.COUNT_REQUIRED)
        .min(1, message.COUNT_MIN_LENGTH)
        .max(passAvailableQuantity, message.COUNT_MAX_LENGTH),
    }),
    onSubmit: (values) => {
      setAddFlag(true);
      const requestData = {
        url_string: urlString ? urlString : params.uuid,
        mobile: values.mobile,
        confirm_mobile: values.confirm_mobile,
        count: values.count,
        name: values.name,
      };
      dispatch(addUpdateNewData(requestData, '', api.SPLIT_SHARE_PASS));
    },
  });

  if (!isEmpty(data) && !isEmpty(data.splitAdd) && addFlag) {
    setUrlString('');
    setAddFlag(false);
    validation.resetForm();
    setShowSuccessfullyModal(true);
  }

  const getQrSponsorList = () => {
    let uuid = '';
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const qrParam = queryParams.get('qr');
      uuid = qrParam;
    } else {
      uuid = params.uuid;
    }
    setIsSponsorData(true);
    const userDetail = localStorage.getItem('publicMePassUserDetails');

    const requestData = { url_string: uuid };
    dispatch(getData(requestData, '', api.QR_SPONSOR_LIST));
  };

  useEffect(() => {
    if (isApiCall === 0) {
      let uuid = '';
      if (location.search) {
        const queryParams = new URLSearchParams(location.search);
        const qrParam = queryParams.get('qr');
        uuid = qrParam;
      } else {
        uuid = params.uuid;
      }
      setUrlString(uuid);
      setIsUpdateData(true);
      const userDetail = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );

      const requestData = {
        url_string: uuid,
        user_id: userDetail ? userDetail.id : null, // Handle the case where userDetail is null or undefined
      };
      dispatch(getData(requestData, props.router.navigate, api.QR_PASS_DETAILS));

      setIsPopupData(true);
      dispatch(getData(requestData, '', api.QR_POPUP_LIST));
      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate, params.uuid]);

  if (!isEmpty(data) && !isEmpty(data.EventPopup) && isPopupData) {
    setPopupList(data.EventPopup[0]);
    setIsPopupData(false);
    if (data.EventPopup[0].image) {
      setIsShowPopupModal(true);
    }
  }

  if (!isEmpty(data) && !isEmpty(data.QRPassView) && isUpdateData) {
    //Pass In Reg Form Pending
    if (
      data.QRPassView.new_registration_form_id > 0 &&
      data.QRPassView.registration_form_status != 'Approved' &&
      data.QRPassView.event_pass
    ) {
      setIsPassViewShow(false);
      window.location.href = `/qr/${data.QRPassView.url_string}/registration/${data.QRPassView.event_pass.registration_form_slug}`;
    } else {
      setIsPassViewShow(true);
    }
    const qrPassView = data.QRPassView;
    setPassAvailableQuantity(qrPassView.available_quantity);
    setPassDetails(data.QRPassView);
    setEventDetail(data.QRPassView.event);
    setDistributionType(qrPassView.event.distribution_type);
    if (qrPassView.event_date && qrPassView.event_date.meet_link) {
      setMeetLink(qrPassView.event_date.meet_link);
    }

    if (data.QRPassView.event.horizontal_banner_image) {
      //Separate using Comma
      let bannerImages =
        data.QRPassView.event.horizontal_banner_image.split(',');
      setBannerImage(bannerImages);
    }
    if (!isEmpty(data.pricingDetails)) {
      setPricingDetails(data.pricingDetails);
    }
    if (!isEmpty(data.artist)) {
      setArtistDetail(data.artist);
    }
    if (!isEmpty(data.rating)) {
      setRatingDetail(data.rating);
    }
    if (!isEmpty(data.QRPassView.event_pass.background_color)) {
      setBackGroundColor(data.QRPassView.event_pass.background_color);
      let textColorNew =
        data.QRPassView.event_pass.background_color === '#000000'
          ? '#FFFFFF'
          : '#000000';
      setTextColor(textColorNew);
    } else {
      setBackGroundColor('#FFFFFF');
      setTextColor('#000000');
    }
    if (!isEmpty(data.QRPassView.event_pass.primary_color)) {
      setPrimaryColor(
        data.QRPassView.event_pass.primary_color
          ? data.QRPassView.event_pass.primary_color
          : '#e74c3c',
      );
    }
    if (!isEmpty(data.QRPassView.event_pass.secondary_color)) {
      setSecondaryColor(
        data.QRPassView.event_pass.secondary_color
          ? data.QRPassView.event_pass.secondary_color
          : '#FFE272',
      );
    }
    if (data.seasonArray.length > 0) {
      setSeasonArray(data.seasonArray);
    }
    setIsUpdateData(false);
    getQrSponsorList();
  }

  if (!isEmpty(data) && !isEmpty(data.EventSponsor) && isSponsorData) {
    setSponsorList(data.EventSponsor);
    setIsSponsorData(false);
  }

  const toggleSplitModal = () => {
    setShowSplitModal(!showSplitModal);
  };

  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false;
  }

  const openMeetLink = (meet_link) => {
    if (!isEmpty(meet_link)) {
      // Redirect to the meet link
      window.open(meet_link, '_blank');
    }
  };

  const totalCircles = 10; // Total number of circles
  const circles = new Array(totalCircles).fill(0);

  const [activeIndexThingsKnow, setActiveIndexThingsKnow] = useState(false);

  const handleToggleThingsKnow = () => {
    setActiveIndexThingsKnow(!activeIndexThingsKnow);
  };
  const toggleReviewModal = () => {
    console.log(showReviewModal);
    setShowReviewModal(!showReviewModal);
  };

  //Get User Name By Mobile Number
  const [isNameGetByMobile, setIsNameGetByMobile] = useState(false);
  const getUserNameByMobile = (mobile) => {
    if (mobile.length == 10) {
      setIsNameGetByMobile(true);
      const data = {
        mobile: mobile,
      };
      dispatch(getData(data, '', api.GET_USER_NAME_BY_MOBILE));
    }
  };
  if (!isEmpty(data) && data?.user?.name && isNameGetByMobile) {
    validation.setFieldValue('name', data.user.name);
    setIsNameGetByMobile(false);
  }

  useEffect(() => {
    //Mobile Number 10 digit then call api for name get
    if (validation.values.mobile.length == 10) {
      getUserNameByMobile(validation.values.mobile);
    }
  }, [validation.values.mobile]);

  return (
    <>
      {!isEmpty(passDetails) && isPassViewShow && (
        <>
          {passDetails &&
          passDetails.event_pass &&
          passDetails.event_pass.pass_template_slug &&
          passDetails.event_pass.pass_template_slug == 'navratri-2024' ? (
            <>
              <Navratri2024
                passDetails={passDetails}
                pricingDetails={pricingDetails}
                sponsorList={sponsorList}
                textColor={textColor}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                backGroundColor={backGroundColor}
                RFIDImage={RFIDImage}
                ExpiredImage={ExpiredImage}
                DeletedImage={DeletedImage}
                ScannedImage={ScannedImage}
                SharedImage={SharedImage}
                map={map}
                toggleSplitModal={toggleSplitModal}
                setShowTermsModal={setShowTermsModal}
                setUrlString={setUrlString}
                setPassAvailableQuantity={setPassAvailableQuantity}
                seasonArray={seasonArray}
                distributionType={distributionType}
                meetLink={meetLink}
              />
            </>
          ) : passDetails &&
            passDetails.event_pass &&
            passDetails.event_pass.pass_template_slug &&
            passDetails.event_pass.pass_template_slug == 'holi-2025' ? (
            <>
              <HoliPass
                passDetails={passDetails}
                pricingDetails={pricingDetails}
                sponsorList={sponsorList}
                textColor={textColor}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                backGroundColor={backGroundColor}
                RFIDImage={RFIDImage}
                ExpiredImage={ExpiredImage}
                DeletedImage={DeletedImage}
                ScannedImage={ScannedImage}
                SharedImage={SharedImage}
                map={map}
                toggleSplitModal={toggleSplitModal}
                setShowTermsModal={setShowTermsModal}
                setUrlString={setUrlString}
                setPassAvailableQuantity={setPassAvailableQuantity}
                seasonArray={seasonArray}
                distributionType={distributionType}
                meetLink={meetLink}
                bannerImage={bannerImage}
                artistDetail={artistDetail}
                eventDetail={EventDetail}
                EventDetail={EventDetail}
                ratingDetail={ratingDetail}
                showReviewModal={showReviewModal}
                toggleReviewModal={toggleReviewModal}
                props={props}
                circles={circles}
                openMeetLink={openMeetLink}
              />
            </>
          ) : (
            <div className={`bg-[#f4f4f4] h-full pb-4`}>
              <div
                className="bg-white px-2 py-6 mb-6 "
                style={{
                  boxShadow: '0px 2px 12px 0px #00000014',
                }}
              >
                <div className="m-auto max-w-[160px]">
                  <img src={logo} alt="mepass" />
                </div>
              </div>
              <div className="w-full md:w-[398px] m-auto px-[46px]">
                {passDetails &&
                passDetails.pass_type_slug == 'season-mepass' ? (
                  <SeasonMePass
                    seasonArray={seasonArray}
                    passDetails={passDetails}
                    pricingDetails={pricingDetails}
                    sponsorList={sponsorList}
                    textColor={textColor}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    backGroundColor={backGroundColor}
                    RFIDImage={RFIDImage}
                    ExpiredImage={ExpiredImage}
                    DeletedImage={DeletedImage}
                    ScannedImage={ScannedImage}
                    SharedImage={SharedImage}
                    BlurImage={BlurImage}
                    RegFormImage={RegFormImage}
                    map={map}
                    toggleSplitModal={toggleSplitModal}
                    setShowTermsModal={setShowTermsModal}
                    setUrlString={setUrlString}
                    setPassAvailableQuantity={setPassAvailableQuantity}
                    distributionType={distributionType}
                    meetLink={meetLink}
                    openMeetLink={openMeetLink}
                    bannerImage={bannerImage}
                    artistDetail={artistDetail}
                    eventDetail={EventDetail}
                    ratingDetail={ratingDetail}
                  />
                ) : (
                  <div
                    className={`rounded-[10px] border border-[#CB2129]`}
                    style={{ backgroundColor: backGroundColor }}
                  >
                    <PassDetails
                      passDetails={passDetails}
                      textColor={textColor}
                      backGroundColor={backGroundColor}
                      bannerImage={bannerImage}
                      primaryColor={primaryColor}
                      secondaryColor={secondaryColor}
                    />
                    <div
                      className="border-b-2 border-dashed my-3 mx-4 relative"
                      style={{ borderColor: primaryColor }}
                    >
                      <span
                        style={{ borderColor: primaryColor }}
                        className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#CB2129] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-left-[12px] box-border flex items-center p-1`}
                      ></span>
                      <span
                        style={{ borderColor: primaryColor }}
                        className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-right-[12px] box-border flex items-center p-1`}
                      ></span>
                    </div>

                    <div className="p-[15px]">
                      <div>
                        {passDetails && passDetails.user_name && (
                          <h2
                            style={{
                              color: textColor,
                            }}
                            className={`text-xl text-center font-bold mb-4 capitalize text-[${textColor}]`}
                          >
                            {passDetails.user_name}
                          </h2>
                        )}
                        <div className="flex mb-5">
                          <div className="flex-1">
                            {passDetails &&
                              passDetails.scan_date &&
                              passDetails.pass_type_slug !=
                                'flexible-mepass' && (
                                <div className="flex gap-1.5 mb-2">
                                  <i
                                    style={{ color: primaryColor }}
                                    class="text-[12px] fa-solid fa-calendar-days mt-[2px]"
                                  ></i>
                                  <p
                                    style={{ color: textColor }}
                                    className=" text-[12px] font-normal"
                                  >
                                    {convertToDate(
                                      passDetails.scan_date,
                                      'pass',
                                    )}
                                  </p>
                                </div>
                              )}
                            {passDetails &&
                              passDetails.event_pass &&
                              passDetails.event_pass.pass_date &&
                              passDetails.event_date &&
                              passDetails.event_date.start_time && (
                                <div className="flex gap-2.5">
                                  <p
                                    style={{ color: textColor }}
                                    className="text-[12px] font-normal"
                                  >
                                    <i
                                      style={{ color: primaryColor }}
                                      className="fa-solid fa-clock mr-[5px]"
                                    ></i>
                                    {convertToAmPm(
                                      passDetails.event_date.start_time,
                                    )}
                                  </p>
                                  {/* <span style={{ color: textColor }} className="font-bold"> <i class="fa-solid fa-credit-card mr-[2px]"></i> 500</span> */}
                                </div>
                              )}
                          </div>
                          <div className="flex-none">
                            {passDetails.available_quantity > 0 && (
                              <>
                                <div
                                  style={{
                                    border: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                    color: textColor,
                                  }}
                                  className={`font-bold text-xl w-[80px] h-[29px] flex items-center justify-center text-[${textColor}] uppercase relative break-all qty-border-liner-gradient bg overflow-hidden bg-transparent`}
                                >
                                  {passDetails.available_quantity}
                                  <sub className="ml-1 text-[8px] font-normal capitalize -bottom-[5px]">
                                    Qty.
                                  </sub>
                                  <span
                                    className="absolute -top-[5px] -right-[6px] size-[14px] rounded-full z-10 border"
                                    style={{
                                      backgroundColor: backGroundColor,
                                      borderColor: primaryColor,
                                    }}
                                  ></span>
                                  <span
                                    className="absolute -bottom-[5px] -right-[6px] size-[14px] rounded-full z-10 border"
                                    style={{
                                      backgroundColor: backGroundColor,
                                      borderColor: primaryColor,
                                    }}
                                  ></span>
                                  <span
                                    className="absolute -top-[5px] -left-[6px] size-[14px] rounded-full z-10 border"
                                    style={{
                                      backgroundColor: backGroundColor,
                                      borderColor: primaryColor,
                                    }}
                                  ></span>
                                  <span
                                    className="absolute -bottom-[5px] -left-[6px] size-[14px] rounded-full z-10 border"
                                    style={{
                                      backgroundColor: backGroundColor,
                                      borderColor: primaryColor,
                                    }}
                                  ></span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          style={{ backgroundColor: primaryColor }}
                          className="max-w-[70%] m-auto p-1.5 rounded-[8px] mb-4"
                        >
                          {passDetails &&
                          passDetails.status !== null &&
                          (passDetails.status === 'Expired' ||
                            passDetails.status === 'Deleted' ||
                            passDetails.status === 'Scann' ||
                            passDetails.status === 'Scann' ||
                            passDetails.available_quantity === 0) ? (
                            <>
                              {passDetails.status === 'Expired' ? (
                                <img
                                  src={ExpiredImage}
                                  alt="img"
                                  className="inline-block rounded-[8px]"
                                />
                              ) : passDetails.status === 'Deleted' ? (
                                <img
                                  src={DeletedImage}
                                  alt="img"
                                  className="inline-block rounded-[8px]"
                                />
                              ) : passDetails.status === 'Scann' ? (
                                <img
                                  src={ScannedImage}
                                  alt="img"
                                  className="inline-block rounded-[8px]"
                                />
                              ) : passDetails.available_quantity === 0 ? (
                                <img
                                  src={SharedImage}
                                  alt="img"
                                  className="inline-block rounded-[8px]"
                                />
                              ) : passDetails &&
                                passDetails.event_pass &&
                                passDetails.event_pass.is_rfid == 'Yes' ? (
                                <img
                                  src={RFIDImage}
                                  alt="img"
                                  className="inline-block rounded-[8px]"
                                />
                              ) : (
                                ''
                              )}
                            </>
                          ) : passDetails &&
                            passDetails.event_pass &&
                            passDetails.event_pass.is_rfid == 'Yes' ? (
                            <img
                              src={RFIDImage}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          ) : passDetails &&
                            passDetails.new_registration_form_id > 0 &&
                            passDetails.registration_form_status !=
                              'Approved' ? (
                            <img
                              src={RegFormImage}
                              alt="img"
                              className="inline-block rounded-[8px] w-full"
                            />
                          ) : passDetails &&
                            passDetails.is_payment_link == 'Yes' &&
                            passDetails.payment_link_status != 'paid' ? (
                            <img
                              src={BlurImage}
                              alt="img"
                              className="inline-block rounded-[8px] w-full"
                            />
                          ) : (
                            <img
                              src={passDetails && passDetails.qr_image}
                              alt="img"
                              className="inline-block rounded-[8px]"
                            />
                          )}
                        </div>
                        {passDetails.seat_label && (
                          <div className="flex gap-3 mb-[21px]">
                            <div>
                              <p className="text-[#101010] text-[12px] font-medium">
                                Theater
                              </p>
                              <h4 className="text-[#CB2129] text-[12px] font-semibold">
                                {passDetails.venue_name}
                              </h4>
                            </div>

                            <div>
                              <p className="text-[#101010] text-[12px] font-medium">
                                Seats
                              </p>
                              <h4 className="text-[#CB2129] text-[12px] font-semibold uppercase">
                                {passDetails.event_pass &&
                                  passDetails.event_pass.name}
                                - {passDetails.seat_label}
                              </h4>
                            </div>
                          </div>
                        )}
                        {(passDetails &&
                          passDetails.event_pass &&
                          passDetails.event_pass.is_rfid == 'Yes') ||
                        (passDetails &&
                          passDetails.status !== null &&
                          (passDetails.status === 'Expired' ||
                            passDetails.status === 'Deleted' ||
                            passDetails.status === 'Scann' ||
                            passDetails.available_quantity === 0)) ? (
                          <>
                            <div className={'flex items-center justify-center'}>
                              {passDetails &&
                              passDetails.event_date &&
                              !isEmpty(passDetails.event_date.gmap_link) ? (
                                <div className="flex-none">
                                  <Link
                                    to={
                                      passDetails &&
                                      passDetails.event_date &&
                                      passDetails.event_date.gmap_link
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location  text-[#CB2129] font-medium w-[100px]"
                                  >
                                    <img
                                      src={locationIcon}
                                      alt="img"
                                      className="inline-block mr-1"
                                    />
                                    Location
                                  </Link>
                                </div>
                              ) : (
                                <>
                                  {passDetails &&
                                    passDetails.event &&
                                    !isEmpty(passDetails.event.gmap_link) && (
                                      <div className="flex-none">
                                        <Link
                                          to={
                                            passDetails &&
                                            passDetails.event &&
                                            passDetails.event.gmap_link
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location text-[#CB2129] font-medium w-[100px]"
                                        >
                                          <img
                                            src={locationIcon}
                                            alt="img"
                                            className="inline-block mr-1"
                                          />
                                          Location
                                        </Link>
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {distributionType !== 'Online' ? (
                              <div
                                className={
                                  passDetails &&
                                  passDetails.is_transferable === 'No'
                                    ? 'flex items-center justify-center'
                                    : 'flex items-center justify-center gap-4'
                                }
                              >
                                {passDetails &&
                                  passDetails.is_transferable === 'Yes' && (
                                    <div className="none">
                                      {passDetails.is_payment_link == 'Yes' &&
                                      passDetails.payment_link_status !=
                                        'paid' ? (
                                        <button
                                          onClick={() => {
                                            window.open(
                                              passDetails.payment_link,
                                            );
                                          }}
                                          style={{
                                            background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                          }}
                                          className={`rounded-[4px] flex items-center justify-center text-[12px] text-white font-medium px-4 py-2`}
                                        >
                                          Make Payment
                                        </button>
                                      ) : passDetails.new_registration_form_id >
                                          0 &&
                                        (passDetails.registration_form_data_id ==
                                          '' ||
                                          passDetails.registration_form_data_id ==
                                            null) ? (
                                        <button
                                          onClick={() => {
                                            props.router.navigate(
                                              `/qr/${passDetails.url_string}/registration/${passDetails.event_pass.registration_form_slug}`,
                                            );
                                          }}
                                          style={{
                                            background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                          }}
                                          className={`rounded-[4px] flex items-center justify-center text-[12px] text-white font-medium px-4 py-2`}
                                        >
                                          Fill the form
                                        </button>
                                      ) : (
                                        <button
                                          onClick={toggleSplitModal}
                                          style={{
                                            background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                          }}
                                          className={`rounded-[4px] flex items-center justify-center text-[12px] text-white font-medium px-4 py-2`}
                                        >
                                          Split & Share
                                        </button>
                                      )}
                                    </div>
                                  )}
                                {passDetails &&
                                passDetails.event_date &&
                                passDetails.event_date.gmap_link ? (
                                  <div className="flex-none">
                                    <Link
                                      to={
                                        passDetails &&
                                        passDetails.event_date &&
                                        passDetails.event_date.gmap_link
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={`rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location  font-medium text-[#CB2129] w-[100px]`}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="img"
                                        className="inline-block mr-1"
                                      />
                                      Location
                                    </Link>
                                  </div>
                                ) : (
                                  <>
                                    {passDetails &&
                                    passDetails.event &&
                                    !isEmpty(passDetails.event.gmap_link) ? (
                                      <div className="flex-none">
                                        <Link
                                          to={
                                            passDetails &&
                                            passDetails.event &&
                                            passDetails.event.gmap_link
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ borderColor: primaryColor }}
                                          className="rounded-[4px] py-2 px-4 flex items-center justify-center text-[12px] pass-location text-[#CB2129] font-medium w-[100px]"
                                        >
                                          <img
                                            src={locationIcon}
                                            alt="img"
                                            className="inline-block mr-1"
                                          />
                                          Location
                                        </Link>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <div className="flex justify-center">
                                <button
                                  onClick={() => openMeetLink(meetLink)}
                                  style={{
                                    background: `linear-gradient(96.63deg, ${secondaryColor} -28.78%, ${primaryColor} 28.58%, ${primaryColor} 64.86%, ${secondaryColor} 137.34%)`,
                                  }}
                                  className={`rounded-[4px] flex items-center justify-center text-base text-white font-semibold w-[140px] py-3 `}
                                >
                                  {!isEmpty(meetLink)
                                    ? 'Join Now'
                                    : 'Link Will Be Shared Soon'}
                                </button>
                              </div>
                            )}
                          </>
                        )}
                        <div className="mb-8"></div>
                        {passDetails.is_payment_link == 'Yes' &&
                        passDetails.payment_link_status != 'paid' ? (
                          <p
                            style={{ color: textColor }}
                            className={`text-[12px] text-[${textColor}]  font-semibold`}
                          >
                            Excited to host you! Complete your payment now to
                            secure your Mepass experience
                          </p>
                        ) : (
                          ''
                        )}
                        {passDetails.new_registration_form_id > 0 &&
                        (passDetails.registration_form_data_id == '' ||
                          passDetails.registration_form_data_id == null) ? (
                          <p
                            style={{ color: textColor }}
                            className={`text-[12px] text-[${textColor}]  font-semibold`}
                          >
                            Excited to host you! Complete your
                            <span
                              style={{ color: primaryColor }}
                              className="cursor-pointer"
                              onClick={() => {
                                props.router.navigate(
                                  `/qr/${passDetails.url_string}/registration/${passDetails.event_pass.registration_form_slug}`,
                                );
                              }}
                            >
                              {' '}
                              registration form
                            </span>{' '}
                            to secure your Mepass experience
                          </p>
                        ) : (
                          ''
                        )}
                        {passDetails &&
                          passDetails.event_pass &&
                          passDetails.event_pass.notes && (
                            <div className="group bg-[#F9F9F9] rounded-[10px] p-3 mt-4">
                              <button
                                onClick={() => setIsThings(!isThings)}
                                className="w-full flex justify-between items-center cursor-pointer select-none"
                              >
                                <p className="text-base text-[#101010] font-semibold">
                                  Things to know
                                </p>
                                <i
                                  className={`fas text-[#000000] ${
                                    isThings
                                      ? 'fa-chevron-up'
                                      : 'fa-chevron-down'
                                  }`}
                                ></i>
                              </button>
                              {isThings && (
                                <div>
                                  <div
                                    style={{ borderColor: secondaryColor }}
                                    className="border-b-2 border my-3"
                                  ></div>
                                  <div
                                    className={`${
                                      passDetails &&
                                      passDetails.event_pass &&
                                      passDetails.event_pass.notes &&
                                      passDetails.event_pass.notes.includes(
                                        '<ul>',
                                      )
                                        ? 'bullet-point'
                                        : passDetails &&
                                          passDetails.event_pass &&
                                          passDetails.event_pass.notes &&
                                          passDetails.event_pass.notes.includes(
                                            '<ol>',
                                          )
                                        ? 'number-point'
                                        : ''
                                    }`}
                                    dangerouslySetInnerHTML={{
                                      __html: passDetails.event_pass.notes,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        {localStorage.getItem('publicMePassUser') &&
                          (ratingDetail.event == 'No' ||
                            ratingDetail.artist == 'No') && (
                            <>
                              <div
                                className="bg-[#F9F9F9] rounded-[10px] p-3 cursor-pointer mt-4"
                                onClick={() => {
                                  toggleReviewModal();
                                }}
                              >
                                <div className="flex gap-3">
                                  <div className="flex-1">
                                    <p className="text-[15px] text-left text-[#101010] font-semibold">
                                      Share your experience
                                    </p>
                                  </div>
                                  <div className="flex-none">
                                    <img
                                      src={shareYourExperience}
                                      alt="img"
                                      className="inline-block"
                                    />
                                  </div>
                                </div>
                              </div>
                              <ReviewPopup
                                isVisible={showReviewModal}
                                toggleReviewModal={toggleReviewModal}
                                props={props}
                                EventDetail={EventDetail}
                                ArtistDetail={artistDetail}
                                ratingDetail={ratingDetail}
                              />
                            </>
                          )}
                        <>
                          <PriceDetail
                            passDetails={passDetails}
                            pricingDetails={pricingDetails}
                            textColor={textColor}
                            secondaryColor={secondaryColor}
                            primaryColor={primaryColor}
                          />
                        </>
                      </div>
                    </div>

                    {!isEmpty(sponsorList) && (
                      <>
                        <div
                          className="border-b-2 border-dashed my-3 mx-4 relative"
                          style={{ borderColor: primaryColor }}
                        >
                          <span
                            style={{ borderColor: primaryColor }}
                            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#CB2129] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-left-[12px] box-border flex items-center p-1`}
                          ></span>
                          <span
                            style={{ borderColor: primaryColor }}
                            className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-right-[12px] box-border flex items-center p-1`}
                          ></span>
                        </div>
                        <SponserList
                          sponsorList={sponsorList}
                          textColor={textColor}
                        />
                      </>
                    )}
                    <div
                      className="border-b-2 border-dashed my-3 mx-4 relative"
                      style={{ borderColor: primaryColor }}
                    >
                      <span
                        style={{ borderColor: primaryColor }}
                        className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -left-[27px] bg-[#f4f4f4] border border-[#CB2129] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-left-[12px] box-border flex items-center p-1`}
                      ></span>
                      <span
                        style={{ borderColor: primaryColor }}
                        className={`w-[21px] h-[21px] rounded-full absolute -top-[10px] -right-[27px] bg-[#f4f4f4] border border-[#f4f4f4] after:bg-[#f4f4f4] after:h-[30px] after:w-[21px] after:absolute after:-right-[12px] box-border flex items-center p-1`}
                      ></span>
                    </div>
                    <div className="px-[15px] py-[20px]">
                      <h3
                        style={{ color: textColor }}
                        className="text-base text-center font-semibold mb-3"
                      >
                        Download App
                      </h3>
                      <div className="grid grid-cols-2 gap-5">
                        {process.env.REACT_APP_PLAY_STORE_URL ? (
                          <Link
                            to={process.env.REACT_APP_PLAY_STORE_URL}
                            target="_blank"
                          >
                            <img
                              src={img1}
                              alt="img"
                              className="inline-block w-full"
                            />
                          </Link>
                        ) : (
                          <img
                            src={img1}
                            alt="img"
                            className="inline-block w-full"
                          />
                        )}
                        {process.env.REACT_APP_APP_STORE_URL ? (
                          <Link
                            to={process.env.REACT_APP_APP_STORE_URL}
                            target="_blank"
                          >
                            <img
                              src={img}
                              alt="img"
                              className="inline-block w-full"
                            />
                          </Link>
                        ) : (
                          <img
                            src={img}
                            alt="img"
                            className="inline-block w-full"
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative w-full mt-6 flex justify-center">
                      <div className="flex justify-between absolute w-[98%] top-[50%] -translate-y-[50%] mt-[1px]">
                        {circles.map((_, index) => (
                          <span
                            key={index}
                            style={{ borderColor: primaryColor }}
                            className={`w-[30px] h-[30px] rounded-full bg-[#f4f4f4] border flex items-center justify-center p-1 -rotate-90 relative after:absolute after:w-[17px] after:md:h-[30px] after:h-[36px] after:bg-[#f4f4f4] after:-left-[3px] after:top-[50%] after:-translate-y-[50%] after:content-[''] ${
                              index === 0 ? 'pl-0' : ''
                            } ${index === circles.length - 1 ? 'pr-0' : ''}`}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="mt-4 text-center">
                  <p
                    className={`text-sm text-[#1A1A1A] font-body font-normal text-center`}
                  >
                    Technology Partner :{" "}
                    <Link
                      to="https://www.bsptechno.com/"
                      target="blank"
                      className=" ml-1"
                    >
                      BSP Technologies
                    </Link>
                  </p>
                </div> */}
              </div>
            </div>
          )}
        </>
      )}

      {/* ----------------------------------split-share-modal-start------------------- */}
      {showSplitModal ? (
        <>
          <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div
                className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[30%]"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="p-5 relative">
                  <div className="flex items-center justify-center gap-6">
                    <h2 className="text-[#101010] mb-2 text-xl font-semibold">
                      Share & Split
                    </h2>
                    <button
                      type="button"
                      className="py-2 px-2 absolute top-2 right-5"
                      onClick={() => {
                        toggleSplitModal();
                        setUrlString('');
                      }}
                    >
                      <i className="fas fa-times text-2xl"></i>
                    </button>
                  </div>
                  <div className="border-b-2 border-[#F0BA0E]"></div>
                </div>
                <div className="bg-white p-5 pt-0">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <div className="mb-4">
                      <label className="text-sm font-medium text-[#101010] mb-2 block">
                        Mobile Number
                      </label>
                      <input
                        name="mobile"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values['mobile'] || ''}
                        className="p-[13px] block w-full bg-[#F9F9F9] font-medium rounded-[4px] text-base text-[#101010] placeholder:text-[#616161]"
                        placeholder={'Enter your number'}
                      />
                      {validation.errors['mobile'] ? (
                        <div className="text-red-500">
                          {validation.errors['mobile']}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <label className="text-sm font-medium text-[#101010] mb-2 block">
                        Confirm Mobile Number
                      </label>
                      <input
                        name="confirm_mobile"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values['confirm_mobile'] || ''}
                        className="p-[13px] block w-full bg-[#F9F9F9] font-medium rounded-[4px] text-base text-[#101010] placeholder:text-[#616161]"
                        placeholder={'confirm your number'}
                      />
                      {validation.errors['confirm_mobile'] ? (
                        <div className="text-red-500">
                          {validation.errors['confirm_mobile']}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <label className="text-sm font-medium text-[#101010] mb-2 block">
                        Name
                      </label>
                      <input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values['name'] || ''}
                        className="p-[13px] block w-full bg-[#F9F9F9] font-medium rounded-[4px] text-base text-[#101010] placeholder:text-[#616161]"
                        placeholder={'Enter your name'}
                      />
                      {validation.errors['name'] ? (
                        <div className="text-red-500">
                          {validation.errors['name']}{' '}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <label className="text-sm font-medium text-[#101010] mb-2 block">
                        Add Pass Quantity ({passAvailableQuantity})
                      </label>
                      <input
                        name="count"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values['count'] || ''}
                        className="p-[13px] block w-full bg-[#F9F9F9] font-medium rounded-[4px] text-base text-[#101010] placeholder:text-[#616161]"
                        placeholder={'Enter quantity'}
                      />
                      {validation.errors['count'] ? (
                        <div className="text-red-500">
                          {validation.errors['count']}{' '}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      disabled={validation.isSubmitting}
                      className="btn-liner-gradient text-white px-4 py-3 rounded-lg text-lg font-medium w-full mt-3"
                    >
                      Share
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {/* ----------------------------------split-share-modal-end------------------- */}
      {/* ----------------------------------successfully-modal-start------------------- */}
      {showSuccessfullyModal ? (
        <>
          <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div
                className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[35%]"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white p-7 ">
                  <div className="w-[260px] h-full flex justify-center m-auto">
                    <img
                      src={passesSuccessImage}
                      alt="img"
                      className="block m-auto"
                    />
                  </div>
                  <p className="text-center text-xl text-[#101010] font-semibold">
                    Your passes have been successfully shared!
                  </p>
                  <button
                    onClick={() => {
                      setShowSuccessfullyModal(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 700);
                    }}
                    className="btn-liner-gradient px-8 py-4 rounded-lg text-white text-base font-body font-medium w-full uppercase tracking-[0.3rem] mt-10"
                  >
                    ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* ----------------------------------Tearms-modal-start------------------- */}
      {showTermsModal ? (
        <>
          <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div
                className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[35%]"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white p-7 ">
                  {/* <p className={`text-2xl font-body font-medium text-primary text-center uppercase ${passDetails && passDetails.event_pass && passDetails.event_pass.notes && passDetails.event_pass.notes.includes('<ul>') ? "bullet-point" : passDetails.event_pass && passDetails.event_pass.notes && passDetails.event_pass.notes.includes('<ol>') ? "number-point" : ""}`}
                                            dangerouslySetInnerHTML={
                                                {
                                                    __html: passDetails.event_pass.notes
                                                }
                                            }> </p> */}

                  <div
                    className={`${
                      passDetails &&
                      passDetails.event_pass &&
                      passDetails.event_pass.notes &&
                      passDetails.event_pass.notes.includes('<ul>')
                        ? 'bullet-point'
                        : passDetails &&
                          passDetails.event_pass &&
                          passDetails.event_pass.notes &&
                          passDetails.event_pass.notes.includes('<ol>')
                        ? 'number-point'
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: passDetails.event_pass.notes,
                    }}
                  />
                  <button
                    onClick={() => {
                      setShowTermsModal(false);
                    }}
                    className="bg-primary border border-primary px-8 py-4 rounded-lg text-white text-base font-body font-medium w-full uppercase tracking-[0.3rem] mt-10"
                  >
                    ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {/* ----------------------------------successfully-modal-end------------------- */}

      {/* ----------------------------------popup-modal-start------------------- */}
      {showPopupModal ? (
        <>
          <div className="fixed z-30 overflow-y-auto top-[50%] origin-center w-full left-[50%] -translate-y-1/2 -translate-x-1/2">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity ">
                <div className="absolute inset-0 bg-[#000000B2]" />
              </div>

              <div
                className="inline-block align-center rounded-lg text-left overflow-hidden transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[21%] mt-5"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="text-center mt-14">
                  <div className="flex items-center justify-end mb-4">
                    <button
                      type="button"
                      className="py-2 px-2 flex items-center justify-center bg-white h-[30px] w-[30px] rounded-full"
                      onClick={() => {
                        setIsShowPopupModal(false);
                      }}
                    >
                      <i className="fas fa-times text-xl"></i>
                    </button>
                  </div>
                  <Link
                    to={
                      popupList.promotional_link
                        ? popupList.promotional_link
                        : '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={popupList.image}
                      alt="img"
                      className="inline-block"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default withRouter(QrCode);
