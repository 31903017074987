import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import calendar from '../../assets/light_theme_images/calender.svg';
import clock from '../../assets/light_theme_images/clock.svg';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
import withRouter from '../../components/Common/withRouter';
import EventView from '../../components/Event/View';
import { pushViewEvent } from '../../seo/gtevents';
import SeoDetails from '../../seo/SeoDetails';
import ChipFilter from '../Home/components/ChipFilter';
import useEventCategory from './hooks/useEventCategory';

const EventCategory = (props) => {
  const {
    events,
    totalEvents,
    trending,
    swiperRef,
    loadMore,
    handlePageClick,
    seoData,
  } = useEventCategory(props.router);

  return (
    <>
      <SeoDetails
        title={seoData?.seo_title}
        description={seoData?.short_description}
        ogImage={seoData?.seo_image}
      />

      <div onClick={handlePageClick}>
        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          slidesPerView={1}
          freeMode={true}
          pagination={{ clickable: true }}
          modules={[Autoplay, FreeMode, Pagination]}
          className="mySwiper banner-slider event-category-slider"
        >
          {!isEmpty(trending) &&
            trending.map((singleCms, index) =>
              index < 3 && singleCms.event ? (
                <SwiperSlide key={index}>
                  <div className="relative w-full min-h-[620px] md:min-h-full bg-cover pt-14 md:pt-[135px] pb-10 md:pb-20">
                    <div
                      className="absolute inset-0 -z-10 bg-cover bg-center bg-no-repeat blur-md opacity-50"
                      style={{
                        backgroundImage: `url(${singleCms.event.banner_image})`,
                      }}
                    ></div>
                    <div className="absolute inset-0 bg-[#00000033] -z-10"></div>

                    <div className="container">
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10 lg:gap-32 mt-5 md:mt-0 items-end md:items-center">
                        <div className="col-span-1 xl:col-span-2">
                          <h1 className="text-lg md:text-2xl lg:text-[32px] font-bold text-white">
                            #{index + 1} Trending
                          </h1>
                          <Link
                            to={`/events/${singleCms.event.slug}`}
                            onClick={() =>
                              pushViewEvent(
                                'EventPageBannerView',
                                'click_eventView',
                                'View Event',
                                singleCms.event,
                              )
                            }
                          >
                            <h2 className="text-2xl lg:text-4xl xl:text-7xl font-bold text-white">
                              {singleCms.event.name}
                            </h2>
                          </Link>

                          <p className="text-white text-base pt-4">
                            {singleCms.event.short_description}
                          </p>

                          <div className="block lg:flex gap-4 pt-3 md:pt-5">
                            {singleCms.event.dates[0]?.date && (
                              <h4 className="text-base md:text-[20px] text-white">
                                <img
                                  src={calendar}
                                  className="inline-block mr-2"
                                />
                                {convertToDate(singleCms.event.dates[0].date)}
                              </h4>
                            )}
                            {singleCms.event.dates[0]?.start_time && (
                              <h5 className="text-base md:text-[20px] text-white">
                                <img
                                  src={clock}
                                  className="inline-block mr-2"
                                />
                                {convertToAmPm(
                                  singleCms.event.dates[0].start_time,
                                )}
                              </h5>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ) : null,
            )}
        </Swiper>
      </div>

      <ChipFilter props={props} />

      <section className="bg-white py-16">
        <div className="container">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 lg:gap-5">
            {events.map((singleEvent, index) => (
              <Link key={index} to={`/events/${singleEvent.slug}`}>
                <EventView singleEvent={singleEvent} />
              </Link>
            ))}
          </div>
          {events.length === 0 && (
            <div className="text-center text-black">
              <h2 className="text-2xl font-body font-normal text-center">
                No Events Found
              </h2>
            </div>
          )}
          {events.length < totalEvents && (
            <button
              onClick={loadMore}
              className="border border-red-600 text-red-600 py-2 px-4 rounded-lg"
            >
              Load more
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export default withRouter(EventCategory);
