import React, { useEffect, useRef } from 'react';

const SeatJsonSelection = ({
  seatsJsonData,
  selectedSeats,
  handleSeatSelection,
  removeSelectedSeat,
  passList,
}) => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollLeft = (scrollWidth - clientWidth) / 2;
    }
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-center gap-7 md:gap-[120px] mb-10 mt-7">
        <div className="flex items-center gap-2">
          <div className="flex-none">
            <div className="bg-[#E2E2E2] border border-[#E2E2E2] size-[20px] md:size-[28px] rounded-[2px] px-[6px] py-[5px] text-[#E2E2E2] flex items-center justify-center text-[12px]"></div>
          </div>
          <div className="flex-1">
            <p className="text-[#101010] font-semibold text-[12px]  md:text-base">
              Booked
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex-none">
            <div className="bg-[#17A056] border border-[#17A056] size-[20px] md:size-[28px] rounded-[2px] px-[6px] py-[5px] text-[#17A056] flex items-center justify-center text-[12px]"></div>
          </div>
          <div className="flex-1">
            <p className="text-[#101010] font-semibold text-[12px]  md:text-base">
              Selected
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex-none">
            <div className="bg-white border border-[#20CB6F] size-[20px] md:size-[28px] rounded-[2px] px-[6px] py-[5px] text-[#20CB6F] flex items-center justify-center text-[12px]"></div>
          </div>
          <div className="flex-1">
            <p className="text-[#101010] font-semibold text-[12px]  md:text-base">
              Available
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="bg-white w-[310px] h-[80px] flex justify-center items-center rounded-[4px] px-3 py-3 border border-[#101010]">
          <h3 className="text-lg md:text-3xl text-[#101010] font-body font-semibold uppercase">
            STAGE
          </h3>
        </div>
      </div>
      <div className="mt-14">
        <div className="overflow-x-auto" ref={scrollContainerRef}>
          {seatsJsonData.map((groups) => {
            // Find max number of seats in any row to set colspan dynamically
            const maxSeats = Math.max(
              ...groups.seatLayout.map((group) => group.seat_obj.length),
            );

            return (
              <div key={groups.mongo_venue_id} className="mb-6">
                <table
                  className="w-full border border-[#E2E2E2] rounded-lg p-5"
                  style={{ borderCollapse: 'inherit' }}
                >
                  <thead>
                    <tr>
                      <th colSpan={maxSeats + 1} className="text-center pb-5">
                        {passList
                          .filter((pass) => pass.id === groups.event_pass_id)
                          .map((pass) => (
                            <div className="flex items-center justify-center">
                              <h2
                                key={pass.id}
                                className="text-base font-semibold text-[#101010"
                              >
                                {groups.event_pass_name}{' '}
                              </h2>
                              <div className="flex items-center gap-1 ml-2.5">
                                <div className="flex items-center">
                                  <p className="text-[#101010] text-2xl">
                                    ₹{pass.amount_after_discount}
                                  </p>
                                  {pass.amount > 0 &&
                                  pass.amount != pass.amount_after_discount ? (
                                    <h5 className="text-[#5D5D5D] ml-2 text-base">
                                      <del>₹ {pass.amount}</del>
                                    </h5>
                                  ) : null}
                                </div>
                                {pass.percentage > 0 && (
                                  <p className="text-base font-medium text-[#388e3c] ml-1">
                                    ({pass.percentage}% off)
                                  </p>
                                )}
                              </div>
                            </div>
                          ))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.seatLayout.map((group) => (
                      <tr key={group.grid_row_id}>
                        {group.seat_obj.map((seat) => (
                          <td key={seat.grid_number} className="p-[4px]">
                            {seat.is_space === 'No' ? (
                              <div
                                onClick={() =>
                                  selectedSeats?.includes(seat.seat_label)
                                    ? removeSelectedSeat(seat, group)
                                    : handleSeatSelection(seat, group)
                                }
                                className="w-[35px] h-[35px] flex items-center justify-center rounded-md text-sm"
                                style={{
                                  cursor: 'pointer',
                                  border: '1px solid',
                                  backgroundColor:
                                    seat?.is_seat_label === 'Yes'
                                      ? '#FFFFFF'
                                      : seat.is_blocked === 'Yes'
                                      ? '#E2E2E2'
                                      : seat.is_booked === 'Yes'
                                      ? '#F2F2F2'
                                      : seat?.booking_under_process === 'Yes'
                                      ? '#F2F2F2'
                                      : selectedSeats?.includes(seat.seat_label)
                                      ? groups.event_pass_color
                                      : 'white',
                                  borderColor:
                                    seat?.is_seat_label === 'Yes'
                                      ? '#FFFFFF'
                                      : seat.is_blocked === 'Yes'
                                      ? '#E2E2E2'
                                      : seat.is_booked === 'Yes'
                                      ? '#F2F2F2'
                                      : seat?.booking_under_process === 'Yes'
                                      ? '#F2F2F2'
                                      : selectedSeats?.includes(seat.seat_label)
                                      ? groups.event_pass_color
                                      : groups.event_pass_color,
                                  color:
                                    seat?.is_seat_label === 'Yes'
                                      ? '#5D5D5D'
                                      : seat.is_blocked === 'Yes' ||
                                        seat.is_booked === 'Yes'
                                      ? '#A0A0A0'
                                      : seat?.booking_under_process === 'Yes'
                                      ? '#A0A0A0'
                                      : selectedSeats?.includes(seat.seat_label)
                                      ? 'white'
                                      : groups.event_pass_color,
                                }}
                              >
                                {seat.seat_label}
                              </div>
                            ) : (
                              <div className="w-[35px] h-[35px]"></div>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
SeatJsonSelection.propTypes = {};
export default SeatJsonSelection;
