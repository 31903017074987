import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/default/organiser-logo.png';
import banner from '../../assets/images/mobile/artist-banner.png';
import banner2 from '../../assets/images/organiser-banner.png';
import icon from '../../assets/images/small/star-yellow.png';
import testimonail from '../../assets/images/small/testimonail-logo.png';
import { convertToDate, numberFormatter } from '../../common/commonFunction';
import StarRating from '../../components/Common/StarRating';
import Artist from '../../components/Event/Details/Artist';
import EventView from '../../components/Event/View';
import SkeletonLoaderOrganiserDetails from '../../components/SkeletonLoader/Organiser/Details';
import api from '../../constants/api_constants';
import SeoDetails from '../../seo/SeoDetails';
import whatsappIcon from '../../assets/light_theme_images/whatsapp.svg';
import {
  addUpdateNewData,
  clearData,
  getData,
} from '../../store/AppMaster/actions';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import calendarIcon from '../../assets/light_theme_images/event-calendar.svg';
import calendar from '../../assets/light_theme_images/calender-dark.svg';
import fanebaseIcon from '../../assets/light_theme_images/fanebase.svg';
import starIcon from '../../assets/light_theme_images/star-dark.svg';
import locationIcon from '../../assets/light_theme_images/location-dark.svg';
import instagramIcon from '../../assets/light_theme_images/instagram.svg';
import facebookIcon from '../../assets/light_theme_images/facebook.svg';
import twitterIcon from '../../assets/light_theme_images/twitter.svg';
import postEvent from '../../assets/light_theme_images/post-event.png';
import postEvent2 from '../../assets/light_theme_images/post-event2.png';
import postEvent3 from '../../assets/light_theme_images/post-event3.png';
import withRouter from '../../components/Common/withRouter';
import hBannerImageDefault from '../../assets/images/default/PastEventsBanner.png';

const OrganiserDetails = (props) => {
  const [activeButton, setActiveButton] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const [bannerImage, setBannerImage] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [isPastEventData, setIsPastEventData] = useState(false);
  const [pasteventDetail, setPastEventDetail] = useState(null);
  const [artists, setArtists] = useState(null);
  const [profileFlag, setProfileFlag] = useState(false);
  const [organiser, setOrganiser] = useState(null);
  const [ratingUser, setRatingUser] = useState({});
  const [eventDetail, setEventDetail] = useState(null);
  const [isEventData, setIsEventData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isOrganiserDataGet, setIsOrganiserDataGet] = useState(false);
  const [isUpcomingEventData, setIsUpcomingEventData] = useState(false);
  const [upcomingEventDetail, setUpcomingEventDetail] = useState('');
  const [isArtistDataGet, setIsArtistDataGet] = useState(true);
  const [isAddData, setIsAddData] = useState(true);
  const [ratingList, setRatingList] = useState([]);
  const [embedUrl, setEmbedUrl] = useState('');
  const ratings = [1, 2, 3, 4, 5];

  // Calculate total ratings from ratingUser
  const totalRatings = Object.values(ratingUser).reduce(
    (sum, count) => sum + count,
    0,
  );

  const extractEmbedUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const videoId = parsedUrl.searchParams.get('v'); // Extract video ID
      const playlistId = parsedUrl.searchParams.get('list'); // Extract playlist ID
      if (videoId) {
        let embedBase = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        if (playlistId) {
          embedBase += `&list=${playlistId}`;
        }
        return embedBase; // Construct the iframe embed URL
      }
      return null;
    } catch (error) {
      return null; // Handle invalid URLs
    }
  };

  const organiserDataGet = useCallback(() => {
    setIsOrganiserDataGet(true);
    const userDetail = JSON.parse(
      localStorage.getItem('publicMePassUserDetails'),
    );
    let requestData = {
      merchant_company_slug: params.organiser_slug,
      user_id: userDetail && userDetail.id ? userDetail.id : '',
    };
    dispatch(clearData());
    dispatch(getData(requestData, '', api.ORGANISER_DETAILS));
  });

  const pastEventDataGet = useCallback(() => {
    setIsPastEventData(true);
    let requestData = {
      merchant_company_slug: params.organiser_slug,
      events: 'Past',
    };
    dispatch(clearData());
    dispatch(getData(requestData, '', api.EVENT_LIST));
  });

  const upComingEventDataGet = useCallback(() => {
    setIsUpcomingEventData(true);
    let requestData = {
      merchant_company_slug: params.organiser_slug,
      events: 'Upcoming',
    };
    dispatch(clearData());
    dispatch(getData(requestData, '', api.EVENT_LIST));
  });

  const artistDataGet = useCallback(() => {
    setIsArtistDataGet(true);
    let requestData = {
      merchant_company_slug: params.organiser_slug,
    };
    dispatch(clearData());
    dispatch(getData(requestData, '', api.EVENT_ARTIST_LIST));
  });

  useEffect(() => {
    if (isApiCall === 0) {
      organiserDataGet();
      artistDataGet();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.userView) && isOrganiserDataGet) {
      setOrganiser(data.userView);
      setRatingList(data?.rating);
      setIsOrganiserDataGet(false);
      setRatingUser(data.userView.ratingUser);
      if (data.userView.cover_image_path) {
        let bannerImage = data.userView.cover_image_path.split(',');
        setBannerImage(bannerImage);
      }
      if (data.userView.youtube_url) {
        setEmbedUrl(extractEmbedUrl(data.userView.youtube_url));
      }
      upComingEventDataGet();
      pastEventDataGet();
    }

    if (!isEmpty(data) && !isEmpty(data.profileView) && profileFlag) {
      setProfileData(data.profileView);
    }

    if (!isEmpty(data) && !isEmpty(data.artistList) && isArtistDataGet) {
      setArtists(data.artistList);
      setIsArtistDataGet(false);
    }
  }, [data]);

  if (
    !isEmpty(data) &&
    !isEmpty(data.eventList) &&
    isUpcomingEventData &&
    data.event_type == 'Upcoming'
  ) {
    setEventDetail(data.eventList);

    setIsUpcomingEventData(false);
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.eventList) &&
    isPastEventData &&
    data.event_type == 'Past'
  ) {
    data.eventList.map((item) => {
      if (item.horizontal_banner_image) {
        let hBannerImage = item.horizontal_banner_image.split(',');
        item.hBannerImage = hBannerImage[0];
      }
    });
    setPastEventDetail(data.eventList);
    setIsPastEventData(false);
  }

  const addToFavorite = () => {
    if (localStorage.getItem('publicMePassUser')) {
      setIsAddData(true);
      const newData = {
        favorite_id: organiser && organiser.id,
        type: 'Merchant',
        message_type: 'Follow',
      };
      dispatch(
        addUpdateNewData(newData, props.router.navigate, api.ADD_FAVORITES),
      );
    }
  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    organiser.is_favorite = 'Yes';
  }

  // Remove favorite
  const [isRemoveData, setIsRemoveData] = useState(false);
  const removeToFavorite = (favorite_id) => {
    setIsRemoveData(true);
    const newData = {
      favorite_id: favorite_id,
      type: 'Merchant',
      message_type: 'Follow',
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.REMOVE_FAVORITES),
    );
  };
  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    organiser.is_favorite = 'No';
  }

  const testimonialEnabled =
    process.env.REACT_APP_ARTIST_VIEW_TESTIMONIAL === 'true';

  return (
    <>
      <SeoDetails
        title={organiser && organiser.merchant_company_name + ' | mepass.in'}
        description={organiser && organiser.short_description}
        ogImage={organiser && organiser.logo}
      />
      <section className="pt-10">
        <div className="container">
          <Swiper
            cssMode={true}
            mousewheel={true}
            keyboard={true}
            slidesPerView={1}
            centeredSlides={false}
            spaceBetween={40}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1.1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1.1,
                spaceBetween: 40,
              },
            }}
            modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
            className="mySwiper event-details-slider"
          >
            {bannerImage && (
              <>
                {bannerImage.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item ? item : hBannerImageDefault}
                      alt=""
                      className="rounded-lg aspect-[2.33/1] w-full"
                    />
                  </SwiperSlide>
                ))}
              </>
            )}

            {embedUrl && (
              <SwiperSlide>
                <iframe
                  className="rounded-xl aspect-[99/40] w-full"
                  src={embedUrl}
                  allow="fullscreen"
                  allowfullscreen
                ></iframe>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </section>
      <section className="bg-white pt-14 pb-14">
        <div className="container">
          <div className="flex">
            <div className="flex-none w-[37%] md:w-[30%] lg:w-1/4 ">
              {organiser && organiser.logo && (
                <div className="bg-white rounded-lg max-h-full md:max-h-80 flex items-center justify-center">
                  <img
                    src={organiser.logo ? organiser.logo : logo}
                    alt=""
                    className="rounded-xl"
                  />
                </div>
              )}
            </div>
            <div className="flex-1 w-full md:w-3/4 ml-3 md:ml-6">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  {organiser && organiser.merchant_company_name && (
                    <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-[#101010]">
                      {organiser.merchant_company_name}
                    </h2>
                  )}
                  <div className="flex gap-1.5 mt-3 md:mt-5">
                    <img
                      src={locationIcon}
                      alt="img"
                      className="inline-block mt-[2px] size-4 md:size-6"
                    />
                    <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                      {organiser && organiser.address}
                    </p>
                  </div>
                  <div className="flex gap-1.5 mt-3 md:mt-5">
                    {organiser && organiser.total_event && (
                      <div className="flex items-center gap-1.5 md:gap-2">
                        <img
                          src={calendarIcon}
                          alt="img"
                          className="inline-block size-4 md:size-5 -mt-[2px]"
                        />
                        <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                          Events Organised:{' '}
                          <b>{numberFormatter(organiser.total_event)}+</b>
                        </p>
                      </div>
                    )}
                    {organiser && organiser.rating > 0 && totalRatings > 0 && (
                      <div className="flex items-center gap-1 md:gap-2 ">
                        <img
                          src={starIcon}
                          alt="img"
                          className="inline-block size-4 md:size-5 -mt-[2px] md:-mt-[3px]"
                        />
                        {organiser && organiser.rating && (
                          <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                            {organiser.rating}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  {organiser && organiser.fanbase && (
                    <div className="flex gap-2 mt-3 md:mt-5">
                      <img
                        src={fanebaseIcon}
                        alt="img"
                        className="inline-block size-4 md:size-6"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Fanbase: <b>{numberFormatter(organiser.fanbase)}</b>
                      </p>
                    </div>
                  )}
                </div>
                {localStorage.getItem('publicMePassUser') ? (
                  <div className="flex-none">
                    {organiser?.is_favorite &&
                    organiser.is_favorite === 'Yes' ? (
                      <button
                        onClick={() => {
                          removeToFavorite(organiser.id);
                        }}
                        className="btn-liner-gradient text-white text-base text-center font-semibold py-3 px-6 rounded-lg duration-1000 ease-in-out"
                      >
                        Following
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          addToFavorite();
                        }}
                        className="btn-liner-gradient text-white text-base text-center font-semibold py-3 px-6 rounded-lg duration-1000 ease-in-out"
                      >
                        Follow
                      </button>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="flex flex-col gap-4"></div>
            </div>
          </div>

          {/* --------About Organiser------------ */}

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-20 pt-10">
            {organiser && organiser.about_company && (
              <div className="md:col-span-2">
                <h2 className="text-xl md:text-3xl font-semibold text-[#101010] ">
                  Meet the minds behind It
                </h2>
                <p className="mt-2 md:mt-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: organiser && organiser.about_company,
                    }}
                  />
                </p>
              </div>
            )}
            <div>
              {organiser && organiser.address && (
                <>
                  <h4 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                    Find us here
                  </h4>
                  <p className="text-base text-[#101010] font-normal mt-2">
                    {organiser && organiser.address}
                  </p>
                </>
              )}
              {organiser &&
                (organiser.facebook_url ||
                  organiser.instagram_url ||
                  organiser.youtube_url) && (
                  <div className="flex flex-col gap-4 mt-10">
                    <h4 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                      Invite your tribe
                    </h4>
                    <ul className="flex gap-5">
                      {organiser && organiser.whatsapp_url && (
                        <Link
                          to={organiser.whatsapp_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={whatsappIcon} alt="" />
                          </li>
                        </Link>
                      )}
                      {organiser && organiser.instagram_url && (
                        <Link
                          to={organiser.instagram_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={instagramIcon} alt="" />
                          </li>
                        </Link>
                      )}
                      {organiser && organiser.facebook_url && (
                        <Link
                          to={organiser.facebook_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={facebookIcon} alt="" />
                          </li>
                        </Link>
                      )}
                      <li>
                        <img src={twitterIcon} alt="" />
                      </li>
                    </ul>
                  </div>
                )}
            </div>
          </div>

          {/* --------Upcoming Events------------ */}
          {!isEmpty(eventDetail) && eventDetail.length > 0 && (
            <div className="pt-10">
              <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] mb-5">
                Don’t miss what’s coming
              </h2>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.3,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {eventDetail.map((singleEvent) => (
                  <SwiperSlide key={singleEvent.slug}>
                    <Link to={'/events/' + singleEvent.slug}>
                      <EventView singleEvent={singleEvent} />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          {/* --------Past Events------------ */}
          {pasteventDetail && pasteventDetail.length > 0 && (
            <div className="pt-7 md:pt-10">
              <h2 className="text-xl md:text-3xl font-semibold text-[#101010] mb-5">
                You might have missed
              </h2>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={3}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.8,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {pasteventDetail &&
                  pasteventDetail.map((singleEvent) => (
                    <SwiperSlide key={singleEvent.slug}>
                      <Link to={'/events/' + singleEvent.slug}>
                        <div className="flex flex-col gap-4">
                          <div>
                            <img
                              src={
                                singleEvent.hBannerImage
                                  ? singleEvent.hBannerImage
                                  : hBannerImageDefault
                              }
                              alt="img"
                              className="rounded-lg aspect-[2.33\1]"
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-1.5">
                              <img
                                src={calendar}
                                alt="img"
                                className="inline-block size-4 md:size-6"
                              />
                              <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                                {singleEvent.dates && singleEvent.dates[0]
                                  ? singleEvent.dates[0]['date'] &&
                                    convertToDate(singleEvent.dates[0]['date'])
                                  : ''}
                              </p>
                            </div>
                            {singleEvent.dates &&
                            singleEvent.dates[0] &&
                            singleEvent.dates[0]['address'] ? (
                              <div className="flex gap-1.5">
                                <img
                                  src={locationIcon}
                                  alt="img"
                                  className="inline-block size-4 md:size-6 mt-[2px]"
                                />
                                <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                                  {singleEvent.dates[0]['address']}{' '}
                                  {singleEvent.dates[0]['city']}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}

                {/* <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent3} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img
                        src={calendar}
                        alt="img"
                        className="inline-block size-4 md:size-6"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block size-4 md:size-6 mt-[2px]"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img
                        src={calendar}
                        alt="img"
                        className="inline-block size-4 md:size-6"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block size-4 md:size-6 mt-[2px]"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
              </Swiper>
            </div>
          )}
          {/* ----People say about us---- */}
          {!isEmpty(ratingList) && (
            <div className="mt-8">
              <h4 className="text-xl md:text-3xl font-semibold text-[#101010] mb-5">
                What people say about us
              </h4>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={3}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {ratingList.map((item, index) => (
                  <SwiperSlide>
                    <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-lg md:rounded-[16px] p-3 md:p-6 duration-1000 ease-in-out">
                      <p className="text-[#101010] font-normal text-[8px] md:text-lg">
                        {item.comments}
                      </p>
                      <div className="flex items-center mt-4">
                        <div className="flex-none">
                          {item.profile_image ? (
                            <div className="w-[20px] md:w-[40px] h-[20px] md:h-[40px] rounded-full overflow-hidden">
                              <img
                                src={item.profile_image}
                                alt=""
                                className="aspect-[1/1] rounded-full object-cover"
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="flex-1 ml-3">
                          <h4 className="text-[#101010] font-semibold text-[10px] md:text-xl">
                            {item.user_name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          {/* --------Artists worked with------------ */}
          <div className="pt-10">
            <Artist artists={artists} props={props} />
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(OrganiserDetails);
