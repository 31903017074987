import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import { convertToAmPm } from '../../common/commonFunction';

const PriceDetail = ({
  passDetails,
  pricingDetails,
  textColor,
  secondaryColor,
}) => {
  return (
    <>
      {passDetails.allocation_through == 'Share' ? (
        <div className="group border border-[#E7E7E7] rounded-[10px] p-4">
          <h2
            className={`text-lg text-[${textColor}] font-body font-semibold`}
            style={{ color: textColor }}
          >
            Shared Pass
          </h2>
        </div>
      ) : (
        <>
          <details className="group border border-[#E7E7E7] rounded-[10px] p-4">
            <summary className="select-none flex justify-between items-center cursor-pointer">
              <p
                className="text-base text-primary font-body font-normal group-open:hidden"
                style={{ color: textColor }}
              >
                Pricing Details
              </p>
              <p
                className="text-base text-[#5A5A5A] font-body font-normal hidden group-open:block"
                style={{ color: textColor }}
              >
                Pricing Details
              </p>
              <svg
                className="h-4 w-4 text-[#5A5A5A] hidden group-open:block"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M5.575 13.729C4.501 15.033 5.43 17 7.12 17h9.762c1.69 0 2.618-1.967 1.544-3.271l-4.881-5.927a2 2 0 0 0-3.088 0l-4.88 5.927Z"
                  clipRule="evenodd"
                />
              </svg>
              <svg
                className="h-4 w-4 text-[#5A5A5A] group-open:hidden"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M18.425 10.271C19.499 8.967 18.57 7 16.88 7H7.12c-1.69 0-2.618 1.967-1.544 3.271l4.881 5.927a2 2 0 0 0 3.088 0l4.88-5.927Z"
                  clipRule="evenodd"
                />
              </svg>
            </summary>
            <div>
              <div className="border-b-2 border-dashed border-primary my-3"></div>
              <h3
                className="text-lg text-[#5A5A5A] font-body font-semibold mb-3"
                style={{ color: textColor }}
              >
                {passDetails && passDetails.event && passDetails.event.name}
              </h3>
              {pricingDetails && pricingDetails.amount > 0 && (
                <div className="flex items-center justify-between gap-4 mb-3">
                  <h3
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    Ticket Price
                  </h3>
                  <p
                    className="text-base  font-body font-medium"
                    style={{ color: textColor }}
                  >
                    ₹ {pricingDetails.amount}
                  </p>
                </div>
              )}
              {pricingDetails && pricingDetails.amount > 0 && (
                <div className="flex items-center justify-between gap-4 mb-3">
                  <h3
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    QTY
                  </h3>
                  <p
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    {passDetails.available_quantity}
                  </p>
                </div>
              )}
              {pricingDetails && pricingDetails.igst > 0 && (
                <div className="flex items-center justify-between gap-4 mb-3">
                  <h3
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    IGST
                  </h3>
                  <p
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    ₹ {pricingDetails.igst}
                  </p>
                </div>
              )}
              {pricingDetails && pricingDetails.convenience_charge > 0 && (
                <div className="flex items-center justify-between gap-4 mb-3">
                  <h3
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    Convenience Charge
                  </h3>
                  <p
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    ₹ {pricingDetails.convenience_charge}
                  </p>
                </div>
              )}
              {pricingDetails && pricingDetails.discount > 0 && (
                <div className="flex items-center justify-between gap-4 mb-3">
                  <h3
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    Discount
                  </h3>
                  <p
                    className="text-base font-body font-medium"
                    style={{ color: textColor }}
                  >
                    ₹ {pricingDetails.discount}
                  </p>
                </div>
              )}
              <div className="border-b-2 border-dashed border-primary my-4"></div>
              {pricingDetails.net_amount > 0 && (
                <div className="flex items-center justify-between gap-4 mb-3">
                  <h3
                    className="text-base text-[#5A5A5A] font-body font-medium"
                    style={{ color: textColor }}
                  >
                    Amount Paid
                  </h3>
                  <p
                    className="text-base text-[#5A5A5A] font-body font-medium"
                    style={{ color: textColor }}
                  >
                    ₹ {pricingDetails.net_amount}
                  </p>
                </div>
              )}
            </div>
          </details>
        </>
      )}
    </>
  );
};
PriceDetail.propTypes = {};
export default PriceDetail;
