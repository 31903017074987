import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getEventDetail,
  clearEventDetailData,
  getEventArtists,
  getEventSponsors,
  getNearestEvents,
} from '../../../store/events/actions';
import { clearData, getData } from '../../../store/AppMaster/actions';
import api from '../../../constants/api_constants';
import { convertToAmPm, convertToDate } from '../../../common/commonFunction';

const useEventDetails = (props) => {
  const dispatch = useDispatch();

  const { eventDetail, eventRating, upcomingEventDetail, artists, sponsors } =
    useSelector((state) => ({
      eventDetail: state.events.eventDetail,
      eventRating: state.events.eventRating,
      upcomingEventDetail: state.events.eventNearestEvents,
      artists: state.events.eventArtists,
      sponsors: state.events.eventSponsors,
    }));

  const { data, loading } = useSelector((state) => ({
    data: state.AppMaster.data,
    loading: state.AppMaster.loading,
  }));

  const [bannerImage, setBannerImage] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [videoUrl, setVideoUrl] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isAddData, setIsAddData] = useState(false);
  const [isRemoveData, setIsRemoveData] = useState(false);

  // Store previous values of event category slug
 const prevEventSlug = useRef(props.router.params.event_slug);

  // Artist data fetch
  const artistDataGet = useCallback(() => {
    const userDetail = JSON.parse(
      localStorage.getItem('publicMePassUserDetails'),
    );
    let requestData = {
      event_slug: props.router.params.event_slug,
      user_id: userDetail?.id || '',
    };
    dispatch(getEventArtists(requestData, props.router.navigate));
  }, [dispatch, props.router]);

  // Sponsor data fetch
  const sponsorDataGet = useCallback(() => {
    let requestData = { event_slug: props.router.params.event_slug };
    dispatch(getEventSponsors(requestData, props.router.navigate));
  }, [dispatch, props.router]);

  // Upcoming event data fetch
  const upComingEventDataGet = () => {
    const userDetail = JSON.parse(
      localStorage.getItem('publicMePassUserDetails'),
    );
    let requestData = {
      event_slug: props.router.params.event_slug,
      user_id: userDetail?.id || '',
    };
    dispatch(getNearestEvents(requestData, props.router.navigate));
  };

  useEffect(() => {
    const {  event_slug } =props.router.params.event_slug;
    if (event_slug !== prevEventSlug.current) {
      const userDetail = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );
      prevEventSlug.current =props.router.params.event_slug;
      const requestData = {
        event_slug:props.router.params.event_slug,
        user_id: userDetail?.id || '',
      };
      dispatch(getEventDetail(requestData, props.router.navigate));
      artistDataGet();
      sponsorDataGet();
      upComingEventDataGet();
    }
  }, [props.router.params.event_slug]);

  // Handle event detail and related data updates
  useEffect(() => {
    if (!isEmpty(eventDetail)) {
      if (eventDetail.dates?.length > 0 && eventDetail.dates[0].gmap_link) {
        const match = eventDetail.dates[0].gmap_link.match(
          /place\/([^/]+)\/@(-?\d+\.\d+),(-?\d+\.\d+)/,
        );
        if (match) {
          const [latitude, longitude] = [
            parseFloat(match[2]),
            parseFloat(match[3]),
          ];
          setMapCenter({ lat: latitude, lng: longitude });
        }
      }
      if (eventDetail.horizontal_banner_image) {
        setBannerImage(eventDetail.horizontal_banner_image.split(','));
      }
      if (eventDetail.youtube_url) {
        setEmbedUrl(extractEmbedUrl(eventDetail.youtube_url));
        setVideoUrl(eventDetail.youtube_url);
      }
    }
  }, [ eventDetail]);

  // Extract embed URL from YouTube URL
  const extractEmbedUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const videoId = parsedUrl.searchParams.get('v');
      const playlistId = parsedUrl.searchParams.get('list');
      return videoId
        ? `https://www.youtube.com/embed/${videoId}?autoplay=1&list=${
            playlistId || ''
          }`
        : null;
    } catch (error) {
      return null;
    }
  };

  // Add to favorites
  const addToFavorite = (eventId) => {
    setIsAddData(true);
    const newData = { favorite_id: eventId, type: 'Event' };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.ADD_FAVORITES),
    );
  };

  // Remove from favorites
  const removeToFavorite = (eventId) => {
    setIsRemoveData(true);
    const newData = { favorite_id: eventId, type: 'Event' };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.REMOVE_FAVORITES),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    setIsUpdateData(true);
    eventDetail.is_favorite = 'Yes';
  }

  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    setIsUpdateData(true);
    dispatch(clearData());
    eventDetail.is_favorite = 'No';
  }

  const eventDateDisplay = () => {
    if (eventDetail.dates && eventDetail.dates[0]) {
      //First and Last Array Date not Same then Display Date
      if (eventDetail.dates.length > 1) {
        if (
          eventDetail.dates[0]['date'] !=
          eventDetail.dates[eventDetail.dates.length - 1]['date']
        ) {
          //First and Last Array Date in Year Same then only Date and month Display Remove Year
          if (
            eventDetail.dates[0]['date'].split('-')[2] ==
            eventDetail.dates[eventDetail.dates.length - 1]['date'].split(
              '-',
            )[2]
          ) {
            let firstDate = convertToDate(eventDetail.dates[0]['date']);
            let lastDate = convertToDate(
              eventDetail.dates[eventDetail.dates.length - 1]['date'],
            );
            return (
              firstDate.split(',')[0] +
              ', ' +
              firstDate.split(',')[1] +
              ' - ' +
              lastDate
            );
          } else {
            return (
              convertToDate(eventDetail.dates[0]['date']) +
              ' - ' +
              convertToDate(
                eventDetail.dates[eventDetail.dates.length - 1]['date'],
              )
            );
          }
        } else {
          return convertToDate(eventDetail.dates[0]['date']);
        }
      } else {
        return convertToDate(eventDetail.dates[0]['date']);
      }
    }
  };

  const getFullAddress = (date) => {
    let address = '';
    if (date['address']) {
      address = date['address'];
    }
    if (date['city']) {
      address += ', ' + date['city'];
    }
    if (date['state'] && date['state'] != date['city']) {
      address += ', ' + date['state'];
    }
    if (date['country'] && date['country'] != date['state']) {
      address += ', ' + date['country'];
    }
    return address;
  };

  const mapContainerStyle = {
    width: '100%',
    height: '300px',
    borderRadius: '12px',
  };

  const handleMarkerClick = () => {
    // Replace with the desired Google Maps redirection link
    if (
      singleEvent.dates &&
      singleEvent.dates.length > 0 &&
      singleEvent.dates[0].gmap_link
    ) {
      window.open(singleEvent.dates[0].gmap_link, '_blank');
    } else if (singleEvent.gmap_link) {
      window.open(singleEvent.gmap_link, '_blank');
    }
  };

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null); // Store Swiper instance

  const handleReachEnd = () => {
    if (swiperInstance && swiperInstance.autoplay) {
      swiperInstance.autoplay.stop(); // Stop autoplay when the last slide is reached
    }
  };

  // Return all states and functions needed by the component
  return {
    eventDetail,
    artists,
    sponsors,
    upcomingEventDetail,
    bannerImage,
    videoUrl,
    embedUrl,
    isVideoPlaying,
    mapCenter,
    addToFavorite,
    removeToFavorite,
    isAddData,
    isRemoveData,
    setIsAddData,
    setIsRemoveData,
    eventRating,
    eventDateDisplay,
    getFullAddress,
    mapContainerStyle,
    handleMarkerClick,
    openIndex,
    handleToggle,
    swiperInstance,
    handleReachEnd,
    setSwiperInstance,
    swiperRef
  };
};

export default useEventDetails;
