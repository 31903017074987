import React, { useState, useEffect, useCallback } from 'react';
import Artist from '../../components/Event/Details/Artist';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../constants/api_constants';
import { isEmpty } from 'lodash';
import {
  getData,
  clearData,
  addUpdateNewData,
} from '../../store/AppMaster/actions';
import { Link } from 'react-router-dom';
import EventView from '../../components/Event/View';
import withRouter from '../../components/Common/withRouter';
import SeoDetails from '../../seo/SeoDetails';
import SkeletonLoaderArtiest from '../../components/SkeletonLoader/Artiest/Index';
import arjitSingh from '../../assets/light_theme_images/arjitsingh.png';
import bannerImageDefault from '../../assets/images/default/event-image.jpg';
import hBannerImageDefault from '../../assets/images/default/PastEventsBanner.png';
import { convertToAmPm, convertToDate } from '../../common/commonFunction';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from 'swiper/modules';
// Import Swiper styles
import userImageDefault from '../../assets/images/default/user-image.png';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import calendarIcon from '../../assets/light_theme_images/born-calendar.svg';
import categoryIcon from '../../assets/light_theme_images/category-icon.svg';
import calendar from '../../assets/light_theme_images/calender-dark.svg';
import fanebaseIcon from '../../assets/light_theme_images/fanebase.svg';
import starIcon from '../../assets/light_theme_images/star-dark.svg';
import locationIcon from '../../assets/light_theme_images/location-dark.svg';
import instagramIcon from '../../assets/light_theme_images/instagram.svg';
import facebookIcon from '../../assets/light_theme_images/facebook.svg';
import twitterIcon from '../../assets/light_theme_images/twitter.svg';
import postEvent from '../../assets/light_theme_images/post-event.png';
import postEvent2 from '../../assets/light_theme_images/post-event2.png';
import postEvent3 from '../../assets/light_theme_images/post-event3.png';
import gallaryImg from '../../assets/light_theme_images/gallary-img.png';
import gallaryImg2 from '../../assets/light_theme_images/gallary-img2.png';
import gallaryImg3 from '../../assets/light_theme_images/gallary-img3.png';

function Artiest(props) {
  const [activeButton, setActiveButton] = useState(1);
  const [eventDetail, setEventDetail] = useState(null);
  const [isEventData, setIsEventData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [artistDetails, setArtistDetails] = useState(false);
  const [artistView, setArtistView] = useState({});
  const [isUpcomingEventData, setIsUpcomingEventData] = useState(false);
  const [upcomingEventDetail, setUpcomingEventDetail] = useState('');
  const [isAddData, setIsAddData] = useState(true);
  const [favoriteList, getFavoriteList] = useState(false);
  const [artists, setArtists] = useState(null);
  const [coverImage, setCoverImage] = useState('');
  const [ratingList, setRatingList] = useState([]);
  const [isPastEventData, setIsPastEventData] = useState(false);
  const [pasteventDetail, setPastEventDetail] = useState(null);
  const [artistList, setArtistList] = useState([]);
  const [isArtistList, setIsArtistList] = useState(false);
  const [hBannerImage, setHBannerImage] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();

  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const ratingData = artistView.ratingUser || {};
  const pastEventDataGet = useCallback(() => {
    setIsPastEventData(true);
    let requestData = {
      artist_slug: params.artiest_slug,
      events: 'Past',
    };
    dispatch(clearData());
    dispatch(getData(requestData, '', api.EVENT_LIST));
  });

  const upComingEventDataGet = useCallback(() => {
    setIsUpcomingEventData(true);
    let requestData = {
      artist_slug: params.artiest_slug,
      events: 'Upcoming',
    };
    dispatch(clearData());
    dispatch(getData(requestData, '', api.EVENT_LIST));
  });

  const addToFavorite = () => {
    if (localStorage.getItem('publicMePassUser')) {
      setIsAddData(true);
      const newData = {
        favorite_id: artistView.id,
        type: 'Artist',
        message_type: 'Follow',
      };
      dispatch(
        addUpdateNewData(newData, props.router.navigate, api.ADD_FAVORITES),
      );
    }
  };

  if (!isEmpty(data) && !isEmpty(data.favoritesAdd) && isAddData) {
    setIsAddData(false);
    artistView.is_favorite = 'Yes';
  }

  // Remove favorite
  const [isRemoveData, setIsRemoveData] = useState(false);
  const removeToFavorite = (favorite_id) => {
    setIsRemoveData(true);
    const newData = {
      favorite_id: favorite_id,
      type: 'Artist',
      message_type: 'Follow',
    };
    dispatch(
      addUpdateNewData(newData, props.router.navigate, api.REMOVE_FAVORITES),
    );
  };
  if (!isEmpty(data) && !isEmpty(data.favoritesDelete) && isRemoveData) {
    setIsRemoveData(false);
    artistView.is_favorite = 'No';
  }

  useEffect(() => {
    getArtiestDetails();
  }, [params.artiest_slug]);

  const getArtiestDetails = () => {
    setArtistDetails(true);
    setIsArtistList(true);
    const userDetail = JSON.parse(
      localStorage.getItem('publicMePassUserDetails'),
    );
    const requestData = {
      artist_slug: params.artiest_slug,
      user_id: userDetail && userDetail.id ? userDetail.id : '',
    };
    dispatch(clearData());
    dispatch(getData(requestData, props.router.navigate, api.ARTIST_VIEW));
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.artistView) && artistDetails) {
      upComingEventDataGet();
      pastEventDataGet();
      setRatingList(data.rating);
      if (data.artistView.category_of_artist) {
        data.artistView.category_of_artist =
          data.artistView.category_of_artist &&
          data.artistView.category_of_artist
            .split(',')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(', ');
      }
      setArtistView(data.artistView);

      if (data.artistView.cover_image) {
        //Separate using Comma
        let coverImage = data.artistView.cover_image.split(',');
        setCoverImage(coverImage);
      }
      setArtistDetails(false);
    }
    if (!isEmpty(data) && !isEmpty(data.profileView) && profileFlag) {
      setProfileData(data.profileView);
    }

    if (!isEmpty(data) && !isEmpty(data.similarArtist) && isArtistList) {
      setArtists(data.similarArtist);
      setIsArtistList(false);
    }
  }, [data, artistDetails, isPastEventData, isUpcomingEventData]);

  const totalRatings = Object.values(ratingData).reduce(
    (acc, count) => acc + count,
    0,
  );
  if (
    !isEmpty(data) &&
    !isEmpty(data.eventList) &&
    isUpcomingEventData &&
    data.event_type == 'Upcoming'
  ) {
    setEventDetail(data.eventList);
    setIsUpcomingEventData(false);
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.eventList) &&
    isPastEventData &&
    data.event_type == 'Past'
  ) {
    data.eventList.map((item) => {
      if (item.horizontal_banner_image) {
        let hBannerImage = item.horizontal_banner_image.split(',');
        item.hBannerImage = hBannerImage[0];
      }
    });
    setPastEventDetail(data.eventList);
    setIsPastEventData(false);
  }
  const getPercentage = (count) =>
    totalRatings > 0 ? (count / totalRatings) * 100 : 0;

  const instagramIntegration =
    process.env.REACT_APP_ARTIST_VIEW_INSTAGRAM_INTIGRATION === 'true';
  const testimonialEnabled =
    process.env.REACT_APP_ARTIST_VIEW_TESTIMONIAL === 'true';

  return (
    <>
      <SeoDetails
        title={`${artistView.name + ' | mepass.in'}`}
        ogImage={artistView.image}
      />
      {coverImage && coverImage.length > 0 && (
        <section className="pt-10">
          <div className="container">
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={1.1}
              centeredSlides={false}
              spaceBetween={40}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1.1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 1.1,
                  spaceBetween: 40,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper event-details-slider"
            >
              {coverImage.map((singleImage, index) => (
                <SwiperSlide>
                  <img
                    src={singleImage}
                    alt=""
                    className="rounded-xl aspect-[99/40] w-full"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}

      <section className="bg-white pt-14 pb-14">
        <div className="container">
          <div className="flex">
            <div className="flex-none w-[37%] md:w-[30%] lg:w-1/4 ">
              <img
                src={artistView.image ? artistView.image : userImageDefault}
                alt=""
                className="rounded-[4px] md:rounded-xl aspect-[1/1] m-auto"
              />
            </div>
            <div className="flex-1 w-full md:w-3/4 ml-3 md:ml-6">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1">
                  <h2 className="text-lg md:text-2xl lg:text-4xl font-bold text-[#101010]">
                    {artistView.name}
                  </h2>
                  <div className="flex gap-4 mt-3 md:mt-5">
                    {artistView.fanbase > 0 && (
                      <div className="flex gap-2">
                        <img
                          src={categoryIcon}
                          alt="img"
                          className="inline-block size-4 md:size-6 mt-[2px] md:mt-0"
                        />
                        <p className="text-[#101010] text-sm md:text-xl font-normal">
                          Category:{' '}
                          <b>
                            {' '}
                            {artistView.category_of_artist
                              ? artistView.category_of_artist
                              : ''}
                          </b>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex gap-4 mt-3 md:mt-5">
                    {artistView.fanbase > 0 && (
                      <div className="flex gap-2">
                        <img
                          src={fanebaseIcon}
                          alt="img"
                          className="inline-block size-4 md:size-6 mt-[2px] md:mt-0"
                        />
                        <p className="text-[#101010] text-sm md:text-xl font-normal">
                          Fanbase:{' '}
                          <b>
                            {' '}
                            {artistView.fanbase >= 1 ? artistView.fanbase : 0}
                          </b>
                        </p>
                      </div>
                    )}
                    {artistView.rating > 0 && totalRatings > 0 && (
                      <div className="flex items-center gap-2">
                        <img
                          src={starIcon}
                          alt="img"
                          className="inline-block -mt-[2px] size-4 md:size-6"
                        />
                        <p className="text-[#101010] text-sm md:text-xl font-normal">
                          {artistView.rating === '' ? 0 : artistView.rating}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {localStorage.getItem('publicMePassUser') ? (
                  <div className="flex-none">
                    {artistView.is_favorite === 'Yes' ? (
                      <button
                        onClick={() => {
                          removeToFavorite(artistView.id);
                        }}
                        className="btn-liner-gradient text-white text-base text-center font-semibold py-3 px-6 rounded-lg duration-1000 ease-in-out"
                      >
                        Following
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          addToFavorite();
                        }}
                        className="btn-liner-gradient text-white text-base text-center font-semibold py-3 px-6 rounded-lg duration-1000 ease-in-out"
                      >
                        Follow
                      </button>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          {/* --------About Artist------------ */}

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-20 pt-10">
            <div className="col-span-2">
              {artistView.about_artist && (
                <>
                  <h2 className="text-xl md:text-3xl font-semibold text-[#101010] ">
                    The story behind glory
                  </h2>
                  <p className="text-xl text-[#101010] font-normal mt-2 md:mt-4">
                    {artistView.about_artist}
                  </p>
                </>
              )}
            </div>
            <div>
              {artistView &&
                (artistView.facebook_url ||
                  artistView.instagram_url ||
                  artistView.twitter_url) && (
                  <div className="flex flex-col gap-4">
                    <h4 className="text-base md:text-2xl font-semibold text-[#101010] ">
                      Invite your tribe
                    </h4>
                    <ul className="flex gap-5">
                      {artistView.instagram_url && (
                        <Link
                          to={artistView.instagram_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={instagramIcon} alt="" />
                          </li>
                        </Link>
                      )}
                      {artistView.facebook_url && (
                        <Link
                          to={artistView.facebook_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={facebookIcon} alt="" />
                          </li>
                        </Link>
                      )}
                      {artistView.twitter_url && (
                        <Link
                          to={artistView.twitter_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <img src={twitterIcon} alt="" />
                          </li>
                        </Link>
                      )}
                    </ul>
                  </div>
                )}
            </div>
          </div>

          {/* --------Upcoming Events------------ */}
          {!isEmpty(eventDetail) && eventDetail.length > 0 && (
            <div className="pt-10">
              <h2 className="text-xl md:text-3xl font-semibold text-[#101010] mb-5">
                Don’t miss what’s coming
              </h2>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={4}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 2.1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {eventDetail.map((singleEvent) => (
                  <SwiperSlide key={singleEvent.slug}>
                    <Link to={'/events/' + singleEvent.slug}>
                      <EventView singleEvent={singleEvent} />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          {/* ----Gallery---- */}

          {/* --------Past Events------------ */}
          {pasteventDetail && pasteventDetail.length > 0 && (
            <div className="pt-7 md:pt-10">
              <h2 className="text-xl md:text-3xl font-semibold text-[#101010] mb-5">
                You might have missed
              </h2>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={3}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.8,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {pasteventDetail &&
                  pasteventDetail.map((singleEvent) => (
                    <SwiperSlide key={singleEvent.slug}>
                      <Link to={'/events/' + singleEvent.slug}>
                        <div className="flex flex-col gap-4">
                          <div>
                            <img
                              src={
                                singleEvent.hBannerImage
                                  ? singleEvent.hBannerImage
                                  : hBannerImageDefault
                              }
                              alt="img"
                              className="rounded-lg aspect-[2.33\1]"
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-1.5">
                              <img
                                src={calendar}
                                alt="img"
                                className="inline-block size-4 md:size-6"
                              />
                              <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                                {singleEvent.dates && singleEvent.dates[0]
                                  ? singleEvent.dates[0]['date'] &&
                                    convertToDate(singleEvent.dates[0]['date'])
                                  : ''}
                              </p>
                            </div>
                            {singleEvent.dates &&
                            singleEvent.dates[0] &&
                            singleEvent.dates[0]['address'] ? (
                              <div className="flex gap-1.5">
                                <img
                                  src={locationIcon}
                                  alt="img"
                                  className="inline-block size-4 md:size-6 mt-[2px]"
                                />
                                <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                                  {singleEvent.dates[0]['address']}{' '}
                                  {singleEvent.dates[0]['city']}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}

                {/* <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent3} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img
                        src={calendar}
                        alt="img"
                        className="inline-block size-4 md:size-6"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block size-4 md:size-6 mt-[2px]"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex flex-col gap-4">
                  <div>
                    <img src={postEvent} alt="img" className="rounded-lg" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1.5">
                      <img
                        src={calendar}
                        alt="img"
                        className="inline-block size-4 md:size-6"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Sun 20 Oct 24
                      </p>
                    </div>
                    <div className="flex gap-1.5">
                      <img
                        src={locationIcon}
                        alt="img"
                        className="inline-block size-4 md:size-6 mt-[2px]"
                      />
                      <p className="text-[#101010] text-[12px] md:text-xl font-normal">
                        Tea Post, Gandhinagar
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
              </Swiper>
            </div>
          )}
          {/* ----People say about us---- */}
          {!isEmpty(ratingList) && (
            <div className="mt-8">
              <h4 className="text-xl md:text-3xl font-semibold text-[#101010] mb-5">
                What fans are saying
              </h4>
              <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={3}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                className="mySwiper gallary-slider !pb-10"
              >
                {ratingList.map((item, index) => (
                  <SwiperSlide>
                    <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-lg md:rounded-[16px] p-3 md:p-6 duration-1000 ease-in-out">
                      <p className="text-[#101010] font-normal text-[8px] md:text-lg">
                        {item.comments}
                      </p>
                      <div className="flex items-center mt-4">
                        <div className="flex-none">
                          {item.profile_image ? (
                            <div className="w-[20px] md:w-[40px] h-[20px] md:h-[40px] rounded-full overflow-hidden">
                              <img
                                src={item.profile_image}
                                alt=""
                                className="aspect-[1/1] rounded-full object-cover"
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="flex-1 ml-3">
                          <h4 className="text-[#101010] font-semibold text-[10px] md:text-xl">
                            {item.user_name}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          {/* --------Artists worked with------------ */}
          <div className="pt-10">
            <Artist artists={artists} props={props} displayName="Peers" />
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          {/* {instagramIntegration && (
            <div className="pt-10 md:pt-24">
              <a
                href={artistView.youtube_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-primary border border-primary text-white text-sm md:text-base font-normal tracking-[.25em] py-1 md:py-2 px-2 md:px-8 rounded font-body hover:bg-white hover:text-primary duration-1000 ease-in-out uppercase">
                  <i className="fab fa-instagram mr-1 md:mr-3"></i>Follow
                  Instgram
                </button>
              </a>
              <div className="grid grid-cols-4 gap-4 md:gap-8 mt-8">
                <div>
                  <img src={follow1} alt="" className="w-full" />
                </div>
                <div>
                  <img src={follow2} alt="" className="w-full" />
                </div>
                <div>
                  <img src={follow3} alt="" className="w-full" />
                </div>
                <div>
                  <img src={follow4} alt="" className="w-full" />
                </div>
              </div>
            </div>
          )} */}
          {/* -------------------------------section-end------------------------- */}

          {/* -----------------------------------section-start---------------------- */}

          {/* -------------------------------Testimonail-Start------------------------- */}

          {/* {testimonialEnabled && (
            <div className="pt-10 md:pt-24">
              <h2 className="text-2xl md:text-[40px] font-body font-light tracking-[.150em] text-white uppercase text-center">
                Testimonial
              </h2>
              <div className="hidden md:block">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8">
                
                  <div className="bg-black p-5 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-base font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img src={testimonail} alt="" className="rounded-lg" />
                    </div>
                    <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  
                  <div className="bg-black p-5 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-base font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img src={testimonail} alt="" className="rounded-lg" />
                    </div>
                    <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
               
                  <div className="bg-black p-5 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-base font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img src={testimonail} alt="" className="rounded-lg" />
                    </div>
                    <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                 
                  <div className="bg-black p-5 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-base font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-xs mt-2">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img src={testimonail} alt="" className="rounded-lg" />
                    </div>
                    <p className="text-[#DDDDDD] text-lg font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:hidden block">
                <div className="grid grid-cols-2 gap-4 mt-8">
                 
                  <div className="bg-black p-2 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-sm font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img
                        src={testimonail}
                        alt=""
                        className="rounded-lg w-[40%]"
                      />
                    </div>
                    <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  
                  <div className="bg-black p-2 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-sm font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img
                        src={testimonail}
                        alt=""
                        className="rounded-lg w-[40%]"
                      />
                    </div>
                    <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                 
                  <div className="bg-black p-2 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-sm font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img
                        src={testimonail}
                        alt=""
                        className="rounded-lg w-[40%]"
                      />
                    </div>
                    <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                  <div className="bg-black p-2 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-white text-sm font-body font-bold">
                          Jenny Wilson
                        </h3>
                        <div className="flex items-center gap-1 text-primary text-[12px] mt-1">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <img
                        src={testimonail}
                        alt=""
                        className="rounded-lg w-[40%]"
                      />
                    </div>
                    <p className="text-[#DDDDDD] text-sm font-body font-normal mt-3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {/* -------------------------------Testimonail-end------------------------- */}
        </div>
      </section>
    </>
  );
}

export default withRouter(Artiest);
