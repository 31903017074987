import React from 'react';
import { Link } from 'react-router-dom';
import ArtistView from '../../Artist/View';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { isEmpty } from 'lodash';

const Artist = ({ artists, props, displayName }) => {

  return (
    <>
      {!isEmpty(artists) ? (
        <div>
          <h3 className="text-xl md:text-[32px] font-semibold text-[#212121] ">
            {displayName ? displayName : 'Stars in our journey'}
          </h3>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.4,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper mt-5 gallary-slider !pb-10"
          >
            {artists &&
              artists.map((singleArtist, index) => (
                <>
                  <SwiperSlide>
                    <Link to={'/artiest/' + singleArtist.slug}>
                      <ArtistView
                        singleArtist={singleArtist}
                        key={index}
                        pageTitle="EventDetails"
                      />
                    </Link>
                  </SwiperSlide>
                </>
              ))}
          </Swiper>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Artist;
