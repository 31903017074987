const apiConstants = {
  QR_PASS_DETAILS: 'v1/public/qr-pass/view',
  QR_SPONSOR_LIST: 'v1/public/qr-pass/sponsor-view',
  QR_POPUP_LIST: 'v1/public/qr-pass/popup-view',
  SPLIT_SHARE_PASS: 'v1/public/qr-pass/split-share-pass',
  EVENT_LIST: 'v1/public/event-detail/list',
  EVENT_DETAILS: 'v1/public/event-detail/event-view',
  EVENT_ARTIST_LIST: 'v1/public/event-detail/artist-list',
  EVENT_SPONSOR_LIST: 'v1/public/event-detail/sponsor-list',
  ORGANISER_DETAILS: 'v1/public/event-detail/merchant-view',
  CMS_PAGE: 'v1/public/cms-page',
  CHECK_IS_EVENT_SELLER: 'v1/public/register/check-event-seller',
  EVENT_SELLER_SAVE: 'v1/public/register/seller-register',
  SEND_OTP: 'v1/public/send-otp',
  LOGIN: 'v1/public/public-login',
  GOOGLE_SIGNUP: 'v1/public/google-signup',
  CHECK_MOBILE: 'v1/public/check-mobile',
  CHECK_EMAIL: 'v1/public/check-email',
  CITY_LIST_FOR_HEADER: 'v1/public/country-state-city/city-list',
  CATEGORY_LIST: 'v1/public/event-detail/category-list',
  GET_PASS_PURCHASE_LIST: 'v1/public/payment/get-details',
  REGISTER: 'v1/public/public-signup',
  GET_PROFILE: 'v1/public/get-profile',
  UPDATE_PROFILE: 'v1/public/update-profile',
  GET_NOTIFICATION: 'v1/public/notification',
  APPLY_OFFER: 'v1/public/payment/offer-apply',
  MAKE_PAYMENT: 'v1/public/payment/make-payment',
  CHECK_PAYMENT_STATUS: 'v1/public/payment/check-order-status',
  ARTIST_VIEW: 'v1/public/event-detail/artist-view',
  UPCOMING_EVENT: 'v1/public/event-detail/upcoming-event',
  BILLING_INFO: 'v1/public/payment/billing-details',
  VIEW_USER_PROFILE: 'v1/public/profile/view',
  UPDATE_USER_PROFILE: 'v1/public/profile/profile-update',
  GET_TICKET_HISTORY: 'v1/public/event-pass-history/pass-history',
  UPDATE_SETTING: 'v1/public/profile/setting',
  ADD_FAVORITES_ARTIST_LIST: 'v1/public/add-favorites/list',
  ARTIST_LIST: 'v1/public/add-favorites/artist-list',
  ADD_FAVORITES: 'v1/public/add-favorites/store',
  REMOVE_FAVORITES: 'v1/public/add-favorites/delete',
  GET_EVENT_DATE_LIST: 'v1/public/payment/date-dropdown',
  GET_PASS_LIST_BY_DATE: 'v1/public/payment/get-details-date',
  GET_TICKET_HISTORY_DETAILS: 'v1/public/event-pass-history/share-history',
  CMS_PAGE_DROPDOWN: 'v1/public/cms-page-dropdown',
  NOTIFICATION_LIST: 'v1/public/notification/list',
  MARK_ALL_AS_READ: 'v1/public/notification/read',
  UPDATE_FCM_TOKEN: 'v1/public/add-notification-token',
  USER_VIEW: 'v1/public/profile/newView',
  SAVE_RATING: 'v1/public/rating/store',
  SAVE_FEEDBACK: 'v1/public/add-favorites/store',
  DELETE_FEEDBACK: 'v1/public/add-favorites/delete',
  NEAREST_EVENT: 'v1/public/event-detail/nearest-events',
  GET_USER_NAME_BY_MOBILE: 'v1/public/get-details-by-mobile',
  POPULAR_CITY_LIST: 'v1/public/event-detail/popular-cities',
  CITY_DETECT: 'v1/public/city-detect',
  REGISTRATION_FORM_GET: 'v1/public/registration-form/view',
  REGISTRATION_FORM_STORE: 'v1/public/registration-form/submit',
  GET_CITY_BY_STATE: 'v1/public/get-city',
  DELETE_IMAGE: 'v1/public/image-delete',
  UPLOAD_IMAGE: 'v1/public/image-upload',
  BUSINESS_PAGE_COUNT: 'v1/public/business/business-count',
  BLOG_LIST: 'v1/public/blog/list',
  BLOG_DETAIL: 'v1/public/blog/view',
  MERCHANT_LIST: 'v1/public/add-favorites/merchant-list',
  GET_EVENT_PASS_SEAT_LIST: 'v1/public/payment/get-seats',
  CHECK_SEATS_BOOKED_OR_NOT: 'v1/public/payment/check-seats',
};
export default apiConstants;
