import { toast } from 'react-toastify';
import { toastOptions } from './common_helper';

const setOptions = (data) => {
  const authData = JSON.parse(localStorage.getItem('publicMePassUser'));
  return {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authData,
    },
  };
};

const handleApiError = (response, history) => {
  if (response.STATUS_CODE === 401) {
    toast.error(response.MESSAGE, toastOptions);
    localStorage.removeItem('publicMePassUser');
    localStorage.removeItem('publicMePassUserDetails');
    localStorage.removeItem('publicUserSelectMePassCity');
    localStorage.removeItem('passDetailForLocalStorage');
    localStorage.removeItem('selectedDateForLocalStorage');
    localStorage.removeItem('promoCode');
    history('/login', { state: { previousPage: 'Login' } });
  } else if (response.STATUS_CODE === 408) {
    toast.error(response.MESSAGE, toastOptions);
    history('/home');
  } else {
    toast.error(response.MESSAGE, toastOptions);
  }
};

export { setOptions, handleApiError };
