import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';

const SearchModal = ({
  logo,
  calendarGradient,
  cmsData,
  notification,
  authUserDetails,
  user,
  setShowSearchModal,
  setShowCityModal,
  searchEvent,
  setSearchEvent,
  handleEventSearchChange,
}) => {
  return (
    <>
      <div className="fixed z-30 overflow-y-auto top-0 md:top-24 w-full left-0">
        <div className="flex md:items-center justify-center min-h-screen md:pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center bg-white md:rounded-[32px] text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[800px]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="relative z-10 block md:hidden">
              <nav className="bg-white w-full header-border-liner-gradient">
                <div className="container mx-auto py-[15px]">
                  <div className="relative flex md:h-16 items-center justify-between gap-3 md:gap-14">
                    <div className="w-[163.63px]">
                      <Link to="/home">
                        <img
                          className="w-auto"
                          src={logo}
                          alt="Your Company name"
                        />
                      </Link>
                    </div>
                    <div className="flex-auto items-center w-full">
                      <div>
                        <div className="flex items-center justify-end">
                          <div className="block lg:hidden">
                            <div className="flex">
                              <div className="relative mr-2">
                                <div
                                  onClick={() => {
                                    setShowSearchModal(true);
                                    setShowCityModal(false);
                                  }}
                                  className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                                >
                                  <i class="fa-solid fa-magnifying-glass text-[#101010]"></i>
                                </div>
                              </div>
                              <div className="relative mr-2">
                                <div
                                  className="size-[34px] md:size-[45px] flex items-center justify-center cursor-pointer search-border-liner-gradient"
                                  onClick={() => {
                                    setShowCityModal(true);
                                    setShowSearchModal(false);
                                  }}
                                >
                                  <div
                                    className="flex items-center cursor-pointer"
                                    onClick={() => {
                                      setShowCityModal(true);
                                    }}
                                  >
                                    <i className="fa-solid fa-location-dot text-[#101010]"></i>
                                    {/* <p className="text-sm md:text-lg text-black font-normal">
                        {props.router.params.city_name
                          ? props.router.params.city_name
                          : ""}
                      </p> */}
                                  </div>
                                </div>
                              </div>
                              <ProfileMenu
                                notification={notification}
                                authUserDetails={authUserDetails}
                                user={user}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            <div className="bg-white p-5 md:p-[30px] h-screen md:h-full">
              <div className="flex gap-3">
                <div className="flex-1">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="search events/concerts/gigs"
                      value={searchEvent}
                      onChange={(e) => setSearchEvent(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleEventSearchChange();
                        }
                      }}
                      className="w-full bg-white border border-[#5D5D5D] rounded-lg p-4 text-[#101010] text-base font-normal outline-none  focus:border-[#5D5D5D] placeholder:text-[#5D5D5D]"
                    />
                    <span
                      onClick={() => handleEventSearchChange()}
                      className="cursor-pointer bg-white p-2 absolute top-1/2 right-3 -translate-y-1/2"
                    >
                      <i className="fa fa-search text-[#5D5D5D] text-xl"></i>
                    </span>
                  </div>
                </div>
                <div className="flex-none">
                  <button
                    type="button"
                    className="p-[10px] size-[57px] rounded-[4px] border border-[#5D5D5D] flex items-center justify-center"
                    onClick={() => {
                      setShowSearchModal(false);
                      setShowCityModal(false);
                    }}
                  >
                    <i className="fas fa-times text-2xl text-[#5A5A5A]"></i>
                  </button>
                </div>
              </div>
              <div className="flex mt-8 mb-6">
                <img
                  src={calendarGradient}
                  alt="calendar"
                  className="inline-block mr-3"
                />
                <h3 className="text-xl font-semibold text-tittle-liner-gradient-color">
                  Trending events
                </h3>
              </div>
              <div className="max-h-[70vh] md:max-h-[53vh] overflow-auto pr-2">
                {!isEmpty(cmsData) &&
                  cmsData.map((singleCms, index) => (
                    <>
                      {index < 6 && !isEmpty(singleCms.event) ? (
                        <>
                          <Link
                            to={'/events/' + singleCms.event.slug}
                            onClick={() => setShowSearchModal(false)}
                          >
                            <div className="bg-white border border-[#E7E7E7] p-4 rounded-lg mb-3 hover:border-[#CB2129]">
                              <div className="flex gap-4">
                                <div className="flex-1">
                                  <p className="text-sm md:text-base text-[#101010] font-base">
                                    {singleCms &&
                                      singleCms.event &&
                                      singleCms.event.name}
                                  </p>
                                </div>
                                <div className="flex-none">
                                  <i className="fas fa-chevron-right mt-[2px]"></i>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchModal;
