import React, { useEffect, useState } from 'react';
import contact from '../../assets/images/contact-us.png';
import img1 from '../../assets/images/contact-preview.png';
import instagram from '../../assets/images/small/instagram-img.png';
import whatsapp from '../../assets/images/small/whatsapp-img.png';
import messanger from '../../assets/images/small/messanger-img.png';
import linkedin from '../../assets/images/small/linkdin-img.png';
import { Link } from 'react-router-dom';
import SeoDetails from '../../seo/SeoDetails';
import ticketAudience from '../../assets/light_theme_images/ticket-audience.svg';
import business from '../../assets/light_theme_images/business.svg';
import mailIcon from '../../assets/light_theme_images/mail.svg';
import phoneIcon from '../../assets/light_theme_images/phone.svg';
function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      id: 1,
      question: 'What is Mepass, and how does it work?',
      answer:
        'Mepass is a digital event ticketing platform that connects attendees and organisers by providing a seamless ticket purchasing and event management experience. Through our website or app, you can browse events, purchase tickets, and enjoy easy access to events.',
    },
    {
      id: 2,
      question: 'How do I purchase tickets for an event on Mepass?',
      answer:
        'To buy tickets, simply browse events on our platform, select your desired event, choose your ticket type, and proceed to checkout. You can complete your purchase using a secure payment method, and your ticket will be emailed to you instantly.',
    },
    {
      id: 3,
      question: 'Can I get a refund if I cannot attend an event?',
      answer:
        'Refund policies vary by event and organizer. Please check the event’s specific refund policy on the event page or contact our support team if you have questions about refunds for your ticket purchase.',
    },
    {
      id: 4,
      question: 'How do I access my tickets after purchase?',
      answer:
        'Your tickets will be sent to the email address you used during purchase. You can also access them through your Mepass account by logging in and viewing your ticket history.',
    },
    {
      id: 5,
      question:
        'What should I do if I encounter issues with my tickets or event access?',
      answer:
        'If you face any issues with your tickets or at the event, reach out to us through our support email or chat feature. We’re here to help resolve any problems and ensure you have a smooth event experience',
    },
    {
      id: 6,
      question: 'How do I become an event organizer on Mepass?',
      answer:
        "If you're interested in hosting events through Mepass, visit our Organizer section or contact our team. We’ll guide you through the setup process and help you start selling tickets in no time.",
    },
  ];

  return (
    <>
      <SeoDetails
        title="mepass | Contact Us"
        description="mepass | Contact Us"
      />
      <section className="bg-white pt-14 pb-14">
        <div className="container">
          <div className="text-center">
            <h2 className="text-2xl md:text-4xl text-[#101010] font-semibold ">
              Got Questions? We’re Here to Help!
            </h2>
            <p className="text-lg text-[#101010] font-normal mt-2">
              Customer satisfaction is our priority! Contact us with any
              questions or feedback to make your entertainment
              <br /> booking smooth and enjoyable. Let’s bring your
              entertainment dreams to life!
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-10">
            <div className="bg-[#FFFDF5] rounded-[12px] p-3 md:p-6">
              <div className="flex flex-col gap-2.5">
                <img
                  src={ticketAudience}
                  alt="img"
                  className="inline-block size-12"
                />
                <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold ">
                  Ticket & Audience
                </h3>
                <p className="text-lg md:text-xl text-[#101010] font-normal">
                  Customer satisfaction is key! Contact us for a smooth
                  entertainment booking journey
                </p>
              </div>
              <div className="grid grid-cols-2 gap-1.5 md:gap-4 mt-4">
                <div className="bg-white rounded-[4px] p-2 md:p-4">
                  <div className="flex mb-[2px] items-center">
                    <img
                      src={phoneIcon}
                      alt="img"
                      className="inline-block mr-2"
                    />
                    <p className="text-base font-normal text-[#101010]">
                      Mobile
                    </p>
                  </div>
                  <Link
                    to="tel:++91 9099060633"
                    className="text-sm md:text-lg font-semibold text-[#101010]"
                  >
                    +91 9099060633
                  </Link>
                </div>
                <div className="bg-white rounded-[4px] p-2 md:p-4">
                  <div className="flex mb-[2px] items-center">
                    <img
                      src={mailIcon}
                      alt="img"
                      className="inline-block mr-2"
                    />
                    <p className="text-base font-normal text-[#101010]">
                      Email
                    </p>
                  </div>
                  <Link
                    to="mailto:support@mepass.in"
                    className="text-sm md:text-lg font-semibold text-[#101010] break-all"
                  >
                    support@mepass.in
                  </Link>
                </div>
              </div>
            </div>
            <div className="bg-[#FFFDF5] rounded-[12px] p-3 md:p-6">
              <div className="flex flex-col gap-2.5">
                <img
                  src={business}
                  alt="img"
                  className="inline-block size-12"
                />
                <h3 className="text-2xl md:text-3xl text-[#101010] font-semibold ">
                  For Business
                </h3>
                <p className="text-lg md:text-xl text-[#101010] font-normal">
                  Stay updated with our latest promotions, discounts and
                  special offers.
                </p>
              </div>
              <div className="grid grid-cols-2 gap-1.5 md:gap-4 mt-4">
                <div className="bg-white rounded-[4px] p-4">
                  <div className="flex mb-[2px] items-center">
                    <img
                      src={phoneIcon}
                      alt="img"
                      className="inline-block mr-2"
                    />
                    <p className="text-base font-normal text-[#101010]">
                      Mobile
                    </p>
                  </div>
                  <Link
                    to="tel:+91 9925060619"
                    className="text-sm md:text-lg font-semibold text-[#101010]"
                  >
                    +91 9925060619
                  </Link>
                </div>
                <div className="bg-white rounded-[4px] p-4">
                  <div className="flex mb-[2px] items-center">
                    <img
                      src={mailIcon}
                      alt="img"
                      className="inline-block mr-2"
                    />
                    <p className="text-base font-normal text-[#101010]">
                      Email
                    </p>
                  </div>
                  <Link
                    to="mailto:onboarding@mepass.in"
                    className="text-sm md:text-lg font-semibold text-[#101010] break-all"
                  >
                    onboarding@mepass.in
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="text-center mb-5">
              <h2 className="text-2xl md:text-4xl text-[#101010] font-semibold ">
                FAQs
              </h2>
              <p className="text-base md:text-lg text-[#101010] font-normal mt-2">
                Find answers to common queries or contact our support team for
                quick assistance. We're here to make your <br /> Mepass
                experience seamless!
              </p>
            </div>
            <div className="accordion-wrapper space-y-4">
              {faqData.map((item, index) => (
                <div
                  key={item.id}
                  className="bg-[#F9F9F9] rounded-lg px-4 py-3"
                >
                  <div
                    className="accordion-header flex gap-2 cursor-pointer"
                    onClick={() => handleToggle(index)}
                  >
                    <h3 className="text-lg font-bold text-gray-800 flex-1">
                      {item.question}
                    </h3>
                    <div className="flex-none">
                      <button className="w-6 h-6 flex items-center justify-center text-black">
                        <i
                          className={`fas ${
                            openIndex === index
                              ? 'fa-chevron-up'
                              : 'fa-chevron-down'
                          }`}
                        ></i>
                      </button>
                    </div>
                  </div>
                  {openIndex === index && (
                    <div className="mt-3 text-lg font-normal">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* -------------------------------section-start------------------------- */}
      {/* <section className="bg-neutral py-24">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <p className="text-white text-base leading-6 font-body font-normal">
                Customer satisfaction is key! Contact us for a smooth
                entertainment booking journey
              </p>
              <p className="text-white text-base leading-6 font-body font-normal mt-5">
                Your satisfaction is our priority! At Mepass, we're here to make
                your entertainment booking experience seamless and enjoyable.
                Reach out to us with any questions or feedback. Let's make your
                entertainment dreams a reality
              </p>
            </div>
            <div>
              <img src={img1} alt="" className="w-full" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-7">
            <div className="bg-black p-5 rounded-lg">
              <h2 className="text-2xl xl:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
                Ticket & Audience
              </h2>
              <p className="text-white text-base leading-6 font-body font-normal mt-5">
                Customer satisfaction is key! Contact us for a smooth
                entertainment booking journey
              </p>
              <div className="block lg:flex gap-8 mt-5">
                <div>
                  <p className="text-white font-body font-normal text-base">
                    Mobile Number
                  </p>
                  <span className="text-white text-sm font-body font-semibold">
                    <i className="fas fa-phone text-[10px] mr-2"></i>
                    <Link to="tel:+917228930708">7228930708</Link>/
                    <Link to="tel:+917228930709">09</Link>/
                    <Link to="tel:+917228930707">07</Link>
                  </span>
                </div>
                <div className="mt-3 lg:mt-0">
                  <p className="text-white font-body font-normal text-base ">
                    Email Address
                  </p>
                  <span className="text-white text-sm font-body font-semibold">
                    <i className="fas fa-envelope text-[12px] mr-2"></i>
                    <Link to="Support@mepass.in">Support@mepass.in</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-black p-5 rounded-lg">
              <h2 className="text-2xl xl:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
                For Business
              </h2>
              <p className="text-white text-base font-body font-normal mt-5">
                Stay updated with our latest promotions, discounts and special
                offers.
              </p>
              <div className="block lg:flex items-center gap-8 mt-5">
                <div>
                  <p className="text-white font-body font-normal text-base">
                    Mobile Number
                  </p>
                  <span className="text-white text-sm font-body font-semibold">
                    <i className="fas fa-phone text-[10px] mr-2"></i>9099060633
                  </span>
                </div>
                <div className="mt-3 lg:mt-0">
                  <p className="text-white font-body font-normal text-base">
                    Email Address
                  </p>
                  <span className="text-white text-sm font-body font-semibold">
                    <i className="fas fa-envelope text-[12px] mr-2"></i>
                    <Link to="mailto:Onboarding@mepass.in">
                      Onboarding@mepass.in
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-7">
            <div className="bg-black p-5 rounded-lg">
              <h2 className="text-2xl xl:text-[40px] font-body font-light tracking-[.150em] text-white uppercase">
                connect with us
              </h2>
              <p className="text-white text-base font-body font-normal mt-5">
                Customer satisfaction is key! Contact us for a smooth
                entertainment booking journey
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10 mt-7">
                <Link
                  to="https://www.facebook.com/mepassbharat/"
                  target="_blank"
                  className="border border-[#0084FF] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#0084FF] font-body font-normal whitespace-nowrap"
                >
                  <img src={messanger} alt="" className="mr-2 inline" />
                  Message Us
                </Link>
                <button className="border border-[#66E066] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#66E066] font-body font-normal whitespace-nowrap">
                  <img src={whatsapp} alt="" className="mr-2 inline" />
                  Send Message
                </button>
                <Link
                  to="https://www.linkedin.com/company/mepassbharat/"
                  target="_blank"
                  className="border border-[#1686B0] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#1686B0] font-body font-normal whitespace-nowrap"
                >
                  <img src={linkedin} alt="" className="mr-2 inline" />
                  Message
                </Link>
                <Link
                  to="https://www.instagram.com/mepassbharat/"
                  target="_blank"
                  className="border border-[#FF7944] py-3 px-10 w-full 2xl:w-72 rounded-lg text-2xl text-[#FF7944] font-body font-normal whitespace-nowrap"
                >
                  <img src={instagram} alt="" className="mr-2 inline" />
                  Message
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* -------------------------------section-end------------------------- */}
    </>
  );
}

export default ContactUs;
