import { find, get, isEmpty, set, times } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withRouter from '../../components/Common/withRouter';
import EventDetails from '../../components/PassPurchase/EventDetails';
import EventDetailsSeats from '../../components/PassPurchase/EventDetailsSeats';
import api from '../../constants/api_constants';
import {
  addUpdateNewData,
  clearData,
  getData,
} from '../../store/AppMaster/actions';
import getPassCalculation from './PassPaymentCalculation';
import PaymentOption from './PaymentOption';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LoginPopup from '../../components/Event/Login';
import SelectDate from '../../components/PassPurchase/SelectDate';
import SelectDateTime from '../../components/PassPurchase/SelectDateTime';
import SelectPass from '../../components/PassPurchase/SelectPass';
import SelectPassDateTime from '../../components/PassPurchase/SelectPassDateTime';
import SeatJsonSelection from '../../components/PassPurchase/SeatJsonSelection';
import { pushMakePayment } from '../../seo/gtevents';
import SeoDetails from '../../seo/SeoDetails';
import { toast } from 'react-toastify';

const PassPurchase = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [passWithQuantity, setPassWithQuantity] = useState([]);
  const [event, setEvent] = useState('');
  const [eventDates, setEventDates] = useState([]);
  const [settings, setSettings] = useState('');
  const [passList, setPassList] = useState([]);
  const [passOffers, setPassOffers] = useState([]);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isDefault, setDefault] = useState(true);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isUpdateDateData, setIsUpdateDateData] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [isProcessedToPay, setIsProcessedToPay] = useState(false);
  const [passPaymentCalculation, setPassPaymentCalculation] = useState([]);
  const [makePaymentError, setMakePaymentError] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [optionGroupDate, setOptionGroupDate] = useState([]);
  const [optionGroupTime, setOptionGroupTime] = useState([]);
  const [dailyPassCount, setDailyPassCount] = useState(0);
  const [sessionPassCount, setSessionPassCount] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [isApplyPromoCode, setIsApplyPromoCode] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownResOpen, setIsDropdownResOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [movieQuantity, setMovieQuantity] = useState(1);
  const dropdownRef = useRef(null);
  const dropdownResRef = useRef(null);
  const [openSeatsSelection, setOpenSeatsSelection] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [seatsJsonData, setSeatsJsonData] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleResDropdown = () => {
    setIsDropdownResOpen(!isDropdownResOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  const handleClickOutsideRes = (event) => {
    if (
      dropdownResRef.current &&
      !dropdownResRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (isDropdownResOpen) {
      document.addEventListener('mousedown', handleClickOutsideRes);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideRes);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownResOpen]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (isApiCall === 0) {
      getEventDateList();
      setIsApiCall(1);
    }
  }, [
    isApiCall,
    props.router.params.event_slug,
    dispatch,
    props.router.navigate,
  ]);

  const getEventDateList = () => {
    setIsUpdateDateData(true);
    const requestEventDateData = {
      event_slug: props.router.params.event_slug,
    };
    dispatch(
      getData(
        requestEventDateData,
        props.router.navigate,
        api.GET_EVENT_DATE_LIST,
      ),
    );
  };

  const getPassListData = (date_id) => {
    setIsUpdateData(true);
    const requestData = {
      event_slug: props.router.params.event_slug,
      event_date_id: date_id,
    };
    dispatch(clearData());
    dispatch(
      getData(requestData, props.router.navigate, api.GET_PASS_LIST_BY_DATE),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.event) && isUpdateDateData) {
    setEvent(data.event);
    if (data.event.event_type_id == 4) {
      setEventDates(data.eventDates);
      const mergedDates = data.eventDates.reduce((acc, event) => {
        const existingDate = acc.find(
          (item) => item.formatted_date === event.formatted_date,
        );
        if (existingDate) {
          existingDate.times.push({
            value: event.id,
            label: event.formattedStartTime,
          });
        } else {
          acc.push({
            ...event, // Keep all properties from the first event of the date
            label: event.formatted_date, // Add label field
            value: event.id, // Add value field (taking the first ID for reference)
            times: [
              {
                value: event.id,
                label: event.formattedStartTime,
              },
            ],
          });
        }

        return acc;
      }, []);

      setOptionGroupDate(mergedDates);
      //Selected Date and Time in First Auto Selected
      if (localStorage.getItem('selectedDateForLocalStorage')) {
        let selectedDateFromLocalStorage = JSON.parse(
          localStorage.getItem('selectedDateForLocalStorage'),
        );
        setSelectedDate({
          label: selectedDateFromLocalStorage.label,
          value: selectedDateFromLocalStorage.value,
        });
        setOptionGroupTime(
          mergedDates.find(
            (item) => item.id === selectedDateFromLocalStorage.value,
          ).times,
        );
        localStorage.removeItem('selectedDateForLocalStorage');
      } else {
        // setSelectedDate({
        //   label: mergedDates && mergedDates[0] && mergedDates[0].label,
        //   value: mergedDates && mergedDates[0] && mergedDates[0].value,
        // });
        // setOptionGroupTime(
        //   mergedDates && mergedDates[0] && mergedDates[0].times,
        // );
      }
      // setOptionGroupTime(mergedDates && mergedDates[0] && mergedDates[0].times);
      // setSelectedTime({
      //   label: mergedDates && mergedDates[0] && mergedDates[0].times[0].label,
      //   value: mergedDates && mergedDates[0] && mergedDates[0].times[0].value,
      // });
      //getPassListData(mergedDates[0].times[0].time_id);
    } else {
      setEventDates(data.eventDates);
      setDailyPassCount(data.passAvailability.dailyPassType);
      setSessionPassCount(data.passAvailability.seasonPassType);
      const passDates = [];
      data.eventDates.map((eventDate) => {
        if (eventDate.dailyPassType > 0) {
          passDates.push({
            label: eventDate.formatted_date,
            value: eventDate.id,
          });
        }
      });
      setOptionGroupDate(passDates);
      if (data.passAvailability.dailyPassType > 0) {
        let isDefaultDate = false;
        if (!isDefaultDate) {
          if (data.passAvailability.seasonPassType > 0) {
            // setSelectedDate({ label: '', value: '' });
            // setSelectedDate({ label: '', value: '' });
            // getPassListData('');
            isDefaultDate = true;
          }
        }
        data.eventDates.map((item, index) => {
          if (item.dailyPassType > 0 && !isDefaultDate) {
            if (localStorage.getItem('selectedDateForLocalStorage')) {
              let selectedDateFromLocalStorage = JSON.parse(
                localStorage.getItem('selectedDateForLocalStorage'),
              );
              if (selectedDateFromLocalStorage.value == item.id) {
                setSelectedDate({ label: item.formatted_date, value: item.id });
                getPassListData(item.id);
                isDefaultDate = true;
                localStorage.removeItem('selectedDateForLocalStorage');
              }
            } else {
              // setSelectedDate({ label: item.formatted_date, value: item.id });
              // getPassListData(item.id);
              isDefaultDate = true;
            }
          }
        });
      } else {
        //getPassListData();
      }
    }

    setIsUpdateDateData(false);
  }
  const handlePromoCodeApply = () => {
    if (!isEmpty(promoCode)) {
      setPromoCode(promoCode);
      // If Pass Quantity is 0 then set it to remove it from the request
      setPassPaymentCalculation(
        getPassCalculation(passList, promoCode, event, settings, passOffers),
      );
    }
  };

  const removePromoCode = () => {
    setPromoCode('');
    // If Pass Quantity is 0 then set it to remove it from the request
    setPassPaymentCalculation(
      getPassCalculation(passList, '', event, settings, passOffers),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.settings) && isUpdateData) {
    setSettings(data.settings);
    if (data.eventPasses.length > 0) {
      data.eventPasses &&
        data.eventPasses.forEach((pass) => {
          pass.percentage = 0;
          if (pass.amount > 0 && pass.amount_after_discount != pass.amount) {
            pass.percentage = Math.round(
              ((pass.amount - pass.amount_after_discount) / pass.amount) * 100,
            );
          }
          pass.quantity = 0;
          pass.PriceDisplay = 0;
          if (
            pass.pass_type_slug === 'cover-mepass' ||
            pass.pass_type_slug === 'normal-mepass'
          ) {
            pass.event_date_id =
              pass.eventPassDates.length > 0
                ? pass.eventPassDates[0].event_date_id
                : '';
            setPassWithQuantity((passWithQuantity) => [
              ...passWithQuantity,
              {
                event_pass_id: pass.id,
                quantity: 0,
                event_date_id:
                  pass.eventPassDates.length > 0
                    ? pass.eventPassDates[0].event_date_id
                    : '',
              },
            ]);
          } else {
            setPassWithQuantity((passWithQuantity) => [
              ...passWithQuantity,
              {
                event_pass_id: pass.id,
                quantity: 0,
                event_date_id: '',
              },
            ]);
          }
        });
    }
    if (data.eventPasses.length > 0) {
      if (localStorage.getItem('passDetailForLocalStorage')) {
        let passDetailFromLocalStorage = JSON.parse(
          localStorage.getItem('passDetailForLocalStorage'),
        );
        passDetailFromLocalStorage.map((passDetail) => {
          data.eventPasses.map((pass) => {
            if (passDetail.event_pass_id === pass.id) {
              pass.quantity = passDetail.quantity;
            }
          });
        });
        localStorage.removeItem('passDetailForLocalStorage');
      }
    }
    setPassList(data.eventPasses);
    setPassOffers(data.offers);
    setPassPaymentCalculation(
      getPassCalculation(
        data.eventPasses,
        promoCode,
        event,
        data.settings,
        data.offers,
      ),
    );

    if (localStorage.getItem('promoCode')) {
      setPromoCode(localStorage.getItem('promoCode'));
      let promoCode = localStorage.getItem('promoCode');
      // If Pass Quantity is 0 then set it to remove it from the request
      setPassPaymentCalculation(
        getPassCalculation(
          data.eventPasses,
          promoCode,
          event,
          data.settings,
          data.offers,
        ),
      );
      localStorage.removeItem('promoCode');
    }
    setIsUpdateData(false);
  }

  const handleIncrement = (passId, prevQuantity) => {
    setMakePaymentError('');
    passList.forEach((pass) => {
      if (pass.id === passId) {
        pass.quantity += 1;
      }
    });
    setPassList(passList);

    setPassPaymentCalculation(
      getPassCalculation(passList, promoCode, event, settings, passOffers),
    );
  };

  const handleDecrement = (passId, prevQuantity) => {
    if (prevQuantity - 1 >= 0) {
      passList.forEach((pass) => {
        if (pass.id === passId) {
          pass.quantity -= 1;
        }
      });
      setPassList(passList);
      setPassPaymentCalculation(
        getPassCalculation(passList, promoCode, event, settings, passOffers),
      );
    }
  };

  //Payment Code
  // Apply promo code
  const [paymentStatus, setPaymentStatus] = useState('');
  const [orderDetails, setOrderDetails] = useState({});
  const [isCheckSeatsBookedOrNot, setIsCheckSeatsBookedOrNot] = useState(false);
  const makePayment = () => {
    if (localStorage.getItem('publicMePassUser')) {
      const pass_with_quantity = passList.filter(
        (passWithQuantity) => passWithQuantity.quantity > 0,
      );
      if (pass_with_quantity.length > 0) {
        if (event.event_type_id == 4) {
          checkSeatsBookedOrNot();
        } else {
          setMakePaymentError('');
          setIsProcessedToPay(true);
        }
      } else {
        setMakePaymentError('Please select atleast one pass to proceed');
      }
    } else {
      //setIsLoginOpen(true);
      setIsProcessedToPay(false);
    }
  };

  const checkSeatsBookedOrNot = () => {
    setIsCheckSeatsBookedOrNot(true);
    const requestData = {
      event_slug: props.router.params.event_slug,
      event_date_id: selectedTime.value,
      seat_labels: selectedSeats.join(','),
    };
    dispatch(
      getData(
        requestData,
        props.router.navigate,
        api.CHECK_SEATS_BOOKED_OR_NOT,
      ),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.is_booked) && isCheckSeatsBookedOrNot) {
    setIsCheckSeatsBookedOrNot(false);
    if (data.is_booked == 'No') {
      setMakePaymentError('');
      setIsProcessedToPay(true);
    } else {
      setMakePaymentError(
        'Selected seats are already booked. Please select other seats.',
      );
      setSeatsJsonData([]);
      const requestData = {
        event_slug: props.router.params.event_slug,
        event_date_id: selectedTime.value,
      };
      dispatch(
        getData(
          requestData,
          props.router.navigate,
          api.GET_EVENT_PASS_SEAT_LIST,
        ),
      );
    }
  }

  const handleDateDropdown = (selectedOption) => {
    if (selectedOption && selectedOption.label) {
      if (event.event_type_id == 4) {
        setSelectedDate({
          label: selectedOption.label,
          value: selectedOption.value,
          times: selectedOption.times,
        });
        setOptionGroupTime(selectedOption.times);
        setSelectedTime({
          label: '',
          value: '',
        });
      } else {
        setSelectedDate({
          label: selectedOption.label,
          value: selectedOption.value,
        });
      }
      toggleDropdown();
      if (event.event_type_id != 4) {
        getPassListData(selectedOption.value);
      }
    } else {
      if (event.event_type_id == 4) {
        setSelectedDate({
          label: '',
          value: '',
          times: [],
        });
        setOptionGroupTime([]);
        setSelectedTime({
          label: '',
          value: '',
        });
      } else {
        setSelectedDate({
          label: '',
          value: '',
        });
      }

      if (event.event_type_id != 4) {
        getPassListData('');
      }
    }
  };

  const handleTimeDropdown = (selectedOption) => {
    if (selectedOption && selectedOption.label) {
      setSelectedTime({
        label: selectedOption.label,
        value: selectedOption.value,
      });
    } else {
      setSelectedTime({
        label: '',
        value: '',
      });
    }
  };

  if (!isEmpty(data) && !isEmpty(data.makePayment) && isEmpty(orderDetails)) {
    setOrderDetails(data.makePayment);
    if (data.makePayment.net_amount > 0) {
      setPaymentStatus('');
      const userDetail = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );
      const createOrder = {
        amount: data.makePayment.net_amount,
        currency: 'INR',
        description: 'Test Transaction',
        order_id: data.makePayment.payment_order_id,
        name: data.makePayment.user_name,
        email: data.makePayment.user_email,
        contact: userDetail.mobile,
      };
      handlePaymentRazorpay(createOrder);
    } else if (data.makePayment.net_amount == 0) {
      setIsProcessedToPay(false);
      props.router.navigate(
        `/payment/${data.makePayment.payment_order_id}/thank-you`,
      );
    }
  }
  //  Payment Details
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  function getPaymentStatus(payment_id, order_id, signature) {
    setPaymentStatus('');
    const requestData = {
      payment_id: payment_id,
      order_id: order_id,
      signature: signature,
    };
    dispatch(addUpdateNewData(requestData, '', api.CHECK_PAYMENT_STATUS));
  }

  if (
    !isEmpty(data) &&
    !isEmpty(data.paymentStatus) &&
    isEmpty(paymentStatus)
  ) {
    setPaymentStatus(data.paymentStatus);
    if (data.paymentStatus.status === 'paid') {
      props.router.navigate(
        `/payment/${data.paymentStatus.payment_order_id}/thank-you`,
      );
    } else if (data.paymentStatus.status === 'created') {
      setTimeout(() => {
        getPaymentStatus(data.paymentStatus.payment_order_id);
      }, 2000);
    } else if (
      data.paymentStatus.status === 'attempted' ||
      data.paymentStatus.retry === 'No'
    ) {
      setTimeout(() => {
        getPaymentStatus(data.paymentStatus.payment_order_id);
      }, 2000);
    } else if (
      data.paymentStatus.status === 'attempted' ||
      data.paymentStatus.retry === 'Yes'
    ) {
      const userDetail = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );
      const createOrder = {
        amount: data.makePayment.totalAmount,
        currency: 'INR',
        description: 'Test Transaction',
        order_id: data.makePayment.payment_order_id,
        name: data.makePayment.user_name,
        email: data.makePayment.user_email,
        contact: userDetail.mobile,
      };
      handlePaymentRazorpay(createOrder);
    }
  }

  function handlePaymentRazorpay(order) {
    try {
      // Initialize Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, //  Razorpay API key Get From .env file
        amount: order.amount, // Amount in smallest currency unit (e.g., paise for INR)
        currency: 'INR',
        name: process.env.REACT_APP,
        description: order.description,
        //image: event.mepass_logo ? event.mepass_logo : '',
        order_id: order.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          // Handle payment success
          // You should verify this payment ID & signature in your server.
          getPaymentStatus(
            response.razorpay_payment_id,
            response.razorpay_order_id,
            response.razorpay_signature,
          );
        },
        prefill: {
          name: order.name,
          email: order.email,
          contact: order.contact_number,
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#CB2027',
        },
      };
      let paymentObject = new Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Payment failed', error);
    }
  }

  const processedToPay = () => {
    if (localStorage.getItem('publicMePassUser')) {
      const pass_with_quantity = passWithQuantity.filter(
        (passWithQuantity) => passWithQuantity.quantity > 0,
      );
      setOrderDetails('');
      let requestData = getPassCalculation(
        passList,
        promoCode,
        event,
        settings,
        passOffers,
      );
      if (event.event_type_id == 4) {
        requestData.selected_seats = selectedSeats && selectedSeats.join(',');
        requestData.event_date_id = selectedTime.value;
        requestData.venue_event_id = seatsJsonData.id;
      }
      dispatch(addUpdateNewData(requestData, '', api.MAKE_PAYMENT));
    } else {
      setIsProcessedToPay(false);
    }
  };

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    if (showOtpModal) {
      setShowOtpModal(false);
    }
  };

  const toggleOtpModal = () => {
    setShowOtpModal(!showOtpModal);
    if (showLoginModal) {
      setShowLoginModal(false);
    }
  };

  const openSeatsStage = () => {
    setSeatsJsonData([]);
    const requestData = {
      event_slug: props.router.params.event_slug,
      event_date_id: selectedTime.value,
    };
    dispatch(
      getData(requestData, props.router.navigate, api.GET_EVENT_PASS_SEAT_LIST),
    );
    getPassListData(selectedTime.value);
    setOpenSeatsSelection(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(selectedTime);
      if (selectedTime && selectedTime.value) {
        setSeatsJsonData([]);
        const requestData = {
          event_slug: props.router.params.event_slug,
          event_date_id: selectedTime.value,
        };
        dispatch(
          getData(
            requestData,
            props.router.navigate,
            api.GET_EVENT_PASS_SEAT_LIST,
          ),
        );
      }
    }, 60000); // Calls every 60 seconds

    return () => clearInterval(interval); // Cleanup when component unmounts
  }, []);

  const setSeatArrangementFinal = (seatArrangementView) => {
    seatArrangementView.map((seat, indexKey) => {
      let firstNonSpaceIndex = '';
      let lastNonSpaceIndex = '';
      seat.seatLayout.map((group) => {
        //Find the index of the first seat that is not a space
        firstNonSpaceIndex = group.seat_obj.findIndex(
          (seat) => seat.is_space === 'No',
        );
        //Find the index of the last seat that is not a space
        lastNonSpaceIndex = group.seat_obj.reduce(
          (acc, seat, index) => (seat.is_space === 'No' ? index : acc),
          0,
        );
      });
      seat.seatLayout.map((group) => {
        group.seat_obj.splice(firstNonSpaceIndex, 0, {
          grid_number: 0,
          seat_numer: '-',
          is_space: 'No',
          seat_label: group.grid_row_name,
          is_blocked: 'No',
          is_booked: 'No',
          is_seat_label: 'Yes',
        });
        if (lastNonSpaceIndex !== -1) {
          group.seat_obj.splice(lastNonSpaceIndex + 2, 0, {
            grid_number: `last_${group.grid_row_id}`,
            seat_number: '-',
            is_space: 'No',
            seat_label: group.grid_row_name, // Grid Row Name as Label
            is_blocked: 'No',
            is_booked: 'No',
            is_seat_label: 'Yes',
          });
        }
      });
    });
    return seatArrangementView;
  };

  if (!isEmpty(data) && !isEmpty(data.venueEvent) && isEmpty(seatsJsonData)) {
    data.venueEvent[0].pass_wise_events = setSeatArrangementFinal(
      data.venueEvent[0].pass_wise_events,
    );
    setSeatsJsonData(data.venueEvent[0]);
  }

  const handleSeatSelection = (seat, row) => {
    let seatLabel = seat.seat_label;
    let rowSeats = row.seat_obj;
    let maxSeatSelection = movieQuantity;

    let newSelectedSeats = [...selectedSeats]; // Copy current selection

    if (newSelectedSeats.length < maxSeatSelection) {
      // **Continue selecting remaining seats**
      let remainingSeats = maxSeatSelection - newSelectedSeats.length;
      let selectedCount = 0;
      let startIndex = rowSeats.findIndex((s) => s.seat_label === seatLabel);

      if (startIndex !== -1) {
        for (let i = startIndex; i < rowSeats.length; i++) {
          if (selectedCount >= remainingSeats) break;

          let currentSeat = rowSeats[i];

          if (
            currentSeat.is_booked === 'Yes' ||
            currentSeat.is_space === 'Yes' ||
            currentSeat?.is_seat_label === 'Yes'
          ) {
            break; // **Stop at `isBooked` or `isSpace`**
          }

          newSelectedSeats.push(currentSeat.seat_label);
          selectedCount++;
        }
      }
    } else {
      // **Start fresh selection**
      newSelectedSeats = [];
      let selectedCount = 0;
      let startIndex = rowSeats.findIndex((s) => s.seat_label === seatLabel);

      if (startIndex !== -1) {
        for (let i = startIndex; i < rowSeats.length; i++) {
          if (selectedCount >= maxSeatSelection) break;

          let currentSeat = rowSeats[i];

          if (
            currentSeat.is_booked === 'Yes' ||
            currentSeat.is_space === 'Yes' ||
            currentSeat?.is_seat_label == 'Yes'
          ) {
            break;
          }

          newSelectedSeats.push(currentSeat.seat_label);
          selectedCount++;
        }
      }
    }

    //**Selected Seat Using Event Pass Id Get in seatsJsonData**/
    let eventPassId = [];
    let eventPass = seatsJsonData.pass_wise_events;
    eventPass.map((pass) => {
      pass.seatLayout.map((group) => {
        group.seat_obj.map((seat) => {
          if (newSelectedSeats.includes(seat.seat_label)) {
            eventPassId.push(pass.event_pass_id);
          }
        });
      });
    });

    // **Update Pass Quantity**
    let updatedPassList = passList.map((pass) => {
      if (eventPassId.includes(pass.id)) {
        pass.quantity = eventPassId.filter((id) => id === pass.id).length;
      } else {
        pass.quantity = 0;
      }
      pass.event_date_id = selectedTime.value;
      return pass;
    });

    //Same label seat remove
    let newSelectedSeatsUnique = newSelectedSeats.filter(
      (item, index) => newSelectedSeats.indexOf(item) === index,
    );
    setSelectedSeats(newSelectedSeatsUnique);
    setPassList(updatedPassList);
    setPassPaymentCalculation(
      getPassCalculation(
        updatedPassList,
        promoCode,
        event,
        settings,
        passOffers,
      ),
    );
  };

  const removeSelectedSeat = (seat) => {
    let newSelectedSeats = selectedSeats.filter((s) => s !== seat.seat_label);
    let eventPassId = [];
    let eventPass = seatsJsonData.pass_wise_events;
    eventPass.map((pass) => {
      pass.seatLayout.map((group) => {
        group.seat_obj.map((seat) => {
          if (newSelectedSeats.includes(seat.seat_label)) {
            eventPassId.push(pass.event_pass_id);
          }
        });
      });
    });

    // **Update Pass Quantity**
    let updatedPassList = passList.map((pass) => {
      if (eventPassId.includes(pass.id)) {
        pass.quantity = eventPassId.filter((id) => id === pass.id).length;
      } else {
        pass.quantity = 0;
      }
      pass.event_date_id = selectedTime.value;
      return pass;
    });
    setSelectedSeats(newSelectedSeats);
    setPassList(updatedPassList);
    setPassPaymentCalculation(
      getPassCalculation(
        updatedPassList,
        promoCode,
        event,
        settings,
        passOffers,
      ),
    );
  };

  return (
    <>
      <SeoDetails
        title={'Book your Pass Now | ' + event.name}
        description={event.tagline + ' Pass Booking'}
        keywords={event.keywords}
        ogImage={event.image}
      />
      <>
        {isProcessedToPay ? (
          <PaymentOption
            event={event}
            paymentDetails={passPaymentCalculation}
            passList={passList}
            processedToPay={processedToPay}
            settings={settings}
            setIsProcessedToPay={setIsProcessedToPay}
            passPaymentCalculation={passPaymentCalculation}
            passOffers={passOffers}
            handlePromoCodeApply={handlePromoCodeApply}
            removePromoCode={removePromoCode}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
          />
        ) : (
          <div className="bg-white pt-14 pb-14">
            {!openSeatsSelection ? (
              <div className="container">
                <EventDetails event={event} eventDates={eventDates} />

                {/* Select Dates Section */}
                {event.event_type_id == 4 ? (
                  <SelectDateTime
                    dropdownRef={dropdownRef}
                    toggleDropdown={toggleDropdown}
                    isDropdownOpen={isDropdownOpen}
                    optionGroupDate={optionGroupDate}
                    selectedDate={selectedDate}
                    handleDateDropdown={handleDateDropdown}
                    handleTimeDropdown={handleTimeDropdown}
                    selectedTime={selectedTime}
                    activeTab={activeTab}
                    sessionPassCount={sessionPassCount}
                    optionGroupTime={optionGroupTime}
                  />
                ) : (
                  <SelectDate
                    dropdownRef={dropdownRef}
                    toggleDropdown={toggleDropdown}
                    isDropdownOpen={isDropdownOpen}
                    optionGroupDate={optionGroupDate}
                    selectedDate={selectedDate}
                    handleDateDropdown={handleDateDropdown}
                    activeTab={activeTab}
                    sessionPassCount={sessionPassCount}
                  />
                )}

                {/* Pass List Section */}
                {event.event_type_id == 4 ? (
                  <>
                    {selectedTime && selectedTime.value && (
                      <div className="bg-[#F9F9F9] p-4 md:p-7 rounded-lg mt-7">
                        <SelectPassDateTime
                          movieQuantity={movieQuantity}
                          setMovieQuantity={setMovieQuantity}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!isEmpty(passList) && (
                      <div className="bg-[#F9F9F9] p-4 md:p-7 rounded-lg mt-7">
                        <h3 className="text-[22px] md:text-3xl text-[#101010] font-semibold mb-5">
                          Choose Mepass
                        </h3>
                        {passList.map((pass, index) => (
                          <SelectPass
                            key={index}
                            pass={pass}
                            handleIncrement={handleIncrement}
                            handleDecrement={handleDecrement}
                            activeTab={activeTab}
                          />
                        ))}
                      </div>
                    )}
                  </>
                )}
                {event.event_type_id == 4 &&
                  selectedDate &&
                  selectedTime &&
                  selectedTime.value && (
                    <div className="mt-2 text-[#737373] font-body font-normal text-sm">
                      Note: Maximum 10 seats can be selected at one time
                    </div>
                  )}
                {event.event_type_id == 4 ? (
                  <>
                    {selectedDate && selectedTime && selectedTime.value && (
                      <div className="flex justify-center gap-3 mt-[40px]">
                        <button
                          onClick={() => {
                            props.router.navigate(`/event/${event.slug}`);
                          }}
                          className="bg-white  text-[#CB2129] border border-[#CB2129] text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg "
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            openSeatsStage();
                          }}
                          className="btn-liner-gradient  text-white text-[12px] md:text-base font-medium py-1 md:py-[10px] px-3 md:px-7 rounded-[4px] md:rounded-lg"
                        >
                          Proceed
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {passList.reduce(
                      (total, pass) => total + pass.quantity,
                      0,
                    ) > 0 && (
                      <div className="bg-[#F9F9F9] p-5 md:p-7 rounded-lg mt-7">
                        <div className="border-y-2 border-[#E7E7E7] py-5 my-5">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-base text-[#101010] font-body font-normal">
                              GST
                            </p>
                            <p className="text-base text-[#101010] font-body font-medium">
                              ₹{passPaymentCalculation.tax_amount || 0}
                            </p>
                          </div>
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-base text-[#101010] font-body font-normal">
                              Convenience Charge
                            </p>
                            <p className="text-base text-[#101010] font-body font-medium">
                              ₹{passPaymentCalculation.convenience_charge || 0}
                            </p>
                          </div>
                          {passPaymentCalculation.discount > 0 && (
                            <div className="flex items-center justify-between mb-2">
                              <p className="text-base text-[#101010] font-body font-normal">
                                Discount applied
                              </p>
                              <p className="text-base text-[#101010] font-body font-medium">
                                - ₹{passPaymentCalculation.discount}
                              </p>
                            </div>
                          )}
                        </div>

                        {/* Total Amount and Actions */}
                        <div className="flex items-center justify-between text-center md:text-left">
                          <div>
                            <span className="text-xl md:text-3xl text-[#101010] font-body font-semibold">
                              ₹{passPaymentCalculation.net_amount || 0}
                            </span>
                            <p className="text-base md:text-xl text-[#101010] font-body font-normal mt-1 md:mt-2">
                              Amount
                            </p>
                          </div>
                          {localStorage.getItem('publicMePassUser') ? (
                            <>
                              {makePaymentError && (
                                <p className="text-red-500 text-sm font-body font-normal mt-2">
                                  {makePaymentError}
                                </p>
                              )}
                              {passList.reduce(
                                (total, pass) => total + pass.quantity,
                                0,
                              ) > 0 && (
                                <button
                                  onClick={() => {
                                    makePayment();
                                    pushMakePayment(
                                      'makePayment',
                                      'PassPurchase',
                                      'Make Payment',
                                      passList,
                                      selectedDate.label,
                                      passPaymentCalculation.net_amount,
                                    );
                                  }}
                                  className="btn-liner-gradient text-white text-base md:text-lg font-semibold py-2 md:py-4 px-4 md:px-7 rounded-lg mt-3 md:mt-0"
                                >
                                  Make Payment
                                </button>
                              )}
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                toggleLoginModal();
                              }}
                              className="btn-liner-gradient text-white text-lg font-semibold py-3 px-8 rounded-lg mt-3 md:mt-0"
                            >
                              Login
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="container">
                <EventDetailsSeats
                  event={event}
                  eventDates={eventDates}
                  seatsJsonData={seatsJsonData}
                />
                {seatsJsonData &&
                  passList &&
                  seatsJsonData.pass_wise_events && (
                    <SeatJsonSelection
                      seatsJsonData={seatsJsonData?.pass_wise_events}
                      selectedSeats={selectedSeats}
                      handleSeatSelection={handleSeatSelection}
                      removeSelectedSeat={removeSelectedSeat}
                      passList={passList}
                    />
                  )}

                <div className="bg-[#F9F9F9] p-5 md:p-7 rounded-lg mt-7">
                  <div className="border-y-2 border-[#E7E7E7] py-5 my-5">
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-base text-[#101010] font-body font-normal">
                        GST
                      </p>
                      <p className="text-base text-[#101010] font-body font-medium">
                        ₹{passPaymentCalculation.tax_amount || 0}
                      </p>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-base text-[#101010] font-body font-normal">
                        Convenience Charge
                      </p>
                      <p className="text-base text-[#101010] font-body font-medium">
                        ₹{passPaymentCalculation.convenience_charge || 0}
                      </p>
                    </div>
                    {passPaymentCalculation.discount > 0 && (
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-base text-[#101010] font-body font-normal">
                          Discount applied
                        </p>
                        <p className="text-base text-[#101010] font-body font-medium">
                          - ₹{passPaymentCalculation.discount}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Total Amount and Actions */}
                  <div className="flex items-center justify-between text-center md:text-left">
                    <div>
                      <span className="text-xl md:text-3xl text-[#101010] font-body font-semibold">
                        ₹{passPaymentCalculation.net_amount || 0}
                      </span>
                      <p className="text-base md:text-xl text-[#101010] font-body font-normal mt-1 md:mt-2">
                        {selectedSeats.length}
                        {event.event_type_id == 4 ? ' ticket' : ' pass'} added
                      </p>
                    </div>
                    {localStorage.getItem('publicMePassUser') ? (
                      <>
                        {makePaymentError && (
                          <p className="text-red-500 text-sm font-body font-normal mt-2">
                            {makePaymentError}
                          </p>
                        )}
                        {selectedSeats.length > 0 && (
                          <button
                            onClick={() => {
                              makePayment();
                            }}
                            className="btn-liner-gradient text-white text-base md:text-lg font-semibold py-2 md:py-4 px-4 md:px-7 rounded-lg mt-3 md:mt-0"
                          >
                            Checkout
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        onClick={() => {
                          toggleLoginModal();
                        }}
                        className="btn-liner-gradient text-white text-lg font-semibold py-3 px-8 rounded-lg mt-3 md:mt-0"
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>

      {/* -----------OTP-popup------ */}

      {!localStorage.getItem('publicMePassUser') && (
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          >
            <LoginPopup
              isVisible={showLoginModal}
              toggleLoginModal={toggleLoginModal}
              toggleOtpModal={toggleOtpModal}
              props={props}
            />
          </GoogleReCaptchaProvider>
        </GoogleOAuthProvider>
      )}
    </>
  );
};

export default withRouter(PassPurchase);
