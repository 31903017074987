import React from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../../../components/Common/withRouter';
import useChipFilter from '../../../hooks/useChipFilter';

const ChipFilter = ({ props }) => {
  const { sidebarArray, activeNavMenu, setActiveNavMenu } = useChipFilter(
    props.router,
  );

  return (
    <section className="pt-10 md:pt-16">
      <div className="container">
        <div className="flex lg:flex-wrap gap-3 md:gap-5 overflow-auto">
          {sidebarArray.map((item, index) => (
            <Link
              to={item.link}
              key={item.name + index}
              onClick={() => setActiveNavMenu(item.link + index)}
              className={`py-1 lg:py-2 px-4 lg:px-[20px] text-base lg:text-2xl border duration-300 ease-in-out rounded-full text-nowrap 
                ${
                  activeNavMenu === item.link + index
                    ? 'bg-[#CB2129] text-white border-[#CB2129] font-semibold'
                    : 'bg-white border-[#101010] text-[#101010] font-medium'
                }`}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withRouter(ChipFilter);
