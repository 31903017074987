import React from 'react';
import withRouter from '../../components/Common/withRouter';
import img from '../../assets/images/default/event-image.jpg';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import labelConstants from '../../constants/label';
import succesfully from '../../assets/images/small/succesfully.png';
import * as Yup from 'yup';
import {
  isImageJpgOrPng,
  isImageSizeValid,
  isPdforImageJpgOrPng,
} from '../../common/commonFunction';
import FileUpload from '../../components/Common/FileUpload';
import Text from '../../components/Common/Text';
import api from '../../constants/api_constants';
import messageConstant from '../../constants/message';
import regex from '../../constants/regex';
import RadioButton from '../../components/Common/RadioButton';
import TANUpload from '../../components/Common/TANUpload';
import {
  addUpdateNewDataMultipleForm,
  clearData,
  getData,
} from '../../store/AppMaster/actions';
import Textarea from '../../components/Common/Textarea';
import DatePicker from '../../components/Common/DatePicker';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Registration = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 300,
    height: 300,
    aspect: 1,
  });
  const imageRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const handleReCaptchaVerifyOnSubmit = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return '';
    }

    const token = await executeRecaptcha('submit');
    return token;
  };

  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSuccessfullyModal, setShowSuccessfullyModal] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [isApiCall, setIsApiCall] = useState(0);
  const [eventfilePreview, seteventfilePreview] = useState('');
  const [eventFileType, seteventFileType] = useState('');
  const [eventfilePreviewBack, seteventfilePreviewBack] = useState('');
  const [eventFileTypeBack, seteventFileTypeBack] = useState('');
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [eventData, setEventData] = useState('');
  const [sellerData, setSellerData] = useState('');

  //Submit form
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      gender: 'Male',
      mobile: '',
      email: '',
      alternate_mobile: '',
      alternate_email: '',
      address: '',
      profile_image: '',
      document: '',
      document_back: '',
      dob: '',
      occupation: '',
      age: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(messageConstant.FULL_NAME_REQUIRED)
        .min(regex.NAME_MIN, messageConstant.FULL_NAME_MIN_LENGTH)
        .max(regex.NAME_MAX, messageConstant.FULL_NAME_MAX_LENGTH),
      gender: Yup.string().required(messageConstant.GENDER_REQUIRED),
      mobile: Yup.string()
        .required(messageConstant.MOBILE_REQUIRED)
        .matches(/^[0-9]+$/, messageConstant.MOBILE_INVALID)
        .min(10, messageConstant.MOBILE_MIN_LENGTH)
        .max(10, messageConstant.MOBILE_MAX_LENGTH),
      email: Yup.string()
        // required(messageConstant.EMAIL_REQUIRED).
        .email(messageConstant.EMAIL_INVALID),
      alternate_mobile: Yup.string()
        .matches(/^[0-9]+$/, messageConstant.MOBILE_INVALID)
        .min(10, messageConstant.MOBILE_MIN_LENGTH)
        .max(10, messageConstant.MOBILE_MAX_LENGTH),
      alternate_email: Yup.string().email(messageConstant.EMAIL_INVALID),
      profile_image: Yup.mixed().test(
        'fileFormat',
        messageConstant.IMAGE_TYPE,
        (value) => {
          if (!value) {
            return true;
          } // If no image provided, pass validation
          return isImageJpgOrPng(value);
        },
      ),
      document: Yup.mixed().test(
        'fileFormat',
        messageConstant.PDF_IMAGE_TYPE,
        (value) => {
          if (!value) return true; // If no image provided, pass validation
          return isPdforImageJpgOrPng(value);
        },
      ),
      document_back: Yup.mixed().test(
        'fileFormat',
        messageConstant.PDF_IMAGE_TYPE,
        (value) => {
          if (!value) return true; // If no image provided, pass validation
          return isPdforImageJpgOrPng(value);
        },
      ),
    }),
    onSubmit: async (values) => {
      setAddFlag(true);
      const newToken = await handleReCaptchaVerifyOnSubmit();
      const requestData = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        alternate_mobile: values.alternate_mobile,
        alternate_email: values.alternate_email,
        gender: values.gender,
        address: values.address,
        dob: values.dob,
        occupation: values.occupation,
        profile_image: values.profile_image,
        document: values.document,
        document_back: values.document_back,
        event_id: params.event_id,
        seller_id: params.seller_id,
        gRecaptchaResponse: newToken,
      };
      dispatch(
        addUpdateNewDataMultipleForm(requestData, '', api.EVENT_SELLER_SAVE),
      );
    },
  });

  if (!isEmpty(data) && !isEmpty(data.eventSeller) && addFlag) {
    setAddFlag(false);
    validation.resetForm();
    setShowSuccessfullyModal(true);
  }
  if (!loading && validation.isSubmitting) {
    validation.isSubmitting = false;
  }

  const handleDeleteLayoutFile = () => {
    seteventFileType('');
    seteventfilePreview(null);
    validation.setFieldValue('document', ''); // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleDeleteLayoutFileBack = () => {
    seteventFileTypeBack('');
    seteventfilePreviewBack(null);
    validation.setFieldValue('document_back', ''); // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  //For profile_image
  const handleDivClick = (event) => {
    event.stopPropagation();
    fileInputRef.current.click();
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    validation.setFieldValue('profile_image', '');
  };

  const handleFileChange = (e) => {
    let errors = {};
    let formIsValid = true;
    if (isImageJpgOrPng(e.target.files[0])) {
      setIsCropModalOpen(true);
      if (e.target.files && e.target.files.length > 0) {
        setCrop(undefined); // Makes crop preview update between images.
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setSelectedFile(reader.result?.toString() || ''),
        );
        reader.readAsDataURL(e.target.files[0]);
        validation.setFieldValue('profile_image', e.target.files[0]);
      }
    } else {
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      /**Error Display */
      errors['profile_image'] = messageConstant.IMAGE_TYPE;
      formIsValid = false;
    }
    setFormErrors(errors);
  };

  const handleEventChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      seteventFileType(file.type);
      validation.setFieldValue('document', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        seteventfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleEventChangeBack = (event) => {
    const file = event.target.files[0];
    if (file) {
      seteventFileTypeBack(file.type);
      validation.setFieldValue('document_back', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        seteventfilePreviewBack(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      const requestData = {
        event_id: params.event_id,
        seller_id: params.seller_id,
      };
      dispatch(clearData());
      dispatch(
        getData(requestData, props.router.navigate, api.CHECK_IS_EVENT_SELLER),
      );
      setIsApiCall(1);
    }
  }, [
    isApiCall,
    dispatch,
    props.router.navigate,
    params.event_id,
    params.seller_id,
  ]);

  if (!isEmpty(data) && !isEmpty(data.event) && isUpdateData) {
    setEventData(data.event);
    setSellerData(data.seller);
    setIsUpdateData(false);
  }

  return (
    <>
      <div className="container">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 mt-8">
          <div className="mr-5 md:mr-10">
            <img
              src={eventData.banner_image ? eventData.banner_image : img}
              alt="sponsor"
              className="inline-block"
            />
          </div>
          <div className="md:col-span-2 lg:col-span-4 xl:col-span-5 border-2 border-[#FFE000] bg-[#FFFCE6] p-5 rounded-[10px]">
            <div className="block md:flex items-center justify-between">
              <div className="flex-1">
                <h1 className="text-base md:text-xl lg:text-3xl text-[#2999F1] font-semibold uppercase">
                  participant entry form
                </h1>
                <h2 className="text-base md:text-xl lg:text-2xl text-[#131313] font-semibold mt-3">
                  {eventData.name ? eventData.name : ''}
                </h2>
              </div>
              <div className="flex-none mt-2 md:mt-0">
                <h2 className="text-base md:text-xl lg:text-2xl text-[#131313] font-semibold md:text-end">
                  {sellerData.name ? sellerData.name : ''}
                </h2>
                <p className="text-base md:text-xl lg:text-2xl text-[#131313] font-regular md:text-end mt-3">
                  {sellerData.mobile ? sellerData.mobile : ''}
                </p>
              </div>
            </div>
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <div className="bg-[#F9F9F9] p-5 rounded-[10px] mt-7 mb-24">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
              <div>
                <FileUpload
                  validation={validation}
                  selectedFile={selectedFile}
                  handleDivClick={handleDivClick}
                  handleDeleteFile={handleDeleteFile}
                  fileInputRef={fileInputRef}
                  handleFileChange={handleFileChange}
                  name="profile_image"
                  isCropModalOpen={isCropModalOpen}
                  setIsCropModalOpen={setIsCropModalOpen}
                  crop={crop}
                  setCrop={setCrop}
                  imageRef={imageRef}
                  setSelectedFile={setSelectedFile}
                  minWidth={300}
                  minHeight={300}
                  formErrors={formErrors}
                />
              </div>
              <div className="md:col-span-2 lg:col-span-3">
                <div className="grid grid-cols-1">
                  <div className="col-span-2">
                    <Text
                      validation={validation}
                      name="name"
                      label={labelConstants.FULL_NAME}
                      isRequire={true}
                      regexCompare={regex.NAME}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
                  <div>
                    <Text
                      validation={validation}
                      name="email"
                      label="Email"
                      isRequire={false}
                    />
                  </div>
                  <div>
                    <Text
                      validation={validation}
                      name="mobile"
                      label="Mobile"
                      isRequire={true}
                      showIcon={true}
                      regexCompare={regex.MOBILE}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 items-center gap-1 md:gap-5 mt-5">
                  <label className="block text-base text-[#5A5A5A] font-normal mb-2 md:col-span-1">
                    Gender<span className="text-primary">*</span>
                  </label>
                  <div className="flex items-center justify-between gap-3 lg:gap-4">
                    <div className="inline-flex">
                      <RadioButton
                        validation={validation}
                        label="Male"
                        name="gender"
                        value="Male"
                        id="Male"
                      />
                    </div>
                    <div className="inline-flex">
                      <RadioButton
                        validation={validation}
                        label="Female"
                        name="gender"
                        value="Female"
                        id="Female"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Textarea
                    validation={validation}
                    name="address"
                    label={labelConstants.ADDRESS}
                    isRequire={false}
                    row={3}
                    isDisplayPlaceHolder={true}
                    isLabelDisplay={false}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
              <div>
                <DatePicker
                  validation={validation}
                  name="dob"
                  label="Date of Birth"
                  isRequire={false}
                />
              </div>
              <div className="md:mt-8">
                <Text
                  validation={validation}
                  name="age"
                  label="Age"
                  isRequire={false}
                  readOnly={true}
                  regexCompare={regex.NUMBER}
                />
              </div>
              <div className="md:mt-8">
                <Text
                  validation={validation}
                  name="occupation"
                  label="Occupation"
                  isRequire={false}
                  regexCompare={regex.NAME}
                />
              </div>
            </div>
            <div className="mt-7">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div>
                  <label className="block text-base text-[#5A5A5A] font-normal mb-2 dark:text-white">
                    Upload Aadhaar Front
                  </label>
                  <TANUpload
                    validation={validation}
                    preview={eventfilePreview}
                    handleChange={handleEventChange}
                    label=""
                    nameInput="document"
                    fileType={eventFileType}
                    handleDeleteFile={handleDeleteLayoutFile}
                  />
                  {validation.errors['document'] ? (
                    <span className="text-red-500">
                      {validation.errors['document']}
                    </span>
                  ) : null}
                </div>
                <div>
                  <div className="flex justify-between">
                    <label className="block text-base text-[#5A5A5A] font-normal mb-2 dark:text-white text-start">
                      Upload Aadhaar Back
                    </label>
                    <label className="block text-base text-[#5A5A5A] font-normal mb-2 dark:text-white text-start md:text-end">
                      Aadhaar Image or PDF
                    </label>
                  </div>
                  <TANUpload
                    validation={validation}
                    preview={eventfilePreviewBack}
                    handleChange={handleEventChangeBack}
                    label=""
                    nameInput="document_back"
                    fileType={eventFileTypeBack}
                    handleDeleteFile={handleDeleteLayoutFileBack}
                  />
                  {validation.errors['document_back'] ? (
                    <span className="text-red-500">
                      {validation.errors['document_back']}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="text-end md:text-center mt-6">
                <button
                  className="border border-primary bg-primary text-white text-base font-body font-medium px-4 py-2 rounded-[5px]"
                  type="submit"
                  disabled={validation.isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {showSuccessfullyModal ? (
        <>
          <div className="fixed z-30 overflow-y-auto top-0 w-full left-0">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div
                className="inline-block align-center bg-white border border-[#4D4444] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] xl:w-[35%]"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white p-7 ">
                  <h2 className="text-2xl font-body font-medium text-primary text-center uppercase">
                    Your data save successfully
                  </h2>
                  <img
                    src={succesfully}
                    alt="img"
                    className="block m-auto mt-10"
                  />
                  <button
                    onClick={() => {
                      setShowSuccessfullyModal(false);
                      setTimeout(() => {
                        window.location.reload();
                      }, 700);
                    }}
                    className="bg-primary border border-primary px-8 py-4 rounded-lg text-white text-base font-body font-medium w-full uppercase tracking-[0.3rem] mt-10"
                  >
                    ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default withRouter(Registration);
