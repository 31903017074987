import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icon from '../../assets/images/small/clear-notification.png';
import icon2 from '../../assets/images/small/notification.png';
import withRouter from '../../components/Common/withRouter';
import { clearData, getData } from '../../store/AppMaster/actions';
import { isEmpty } from 'lodash';
import api from '../../constants/api_constants';
import { convertToDate } from '../../common/commonFunction';

const Notification = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const dispatch = useDispatch();
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isApiCall, setIsApiCall] = useState(0);
  const [isUpdateData, setIsUpdateData] = useState(true);
  const [isAllMarkAsRead, setIsAllMarkAsRead] = useState(false);
  const [filterData, setFilterData] = useState({
    start: 0,
    limit: 10,
    search: '',
    sort_by: '',
    sort_order: 'desc',
  });

  useEffect(() => {
    if (isApiCall === 0) {
      //Get Notification List
      setNotificationList([]);
      setNotificationCount(0);
      getNotificationList();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const getNotificationList = () => {
    setIsUpdateData(true);
    dispatch(clearData());
    dispatch(getData(filterData, props.router.navigate, api.NOTIFICATION_LIST));
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.notificationList) && isUpdateData) {
      setNotificationList((prevNotification) => [
        ...prevNotification,
        ...data.notificationList,
      ]);
      setNotificationCount(data.filter_count);
      filterData.start = filterData.start + filterData.limit;
      setIsUpdateData(false);
    }
  }, [
    data,
    data.notificationList,
    isUpdateData,
    localStorage.getItem('unread_notification_count'),
  ]);

  const markAllAsRead = () => {
    //get all unread notification id
    let unreadNotificationId = [];
    notificationList.map((notification) => {
      if (notification.read == 'Unread') {
        unreadNotificationId.push(notification.id);
      }
    });
    if (unreadNotificationId.length > 0) {
      const unreadNotificationIdString = unreadNotificationId.join(',');
      setIsAllMarkAsRead(true);
      dispatch(clearData());
      dispatch(
        getData(
          { id: unreadNotificationIdString },
          props.router.navigate,
          api.MarkAllAsRead,
        ),
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(data) && isAllMarkAsRead && data.notification) {
      setNotificationList([]);
      setNotificationCount(0);
      setIsAllMarkAsRead(false);
      filterData.start = 0;
      getNotificationList();
    }
  }, [data, isAllMarkAsRead, data.notification ? data.notification : '']);

  function handleLoadMore() {
    getNotificationList();
  }

  const singleNotification = (notification) => {
    setIsAllMarkAsRead(true);
    dispatch(clearData());
    dispatch(
      getData(
        { id: notification.id },
        props.router.navigate,
        api.MarkAllAsRead,
      ),
    );
    if (notification.redirection_url) {
      window.open(notification.redirection_url, '_blank');
    }
  };

  return (
    <>
      <div className="bg-white pt-14 pb-14">
        <div className="container">
          {notificationList.length > 0 ? (
            <>
              <div className="flex items-center justify-end mb-10">
                <div className="flex-1">
                  <h2 className="text-2xl md:text-3xl font-semibold text-[#101010] ">
                    Notifications
                  </h2>
                </div>
                <div className="flex-none"></div>
                <button
                  className="text-base text-[#CB2129] font-body font-medium border border-[#CB2129] py-2 px-4 inline-block rounded-lg"
                  onClick={() => markAllAsRead()}
                >
                  Mark All as Read
                </button>
              </div>

              {/* ----------------card-start--------------- */}
              {notificationList.length > 0 ? (
                <>
                  {notificationList.map((notification, index) => (
                    <div
                      key={index}
                      className={
                        notification.read == 'Unread'
                          ? 'bg-white border border-[#E7E7E7] p-5 rounded-lg mt-5'
                          : 'bg-[#F9F9F9]  p-5 rounded-lg mt-5'
                      }
                    >
                      <div>
                        <h3
                          onClick={() => {
                            {
                              notification.read == 'Unread'
                                ? singleNotification(notification)
                                : notification.redirection_url
                                ? window.open(
                                    notification.redirection_url,
                                    '_blank',
                                  )
                                : '';
                            }
                          }}
                          className="cursor-pointer text-lg md:text-xl text-[#101010] font-semibold"
                        >
                          🎉 {notification.title}
                        </h3>
                        <p className="text-sm md:text-xl text-[#101010] font-normal mt-2">
                          {notification.message}
                        </p>
                        <div className="flex mt-4">
                          <div className="flex-1">
                            <div className="flex">
                              <i className="fas fa-clock text-primary mr-2 mt-[1px] text-sm"></i>

                              <p className="text-sm md:text-base text-[#101010] font-normal">
                                {convertToDate(notification.created_at)}
                              </p>
                            </div>
                          </div>
                          {/* <div className="flex-none">
                        <i className="fas fa-trash-alt text-[#CB2129]"></i>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex items-center justify-center h-[264px] md:h-[400px]">
                  <h3 className="text-xl text-black font-body font-semibold">
                    No Notification Found
                  </h3>
                </div>
              )}
              {/* ----------------card-end---------------- */}
              {notificationCount > notificationList.length && (
                <div className="flex items-center justify-center mt-5">
                  <button
                    className="text-base text-[#CB2129] font-body font-semibold border border-[#CB2129] py-2 px-4 inline-block rounded-lg"
                    onClick={handleLoadMore}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center justify-center h-[264px] md:h-[400px]">
              <h3 className="text-xl text-black font-body font-semibold">
                No Notification Found
              </h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(Notification);
