import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uploadIcon from '../../assets/images/small/upload.svg';
import { isImageJpgOrPng } from '../../common/commonFunction';
import withRouter from '../../components/Common/withRouter';
import api from '../../constants/api_constants';
import {
  addUpdateNewData,
  addUpdateNewDataMultipleForm,
  clearData,
  getData,
} from '../../store/AppMaster/actions';
import RegDate from './RegDate';
import RegDropdown from './RegDropdown';
import RegEditor from './RegEditor';
import RegFile from './RegFile';
import RegText from './RegText';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegUrl from './RegUrl';

const RegistrationForm = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const fileInputRefs = useRef({});
  const [formErrors, setFormErrors] = useState({});
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isApiCall, setIsApiCall] = useState(0);
  const [registrationFormData, setRegistrationFormData] = useState([]);
  const [registrationFormFiled, setRegistrationFormFiled] = useState([]);
  const [previews, setPreviews] = useState({}); // For image previews
  const [isImageUpload, setIsImageUpload] = useState(false);

  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));
  const { error } = useSelector((state) => ({
    error: state.AppMaster.error,
  }));

  useEffect(() => {
    if (isApiCall === 0) {
      getDynamicRegForm();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const getDynamicRegForm = () => {
    setIsUpdateData(true);
    const requestData = {
      slug: params.registration_form_slug,
    };
    dispatch(clearData());
    dispatch(
      getData(requestData, props.router.navigate, api.REGISTRATION_FORM_GET),
    );
  };

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(data.registrationFormViewPublic) &&
      isUpdateData
    ) {
      setRegistrationFormData(data.registrationFormViewPublic);
      if (
        data.registrationFormViewPublic.mongoRegistrationForm.registration_form
      ) {
        const updatedFields =
          data.registrationFormViewPublic.mongoRegistrationForm.registration_form.map(
            (field) => ({
              ...field,
              value: '',
            }),
          );
        setRegistrationFormFiled(updatedFields);
      }
      setIsUpdateData(false);
    }
  }, [
    data,
    isUpdateData,
    data &&
      data?.registrationFormViewPublic?.mongoRegistrationForm
        ?.registration_form,
  ]);

  const [editorStates, setEditorStates] = useState(
    registrationFormFiled.reduce((acc, field) => {
      if (field && field.type === 'textarea') {
        acc[field.field_name] = EditorState.createEmpty();
      }
      return acc;
    }, {}),
  );

  const handleEditorChange = (fieldName, state) => {
    setEditorStates((prev) => ({ ...prev, [fieldName]: state }));
  };

  const handleImageChange = (e, fieldName) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;
    setFormErrors({}); // Clear previous errors
    if (file) {
      if (!isImageJpgOrPng(file)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: 'Only JPG/PNG files are allowed',
        }));
        return; // Stop further execution if validation fails
      } else if (file.size > maxSizeInBytes) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: 'File size should not exceed 2 MB',
        }));
        return; // Stop further execution if validation fails
      } else {
        // Set file in Formik state
        registrationFormFiled.map((field) => {
          if (field.field_name == fieldName) {
            setIsImageUpload(true);
            if (field.value) {
              const deleteImage = {
                image_name: field.value,
                type: 'registration_form',
              };
              dispatch(
                addUpdateNewData(
                  deleteImage,
                  props.router.navigate,
                  api.DELETE_IMAGE,
                ),
              );
            }
            field.value = file;
            const imageUpload = {
              image: file,
              field_name: fieldName,
              type: 'registration_form',
            };
            dispatch(
              addUpdateNewDataMultipleForm(
                imageUpload,
                props.router.navigate,
                api.UPLOAD_IMAGE,
              ),
            );
          }
        });
        // Set preview for display
        setPreviews((prev) => ({
          ...prev,
          [fieldName]: URL.createObjectURL(file),
        })); // Create a blob URL for the file
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.addImage) && isImageUpload) {
      setIsImageUpload(false);
      if (data.addImage.image_name) {
        registrationFormFiled.map((field) => {
          if (field.field_name == data.addImage.field_name) {
            field.value = data.addImage.image_name;
          }
        });
      }
    }
  }, [data, isImageUpload, data && data.addImage]);

  // Handler to trigger the file input click
  const handleDivClick = useCallback((fieldName) => {
    if (fileInputRefs.current[fieldName]) {
      fileInputRefs.current[fieldName].click();
    }
  }, []);

  const [isCityChange, setIsCityChange] = useState(false);
  const handleChangeField = (e, fieldName) => {
    let formField = registrationFormFiled.map((field) => {
      if (field.field_name == fieldName.field_name) {
        if (
          field.type == 'text' ||
          field.type == 'url' ||
          field.type == 'email'
        ) {
          field.value = e.target.value;
        } else if (field.type == 'number') {
          //only number allow
          const re = /^[0-9\b]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            field.value = e.target.value;
          }
        } else {
          field.value = e;
        }
      }
      return field;
    });
    if (fieldName.field_name == 'state') {
      setIsCityChange(true);
      dispatch(
        getData(
          { state_id: e.value },
          props.router.navigate,
          api.GET_CITY_BY_STATE,
        ),
      );
    }
    setRegistrationFormFiled(formField);
  };

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.cityDropdown) && isCityChange) {
      setIsCityChange(false);
      let cityList = data.cityDropdown.map((city) => {
        return {
          value: city.id,
          label: city.name,
        };
      });
      let formField = registrationFormFiled.map((field) => {
        if (field.field_name == 'city') {
          field.value = '';
          field.options = cityList;
        }
        return field;
      });
      setRegistrationFormFiled(formField); // <- This updates state
    }
  }, [data, isCityChange]); // <- But `registrationFormFiled` might not change properly

  const [isAddFlag, setIsAddFlag] = useState(false);
  const dynamicFormSave = async () => {
    let formErrors = {};
    let isFormValid = true;
    const validateField = registrationFormFiled.map((field) => {
      if (
        field.type == 'text' ||
        field.type == 'url' ||
        field.type == 'email' ||
        field.type == 'number'
      ) {
        if (field.is_required == 'Yes' && field.value == '') {
          formErrors[field.field_name] = field.display_name + ' is required';
          isFormValid = false;
        } else if (field.value && field.type == 'email') {
          // Email validation
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(field.value)) {
            formErrors[field.field_name] = 'Please enter a valid email';
            isFormValid = false;
          }
        } else if (field.value && field.type == 'url') {
          //Url validation
          const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
          if (!urlRegex.test(field.value)) {
            formErrors[field.field_name] = 'Please enter a valid URL';
            isFormValid = false;
          }
        } else if (field.value && field.type == 'number') {
          // Mobile number validation
          const mobileRegex = /^[0-9]{10}$/; // Adjust length as needed
          if (!mobileRegex.test(field.value)) {
            formErrors[field.field_name] =
              'Please enter a valid 10-digit mobile number';
            isFormValid = false;
          }
        }
      } else if (field.is_required == 'Yes' && field.type == 'dropdown') {
        if (field.value == '') {
          formErrors[field.field_name] = field.display_name + ' is required';
          isFormValid = false;
        }
      } else if (field.is_required == 'Yes' && field.type == 'textarea') {
        if (!editorStates[field.field_name]) {
          formErrors[field.field_name] = field.display_name + ' is required';
          isFormValid = false;
        } else if (
          editorStates[field.field_name]
            .getCurrentContent()
            .getPlainText()
            .trim() == ''
        ) {
          formErrors[field.field_name] = field.display_name + ' is required';
          isFormValid = false;
        }
      } else if (field.is_required == 'Yes' && field.type == 'date') {
        if (field.value == '') {
          formErrors[field.field_name] = field.display_name + ' is required';
          isFormValid = false;
        }
      } else if (field.is_required == 'Yes' && field.type == 'file') {
        if (field.value == '') {
          formErrors[field.field_name] = field.display_name + ' is required';
          isFormValid = false;
        }
      }
      return field;
    });
    setFormErrors(formErrors);
    if (isFormValid) {
      const updateFields = validateField.map((field) => {
        if (field.type === 'textarea' && editorStates[field.field_name]) {
          const contentTerms =
            editorStates[field.field_name].getCurrentContent();
          const terms = stateToHTML(contentTerms);
          field.value = terms;
        } else if (field.type === 'date') {
          field.value = field.value
            ? moment(field.value).format('DD-MM-YYYY')
            : '';
        } else if (field.type === 'dropdown') {
          field.value =
            field.value && field.value.label ? field.value.label : field.value;
        }
        return field;
      });
      const updatedRegistrationFormFiled = updateFields.map(
        ({ options, ...rest }) => rest,
      );
      dispatch(clearData());
      setIsAddFlag(true);
      const requestData = {
        slug: params.registration_form_slug,
        registration_form_data: JSON.stringify(updatedRegistrationFormFiled),
        url_string: params.uuid,
      };
      dispatch(
        addUpdateNewData(
          requestData,
          props.router.navigate,
          api.REGISTRATION_FORM_STORE,
        ),
      );
      setIsAddFlag(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dynamicFormSave();
  };

  return (
    <>
      {registrationFormFiled && (
        <div className="container">
          <div className="grid mt-8">
            {registrationFormData.form_banner && (
              <div className="col-span-12">
                <img
                  src={registrationFormData.form_banner}
                  alt=""
                  className="rounded-xl aspect-[99/40] w-full"
                />
              </div>
            )}
          </div>
          <div className="grid mt-8">
            <div className="col-span-12">
              <h1 className="text-[32px] md:text-[40px] font-bold text-center md:text-left">
                {registrationFormData.form_name}
              </h1>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="hidden md:block">
              <div className="flex items-start gap-5 mt-5">
                <div className="flex-none w-[200px]">
                  {registrationFormFiled &&
                    registrationFormFiled.map((field) => (
                      <>
                        {field.field_name == 'profile_image' && (
                          <RegFile
                            field={field}
                            handleImageChange={handleImageChange}
                            formErrors={formErrors}
                            previews={previews}
                            setPreviews={setPreviews}
                            fileInputRefs={fileInputRefs}
                            uploadIcon={uploadIcon}
                            handleDivClick={handleDivClick}
                            imageRatio="1/1"
                          />
                        )}
                      </>
                    ))}
                </div>
                <div className="flex-1">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 gap-y-0">
                    {registrationFormFiled &&
                      registrationFormFiled.map((field) => (
                        <div
                          key={field.field_name}
                          className={
                            field.type === 'text' || field.type === 'textarea'
                              ? 'col-span-1 md:col-span-2'
                              : 'col-span-1'
                          }
                        >
                          {(field.display_name == 'Name' ||
                            field.display_name == 'Email') && (
                            <div className="mb-4">
                              <RegText
                                field={field}
                                handleChangeField={handleChangeField}
                                formErrors={formErrors}
                              />
                            </div>
                          )}
                          {field.display_name == 'Mobile No.' && (
                            <div className="mb-4">
                              <RegText
                                field={field}
                                handleChangeField={handleChangeField}
                                formErrors={formErrors}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="block md:hidden">
              <div className="mt-5">
                <div className="w-[200px] m-auto ">
                  {registrationFormFiled &&
                    registrationFormFiled.map((field) => (
                      <>
                        {field.field_name == 'profile_image' && (
                          <RegFile
                            field={field}
                            handleImageChange={handleImageChange}
                            formErrors={formErrors}
                            previews={previews}
                            setPreviews={setPreviews}
                            fileInputRefs={fileInputRefs}
                            uploadIcon={uploadIcon}
                            handleDivClick={handleDivClick}
                            imageRatio="1/1"
                          />
                        )}
                      </>
                    ))}
                </div>
                <div className="flex-1">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 gap-y-0">
                    {registrationFormFiled &&
                      registrationFormFiled.map((field) => (
                        <div
                          key={field.field_name}
                          className={
                            field.type === 'text' || field.type === 'textarea'
                              ? 'col-span-1 md:col-span-2'
                              : 'col-span-1'
                          }
                        >
                          {(field.display_name == 'Name' ||
                            field.display_name == 'Email') && (
                            <div className="mb-4">
                              <RegText
                                field={field}
                                handleChangeField={handleChangeField}
                                formErrors={formErrors}
                              />
                            </div>
                          )}
                          {field.display_name == 'Mobile No.' && (
                            <div className="mb-4">
                              <RegText
                                field={field}
                                handleChangeField={handleChangeField}
                                formErrors={formErrors}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              {registrationFormFiled &&
                registrationFormFiled.map((field) => (
                  <>
                    {field.display_name != 'Name' &&
                      field.display_name != 'Email' &&
                      field.display_name != 'Mobile No.' &&
                      field.display_name != 'Profile Image' && (
                        <>
                          <div
                            key={field.field_name}
                            className={
                              field.type === 'text' || field.type === 'textarea'
                                ? 'col-span-1 md:col-span-2'
                                : 'col-span-1'
                            }
                          >
                            {field.type === 'text' ? (
                              <div>
                                <RegText
                                  field={field}
                                  handleChangeField={handleChangeField}
                                  formErrors={formErrors}
                                />
                              </div>
                            ) : field.type === 'url' ? (
                              <div>
                                <RegUrl
                                  field={field}
                                  handleChangeField={handleChangeField}
                                  formErrors={formErrors}
                                />
                              </div>
                            ) : field.type === 'dropdown' ? (
                              <div>
                                <RegDropdown
                                  field={field}
                                  handleChangeField={handleChangeField}
                                  formErrors={formErrors}
                                />
                              </div>
                            ) : field.type === 'date' ? (
                              <div>
                                <RegDate
                                  field={field}
                                  handleChangeField={handleChangeField}
                                  formErrors={formErrors}
                                />
                              </div>
                            ) : field.type === 'file' ? (
                              <>
                                <RegFile
                                  field={field}
                                  handleImageChange={handleImageChange}
                                  formErrors={formErrors}
                                  previews={previews}
                                  setPreviews={setPreviews}
                                  fileInputRefs={fileInputRefs}
                                  uploadIcon={uploadIcon}
                                  handleDivClick={handleDivClick}
                                  imageRatio="7/3"
                                />
                              </>
                            ) : field.type === 'textarea' ? (
                              <>
                                <RegEditor
                                  field={field}
                                  handleEditorChange={handleEditorChange}
                                  formErrors={formErrors}
                                  editorStates={editorStates}
                                />
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </>
                      )}
                  </>
                ))}
            </div>
            <div className="text-center mt-6 mb-6">
              <button
                className="btn-liner-gradient text-white font-body font-medium px-4 py-2 rounded-[5px]"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default withRouter(RegistrationForm);
