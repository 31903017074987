import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCms } from '../../../store/home/actions';

const useHomeData = (router) => {
  const dispatch = useDispatch();

  const { tradingData, featuredData, exclusiveOfferData, eventData, artistData ,seoData} =
  useSelector((state) => ({
    tradingData: state.home.tradingData,
    featuredData: state.home.featuredData,
    exclusiveOfferData: state.home.exclusiveOfferData,
    eventData: state.home.eventData,
    artistData: state.home.artistData,
    seoData:state.home.seoData
  }));
  
  // Store previous values of city_name and category_name
  const prevCityName = useRef(router.params.city_name);
  const prevCategoryName = useRef(router.params.category_name);
  const cityName = router.params.city_name;

  const fetchData = useCallback((sectionTitle) => {
    let requestData = { section_titles: sectionTitle };

    if (router.params.city_name) requestData.city = router.params.city_name;
    if (router.params.category_name) requestData.category = router.params.category_name;

    dispatch(getCms(requestData, router.navigate));
  }, [dispatch, router.navigate, router.params.city_name, router.params.category_name]);

  useEffect(() => {
    const { city_name, category_name } = router.params;

    if (city_name !== prevCityName.current || category_name !== prevCategoryName.current) {
      // Update previous values
      prevCityName.current = city_name;
      prevCategoryName.current = category_name;

      // Call fetchData only if city or category changed
      fetchData('trending');
      fetchData('feature');
      fetchData('exclusive_offers');
      fetchData('events');
      fetchData('artists');
    }
  }, [ router.params.city_name, router.params.category_name]);

  return {
    tradingData,
    featuredData,
    exclusiveOfferData,
    eventData,
    artistData,
    seoData,
    cityName
  };
};

export default useHomeData;
