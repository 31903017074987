import React from 'react';
import { Link } from 'react-router-dom';
import mobileimg from '../../../assets/light_theme_images/Mobile.svg';
import googlePlay from '../../../assets/light_theme_images/google-play-store-logo.svg';
import appStore from '../../../assets/light_theme_images/Download_on_the_App_Store.svg';
import qrImage from '../../../assets/light_theme_images/qr-code.png';
const AppDetails = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-24 lg:px-14">
        <div className="flex flex-col gap-6 md:gap-10">
          <div className="flex flex-col gap-3">
            <h4 className="text-2xl lg:text-5xl font-semibold text-[#212121]">
              Best of the city, now in your pockets{' '}
            </h4>
            <p className="text-lg lg:text-2xl font-normal text-[#212121]">
              Download the <b>Mepass app</b> to stay updated with the best
              events around you!
            </p>
          </div>
          <div className="flex items-center gap-4 lg:gap-10">
            <div className="flex flex-col gap-6">
              {process.env.REACT_APP_PLAY_STORE_URL ? (
                <Link to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank">
                  <img src={googlePlay} alt="img" />
                </Link>
              ) : (
                <img src={googlePlay} alt="img" />
              )}
              {process.env.REACT_APP_APP_STORE_URL ? (
                <Link to={process.env.REACT_APP_APP_STORE_URL} target="_blank">
                  <img src={appStore} alt="img" />
                </Link>
              ) : (
                <img src={appStore} alt="img" />
              )}
            </div>
            <p className="text-2xl font-semibold text-[#212121]">OR</p>
            <div className="border-2 border-[#101010] rounded-[10px] p-2 w-[170px]">
              <img src={qrImage} alt="img" className="aspect-[1/1]" />
            </div>
          </div>
        </div>
        <div className="ml-auto">
          <img src={mobileimg} alt="img" className="px-10 md:px-0 w-full" />
        </div>
      </div>
    </>
  );
};

export default AppDetails;
