import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import banner3 from '../../assets/images/user-profile-bg.png';
import withRouter from '../../components/Common/withRouter';
import UserProfileDetails from '../../components/UserProfile/UserProfile';
import TicketHistory from '../../components/UserProfile/TicketHistory';
import SettingDetails from '../../components/UserProfile/Setting';
import InterestDetails from '../../components/UserProfile/Interest';
import TicketHistoryDetails from '../../components/UserProfile/TicketHistoryDetails';
import profileIcon from '../../assets/light_theme_images/profile-icon/profile.svg';
import profileIconLight from '../../assets/light_theme_images/profile-icon/profile-light.svg';
import passIcon from '../../assets/light_theme_images/profile-icon/pass.svg';
import passIconLight from '../../assets/light_theme_images/profile-icon/pass-light.svg';
import notificationIcon from '../../assets/light_theme_images/profile-icon/solar_notification.svg';
import notificationIconLight from '../../assets/light_theme_images/profile-icon/solar_notification-light.svg';
import interestIcon from '../../assets/light_theme_images/profile-icon/interest.svg';
import interestIconLight from '../../assets/light_theme_images/profile-icon/interest-light.svg';
import logOutIcon from '../../assets/light_theme_images/profile-icon/log-out.svg';
import logOutIconLight from '../../assets/light_theme_images/profile-icon/log-out-light.svg';
import { useSelector } from 'react-redux';
import api from '../../constants/api_constants';
const UserProfile = (props) => {
  const { loading } = useSelector((state) => ({
    loading: state.AppMaster.loading,
  }));

  const fullUrl = window.location.href;
  const appUrl = fullUrl.split('/');
  const lastSegment = appUrl[appUrl.length - 1];
  const [customActiveTab, setCustomActiveTab] = useState(1);
  const [isApiCall, setIsApiCall] = useState(0);
  const authData = JSON.parse(localStorage.getItem('publicMePassUserDetails'));
  const navigate = useNavigate();
  const toggleCustom = (tab) => {
    setCustomActiveTab(tab);
    if (tab == 3) {
      setIsApiCall(0);
      navigate(`/user-setting`);
    } else if (tab == 2) {
      setIsApiCall(0);
      navigate(`/user-tickets`);
    } else if (tab == 4) {
      setIsApiCall(0);
      navigate(`/user-interest`);
    } else if (tab == 5) {
      // setIsApiCall(0);
      // navigate(`/share-history`);
    } else {
      setIsApiCall(0);
      navigate(`/user-profile`);
    }
  };
  useEffect(() => {
    if (lastSegment == 'user-profile') {
      setIsApiCall(0);
      setCustomActiveTab(1);
    } else if (lastSegment == 'user-setting') {
      setIsApiCall(0);
      setCustomActiveTab(3);
    } else if (lastSegment == 'user-tickets') {
      setIsApiCall(0);
      setCustomActiveTab(2);
    } else if (lastSegment == 'share-history') {
      setIsApiCall(0);
      setCustomActiveTab(5);
    } else if (lastSegment == 'user-interest') {
      setIsApiCall(0);
      setCustomActiveTab(4);
    }
  }, [lastSegment]);

  return (
    <>
      <div className="bg-white py-14 pb-14">
        {loading && (
          <div className="fixed inset-0 z-50 bg-[#FAFAFA] bg-opacity-80 flex items-center justify-center">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        )}
        <div className="container">
          <div className="lg:px-[20px] xl:px-14">
            <h2 className="text-2xl md:text-4xl text-[#101010] font-semibold text-center mb-8">
              Hello {authData.name}!
            </h2>
            <div>
              <div className="bg-[#F9F9F9] p-2 rounded-lg">
                <div className="flex">
                  <div
                    className={`py-[3px] md:py-[9px] px-[5px] md:px-6 text-[13px] md:text-lg font-medium cursor-pointer ${
                      customActiveTab === 1
                        ? 'text-[#CB2129] border-b-2 border-[#CB2129] bg-[#FFFDF5] w-[283%] lg:w-full'
                        : 'text-[#101010] border-b-2 border-[#FFFDF5] bg-white w-full'
                    } text-center rounded-[4px] mr-2 flex items-center justify-center`}
                    onClick={() => toggleCustom(1)}
                  >
                    <img
                      src={
                        customActiveTab === 1 ? profileIconLight : profileIcon
                      }
                      alt="Profile"
                      className="inline-block size-4 md:size-6"
                    />
                    <p
                      className={`${
                        customActiveTab === 1
                          ? 'inline-block ml-2'
                          : 'hidden lg:inline-block ml-0 lg:ml-2'
                      }`}
                    >
                      Profile
                    </p>
                  </div>
                  <div
                    className={`py-[3px] md:py-[9px] px-[5px] md:px-6  text-[13px] md:text-lg font-medium cursor-pointer ${
                      customActiveTab === 2
                        ? 'text-[#CB2129] border-b-2 border-[#CB2129] bg-[#FFFDF5] w-[283%] lg:w-full'
                        : 'text-[#101010] border-b-2 border-[#FFFDF5] bg-white w-full'
                    } text-center rounded-[4px] mr-2 flex items-center justify-center`}
                    onClick={() => {
                      toggleCustom(2);
                    }}
                  >
                    <img
                      src={customActiveTab === 2 ? passIconLight : passIcon}
                      alt="Profile"
                      className="inline-block size-4 md:size-6"
                    />
                    <p
                      className={`${
                        customActiveTab === 2
                          ? 'inline-block ml-2'
                          : 'hidden lg:inline-block ml-0 lg:ml-2'
                      }`}
                    >
                      My Passes
                    </p>
                  </div>
                  <div
                    className={`py-[3px] md:py-[9px] px-[5px] md:px-6  text-[13px] md:text-lg cursor-pointer ${
                      customActiveTab === 3
                        ? 'text-[#CB2129] border-b-2 border-[#CB2129] bg-[#FFFDF5] w-[283%] lg:w-full'
                        : 'text-[#101010] border-b-2 border-[#FFFDF5] bg-white w-full'
                    } text-center rounded-[4px] mr-2 flex items-center justify-center `}
                    onClick={() => toggleCustom(3)}
                  >
                    <img
                      src={
                        customActiveTab === 3
                          ? notificationIconLight
                          : notificationIcon
                      }
                      alt="Profile"
                      className="inline-block size-4 md:size-6"
                    />
                    <p
                      className={`${
                        customActiveTab === 3
                          ? 'inline-block ml-2'
                          : 'hidden lg:inline-block ml-0 lg:ml-2'
                      }`}
                    >
                      Notifications
                    </p>
                  </div>
                  <div
                    className={`py-[3px] md:py-[9px] px-[5px] md:px-6  text-[13px] md:text-lg cursor-pointer ${
                      customActiveTab === 4
                        ? 'text-[#CB2129] border-b-2 border-[#CB2129] bg-[#FFFDF5] w-[283%] lg:w-full'
                        : 'text-[#101010] border-b-2 border-[#FFFDF5] bg-white w-full'
                    } text-center rounded-[4px] mr-2 flex items-center justify-center`}
                    onClick={() => toggleCustom(4)}
                  >
                    <img
                      src={
                        customActiveTab === 4 ? interestIconLight : interestIcon
                      }
                      alt="Profile"
                      className="inline-block size-4 md:size-6"
                    />
                    <p
                      className={`${
                        customActiveTab === 4
                          ? 'inline-block ml-2'
                          : 'hidden lg:inline-block ml-0 lg:ml-2'
                      }`}
                    >
                      Interest
                    </p>
                  </div>
                  <div
                    onClick={() => toggleCustom(5)}
                    className={`py-[3px] md:py-[9px] px-[5px] md:px-6  text-[13px] md:text-lg cursor-pointer ${
                      customActiveTab === 5
                        ? 'text-[#CB2129] border-b-2 border-[#CB2129] bg-[#FFFDF5] w-[283%] lg:w-full'
                        : 'text-[#101010] border-b-2 border-[#FFFDF5] bg-white w-full'
                    } text-center rounded-[4px] mr-2 flex items-center justify-center`}
                  >
                    <img
                      src={customActiveTab === 5 ? logOutIconLight : logOutIcon}
                      alt="Profile"
                      className="inline-block size-4 md:size-6"
                    />
                    <p
                      className={`${
                        customActiveTab === 5
                          ? 'inline-block ml-2'
                          : 'hidden lg:inline-block ml-0 lg:ml-2'
                      }`}
                    >
                      {' '}
                      Log out
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8 lg:px-20">
                <div>
                  {customActiveTab === 1 && (
                    <UserProfileDetails
                      props={props}
                      isApiCall={isApiCall}
                      setIsApiCall={setIsApiCall}
                    ></UserProfileDetails>
                  )}
                  {/* -----------------------------ticket-details-start----------------------- */}
                  {customActiveTab === 2 && (
                    <TicketHistory
                      props={props}
                      isApiCall={isApiCall}
                      setIsApiCall={setIsApiCall}
                    ></TicketHistory>
                  )}
                  {/* -----------------------------ticket-details-end----------------------- */}
                  {/* -----------------------------setting-details-start----------------------- */}
                  {customActiveTab === 3 && (
                    <SettingDetails
                      props={props}
                      isApiCall={isApiCall}
                      setIsApiCall={setIsApiCall}
                    ></SettingDetails>
                  )}
                  {/* -----------------------------setting-details-end----------------------- */}
                  {customActiveTab === 4 && (
                    <InterestDetails
                      props={props}
                      isApiCall={isApiCall}
                      setIsApiCall={setIsApiCall}
                    ></InterestDetails>
                  )}
                  {customActiveTab === 5 && (
                    <>
                      <div className="bg-[#F9F9F9] p-[20px] md:p-[40px] rounded-lg md:rounded-[2px] text-center h-full md:h-[400px] flex flex-col justify-center">
                        <h2 className="text-lg md:text-2xl font-semibold text-[#101010]">
                          You are attempting to log out of Mepass
                        </h2>
                        <h3 className="text-base md:text-2xl font-semibold text-[#101010] mt-8">
                          Are you Sure?
                        </h3>
                        <div className="flex gap-5 mt-4 justify-center">
                          <Link
                            to="/"
                            className="w-[54px] h-[40px] rounded-lg border border-[#CB2129] text-[#CB2129] text-base font-medium flex items-center justify-center"
                          >
                            No{' '}
                          </Link>
                          <Link
                            to="/logout"
                            state={{ previousPage: 'Login' }}
                            className="btn-liner-gradient w-[61px] h-[40px] rounded-lg border border-[#CB2129] text-white text-base font-semibold flex items-center justify-center"
                          >
                            Yes
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(UserProfile);
