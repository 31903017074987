import React from 'react';

const PriceDetail = ({
  passDetails,
  pricingDetails,
  textColor,
  secondaryColor,
}) => {
  const [isOpenPricing, setIsOpenPricing] = useState(false);
  return (
    <>
      {passDetails.allocation_through == 'Share' ? (
        <div className="group border border-[#E7E7E7] rounded-[10px] p-4">
          <h2
            className={`text-lg text-[${textColor}] font-body font-semibold`}
            style={{ color: textColor }}
          >
            Shared Pass
          </h2>
        </div>
      ) : (
        <>
          <div className="group border border-[#E7E7E7] rounded-[10px] p-4">
            <button
              onClick={() => setIsOpenPricing(!isOpenPricing)}
              className="w-full flex justify-between items-center cursor-pointer select-none"
            >
              <p className="text-base text-[#101010] font-semibold">Pricing</p>
              <i
                className={`fas text-[#000000] ${
                  isOpenPricing ? 'fa-chevron-up' : 'fa-chevron-down'
                }`}
              ></i>
            </button>
            {isOpenPricing && (
              <div>
                <div className="border-b-2 border-dashed border-primary my-3"></div>
                <h3
                  className="text-lg text-[#5A5A5A] font-body font-semibold mb-3"
                  style={{ color: textColor }}
                >
                  {passDetails && passDetails.event && passDetails.event.name}
                </h3>
                {pricingDetails && pricingDetails.amount > 0 && (
                  <div className="flex items-center justify-between gap-4 mb-3">
                    <h3
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      Ticket Price
                    </h3>
                    <p
                      className="text-base  font-body font-medium"
                      style={{ color: textColor }}
                    >
                      ₹ {pricingDetails.amount}
                    </p>
                  </div>
                )}
                {pricingDetails && pricingDetails.amount > 0 && (
                  <div className="flex items-center justify-between gap-4 mb-3">
                    <h3
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      QTY
                    </h3>
                    <p
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      {passDetails.available_quantity}
                    </p>
                  </div>
                )}
                {pricingDetails && pricingDetails.igst > 0 && (
                  <div className="flex items-center justify-between gap-4 mb-3">
                    <h3
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      IGST
                    </h3>
                    <p
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      ₹ {pricingDetails.igst}
                    </p>
                  </div>
                )}
                {pricingDetails && pricingDetails.convenience_charge > 0 && (
                  <div className="flex items-center justify-between gap-4 mb-3">
                    <h3
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      Convenience Charge
                    </h3>
                    <p
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      ₹ {pricingDetails.convenience_charge}
                    </p>
                  </div>
                )}
                {pricingDetails && pricingDetails.discount > 0 && (
                  <div className="flex items-center justify-between gap-4 mb-3">
                    <h3
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      Discount
                    </h3>
                    <p
                      className="text-base font-body font-medium"
                      style={{ color: textColor }}
                    >
                      ₹ {pricingDetails.discount}
                    </p>
                  </div>
                )}
                <div className="border-b-2 border-dashed border-primary my-4"></div>
                {pricingDetails.amount > 0 && (
                  <div className="flex items-center justify-between gap-4 mb-3">
                    <h3
                      className="text-base text-[#5A5A5A] font-body font-medium"
                      style={{ color: textColor }}
                    >
                      Amount Paid
                    </h3>
                    <p
                      className="text-base text-[#5A5A5A] font-body font-medium"
                      style={{ color: textColor }}
                    >
                      ₹ {pricingDetails.net_amount}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
PriceDetail.propTypes = {};
export default PriceDetail;
