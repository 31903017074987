import {
  GET_CMS,
  GET_CMS_FAIL,
  GET_CMS_SUCCESS,
  CLEAR_CMS_DATA,
  GET_POPULAR_CITY,
  GET_POPULAR_CITY_SUCCESS,
  GET_POPULAR_CITY_FAIL,
  CLEAR_POPULAR_CITY_DATA,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  CLEAR_CATEGORIES_DATA,
  GET_LOCATION,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAIL,
  CLEAR_LOCATION_DATA,
} from './actionTypes';

export const getCms = (data, history) => ({
  type: GET_CMS,
  payload: { data, history },
});

export const getCmsSuccess = (data) => ({
  type: GET_CMS_SUCCESS,
  payload: { data },
});

export const getCmsFail = (error) => ({
  type: GET_CMS_FAIL,
  payload: error,
});

export const clearCmsData = () => ({
  type: CLEAR_CMS_DATA,
  payload: '',
});

export const getPopularCity = (data, history) => ({
  type: GET_POPULAR_CITY,
  payload: { data, history },
});

export const getPopularCitySuccess = (data) => ({
  type: GET_POPULAR_CITY_SUCCESS,
  payload: { data },
});

export const getPopularCityFail = (error) => ({
  type: GET_POPULAR_CITY_FAIL,
  payload: error,
});

export const clearPopularCityData = () => ({
  type: CLEAR_POPULAR_CITY_DATA,
  payload: '',
});

export const getCategories = (data, history) => ({
  type: GET_CATEGORIES,
  payload: { data, history },
});

export const getCategoriesSuccess = (data) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: { data },
});

export const getCategoriesFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});

export const clearCategoriesData = () => ({
  type: CLEAR_CATEGORIES_DATA,
  payload: '',
});

export const getLocation = (data, history) => ({
  type: GET_LOCATION,
  payload: { data, history },
});

export const getLocationSuccess = (data) => ({
  type: GET_LOCATION_SUCCESS,
  payload: { data },
});

export const getLocationFail = (error) => ({
  type: GET_LOCATION_FAIL,
  payload: error,
});

export const clearLocationData = () => ({
  type: CLEAR_LOCATION_DATA,
  payload: '',
});
