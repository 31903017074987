import instagramIcon from '../../assets/light_theme_images/instagram.svg';
import facebookIcon from '../../assets/light_theme_images/facebook.svg';
import twitterIcon from '../../assets/light_theme_images/twitter.svg';
import whatsappIcon from '../../assets/light_theme_images/whatsapp.svg';
import React from 'react';

const ShareButtons = ({ eventDetail }) => {
  const domain = window.location.hostname;
  let storyUrl = '';
  if (domain === 'www.mepass.in' || domain === 'mepass.in') {
    storyUrl = 'https://www.mepass.in/events/' + eventDetail.slug;
  } else {
    storyUrl =
      'https://stage-frontendpublic.mepass.co.in/events/' + eventDetail.slug;
  }
  const eventUrl = encodeURIComponent(storyUrl);
  const eventTitle = encodeURIComponent(eventDetail.name);
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${eventTitle}&url=${eventUrl}&hashtags=Event,Fun`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${eventUrl}`;
  // const whatsAppShareUrl = `https://web.whatsapp.com/send?text=${eventUrl}`;
  const instagramShareUrl = `https://instagram.com`; // Direct Instagram sharing is not available.
  // const instagramStoryShare = () => {
  //     window.open(`instagram://story?source_url=${eventUrl}`);
  // };
  const whatsAppShareUrl =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
      ? `https://wa.me/?text=${eventUrl}` // Mobile version
      : `https://web.whatsapp.com/send?text=${eventUrl}`;
  const instagramStoryShare = () => {
    const imageUrl = encodeURIComponent(eventDetail.imageUrl || ''); // Replace with your event's image URL
    const redirectUrl = encodeURIComponent(storyUrl); // Optional link when swiping up
    window.open(
      `instagram://story?background_image=${imageUrl}&source_url=${redirectUrl}`,
      '_blank',
    );
  };
  return (
    <>
      <li>
        <a
          className="cursor-pointer"
          href={whatsAppShareUrl}
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsappIcon} alt="icon" />
        </a>
      </li>
      {/* <li>
                <a className="cursor-pointer"
                    onClick={instagramStoryShare}
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={instagramIcon} alt="icon" />
                </a>
            </li> */}
      <li>
        <a href={twitterShareUrl} target="_blank" rel="noreferrer">
          <img src={twitterIcon} alt="icon" />
        </a>
      </li>
      <li>
        <a href={facebookShareUrl} target="_blank" rel="noreferrer">
          <img src={facebookIcon} alt="icon" />
        </a>
      </li>
    </>
  );
};

export default ShareButtons;
