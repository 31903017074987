import { Navigate, Route } from 'react-router-dom';

export function convertToAmPm(time) {
  if (time) {
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert hour '0' to '12'
    return `${hours}:${minutes} ${ampm}`;
  } else {
    return '';
  }
}
export function isPdforImageJpgOrPng(value) {
  try {
    return (
      value &&
      ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type)
    );
  } catch (err) {
    return false;
  }
}
export function isImageJpgOrPng(value) {
  try {
    return value && ['image/jpeg', 'image/png'].includes(value.type);
  } catch (err) {
    return false;
  }
}
export function isImageSizeValid(value, minWidth, minHeight) {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.src = window.URL.createObjectURL(value);
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        resolve(width === minWidth && height === minHeight);
      };
    } catch (err) {
      reject(err);
    }
  });
}
export function numberFormatter(number) {
  return number >= 1000 ? number / 1000 + 'K' : number;
}
export function convertToDate(date, paceToDisplay) {
  //Date Convert Like Sat 18 Jan 2025
  let newDate = new Date(date);
  let month = newDate.toLocaleString('default', { month: 'short' });
  let monthFull = newDate.toLocaleString('default', { month: 'long' });
  let day = newDate.getDate();
  let year = newDate.getFullYear();
  let shortYear = year.toString().substr(-2);
  let dayName = newDate.toLocaleString('default', { weekday: 'short' });
  let fullDayName = newDate.toLocaleString('default', { weekday: 'long' });
  if (paceToDisplay == 'pass') {
    return `${fullDayName}, ${day} ${month}`;
  } else if (paceToDisplay == 'blog') {
    return `${monthFull} ${day}, ${year}`;
  } else {
    return `${dayName} ${day} ${month} ${shortYear}`;
  }
}

/**function for old url to new url redirect */
export function redirectToNewUrl() {
  const oldNewArray = [
    {
      old: '/events/rassleela-navratri-2024',
      new: '/events/raasleela-navratri-2024',
    },
    {
      old: '/events/mandli-garba',
      new: '/events/mandali-garba-2024',
    },
    {
      old: '/events/mandali-garba',
      new: '/events/mandli-garba-2024',
    },
  ];
  return oldNewArray.map((item) => {
    if (window.location.pathname === item.old) {
      window.location.pathname = item.new;
    }
  });
}
