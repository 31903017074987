import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { isEmpty } from 'lodash';

const ProfileMenu = ({ notification, authUserDetails, user, selectCity }) => {
  const [unread_notification_count, setUnreadNotificationCount] = useState(
    JSON.parse(localStorage.getItem('unread_notification_count')),
  );
  useEffect(() => {
    let previousValue = localStorage.getItem('unread_notification_count');
    const interval = setInterval(() => {
      const currentValue = localStorage.getItem('unread_notification_count');
      if (currentValue !== previousValue) {
        previousValue = currentValue;
        setUnreadNotificationCount(JSON.parse(currentValue));
      }
    }, 1000);
    return () => clearInterval(interval); // Cleanup
  }, []);
  const publicUserSelectMePassCity = localStorage.getItem(
    'publicUserSelectMePassCity',
  );

  // Conditionally set the URL based on 'publicUserSelectMePassCity'
  const notificationLink =
    publicUserSelectMePassCity &&
    publicUserSelectMePassCity !== 'null' &&
    publicUserSelectMePassCity.trim() !== ''
      ? `/city/${publicUserSelectMePassCity}/notification`
      : '/notification';

  return (
    <>
      {localStorage.getItem('publicMePassUser') ? (
        <>
          <Link to="/notification">
            <div className="relative hidden lg:block">
              <div className="size-12 bg-transparent search-border-liner-gradient rounded-full flex items-center justify-center">
                <i className="fa-solid fa-bell text-[#101010] text-xl"></i>
              </div>
              <span className="absolute -top-[4px] -right-[5px] bg-white border border-[#CB2129] text-[#101010] text-[11px] font-normal rounded-full px-2 py-1">
                {unread_notification_count}
              </span>
            </div>
          </Link>
          <div className="flex-none">
            <Link to="/user-profile">
              {!isEmpty(authUserDetails.profile_image_path) ? (
                <img
                  src={authUserDetails.profile_image_path || logo}
                  className="size-[34px] md:size-[45px] lg:size-12 rounded-full"
                  alt="Profile"
                />
              ) : (
                <>
                  {!isEmpty(authUserDetails.name) ? (
                    <div className="flex items-center justify-center h-10 lg:h-12 w-10 lg:w-12 bg-[#FFDEDE] rounded-full text-primary">
                      {authUserDetails.name.charAt(0)}
                    </div>
                  ) : (
                    <div className="size-[34px] md:size-[45px] bg-transparent search-border-liner-gradient rounded-full flex items-center justify-center p-1">
                      <img
                        src={user}
                        className="rounded-full inline-block"
                        alt="Default User"
                      />
                    </div>
                  )}
                </>
              )}
            </Link>
          </div>
        </>
      ) : (
        <Link
          to="/login"
          className="btn-liner-gradient text-[12px] md:text-base text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded-lg flex items-center justify-center"
          state={{ is_login_page: 'Yes' }}
        >
          Login / Register
        </Link>
      )}
    </>
  );
};

export default ProfileMenu;
