import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import { convertToAmPm } from '../../common/commonFunction';
import { pushAddToCart } from '../../seo/gtevents';

const SelectPassTime = ({ movieQuantity, setMovieQuantity }) => {
  return (
    <>
      <div className="p-3 rounded-lg">
        <div className="flex gap-2">
          <div className="flex-1">
            <h3 className="text-lg md:text-3xl text-[#101010] font-semibold">
              How may seats?
            </h3>
          </div>
          <div className="flex-none">
            <div className="btn-liner-gradient text-white py-2 px-4 rounded-lg">
              <div className="relative flex items-center gap-3">
                <button
                  type="button"
                  onClick={() => {
                    setMovieQuantity((prev) => (prev > 0 ? prev - 1 : 0));
                  }}
                  className="text-white text-base md:text-2xl"
                >
                  <i className="fas fa-minus"></i>
                </button>
                <input
                  type="text"
                  id="quantity-input"
                  value={movieQuantity ? movieQuantity : 1}
                  className="max-w-[35px] md:max-w-[40px] h-[26px] md:h-auto flex items-center justify-center text-center text-base md:text-2xl font-body font-semibold text-white bg-transparent focus-visible:border-0 focus-visible:outline-0"
                  required
                  readOnly
                />
                <button
                  type="button"
                  onClick={() => {
                    //Add condition to check the max limit of movie quantity 10
                    setMovieQuantity((prev) => (prev < 10 ? prev + 1 : 10));
                  }}
                  className=" text-white"
                >
                  <i className="fas fa-plus text-base md:text-2xl"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
SelectPassTime.propTypes = {};
export default SelectPassTime;
