import React, { useEffect, useState } from 'react';
import footerLogo from '../../assets/light_theme_images/white-logo.svg';
import googleimg from '../../assets/light_theme_images/google-play-store-logo.svg';
import appstore from '../../assets/light_theme_images/Download_on_the_App_Store.svg';
import logo from '../../assets/images/bsp-technologies.svg';
import logo2 from '../../assets/images/weone-logo.jpeg';
import { Link } from 'react-router-dom';
import withRouter from '../Common/withRouter';
const Footer = (props) => {
  const [eventLink, setEventLink] = useState('/events');
  useEffect(() => {
    let cityName = '';
    if (props.router.params.city_name) {
      cityName = props.router.params.city_name;
    } else if (localStorage.getItem('publicUserSelectMePassCity')) {
      cityName = localStorage.getItem('publicUserSelectMePassCity');
    }
    let link = '/events';
    if (cityName) {
      link = `/city/${cityName}/events`;
      setEventLink(link);
    }
  }),
    [];
  return (
    <React.Fragment>
      <div className="bg-black pt-10 pb-10 bg-center bg-blend-multiply">
        <div className="container mx-auto ">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-14 md:gap-10 lg:gap-14">
            <div className="flex flex-col gap-20">
              <div className="text-center md:text-start flex flex-col items-center md:items-start">
                <div className="w-[163.63px]">
                  <img src={footerLogo} alt="footer" className="inline-block" />
                </div>
                <p className="text-sm md:text-base font-medium text-white mt-3 md:mt-2 w-full lg:w-[319px]">
                  Revolutionising Ticketing with Tech for Entire Entertainment
                  Ecosystem
                </p>
              </div>
              <div className="hidden md:block">
                <h4 className="text-lg font-semibold text-white">Follow Us</h4>
                <div className="flex items-center justify-start mt-3">
                  <Link
                    to="https://www.facebook.com/mepassbharat"
                    target="_blank"
                  >
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                      <i className="fab fa-facebook-f text-black text-base"></i>
                    </div>
                  </Link>
                  <Link to="https://x.com/vibewithmepass" target="_blank">
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                      <i className="fa-brands fa-x-twitter text-black text-base"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/mepassbharat/"
                    target="_blank"
                  >
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                      <i className="fab fa-linkedin text-black text-base"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://www.instagram.com/vibewithmepass/"
                    target="_blank"
                  >
                    <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center">
                      <i className="fab fa-instagram text-black text-base"></i>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex justify-center md:justify-end gap-10 md:gap-5 lg:gap-10">
              <div className="flex flex-col gap-6">
                <h4 className="text-base md:text-xl font-semibold text-white">
                  Quick Menu
                </h4>
                <ul className="text-white flex flex-col gap-4">
                  {/* <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/">Home</Link>
                  </li> */}
                  <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/business">Business</Link>
                  </li>
                  <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to={eventLink}>Events</Link>
                  </li>
                  <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  {/* <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="">Exhibitions</Link>
                  </li> */}
                  {/* <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/about-us">About us</Link>
                  </li> */}
                  <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/our-blog">Blogs</Link>
                  </li>
                  {/* <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="">FAQs</Link>
                  </li> */}
                </ul>
              </div>
              <div className="flex flex-col gap-6">
                <h4 className="text-base md:text-xl font-semibold text-white">
                  Company
                </h4>
                <ul className="text-white flex flex-col gap-4">
                  <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/refund-cancellation-policy">Refund Policy</Link>
                  </li>
                  {/* <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/coming-soon">Support</Link>
                  </li> */}
                  <li className="text-sm md:text-sm lg:text-lg font-normal hover:text-[#CB2129] duration-1000 ease-in-out">
                    <Link to="/terms-condition">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-row md:flex-col gap-5 items-end">
              {process.env.REACT_APP_PLAY_STORE_URL ? (
                <Link to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank">
                  <img src={googleimg} className="w-[249.23px]" />
                </Link>
              ) : (
                <img src={googleimg} className="w-[249.23px]" />
              )}
              {process.env.REACT_APP_APP_STORE_URL ? (
                <Link to={process.env.REACT_APP_APP_STORE_URL} target="_blank">
                  <img src={appstore} className="w-[249.23px]" />
                </Link>
              ) : (
                <img src={appstore} className="w-[249.23px]" />
              )}
            </div>
            <div className="block md:hidden">
              <h4 className="text-lg font-semibold text-white text-center">
                Follow Us
              </h4>
              <div className="flex items-center justify-center mt-3">
                <Link
                  to="https://www.facebook.com/mepassbharat"
                  target="_blank"
                >
                  <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                    <i className="fab fa-facebook-f text-black text-base"></i>
                  </div>
                </Link>
                <Link to="https://x.com/vibewithmepass" target="_blank">
                  <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                    <i className="fa-brands fa-x-twitter text-black text-base"></i>
                  </div>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/mepassbharat/"
                  target="_blank"
                >
                  <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center mr-3">
                    <i className="fab fa-linkedin text-black text-base"></i>
                  </div>
                </Link>
                <Link
                  to="https://www.instagram.com/vibewithmepass/"
                  target="_blank"
                >
                  <div className="bg-white w-[32px] h-[32px] rounded justify-center flex items-center">
                    <i className="fab fa-instagram text-black text-base"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* copyright */}
          <div className="footer-border-top-liner-gradient mt-10 pt-5">
            <p className="text-base font-medium text-white text-center">
              © Copyright and all rights reserved by Memorialeye Event
              Management Private Limited
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Footer);
