/* CMS */
export const GET_CMS = 'GET_CMS';
export const GET_CMS_SUCCESS = 'GET_CMS_SUCCESS';
export const GET_CMS_FAIL = 'GET_CMS_FAIL';
export const CLEAR_CMS_DATA = 'CLEAR_CMS_DATA';

/* Popular City */
export const GET_POPULAR_CITY = 'GET_POPULAR_CITY';
export const GET_POPULAR_CITY_SUCCESS = 'GET_POPULAR_CITY_SUCCESS';
export const GET_POPULAR_CITY_FAIL = 'GET_POPULAR_CITY_FAIL';
export const CLEAR_POPULAR_CITY_DATA = 'CLEAR_POPULAR_CITY_DATA';

/* Categories */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';
export const CLEAR_CATEGORIES_DATA = 'CLEAR_CATEGORIES_DATA';

/* Location */
export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';
export const CLEAR_LOCATION_DATA = 'CLEAR_LOCATION_DATA';
