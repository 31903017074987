import React, { useState } from 'react';

const PriceDetail = ({
  passDetails,
  pricingDetails,
  textColor,
  secondaryColor,
  primaryColor,
}) => {
  const [isOpenPricing, setIsOpenPricing] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);

  const handleToggle = () => {
    setActiveIndex(!activeIndex);
  };

  return (
    <>
      {passDetails.allocation_through == 'Share' ? (
        <div className="group border border-[#E7E7E7] rounded-[10px] p-3 mt-4">
          <h3
            className={`text-[15px] text-[${textColor}] font-body font-semibold`}
            style={{ color: textColor }}
          >
            Shared Pass
          </h3>
        </div>
      ) : (
        <>
          <div className="group bg-[#F9F9F9] rounded-[10px] p-3 mt-4">
            <button
              onClick={() => setIsOpenPricing(!isOpenPricing)}
              className="w-full flex justify-between items-center cursor-pointer select-none"
            >
              <p className="text-base text-[#101010] font-semibold">Pricing</p>
              <i
                className={`fas text-[#000000] ${
                  isOpenPricing ? 'fa-chevron-up' : 'fa-chevron-down'
                }`}
              ></i>
            </button>
            {isOpenPricing && (
              <>
                <div
                  style={{ borderColor: secondaryColor }}
                  className="border-b-2 border my-3"
                ></div>
                {passDetails.is_complimentray == 'Yes' ? (
                  <div>
                    <p className="text-md font-body font-normal">
                      This pass is complimentary
                      <span
                        style={{ color: primaryColor }}
                        className="font-bold"
                      >
                        (Not for sale)
                      </span>
                    </p>
                  </div>
                ) : passDetails.for_sponsor == 'Yes' ? (
                  <div>
                    <p className="text-md font-body font-normal">
                      This pass is sponsored
                      <span
                        style={{ color: primaryColor }}
                        className="font-bold"
                      >
                        (Not for sale)
                      </span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <div className="flex mb-3">
                      <div className="flex-1">
                        <h4 className="text-[12px] text-[#101010] font-body font-medium">
                          Order Amount ({passDetails.pass_quantity} Tickets)
                        </h4>
                      </div>
                      <div className="flex-none">
                        {passDetails.pass_quantity > 0 && (
                          <p
                            style={{ color: primaryColor }}
                            className="text-[12px] font-body font-semibold"
                          >
                            ₹
                            {pricingDetails.price_per_pass *
                              passDetails.pass_quantity}
                          </p>
                        )}
                      </div>
                    </div>
                    {pricingDetails.amount > 0 && (
                      <div className="overflow-hidden border-b-2 border-[#E7E7E7] pb-2 mb-2">
                        <div className="flex">
                          <div className="flex-1">
                            <button
                              onClick={handleToggle}
                              className="text-[#101010] text-[12px] font-medium"
                            >
                              Booking Fees
                              <i
                                className={`fas ${
                                  activeIndex ? 'fa-caret-up' : 'fa-caret-down'
                                }  text-[#000000] ml-3`}
                              ></i>
                            </button>
                          </div>
                          <div className="flex-none">
                            <p
                              style={{ color: primaryColor }}
                              className="text-[12px] font-body font-semibold"
                            >
                              ₹{pricingDetails.amount}
                            </p>
                          </div>
                        </div>
                        <div
                          className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                            activeIndex ? 'max-h-screen' : 'max-h-0'
                          }`}
                        >
                          <div className="border-t-2 border-[#E7E7E7] pt-2">
                            {pricingDetails.cgst > 0 && (
                              <div className="flex items-center justify-between gap-4 mb-3">
                                <div className="flex-1">
                                  <h3 className="text-[12px] font-body font-normal">
                                    CGST @{passDetails.cgst}%
                                  </h3>
                                </div>
                                <div className="flex-none">
                                  <p className="text-[12px] font-body font-normal">
                                    ₹{pricingDetails.cgst}
                                  </p>
                                </div>
                              </div>
                            )}
                            {pricingDetails.sgst > 0 && (
                              <div className="flex items-center justify-between gap-4 mb-3">
                                <div className="flex-1">
                                  <h4 className="text-[12px] font-body font-normal">
                                    SGST @{passDetails.sgst}%
                                  </h4>
                                </div>
                                <div className="flex-none">
                                  <p className="text-[12px] font-body font-normal">
                                    ₹{pricingDetails.sgst}
                                  </p>
                                </div>
                              </div>
                            )}
                            {pricingDetails.discount > 0 && (
                              <div className="flex items-center justify-between gap-4">
                                <div className="flex-1">
                                  <h5 className="text-[12px] font-body font-medium">
                                    Discount
                                  </h5>
                                </div>
                                <div className="flex-none">
                                  <p className="text-[12px] text-[#12B347] font-body font-semibold">
                                    ₹{pricingDetails.discount}
                                  </p>
                                </div>
                              </div>
                            )}
                            {pricingDetails.convenience_charge > 0 && (
                              <div className="flex items-center justify-between gap-4">
                                <div className="flex-1">
                                  <h6 className="text-[12px] font-body font-medium">
                                    Convenience Charge
                                  </h6>
                                </div>
                                <div className="flex-none">
                                  <p className="text-[12px] text-[#12B347] font-body font-semibold">
                                    ₹{pricingDetails.convenience_charge}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex items-center justify-between gap-4 mb-2">
                      <h3 className="text-[12px]  font-body font-medium">
                        Order Total
                      </h3>
                      <p className="text-[12px] font-body font-bold">
                        ₹ {pricingDetails.net_amount}
                      </p>
                    </div>
                    {/*<div className="border-b-2 border-[#E7E7E7]"></div>*/}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
PriceDetail.propTypes = {};
export default PriceDetail;
