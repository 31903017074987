import {
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  CLEAR_EVENT_DATA,
  GET_EVENT_DETAIL_FAIL,
  GET_EVENT_DETAIL_SUCCESS,
  CLEAR_EVENT_DETAIL_DATA,
  GET_EVENT_ARTIST_FAIL,
  GET_EVENT_ARTIST_SUCCESS,
  CLEAR_EVENT_ARTIST,
  GET_EVENT_SPONSOR_FAIL,
  GET_EVENT_SPONSOR_SUCCESS,
  CLEAR_EVENT_SPONSOR,
  GET_NEAREST_EVENT_FAIL,
  GET_NEAREST_EVENT_SUCCESS,
  CLEAR_NEAREST_EVENT,
} from './actionTypes';

const INIT_STATE = {
  events: [],
  eventDetail: {},
  eventArtists: [],
  eventSponsors: [],
  eventNearestEvents: [],
  eventRating: [],
  totalEvents: 0,
  error: {},
  loading: false,
};

const events = (state = INIT_STATE, action) => {
  switch (action?.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: [
          ...(state.events || []),
          ...(action?.payload?.data?.eventList || []),
        ],
        totalEvents: action?.payload?.data?.filter_count,
      };

    case GET_EVENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_EVENT_DATA:
      return {
        ...state,
        events: [],
        totalEvents: 0,
      };

    case GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        eventDetail: action?.payload?.data?.eventView,
        eventRating: action?.payload?.data?.rating,
      };

    case GET_EVENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_EVENT_DETAIL_DATA:
      return {
        ...state,
        eventDetail: {},
      };

    case GET_EVENT_SPONSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        eventSponsors: action?.payload?.data?.sponsorList,
      };

    case GET_EVENT_SPONSOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_EVENT_SPONSOR:
      return {
        ...state,
        eventSponsors: [],
      };

    case GET_EVENT_ARTIST_SUCCESS:
      return {
        ...state,
        loading: false,
        eventArtists: action?.payload?.data?.artistList,
      };

    case GET_EVENT_ARTIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_EVENT_ARTIST:
      return {
        ...state,
        eventArtists: [],
      };

    case GET_NEAREST_EVENT_SUCCESS:
      console.log('action?.payload?.data', action?.payload?.data);
      return {
        ...state,
        loading: false,
        eventNearestEvents: action?.payload?.data?.nearestEvents,
      };

    case GET_NEAREST_EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };

    case CLEAR_NEAREST_EVENT:
      return {
        ...state,
        eventNearestEvents: {},
      };
    default:
      return state;
  }
};

export default events;
