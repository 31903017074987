import { combineReducers } from 'redux';

import AppMaster from './AppMaster/reducer';
import events from './events/reducer';
import home from './home/reducer';

const rootReducer = combineReducers({
  AppMaster,
  events,
  home,
});

export default rootReducer;
