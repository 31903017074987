import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import user from '../../assets/images/default/user-image.png';
import { addUpdateNewData, getData } from '../../store/AppMaster/actions';
import EventView from '../Event/View';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';

const Interest = ({ props, isApiCall, setIsApiCall }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [favoriteList, setFavoriteList] = useState([]);
  const [totalFavorite, setTotalFavorite] = useState(0);
  const [isUpdateFavoriteData, setIsUpdateFavoriteData] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // Renamed activeButton to activeTab
  const [isShowFavSearchModal, setShowFavSearchModal] = useState(false);
  const [isUpdateMerchantData, setIsUpdateMerchantData] = useState(false);
  const [requestFavoriteData, setRequestFavoriteData] = useState({
    start: 0,
    limit: 12,
    type: 'Artist',
  });
  const [searchType, setSearchType] = useState('');
  const [requestAllArtist, setRequestAllArtist] = useState({
    start: 0,
    limit: 12,
    type: 'Artist',
    search: '',
  });

  const [requestAllMerchant, setRequestAllMerchant] = useState({
    start: 0,
    limit: 12,
    type: 'Merchant',
    search: '',
  });
  const [allArtistList, setAllArtistList] = useState([]);
  const [allMerchantList, setAllMerchantList] = useState([]);
  const [allArtistTotal, setAllArtistTotal] = useState(0);
  const [allMerchantTotal, setAllMerchantTotal] = useState(0);
  const [totalFavoriteOrg, setTotalFavoriteOrg] = useState(0);
  const [favoriteOrgList, setFavoriteOrgList] = useState([]);
  const [isUpdateFavoriteOrgData, setIsUpdateFavoriteOrgData] = useState(false);
  const [requestFavoriteOrgData, setRequestFavoriteOrgData] = useState({
    start: 0,
    limit: 12,
    type: 'Merchant',
  });
  // Function to handle button click and change active tab
  const handleTabClick = (tabId) => {
    if (tabId === 1) {
      setTotalFavorite(0);
      setFavoriteList([]);
      getFavoriteList();
    } else {
      setTotalFavoriteOrg(0);
      setFavoriteOrgList([]);
      getFavoriteOrgList();
    }
    setActiveTab(tabId);
  };
  useEffect(() => {
    if (isApiCall === 0) {
      if (activeTab == 1) {
        getFavoriteList();
      } else {
        getFavoriteOrgList();
      }
      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate]);

  const getFavoriteOrgList = () => {
    setTotalFavoriteOrg(0);
    setFavoriteOrgList([]);
    setIsUpdateFavoriteOrgData(true);
    dispatch(
      getData(
        requestFavoriteOrgData,
        props.router.navigate,
        api.addFavoritesArtistList,
      ),
    );
  };

  const getFavoriteList = () => {
    setTotalFavorite(0);
    setFavoriteList([]);
    setIsUpdateFavoriteData(true);
    dispatch(
      getData(
        requestFavoriteData,
        props.router.navigate,
        api.addFavoritesArtistList,
      ),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.favoriteList) && isUpdateFavoriteData) {
    if (data.favoriteList.length > 0) {
      data.favoriteList.map((artist) => {
        if (artist.artist_category) {
          artist.artist_category = artist.artist_category
            .split(',')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(', ');
        }
      });
    }
    setFavoriteList((prevFavorite) => [...prevFavorite, ...data.favoriteList]);
    setTotalFavorite(data.filter_count);
    setIsUpdateFavoriteData(false);
  }
  if (
    !isEmpty(data) &&
    !isEmpty(data.favoriteList) &&
    isUpdateFavoriteOrgData
  ) {
    setFavoriteOrgList((prevFavorite) => [
      ...prevFavorite,
      ...data.favoriteList,
    ]);
    setTotalFavoriteOrg(data.filter_count);
    setIsUpdateFavoriteOrgData(false);
  }

  const loadMoreFavorite = () => {
    const newStart = requestFavoriteData.start + 12;
    requestFavoriteData.start = newStart;
    setRequestFavoriteData({
      start: newStart,
    });
    getFavoriteList();
  };

  const loadMoreFavoriteOrg = () => {
    const newStart = requestFavoriteOrgData.start + 12;
    requestFavoriteOrgData.start = newStart;
    setRequestFavoriteOrgData({
      start: newStart,
    });
  };

  const getArtistList = () => {
    setAllArtistTotal(0);
    setIsUpdateData(true);
    dispatch(getData(requestAllArtist, props.router.navigate, api.ARTIST_LIST));
  };

  if (!isEmpty(data) && !isEmpty(data.allArtists) && isUpdateData) {
    setAllArtistList((prevArtists) => [...prevArtists, ...data.allArtists]);
    setAllArtistTotal(data.filter_count);
    setIsUpdateData(false);
  }

  const loadMoreArtist = () => {
    const newStart = requestAllArtist.start + 12;
    requestAllArtist.start = newStart;
    setRequestAllArtist({
      start: newStart,
      limit: 12,
    });
    getArtistList();
  };

  const handleArtistChange = () => {
    setAllArtistList([]);
    setAllArtistTotal(0);
    setIsUpdateData(true);
    getArtistList();
  };

  const getMerchantList = () => {
    setAllMerchantTotal(0);
    setIsUpdateMerchantData(true);
    dispatch(
      getData(requestAllMerchant, props.router.navigate, api.MERCHANT_LIST),
    );
  };

  if (!isEmpty(data) && !isEmpty(data.allMerchants) && isUpdateMerchantData) {
    setAllMerchantList((prevMerchant) => [
      ...prevMerchant,
      ...data.allMerchants,
    ]);
    setAllMerchantTotal(data.filter_count);
    setIsUpdateMerchantData(false);
  }

  const loadMoreMerchant = () => {
    const newStart = requestAllMerchant.start + 12;
    requestAllMerchant.start = newStart;
    setRequestAllMerchant({
      start: newStart,
      limit: 12,
    });
    getMerchantList();
  };

  const handleMerchantChange = () => {
    setAllMerchantList([]);
    setAllMerchantTotal(0);
    setIsUpdateMerchantData(true);
    getMerchantList();
  };

  return (
    <>
      <div className="bg-[#F9F9F9] p-4 md:p-[40px] rounded-lg md:rounded-[2px]">
        <div className="flex items-center justify-between bg-white p-3 rounded-lg mb-7">
          <button
            className={`button ${
              activeTab === 1
                ? 'active-button bg-[#CB2129] rounded-[4px] !text-white '
                : ''
            } py-[10px] px-[30px] text-[#101010] font-semibold text-base md:text-xl w-1/2`}
            onClick={() => handleTabClick(1)}
          >
            Artist
          </button>
          <button
            className={`button ${
              activeTab === 2
                ? 'active-button bg-[#CB2129] rounded-[4px] !text-white '
                : ''
            } py-[10px] px-[30px] text-[#101010] font-semibold text-base md:text-xl w-1/2`}
            onClick={() => handleTabClick(2)}
          >
            Organiser
          </button>
        </div>
        {activeTab == 1 ? (
          <>
            <div className="w-[300px] xl:w-[360px] bg-white rounded-[4px] p-4 mt-[20px] mb-[30px]">
              <div className="flex items-center">
                <i class="fa-solid fa-magnifying-glass text-[#737373] mr-1"></i>
                <input
                  onClick={() => {
                    setSearchType('Artist');
                    setShowFavSearchModal(true);
                    setAllArtistList([]);
                    setAllMerchantList([]);
                    getArtistList();
                  }}
                  type="text"
                  className="text-[#101010] text-base font-medium border border-white bg-transparent rounded-lg  placeholder:text-[#737373] focus-visible:border-white focus-visible:outline-none w-full"
                  placeholder="Search artists"
                />
              </div>
            </div>
            <h2 className="text-[24px] font-semibold text-[#101010]">
              Followed Artists
            </h2>

            <div className="mt-4 grid grid-cols-4 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 gap-4">
              {favoriteList &&
                favoriteList.map((artist, index) => (
                  <div key={index} className="">
                    <div>
                      <img
                        src={artist.image ? artist.image : user}
                        alt={artist.name}
                        className="rounded-[4px] w-full"
                      />
                      <h4 className="text-[#101010] text-[12px] md:text-base font-semibold mt-2">
                        {artist.name}
                      </h4>
                      <div className="flex mt-1">
                        <div className="flex-1">
                          <p className="text-[#737373] text-[12px] md:text-sm font-medium">
                            {artist.artist_category}
                          </p>
                        </div>
                        {artist.rating > 0 && (
                          <div className="flex-none">
                            <div className="border border-[#CB2129] py-[2px] px-[4px] inline-block rounded-full text-nowrap flex-none">
                              <p className="text-black text-[11px] font-semibold">
                                <i className="fa-solid fa-star mr-1 text-[#F0BA0E] text-[10px]"></i>
                                <b>{artist.rating}</b>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex justify-center mt-5">
              {totalFavorite > favoriteList.length ? (
                <button
                  className="border border-[#CB2027] text-[#CB2027] me-2 font-body font-medium px-4 py-2 rounded-[5px]"
                  onClick={loadMoreFavorite}
                >
                  View More
                </button>
              ) : (
                ''
              )}
            </div>
          </>
        ) : activeTab == 2 ? (
          <>
            <div className="w-[300px] xl:w-[360px] bg-white rounded-[4px] p-4 mt-[20px] mb-[30px]">
              <div className="flex items-center">
                <i class="fa-solid fa-magnifying-glass text-[#737373] mr-1"></i>
                <input
                  onClick={() => {
                    setSearchType('Merchant');
                    setShowFavSearchModal(true);
                    setAllArtistList([]);
                    setAllMerchantList([]);
                    getMerchantList();
                  }}
                  type="text"
                  className="text-[#101010] text-base font-medium border border-white bg-transparent rounded-lg  placeholder:text-[#737373] focus-visible:border-white focus-visible:outline-none w-full"
                  placeholder="Search organiser"
                />
              </div>
            </div>
            <h2 className="text-[24px] font-semibold text-[#101010]">
              Followed Organiser
            </h2>
            <div className="mt-4 grid grid-cols-4 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 gap-4">
              {favoriteOrgList &&
                favoriteOrgList.map((favoriteOrg, index) => (
                  <div key={index}>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        addToFavorite(favoriteOrg.id);
                      }}
                    >
                      <img
                        src={favoriteOrg.logo ? favoriteOrg.logo : user}
                        alt={favoriteOrg.name}
                        className="rounded-[4px] w-full"
                      />
                      <h4 className="text-[#101010] text-[12px] md:text-base font-semibold mt-2">
                        {favoriteOrg.name}
                      </h4>
                      <div className="flex mt-1">
                        <div className="flex-1">
                          <p className="text-[#737373] text-[12px] md:text-sm font-medium">
                            {favoriteOrg.merchant_company_name}
                          </p>
                        </div>
                        {favoriteOrg.rating > 0 && (
                          <div className="flex-none">
                            <div className="border border-[#CB2129] py-[2px] px-[4px] inline-block rounded-full text-nowrap flex-none">
                              <p className="text-black text-[11px] font-semibold">
                                <i className="fa-solid fa-star mr-1 text-[#F0BA0E] text-[10px]"></i>
                                <b>{favoriteOrg.rating}</b>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex justify-center mt-5">
              {totalFavoriteOrg > favoriteOrgList.length ? (
                <button
                  className="border border-[#CB2027] text-[#CB2027] me-2 font-body font-medium px-4 py-2 rounded-[5px]"
                  onClick={loadMoreFavoriteOrg}
                >
                  View More
                </button>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {/* Search modal */}
      {isShowFavSearchModal ? (
        <div className="fixed z-30 overflow-y-auto top-0 md:top-24 w-full left-0">
          <div className="flex md:items-center justify-center min-h-screen md:pt-4 md:px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <div
              className="inline-block align-center bg-white md:rounded-[32px] text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full md:w-[80%] lg:w-[800px]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white p-5 md:p-[30px] h-screen md:h-full">
                <div className="flex gap-3">
                  <div className="flex-1">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder={`Search ${
                          searchType == 'Artist' ? 'Artist' : 'Organiser'
                        }`}
                        value={
                          searchType == 'Artist'
                            ? requestAllArtist.search
                            : requestAllMerchant.search
                        }
                        onChange={(e) => {
                          if (searchType == 'Artist') {
                            setRequestAllArtist({
                              ...requestAllArtist,
                              search: e.target.value,
                            });
                            requestAllArtist.search = e.target.value;
                          } else {
                            setRequestAllMerchant({
                              ...requestAllMerchant,
                              search: e.target.value,
                            });
                            requestAllMerchant.search = e.target.value;
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (searchType == 'Artist') {
                              handleArtistChange();
                            } else {
                              handleMerchantChange();
                            }
                          }
                        }}
                        className="w-full bg-white border border-[#5D5D5D] rounded-lg p-4 text-[#101010] text-base font-normal outline-none  focus:border-[#5D5D5D] placeholder:text-[#5D5D5D]"
                      />
                      <span
                        onClick={() =>
                          searchType == 'Artist'
                            ? handleArtistChange()
                            : handleMerchantChange()
                        }
                        className="cursor-pointer bg-white p-2 absolute top-1/2 right-3 -translate-y-1/2"
                      >
                        <i className="fa fa-search text-[#5D5D5D] text-xl"></i>
                      </span>
                    </div>
                  </div>
                  <div className="flex-none">
                    <button
                      type="button"
                      className="p-[10px] size-[57px] rounded-[4px] border border-[#5D5D5D] flex items-center justify-center"
                      onClick={() => {
                        setShowFavSearchModal(false);
                      }}
                    >
                      <i className="fas fa-times text-2xl text-[#5A5A5A]"></i>
                    </button>
                  </div>
                </div>
                <div className="max-h-[70vh] md:max-h-[53vh] overflow-auto pr-2 mt-4">
                  {searchType == 'Artist' ? (
                    <>
                      {!isEmpty(allArtistList) &&
                        allArtistList.map((singleArtist, index) => (
                          <>
                            <Link
                              to={'/artiest/' + singleArtist.slug}
                              onClick={() => setShowFavSearchModal(false)}
                            >
                              <div className="bg-white border border-[#E7E7E7] p-4 rounded-lg mb-3 hover:border-[#5D5D5D]">
                                <div className="flex gap-4">
                                  <div className="flex-1">
                                    <p className="text-sm md:text-base text-[#101010] font-base">
                                      {singleArtist.name}
                                    </p>
                                  </div>
                                  <div className="flex-none">
                                    <i className="fas fa-chevron-right mt-[2px]"></i>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </>
                        ))}
                      {allArtistTotal > allArtistList.length ? (
                        <div className="flex justify-center mt-5">
                          <button
                            className="border border-[#CB2027] text-[#CB2027] me-2 font-body font-medium px-4 py-2 rounded-[5px]"
                            onClick={loadMoreArtist}
                          >
                            View More
                          </button>
                        </div>
                      ) : (
                        ''
                      )}
                      {isEmpty(allArtistList) ? (
                        <div className="text-center mt-5">
                          <p className="text-[#101010] text-base font-medium">
                            No Artist Found
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      {!isEmpty(allMerchantList) &&
                        allMerchantList.map((singleMerchant, index) => (
                          <>
                            <Link
                              key={singleMerchant.id}
                              to={
                                '/organiser/' +
                                singleMerchant.merchant_company_slug
                              }
                              onClick={() => setShowFavSearchModal(false)}
                            >
                              <div className="bg-white border border-[#E7E7E7] p-4 rounded-lg mb-3 hover:border-[#5D5D5D]">
                                <div className="flex gap-4">
                                  <div className="flex-1">
                                    <p className="text-sm md:text-base text-[#101010] font-base">
                                      {singleMerchant.name}
                                    </p>
                                  </div>
                                  <div className="flex-none">
                                    <i className="fas fa-chevron-right mt-[2px]"></i>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </>
                        ))}
                      {allMerchantTotal > allMerchantList.length ? (
                        <div className="flex justify-center mt-5">
                          <button
                            className="border border-[#CB2027] text-[#CB2027] me-2 font-body font-medium px-4 py-2 rounded-[5px]"
                            onClick={loadMoreMerchant}
                          >
                            View More
                          </button>
                        </div>
                      ) : (
                        ''
                      )}
                      {isEmpty(allMerchantList) ? (
                        <div className="text-center mt-5">
                          <p className="text-[#101010] text-base font-medium">
                            No Organiser Found
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
Interest.propTypes = {
  props: PropTypes.object,
};
export default Interest;
