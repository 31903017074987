import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_CMS,
  GET_CATEGORIES,
  GET_POPULAR_CITY,
  GET_LOCATION,
} from './actionTypes';
import {
  getCmsFail,
  getCmsSuccess,
  getCategoriesSuccess,
  getCategoriesFail,
  getPopularCitySuccess,
  getPopularCityFail,
  getLocationSuccess,
  getLocationFail,
} from './actions';
import { fetchApiJSON } from '../../helpers/api';
import { setOptions, handleApiError } from '../../helpers/api_helper';
import apiConstants from '../../constants/api_constants';

const apiBaseUrl = process.env.REACT_APP_API_URL;

function* fetchCms({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.CMS_PAGE;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      response.DATA.responseFor = data.section_titles;
      yield put(getCmsSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getCmsFail(error));
  }
}

function* fetchPopularCity({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.POPULAR_CITY_LIST;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getPopularCitySuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getPopularCityFail(error));
  }
}

function* fetchCategories({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.CMS_PAGE_DROPDOWN;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getCategoriesSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getCategoriesFail(error));
  }
}

function* fetchLocation({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.CITY_DETECT;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getLocationSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getLocationFail(error));
  }
}

function* homeSaga() {
  yield takeEvery(GET_CMS, fetchCms);
  yield takeEvery(GET_POPULAR_CITY, fetchPopularCity);
  yield takeEvery(GET_CATEGORIES, fetchCategories);
  yield takeEvery(GET_LOCATION, fetchLocation);
}

export default homeSaga;
