import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const useChipFilter = (router) => {
  const dispatch = useDispatch();

  const { popularCityData, categoriesWithCity } = useSelector((state) => ({
    popularCityData: state.home.popularCityData,
    categoriesWithCity: state.home.categoriesWithCity,
  }));

  const [sidebarArray, setSidebarArray] = useState([]);
  const [activeNavMenu, setActiveNavMenu] = useState('');

  const fullUrl = window.location.href;
  const domain = window.location.hostname;

  let currentRoute = '';
  if (domain === 'www.mepass.in' || domain === 'mepass.in') {
    currentRoute = fullUrl.replace('https://www.mepass.in', '');
  } else if (domain === 'localhost') {
    currentRoute = fullUrl.replace('http://localhost:3000', '');
  } else {
    currentRoute = fullUrl.replace(
      'https://stage-frontendpublic.mepass.co.in',
      '',
    );
  }

  useEffect(() => {
    setSidebarArray([]);
    const sidebarArray = [];
    const categoryArray = [];
    let cityName = '';
    if (localStorage.getItem('publicUserSelectMePassCity')) {
      cityName = localStorage.getItem('publicUserSelectMePassCity');
    }
    let linkHome = '/home';
    if (cityName) {
      if (popularCityData.length > 0) {
        //filter popular city
        const popularCity = popularCityData.filter(
          (item) => item.name === cityName,
        );
        if (popularCity.length > 0) {
          if (popularCity[0].cmspage == 'Yes') {
            linkHome = `/city/${cityName}`;
          } else {
            linkHome = `/city/${cityName}/events`;
          }
        }
      }
    }
    if (currentRoute == linkHome) {
      setActiveNavMenu(linkHome + sidebarArray.length);
    }
    sidebarArray.push({ name: 'Top Trending', link: linkHome, submenu: [] });
    let link = '/events';
    if (cityName) {
      link = `/city/${cityName}/events`;
    }
    if (currentRoute == link) {
      setActiveNavMenu(link + sidebarArray.length);
    }
    sidebarArray.push({ name: 'Events', link: link, submenu: [] });

    if (!isEmpty(categoriesWithCity)) {
      categoriesWithCity.map((item) => {
        let link = '';
        if (cityName) {
          link = `/city/${cityName}/category/${item.slug}`;
        } else {
          link = `/category/${item.slug}`;
        }
        item.link = link;
        categoryArray.push(item);
      });
    }

    if (!isEmpty(categoryArray)) {
      categoryArray.map((item, itemIndex) => {
        let categoryName =
          item.name.charAt(0).toUpperCase() + item.name.slice(1);
        if (itemIndex < 5) {
          if (currentRoute == item.link) {
            setActiveNavMenu(item.link + sidebarArray.length);
          }
          sidebarArray.push({
            name: categoryName,
            link: item.link,
            submenu: [],
          });
        }
      });
    }
    setSidebarArray(sidebarArray);
  }, [categoriesWithCity, router.params.city_name, popularCityData]);

  return { sidebarArray, activeNavMenu, setActiveNavMenu };
};

export default useChipFilter;
