import React, { useState } from 'react';
import withRouter from '../../components/Common/withRouter';

const SeatBook = (props) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  let seatArrangementView = [
    {
      mongo_venue_id: '67adc5519d9d2767730ca423',
      date: '27-02-2025',
      start_time: '16:00',
      end_time: '17:00',
      event_id: 95,
      json: [
        {
          grid_row_id: 1,
          grid_row_name: 'A',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'A1',
              is_blocked: 'Yes',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'A2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'A3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'A4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'A5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'A6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'A7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'A8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'A9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'A10',
              is_blocked: 'Yes',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'A11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'A12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'A13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'A14',
              is_blocked: 'Yes',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'A15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'A16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'A17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'A18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'A19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'A20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'A21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'A22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'A23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'A24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'A25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'A26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'A27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'A28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 115,
          pass_name: 'Platinum',
          pass_price: '100.00',
        },
        {
          grid_row_id: 2,
          grid_row_name: 'B',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'B1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'B2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'B3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'B4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'B5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'B6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'B7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'B8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'B9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'B10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'B11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'B12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'B13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'B14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'B15',
              is_blocked: 'Yes',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'B16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'B17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'B18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'B19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'B20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'B21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'B22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'B23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'B24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'B25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'B26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'B27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'B28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 115,
          pass_name: 'Platinum',
          pass_price: '100.00',
        },
      ],
      updated_at: '2025-02-17T07:42:12.733000Z',
      created_at: '2025-02-17T07:24:29.909000Z',
      event_pass_id: 115,
      event_pass_name: 'Platinum',
      event_pass_price: '100.00',
      color: '#FE8C04',
      id: '67b2e42dbc8aedf2010f61e7',
    },
    {
      mongo_venue_id: '67adc5519d9d2767730ca423',
      date: '27-02-2025',
      start_time: '16:00',
      end_time: '17:00',
      event_id: 95,
      event_pass_id: 116,
      event_pass_name: 'Diamond',
      event_pass_price: '100.00',
      json: [
        {
          grid_row_id: 3,
          grid_row_name: 'C',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'C1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'C2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'C3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'C4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'C5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'C6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'C7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'C8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'C9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'C10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'C11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'C12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'C13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'C14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'C15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'C16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'C17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'C18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'C19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'C20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'C21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'C22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'C23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'C24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'C25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'C26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'C27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'C28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 116,
          pass_name: 'Diamond',
          pass_price: '100.00',
        },
        {
          grid_row_id: 4,
          grid_row_name: 'D',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'D1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'D2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'D3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'D4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'D5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'D6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'D7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'D8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'D9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'D10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'D11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'D12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'D13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'D14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'D15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'D16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'D17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'D18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'D19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'D20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'D21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'D22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'D23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'D24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'D25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'D26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'D27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'D28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 116,
          pass_name: 'Diamond',
          pass_price: '100.00',
        },
        {
          grid_row_id: 5,
          grid_row_name: 'E',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'E1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'E2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'E3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'E4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'E5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'E6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'E7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'E8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'E9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'E10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'E11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'E12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'E13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'E14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'E15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'E16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'E17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'E18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'E19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'E20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'E21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'E22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'E23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'E24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'E25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'E26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'E27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'E28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 116,
          pass_name: 'Diamond',
          pass_price: '100.00',
        },
        {
          grid_row_id: 6,
          grid_row_name: 'F',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'F1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'F2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'F3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'F4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'F5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'F6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'F7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'F8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'F9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'F10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'F11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'F12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'F13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'F14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'F15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'F16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'F17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'F18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'F19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'F20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'F21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'F22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'F23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'F24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'F25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'F26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'F27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'F28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 116,
          pass_name: 'Diamond',
          pass_price: '100.00',
        },
      ],
      updated_at: '2025-02-17T07:43:10.587000Z',
      created_at: '2025-02-17T07:43:10.587000Z',
      color: '#20CB6F',
      id: '67b2e88ebc8aedf2010f61ed',
    },
    {
      mongo_venue_id: '67adc5519d9d2767730ca423',
      date: '27-02-2025',
      start_time: '16:00',
      end_time: '17:00',
      event_id: 95,
      event_pass_id: 117,
      event_pass_name: 'Gold',
      event_pass_price: '600.00',
      json: [
        {
          grid_row_id: 7,
          grid_row_name: 'G',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'G1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'G2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'G3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'G4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'G5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'G6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'G7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'G8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'G9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'G10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'G11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'G12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'G13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'G14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'G15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'G16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'G17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'G18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'G19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'G20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'G21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'G22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'G23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'G24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'G25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'G26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'G27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'G28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 117,
          pass_name: 'Gold',
          pass_price: '600.00',
        },
        {
          grid_row_id: 8,
          grid_row_name: 'H',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'H1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'H2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'H3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'H4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'H5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'H6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'H7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'H8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'H9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'H10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'H11',
              is_blocked: 'Yes',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'H12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'H13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'H14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'H15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'H16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'H17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'H18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'H19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'H20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'H21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'H22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'H23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'H24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'H25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'H26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'H27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'H28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 117,
          pass_name: 'Gold',
          pass_price: '600.00',
        },
      ],
      updated_at: '2025-02-17T07:45:06.995000Z',
      created_at: '2025-02-17T07:45:06.995000Z',
      color: '#DE15E2',
      id: '67b2e902bc8aedf2010f61f3',
    },
    {
      mongo_venue_id: '67adc5519d9d2767730ca423',
      date: '27-02-2025',
      start_time: '16:00',
      end_time: '17:00',
      event_id: 95,
      event_pass_id: 118,
      event_pass_name: 'Silver',
      event_pass_price: '500.00',
      json: [
        {
          grid_row_id: 9,
          grid_row_name: 'I',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'I1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'I2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'I3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'I4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'I5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'I6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'I7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'I8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'I9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'I10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'I11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'I12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'I13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'I14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'I15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'I16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'I17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'I18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'I19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'I20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'I21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'I22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'I23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'I24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'I25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'I26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 118,
          pass_name: 'Silver',
          pass_price: '500.00',
        },
        {
          grid_row_id: 10,
          grid_row_name: 'J',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'J1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'J2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'J3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'J4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'J5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'J6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'J7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'J8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'J9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'J10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'J11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'J12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'J13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'J14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'J15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'J16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'J17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'J18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'J19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'J20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'J21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'J22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'J23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'J24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'J25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'J26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 118,
          pass_name: 'Silver',
          pass_price: '500.00',
        },
        {
          grid_row_id: 11,
          grid_row_name: 'K',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'K1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'K2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'K3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'K4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'K5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'K6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'K7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'K8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'K9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'K10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'K11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'K12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'K13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'K14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'K15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'K16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'K17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'K18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'K19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'K20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'K21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'K22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'K23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'K24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'K25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'K26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 118,
          pass_name: 'Silver',
          pass_price: '500.00',
        },
        {
          grid_row_id: 12,
          grid_row_name: 'L',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'L1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'L2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'L3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'L4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'L5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'L6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'L7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'L8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'L9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'L10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'L11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'L12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'L13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'L14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'L15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'L16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'L17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'L18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'L19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'L20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'L21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'L22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'L23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'L24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'L25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'L26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 118,
          pass_name: 'Silver',
          pass_price: '500.00',
        },
        {
          grid_row_id: 13,
          grid_row_name: 'M',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'M1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'M2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'M3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'M4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'M5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'M6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'M7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'M8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'M9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'M10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'M11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'M12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'M13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'M14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'M15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'M16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'M17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'M18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'M19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'M20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'M21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'M22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'M23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'M24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'M25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'M26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 118,
          pass_name: 'Silver',
          pass_price: '500.00',
        },
        {
          grid_row_id: 14,
          grid_row_name: 'N',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'N1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'N2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'N3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'N4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'N5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'N6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'N7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'N8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'N9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'N10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'N11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'N12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'N13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'N14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'N15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'N16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'N17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'N18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'N19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'N20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'N21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'N22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'N23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'N24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'N25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'N26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 118,
          pass_name: 'Silver',
          pass_price: '500.00',
        },
      ],
      updated_at: '2025-02-17T07:45:30.467000Z',
      created_at: '2025-02-17T07:45:30.467000Z',
      color: '#3F67F7',
      id: '67b2e91abc8aedf2010f61f9',
    },
    {
      mongo_venue_id: '67adc5519d9d2767730ca423',
      date: '27-02-2025',
      start_time: '16:00',
      end_time: '17:00',
      event_id: 95,
      event_pass_id: 119,
      event_pass_name: 'Bronze',
      event_pass_price: '400.00',
      json: [
        {
          grid_row_id: 15,
          grid_row_name: 'O',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'O1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'O2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'O3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'O4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'O5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'O6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'O7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'O8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'O9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'O10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'O11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'O12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'O13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'O14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'O15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'O16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'O17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'O18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'O19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'O20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'O21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'O22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'O23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'O24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'O25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'O26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'O27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'O28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'O29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'O30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'O31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'O32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'O33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'O34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 119,
          pass_name: 'Bronze',
          pass_price: '400.00',
        },
        {
          grid_row_id: 16,
          grid_row_name: 'P',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'P1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'P2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'P3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'P4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'P5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'P6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'P7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'P8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'P9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'P10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'P11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'P12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'P13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'P14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'P15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'P16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'P17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'P18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'P19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'P20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'P21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'P22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'P23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'P24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'P25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'P26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'P27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'P28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'P29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'P30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'P31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'P32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'P33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'P34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 119,
          pass_name: 'Bronze',
          pass_price: '400.00',
        },
        {
          grid_row_id: 17,
          grid_row_name: 'Q',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'Q1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'Q2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'Q3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'Q4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'Q5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'Q6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'Q7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'Q8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'Q9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'Q10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'Q11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'Q12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'Q13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'Q14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'Q15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'Q16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'Q17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'Q18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'Q19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'Q20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'Q21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'Q22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'Q23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'Q24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'Q25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'Q26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'Q27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'Q28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'Q29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'Q30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'Q31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'Q32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'Q33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'Q34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 119,
          pass_name: 'Bronze',
          pass_price: '400.00',
        },
        {
          grid_row_id: 18,
          grid_row_name: 'R',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'R1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'R2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'R3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'R4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'R5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'R6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'R7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'R8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'R9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'R10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'R11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'R12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'R13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'R14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'R15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'R16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'R17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'R18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'R19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'R20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'R21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'R22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'R23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'R24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'R25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'R26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'R27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'R28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'R29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'R30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'R31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'R32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'R33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'R34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 119,
          pass_name: 'Bronze',
          pass_price: '400.00',
        },
      ],
      updated_at: '2025-02-17T07:45:56.219000Z',
      created_at: '2025-02-17T07:45:56.219000Z',
      color: '#8F07F4',
      id: '67b2e934bc8aedf2010f61ff',
    },
    {
      mongo_venue_id: '67adc5519d9d2767730ca423',
      date: '27-02-2025',
      start_time: '16:00',
      end_time: '17:00',
      event_id: 95,
      event_pass_id: 120,
      event_pass_name: 'Copper',
      event_pass_price: '300.00',
      json: [
        {
          grid_row_id: 19,
          grid_row_name: 'S',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'S1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'S2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'S3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'S4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'S5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'S6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'S7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'S8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'S9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'S10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'S11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'S12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'S13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'S14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'S15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'S16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'S17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'S18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'S19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'S20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'S21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'S22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'S23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'S24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'S25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'S26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'S27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'S28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'S29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'S30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'S31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'S32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'S33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'S34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 120,
          pass_name: 'Copper',
          pass_price: '300.00',
        },
        {
          grid_row_id: 20,
          grid_row_name: 'T',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'T1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'T2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'T3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'T4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'T5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'T6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'T7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'T8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'T9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'T10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'T11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'T12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'T13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'T14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'T15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'T16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'T17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'T18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'T19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'T20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'T21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'T22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'T23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'T24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'T25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'T26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'T27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'T28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'T29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'T30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'T31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'T32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'T33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'T34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 120,
          pass_name: 'Copper',
          pass_price: '300.00',
        },
        {
          grid_row_id: 21,
          grid_row_name: 'U',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'U1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'U2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'U3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'U4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'U5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'U6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'U7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'U8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'U9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'U10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'U11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'U12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'U13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'U14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'U15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'U16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'U17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'U18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'U19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'U20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'U21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'U22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'U23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'U24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'U25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'U26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'U27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'U28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'U29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'U30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'U31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'U32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'U33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'U34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 120,
          pass_name: 'Copper',
          pass_price: '300.00',
        },
        {
          grid_row_id: 22,
          grid_row_name: 'V',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'V1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'V2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'V3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'V4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'V5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'V6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'V7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'V8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'V9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'V10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'V11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'V12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'V13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'V14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'V15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'V16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'V17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'V18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'V19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'V20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'V21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'V22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'V23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'V24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'V25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'V26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'V27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'V28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'V29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'V30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'V31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'V32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'V33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'V34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 120,
          pass_name: 'Copper',
          pass_price: '300.00',
        },
        {
          grid_row_id: 23,
          grid_row_name: 'W',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'W1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'W2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'W3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'W4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'W5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'W6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'W7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'W8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'W9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'W10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'W11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'W12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'W13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'W14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'W15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'W16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'W17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'W18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'W19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'W20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'W21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'W22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'W23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'W24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'W25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'W26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'W27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'W28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'W29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'W30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'W31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'W32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'W33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'W34',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 120,
          pass_name: 'Copper',
          pass_price: '300.00',
        },
      ],
      updated_at: '2025-02-17T07:46:14.686000Z',
      created_at: '2025-02-17T07:46:14.686000Z',
      id: '67b2e946bc8aedf2010f6205',
      color: '#4DB70C',
    },
    {
      mongo_venue_id: '67adc5519d9d2767730ca423',
      date: '27-02-2025',
      start_time: '16:00',
      end_time: '17:00',
      event_id: 95,
      event_pass_id: 121,
      event_pass_name: 'Nickel',
      event_pass_price: '200.00',
      json: [
        {
          grid_row_id: 24,
          grid_row_name: 'X',
          seat_obj: [
            {
              grid_number: 1,
              seat_numer: 1,
              is_space: 'No',
              seat_label: 'X1',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 2,
              seat_numer: 2,
              is_space: 'No',
              seat_label: 'X2',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 3,
              seat_numer: 3,
              is_space: 'No',
              seat_label: 'X3',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 4,
              seat_numer: 4,
              is_space: 'No',
              seat_label: 'X4',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 5,
              seat_numer: 5,
              is_space: 'No',
              seat_label: 'X5',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 6,
              seat_numer: 6,
              is_space: 'No',
              seat_label: 'X6',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 7,
              seat_numer: 7,
              is_space: 'No',
              seat_label: 'X7',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 8,
              seat_numer: 8,
              is_space: 'No',
              seat_label: 'X8',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 9,
              seat_numer: 9,
              is_space: 'No',
              seat_label: 'X9',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 10,
              seat_numer: 10,
              is_space: 'No',
              seat_label: 'X10',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 11,
              seat_numer: 11,
              is_space: 'No',
              seat_label: 'X11',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 12,
              seat_numer: 12,
              is_space: 'No',
              seat_label: 'X12',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 13,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 14,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 15,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 16,
              seat_numer: 13,
              is_space: 'No',
              seat_label: 'X13',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 17,
              seat_numer: 14,
              is_space: 'No',
              seat_label: 'X14',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 18,
              seat_numer: 15,
              is_space: 'No',
              seat_label: 'X15',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 19,
              seat_numer: 16,
              is_space: 'No',
              seat_label: 'X16',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 20,
              seat_numer: 17,
              is_space: 'No',
              seat_label: 'X17',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 21,
              seat_numer: 18,
              is_space: 'No',
              seat_label: 'X18',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 22,
              seat_numer: 19,
              is_space: 'No',
              seat_label: 'X19',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 23,
              seat_numer: 20,
              is_space: 'No',
              seat_label: 'X20',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 24,
              seat_numer: 21,
              is_space: 'No',
              seat_label: 'X21',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 25,
              seat_numer: 22,
              is_space: 'No',
              seat_label: 'X22',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 26,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 27,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 28,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 29,
              seat_numer: '-',
              is_space: 'Yes',
              seat_label: '-',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 30,
              seat_numer: 23,
              is_space: 'No',
              seat_label: 'X23',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 31,
              seat_numer: 24,
              is_space: 'No',
              seat_label: 'X24',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 32,
              seat_numer: 25,
              is_space: 'No',
              seat_label: 'X25',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 33,
              seat_numer: 26,
              is_space: 'No',
              seat_label: 'X26',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 34,
              seat_numer: 27,
              is_space: 'No',
              seat_label: 'X27',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 35,
              seat_numer: 28,
              is_space: 'No',
              seat_label: 'X28',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 36,
              seat_numer: 29,
              is_space: 'No',
              seat_label: 'X29',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 37,
              seat_numer: 30,
              is_space: 'No',
              seat_label: 'X30',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 38,
              seat_numer: 31,
              is_space: 'No',
              seat_label: 'X31',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 39,
              seat_numer: 32,
              is_space: 'No',
              seat_label: 'X32',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 40,
              seat_numer: 33,
              is_space: 'No',
              seat_label: 'X33',
              is_blocked: 'No',
              is_booked: 'No',
            },
            {
              grid_number: 41,
              seat_numer: 34,
              is_space: 'No',
              seat_label: 'X34',
              is_blocked: 'No',
              is_booked: 'No',
            },
          ],
          pass_id: 121,
          pass_name: 'Nickel',
          pass_price: '200.00',
        },
      ],
      updated_at: '2025-02-17T07:46:36.533000Z',
      created_at: '2025-02-17T07:46:36.533000Z',
      color: '#0D8FD7',
      id: '67b2e95cbc8aedf2010f620b',
    },
  ];

  const setSeatArrangementFinal = (seatArrangementView) => {
    seatArrangementView.map((seat) => {
      let firstNonSpaceIndex = '';
      let lastNonSpaceIndex = '';
      seat.json.map((group) => {
        //Find the index of the first seat that is not a space
        firstNonSpaceIndex = group.seat_obj.findIndex(
          (seat) => seat.is_space === 'No',
        );
        //Find the index of the last seat that is not a space
        lastNonSpaceIndex = group.seat_obj.reduce(
          (acc, seat, index) => (seat.is_space === 'No' ? index : acc),
          0,
        );
      });
      seat.json.map((group) => {
        //group.seat_obj in add firstNonSpaceIndex object before psuh new custome object
        //group.seat_obj in add lastNonSpaceIndex object after push new custome object
        group.seat_obj.splice(firstNonSpaceIndex, 0, {
          grid_number: 0,
          seat_numer: '-',
          is_space: 'No',
          seat_label: group.grid_row_name,
          is_blocked: 'No',
          is_booked: 'No',
          is_seat_label: 'Yes',
        });
        if (lastNonSpaceIndex !== -1) {
          group.seat_obj.splice(lastNonSpaceIndex + 2, 0, {
            grid_number: `last_${group.grid_row_id}`,
            seat_number: '-',
            is_space: 'No',
            seat_label: group.grid_row_name, // Grid Row Name as Label
            is_blocked: 'No',
            is_booked: 'No',
            is_seat_label: 'Yes',
          });
        }
      });
    });
    return seatArrangementView;
  };

  let seatArrangementFinal = setSeatArrangementFinal(seatArrangementView);
  console.log('seatArrangementFinal', seatArrangementFinal);
  const handleSeatSelection = (seat) => {
    //Check if the seat is already selected seat.seat_label is exist in selectedSeats array
    let selectedArray = [];
    if (selectedSeats) {
      selectedArray = [...selectedSeats];
    }
    if (selectedArray.includes(seat.seat_label)) {
      //If the seat is already selected then remove the seat from the selectedSeats array
      selectedArray = selectedArray.filter(
        (selectedSeat) => selectedSeat !== seat.seat_label,
      );
    } else {
      //If the seat is not selected then add the seat to the selectedSeats array
      selectedArray.push(seat.seat_label);
    }
    setSelectedSeats(selectedArray);
  };

  return (
    <>
      <div className="p-4">
        <div className="flex justify-center">
          <div className="bg-white w-[310px] h-[80px] flex justify-center items-center rounded-[4px] px-3 py-3 border border-[#101010]">
            <h3 className="text-lg md:text-3xl text-[#101010] font-body font-semibold uppercase">
              STAGE
            </h3>
          </div>
        </div>
        <div className="mt-14">
          <div className="overflow-x-auto">
            {seatArrangementView.map((groups) => {
              // Find max number of seats in any row to set colspan dynamically
              const maxSeats = Math.max(
                ...groups.json.map((group) => group.seat_obj.length),
              );

              return (
                <div key={groups.mongo_venue_id} className="mb-6">
                  <table
                    className="w-full border border-[#E2E2E2] rounded-lg p-5"
                    style={{ borderCollapse: 'inherit' }}
                  >
                    <thead>
                      <tr>
                        <th colSpan={maxSeats + 1} className="text-center pb-5">
                          {/* Display Pass Name */}
                          <h2 className="text-base font-semibold text-[#5D5D5D]">
                            {groups.event_pass_name}{' '}
                            <span className="text-[#101010]">
                              (Rs {groups.event_pass_price})
                            </span>
                          </h2>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groups.json.map((group) => (
                        <tr key={group.grid_row_id}>
                          {group.seat_obj.map((seat) => (
                            <td key={seat.grid_number} className="p-[4px]">
                              {seat.is_space === 'No' ? (
                                <div
                                  onClick={() => handleSeatSelection(seat)}
                                  className="w-[35px] h-[35px] flex items-center justify-center rounded-md text-sm"
                                  style={{
                                    cursor: 'pointer',
                                    border: '1px solid',
                                    backgroundColor:
                                      seat?.is_seat_label === 'Yes'
                                        ? '#FFFFFF'
                                        : seat.is_blocked === 'Yes'
                                        ? '#E2E2E2'
                                        : seat.is_booked === 'Yes'
                                        ? '#F2F2F2'
                                        : selectedSeats?.includes(
                                            seat.seat_label,
                                          )
                                        ? groups.color
                                        : 'white',
                                    borderColor:
                                      seat?.is_seat_label === 'Yes'
                                        ? '#FFFFFF'
                                        : seat.is_blocked === 'Yes'
                                        ? '#E2E2E2'
                                        : seat.is_booked === 'Yes'
                                        ? '#F2F2F2'
                                        : selectedSeats?.includes(
                                            seat.seat_label,
                                          )
                                        ? groups.color
                                        : groups.color,
                                    color:
                                      seat?.is_seat_label === 'Yes'
                                        ? '#5D5D5D'
                                        : seat.is_blocked === 'Yes' ||
                                          seat.is_booked === 'Yes'
                                        ? '#A0A0A0'
                                        : selectedSeats?.includes(
                                            seat.seat_label,
                                          )
                                        ? 'white'
                                        : groups.color,
                                  }}
                                >
                                  {seat.seat_label}
                                </div>
                              ) : (
                                <div className="w-[35px] h-[35px]"></div>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SeatBook);
