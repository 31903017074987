import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '../../constants/api_constants';
import messageConstant from '../../constants/message';
import labelConstants from '../../constants/label';
import regex from '../../constants/regex';
import {
  addUpdateNewDataMultipleForm,
  getData,
} from '../../store/AppMaster/actions';
import DatePicker from '../Common/DatePicker';
import RadioButton from '../Common/RadioButton';
import Text from '../Common/Text';
import Textarea from '../Common/Textarea';
import {
  isImageJpgOrPng,
  isImageSizeValid,
  isPdforImageJpgOrPng,
} from '../../common/commonFunction';
import { Link } from 'react-router-dom';
import Dropdown from '../Common/Dropdown';

const UserProfile = ({ props, isApiCall, setIsApiCall }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [isUpdateFormData, setIsUpdateFormData] = useState(true);
  useEffect(() => {
    if (isApiCall === 0) {
      setIsUpdateData(true);
      const requestData = {};
      dispatch(getData(requestData, props.router.navigate, api.USER_VIEW));

      setIsApiCall(1);
    }
  }, [isApiCall, dispatch, props.router.navigate]);

  if (!isEmpty(data) && !isEmpty(data.profileView) && isUpdateData) {
    setUserDetails(data.profileView);
    setIsUpdateData(false);
  }

  const [profileImagePreview, setProfileImagePreview] = useState('');
  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      validation.setFieldValue('profile_image', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      profile_image: '',
      name: userDetails?.name || '',
      mobile: userDetails?.mobile || '',
      email: userDetails?.email || '',
      address: userDetails?.address || '',
      dob: userDetails?.formatted_dob || '',
      merital: userDetails?.merital || '',
      pincode: userDetails?.pincode || '',
      id_proof: '',
      aadhar_back: '',
      notification: userDetails?.notification || 'No',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(messageConstant.FULL_NAME_REQUIRED)
        .min(regex.NAME_MIN, messageConstant.FULL_NAME_MIN_LENGTH)
        .max(regex.NAME_MAX, messageConstant.FULL_NAME_MAX_LENGTH),
      mobile: Yup.string()
        .required(messageConstant.MOBILE_REQUIRED)
        .matches(/^[0-9]+$/, messageConstant.MOBILE_INVALID)
        .min(10, messageConstant.MOBILE_MIN_LENGTH)
        .max(10, messageConstant.MOBILE_MAX_LENGTH),
      email: Yup.string()
        .required(messageConstant.EMAIL_REQUIRED)
        .email(messageConstant.EMAIL_INVALID),
      id_proof: Yup.mixed().test(
        'fileFormat',
        messageConstant.PDF_IMAGE_TYPE,
        (value) => {
          console.log(value);
          if (!value) return true; // If no image provided, pass validation
          return isPdforImageJpgOrPng(value);
        },
      ),
      aadhar_back: Yup.mixed().test(
        'fileFormat',
        messageConstant.PDF_IMAGE_TYPE,
        (value) => {
          console.log(value);
          if (!value) return true; // If no image provided, pass validation
          return isPdforImageJpgOrPng(value);
        },
      ),
      profile_image: Yup.mixed()
        .test('fileFormat', messageConstant.IMAGE_TYPE, (value) => {
          if (!value) return true; // If no image provided, pass validation
          return isImageJpgOrPng(value);
        })
        .test('fileSize', 'Image dimensions must be 300x300', async (value) => {
          if (!value) return true; // If no image provided, pass validation
          if (isImageJpgOrPng(value)) {
            return isImageSizeValid(value, 300, 300);
          } else {
            return true;
          }
        }),
    }),
    onSubmit: (values) => {
      const requestData = {
        name: values.name,
        mobile: values.mobile,
        email: values.email,
        address: values.address,
        merital: values.merital,
        dob: values.dob,
        profile_image: values.profile_image,
        aadhar_front: values.id_proof,
        aadhar_back: values.aadhar_back,
        notification: values.notification,
      };
      setIsUpdateFormData(true);
      dispatch(
        addUpdateNewDataMultipleForm(
          requestData,
          props.router.navigate,
          api.updateUserProfile,
        ),
      );
    },
  });
  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.profileUpdate) && isUpdateFormData) {
      let publicMePassUserDetails = JSON.parse(
        localStorage.getItem('publicMePassUserDetails'),
      );

      if (data.profileUpdate.id == publicMePassUserDetails.id) {
        publicMePassUserDetails.profile_image_path =
          data.profileUpdate.profile_image_path;
        publicMePassUserDetails.name = data.profileUpdate.name;
        publicMePassUserDetails.mobile = data.profileUpdate.mobile;
        publicMePassUserDetails.email = data.profileUpdate.email;
        localStorage.setItem(
          'publicMePassUserDetails',
          JSON.stringify(publicMePassUserDetails),
        );
      }
      // Dispatch getData again to refresh the user data
      dispatch(getData({}, props.router.navigate, api.USER_VIEW));
      setUserDetails(data.profileUpdate); // Directly update the user details with the new data
      setIsUpdateFormData(false);
    }
  }, [data, isUpdateFormData, dispatch, props.router.navigate]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
      }}
    >
      <div className="bg-[#F9F9F9] p-4 md:p-[40px] rounded-lg md:rounded-[2px]">
        <h2 className="text-[#101010] text-base md:text-2xl font-semibold text-center md:text-left">
          Personal Details
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-0 lg:gap-8 items-center mt-4 mb-4">
          <div>
            <div className="relative m-auto mb-5">
              <input
                type="file"
                name="profile_image"
                id="profile_image"
                onChange={handleProfileImageChange}
                className="hidden"
              />
              <label
                htmlFor="profile_image"
                className="cursor-pointer relative inline-block"
              >
                <img
                  src={profileImagePreview || userDetails?.profile_image_path}
                  alt="user"
                  className="rounded-full  border border-secondary"
                  style={{ height: '150px', width: '150px' }} // Set fixed height and width
                />
                <div className="bg-primary text-white rounded-full w-10 h-10 flex items-center justify-center absolute -right-[3px] xl:-right-[3px] lg:-right-[12px] bottom-4">
                  <i className="fas fa-pen"></i>
                </div>
              </label>
            </div>
            {validation.touched['profile_image'] &&
            validation.errors['profile_image'] ? (
              <div className="text-red-500 mt-2">
                {validation.errors['profile_image']}{' '}
              </div>
            ) : null}
          </div>

          <div className="col-span-2 xl:col-span-3">
            <div className="grid grid-cols-2 gap-3 md:gap-5">
              <Text
                validation={validation}
                name="name"
                label={labelConstants.FULL_NAME}
                isRequire={true}
                regexCompare={regex.NAME}
                className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
              />
              {/* <Text
                validation={validation}
                name="name"
                label={labelConstants.LAST_NAME}
                isRequire={true}
                regexCompare={regex.NAME}
                className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
              /> */}
              <Text
                validation={validation}
                name="mobile"
                label={labelConstants.MOBILE_NUMBER}
                isRequire={true}
                regexCompare={regex.MOBILE}
                maxLength={10}
                readOnly={userDetails.mobile ? true : false}
                className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
              />
              <Text
                className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
                validation={validation}
                name="email"
                label="Email"
                isRequire={true}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 md:mt-10 mb-6">
          <h2 className="text-[#101010] text-base md:text-2xl font-semibold text-center md:text-left">
            Other Details
          </h2>
        </div>
        <div className="grid grid-cols-1">
          <div className="relative">
            <DatePicker
              validation={validation}
              name="dob"
              label="Date of Birth"
              isRequire={false}
              selectedDate={validation.values['dob']}
              className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px] md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
              placeholderText="Date of Birth"
            />
            {validation.errors['dob'] ? (
              <div className="text-red-500">{validation.errors['dob']}</div>
            ) : null}
            <div
              className="absolute right-4 bottom-[7px] md:bottom-[16px] cursor-pointer"
              onClick={() =>
                document.querySelector("input[name='dob']").focus()
              } // Focus the input when the icon is clicked
            >
              <i className="fas fa-calendar text-[#5D5D5D] text-base md:text-lg"></i>
            </div>
          </div>
          <div className="col-span-2  mt-4">
            <Textarea
              className="bg-white border border-[#E7E7E7] text-[#CB2129] text-[12px]md:text-base font-semibold font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
              validation={validation}
              name="address"
              label={labelConstants.ADDRESS}
              isRequire={false}
              row={3}
              isDisplayPlaceHolder={true}
              isLabelDisplay={true}
            />
            {validation.errors['address'] ? (
              <div className="text-red-500">{validation.errors['address']}</div>
            ) : null}
          </div>
        </div>
        <div className="flex items-center justify-between mt-7">
          <h4 className="mb-[10px] block text-base font-medium text-[#101010]">
            Status
          </h4>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="inline-flex items-center">
                <label
                  className="relative flex cursor-pointer items-center rounded-full"
                  htmlFor="single"
                >
                  <div className="inline-flex me-3">
                    <RadioButton
                      validation={validation}
                      label="Single"
                      name="merital"
                      value="single"
                      id="single"
                    />
                  </div>
                  <div className="inline-flex">
                    <RadioButton
                      validation={validation}
                      label="Married"
                      name="merital"
                      value="married"
                      id="married"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 md:gap-5 mt-6">
          <div>
            <div className="relative mb-1">
              <label className="mb-[10px] block text-base font-medium text-[#101010]">
                Front Side
              </label>
              <input
                type="file"
                id="id_proof"
                name="id_proof"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    validation.setFieldValue('id_proof', file);
                  }
                }}
                className="hidden"
              />
              <label
                htmlFor="id_proof"
                className="bg-white border border-[#E7E7E7] text-[#9C9C9C] text-[12px] md:text-base font-normal font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
              >
                {validation.values['id_proof']
                  ? validation.values['id_proof'].name
                  : 'Upload document'}
              </label>
              <div className="absolute right-4 bottom-[6px] md:bottom-[15px] pointer-events-none">
                <i class="fa-solid fa-upload text-[#5D5D5D] text-base md:text-lg"></i>
              </div>
            </div>
            {validation.errors['id_proof'] ? (
              <div className="text-red-500 mb-2">
                {validation.errors['id_proof']}{' '}
              </div>
            ) : null}
            {userDetails?.aadhar_front_path && (
              <Link
                to={userDetails?.aadhar_front_path}
                target="_blank"
                className="text-primary mb-2"
              >
                View Front Side
              </Link>
            )}
          </div>
          <div>
            <div className="relative  mb-1">
              <label className="mb-[10px] block text-base font-medium text-[#101010]">
                Back Side
              </label>
              <input
                type="file"
                id="aadhar_back"
                name="aadhar_back"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    validation.setFieldValue('aadhar_back', file);
                  }
                }}
                className="hidden"
              />
              <label
                htmlFor="aadhar_back"
                className="bg-white border border-[#E7E7E7] text-[#9C9C9C] text-[12px] md:text-base font-normal font-body rounded-[4px] placeholder:text-[#9C9C9C] placeholder:font-normal focus:ring-blue-500 focus:border-blue-500 block w-full p-2 md:p-4"
              >
                {validation.values['aadhar_back']
                  ? validation.values['aadhar_back'].name
                  : 'Upload document'}
              </label>
              <div className="absolute right-4 bottom-[6px] md:bottom-[15px] pointer-events-none">
                <i class="fa-solid fa-upload text-[#5D5D5D] text-base md:text-lg"></i>
              </div>
            </div>
            {validation.errors['aadhar_back'] ? (
              <div className="text-red-500 mb-2">
                {validation.errors['aadhar_back']}{' '}
              </div>
            ) : null}
            {userDetails?.aadhar_back_path && (
              <Link
                to={userDetails?.aadhar_back_path}
                target="_blank"
                className="text-primary mb-2"
              >
                View Back Side
              </Link>
            )}
          </div>
        </div>
        <div className="mt-8">
          <div className="flex justify-center md:justify-end gap-4">
            <button
              type="submit"
              className="text-white btn-liner-gradient text-base font-semibold rounded-lg py-2 px-4 inline-block"
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
UserProfile.propTypes = {
  props: PropTypes.object,
};
export default UserProfile;
