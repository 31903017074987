import {
  GET_EVENTS,
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  GET_EVENT_DETAIL,
  GET_EVENT_DETAIL_FAIL,
  GET_EVENT_DETAIL_SUCCESS,
  CLEAR_EVENT_DATA,
  CLEAR_EVENT_DETAIL_DATA,
  GET_EVENT_SPONSOR,
  GET_EVENT_SPONSOR_SUCCESS,
  GET_EVENT_SPONSOR_FAIL,
  CLEAR_EVENT_SPONSOR,
  GET_EVENT_ARTIST,
  GET_EVENT_ARTIST_SUCCESS,
  GET_EVENT_ARTIST_FAIL,
  CLEAR_EVENT_ARTIST,
  GET_NEAREST_EVENT,
  GET_NEAREST_EVENT_SUCCESS,
  GET_NEAREST_EVENT_FAIL,
  CLEAR_NEAREST_EVENT,
} from './actionTypes';

export const getEvents = (data, history) => ({
  type: GET_EVENTS,
  payload: { data, history },
});

export const getEventsSuccess = (data) => ({
  type: GET_EVENTS_SUCCESS,
  payload: { data },
});

export const getEventsFail = (error) => ({
  type: GET_EVENTS_FAIL,
  payload: error,
});

export const getEventDetail = (data, history) => ({
  type: GET_EVENT_DETAIL,
  payload: { data, history },
});

export const getEventDetailSuccess = (data) => ({
  type: GET_EVENT_DETAIL_SUCCESS,
  payload: { data },
});

export const getEventDetailFail = (error) => ({
  type: GET_EVENT_DETAIL_FAIL,
  payload: error,
});

export const clearEventData = () => ({
  type: CLEAR_EVENT_DATA,
  payload: '',
});

export const clearEventDetailData = () => ({
  type: CLEAR_EVENT_DETAIL_DATA,
  payload: '',
});

export const getEventSponsors = (data, history) => ({
  type: GET_EVENT_SPONSOR,
  payload: { data, history },
});

export const getEventSponsorsSuccess = (data) => ({
  type: GET_EVENT_SPONSOR_SUCCESS,
  payload: { data },
});

export const getEventSponsorsFail = (error) => ({
  type: GET_EVENT_SPONSOR_FAIL,
  payload: error,
});

export const clearEventSponsorsData = () => ({
  type: CLEAR_EVENT_SPONSOR,
  payload: '',
});

export const getEventArtists = (data, history) => ({
  type: GET_EVENT_ARTIST,
  payload: { data, history },
});

export const getEventArtistsSuccess = (data) => ({
  type: GET_EVENT_ARTIST_SUCCESS,
  payload: { data },
});

export const getEventArtistsFail = (error) => ({
  type: GET_EVENT_ARTIST_FAIL,
  payload: error,
});

export const clearEventArtistsData = () => ({
  type: CLEAR_EVENT_ARTIST,
  payload: '',
});

export const getNearestEvents = (data, history) => ({
  type: GET_NEAREST_EVENT,
  payload: { data, history },
});

export const getNearestEventsSuccess = (data) => ({
  type: GET_NEAREST_EVENT_SUCCESS,
  payload: { data },
});

export const getNearestEventsFail = (error) => ({
  type: GET_NEAREST_EVENT_FAIL,
  payload: error,
});

export const clearNearestEventsData = () => ({
  type: CLEAR_NEAREST_EVENT,
  payload: '',
});
