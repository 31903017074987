import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import {
  authProtectedRoutes,
  publicRoutes,
  publicRoutesWithoutHeader,
  publicRoutesWithoutHeaderFooter,
} from './routes';
import { isEmpty } from 'lodash';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/App.css';
import { redirectToNewUrl } from './common/commonFunction';
import Layout from './components/Layout/';
import LayoutWithoutHeader from './components/LayoutWithoutHeader/';
import LayoutWithoutHeaderFooter from './components/LayoutWithoutHeaderFooter/';
import Authmiddleware from './routes/route';

const domain = window.location.hostname;

const App = () => {
  // Google Tag Manager for specific domains
  // In staging, the GTM initialization will always run due to the '1' or environment check.
  // In production, GTM will only initialize for specific domains (www.mepass.in or mepass.in).

  if (domain === 'www.mepass.in' || domain === 'mepass.in') {
    const tagManagerArgs = {
      gtmId: 'GTM-WWKL7MMC',
      dataLayerName: 'mepassDataLayer',
    };
    TagManager.initialize(tagManagerArgs);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        {redirectToNewUrl()}
        {publicRoutesWithoutHeader.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <LayoutWithoutHeader>{route.component}</LayoutWithoutHeader>
            }
            key={idx}
            exact={true}
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<Layout>{route.component}</Layout>}
            key={idx}
            exact={true}
          />
        ))}
        {publicRoutesWithoutHeaderFooter.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <LayoutWithoutHeaderFooter>
                {route.component}
              </LayoutWithoutHeaderFooter>
            }
            key={idx}
            exact={true}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}

        {/* Handle the 301 redirects */}
        <Route
          path="/index.html"
          element={<Navigate replace to="/" state="301" />}
        />
        <Route path="/home" element={<Navigate replace to="/" state="301" />} />

        {/* Fallback for all other routes */}
        <Route
          path="*"
          element={<Navigate replace to="/page-404" state="301" />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default App;
