import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_EVENTS,
  GET_EVENT_ARTIST,
  GET_EVENT_DETAIL,
  GET_EVENT_SPONSOR,
  GET_NEAREST_EVENT,
} from './actionTypes';
import {
  getEventDetailFail,
  getEventDetailSuccess,
  getEventsFail,
  getEventsSuccess,
  getEventArtistsSuccess,
  getEventArtistsFail,
  getEventSponsorsSuccess,
  getEventSponsorsFail,
  getNearestEventsSuccess,
  getNearestEventsFail,
} from './actions';
import { fetchApiJSON } from '../../helpers/api';
import { setOptions, handleApiError } from '../../helpers/api_helper';
import apiConstants from '../../constants/api_constants';

const apiBaseUrl = process.env.REACT_APP_API_URL;

function* fetchEvents({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.EVENT_LIST;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getEventsSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getEventsFail(error));
  }
}

function* fetchEventDetail({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.EVENT_DETAILS;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getEventDetailSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getEventDetailFail(error));
  }
}

function* fetchNearestEvents({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.NEAREST_EVENT;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getNearestEventsSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getNearestEventsFail(error));
  }
}

function* fetchEventArtists({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.EVENT_ARTIST_LIST;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getEventArtistsSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getEventArtistsFail(error));
  }
}

function* fetchEventSponsors({ payload: { data, history } }) {
  try {
    const options = setOptions(data);
    const apiUrl = apiBaseUrl + apiConstants.EVENT_SPONSOR_LIST;
    const response = yield call(fetchApiJSON, apiUrl, options);
    if (response.STATUS_CODE === 200) {
      yield put(getEventSponsorsSuccess(response.DATA));
    } else {
      handleApiError(response, history);
    }
  } catch (error) {
    yield put(getEventSponsorsFail(error));
  }
}

function* eventsSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents);
  yield takeEvery(GET_EVENT_DETAIL, fetchEventDetail);
  yield takeEvery(GET_NEAREST_EVENT, fetchNearestEvents);
  yield takeEvery(GET_EVENT_ARTIST, fetchEventArtists);
  yield takeEvery(GET_EVENT_SPONSOR, fetchEventSponsors);
}

export default eventsSaga;
