import React, { useState, useEffect, useRef } from 'react';

import sliderImg from '../../assets/light_theme_images/collaborative-process.png';
import sliderImg1 from '../../assets/light_theme_images/slider-img1.jpeg';
import sliderImg2 from '../../assets/light_theme_images/slider-img2.jpeg';
import SeoDetails from '../../seo/SeoDetails';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  FreeMode,
  Keyboard,
} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import clientLogo1 from '../../assets/light_theme_images/Rotary Club.svg';
import clientLogo2 from '../../assets/light_theme_images/Leo-club.svg';
import clientLogo3 from '../../assets/light_theme_images/Weone-entertainers.svg';
import clientLogo4 from '../../assets/light_theme_images/Jecrc-university.svg';
import clientLogo5 from '../../assets/light_theme_images/Shankus-group.svg';
import img from '../../assets/light_theme_images/black-silhouettes.png';
import img2 from '../../assets/light_theme_images/focus-young-man.png';
import img3 from '../../assets/light_theme_images/person-writing-sticky-notes-remember.png';
import img4 from '../../assets/light_theme_images/hands-mature-passenger.png';
import KindPlatform from '../../assets/light_theme_images/First-of-its-Kind-Platform.gif';
import EffortlessEvent from '../../assets/light_theme_images/Effortless-Event-Editing.gif';
import SellersScanners from '../../assets/light_theme_images/Sellers-Scanners.gif';
import InnovativePass from '../../assets/light_theme_images/Innovative-Pass-Distributi.gif';
import CountUp from 'react-countup';
import bgColorFull from '../../assets/light_theme_images/colorful-marble-effect-bg.jpg';
import phoneImage1 from '../../assets/light_theme_images/mockup-slide-img.png';
import bethWilson from '../../assets/light_theme_images/beth-wilson.svg';

// gif
import versatilePanel from '../../assets/light_theme_images/gif-icon/versatile-panel.gif';
import streamline from '../../assets/light_theme_images/gif-icon/streamline.gif';
import quickEntryScan from '../../assets/light_theme_images/gif-icon/quick-entry-scan.gif';
import mentor from '../../assets/light_theme_images/gif-icon/mentor.gif';
import management from '../../assets/light_theme_images/gif-icon/management.gif';
import liveTracking from '../../assets/light_theme_images/gif-icon/live-tracking.gif';
import hosting from '../../assets/light_theme_images/gif-icon/hosting.gif';
import effortlessEvent from '../../assets/light_theme_images/gif-icon/effortless-event.gif';
import effectSales from '../../assets/light_theme_images/gif-icon/effect-sales.gif';
import easyBooking from '../../assets/light_theme_images/gif-icon/easy-booking.gif';
import calendarPromotion from '../../assets/light_theme_images/gif-icon/calendar-promotion.gif';
import bannerAds from '../../assets/light_theme_images/gif-icon/banner-ads.gif';
import analytics from '../../assets/light_theme_images/gif-icon/analytics.gif';
import mobileimg from '../../assets/light_theme_images/Business-mobile.svg';
import googlePlay from '../../assets/light_theme_images/google-play-store-logo.svg';
import appStore from '../../assets/light_theme_images/Download_on_the_App_Store.svg';
import qrImage from '../../assets/light_theme_images/qrcode_organiser.png';
import withRouter from '../../components/Common/withRouter';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, getData } from '../../store/AppMaster/actions';
import api from '../../constants/api_constants';

const Business = (props) => {
  const { data } = useSelector((state) => ({
    data: state.AppMaster.data,
  }));

  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const logos = [
    clientLogo1,
    clientLogo2,
    clientLogo3,
    clientLogo4,
    clientLogo5,
    clientLogo1,
    clientLogo2,
    clientLogo3,
    clientLogo4,
    clientLogo5,
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      id: 1,
      question: 'What is Mepass, and how does it work?',
      answer:
        'Mepass is a digital event ticketing platform that connects attendees and organisers by providing a seamless ticket purchasing and event management experience. Through our website or app, you can browse events, purchase tickets, and enjoy easy access to events.',
    },
    {
      id: 2,
      question: 'How do I purchase tickets for an event on Mepass?',
      answer:
        'To buy tickets, simply browse events on our platform, select your desired event, choose your ticket type, and proceed to checkout. You can complete your purchase using a secure payment method, and your ticket will be emailed to you instantly.',
    },
    {
      id: 3,
      question: 'Can I get a refund if I cannot attend an event?',
      answer:
        'Refund policies vary by event and organizer. Please check the event’s specific refund policy on the event page or contact our support team if you have questions about refunds for your ticket purchase.',
    },
    {
      id: 4,
      question: 'How do I access my tickets after purchase?',
      answer:
        'Your tickets will be sent to the email address you used during purchase. You can also access them through your Mepass account by logging in and viewing your ticket history.',
    },
    {
      id: 5,
      question:
        'What should I do if I encounter issues with my tickets or event access?',
      answer:
        'If you face any issues with your tickets or at the event, reach out to us through our support email or chat feature. We’re here to help resolve any problems and ensure you have a smooth event experience',
    },
    {
      id: 6,
      question: 'How do I become an event organizer on Mepass?',
      answer:
        "If you're interested in hosting events through Mepass, visit our Organizer section or contact our team. We’ll guide you through the setup process and help you start selling tickets in no time.",
    },
  ];

  const [activeCard, setActiveCard] = useState(0);

  const handleCardClick = (index) => {
    if (activeCard !== index) {
      setActiveCard(index);
    }
  };
  const [startCount, setStartCount] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true); // Start the counter when the section is in view
          observer.disconnect(); // Stop observing after it starts
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the section is visible
      },
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const cards = [
    {
      img: img,
      icon: KindPlatform,
      title: 'First-of-its-Kind Platform',
      description:
        'The only app exclusively designed for organisers, revolutionising the way events are created and managed.',
    },
    {
      img: img2,
      icon: EffortlessEvent,
      title: 'Effortless Event Editing',
      description:
        'Make real-time updates to your event details anytime, anywhere, with just a few taps.',
    },
    {
      img: img3,
      icon: SellersScanners,
      title: 'Sellers and Scanners',
      description:
        'Manage registrations, ticketing, resellers and scanners seamlessly from a single platform.',
    },
    {
      img: img4,
      icon: InnovativePass,
      title: 'Innovative Pass Distribution',
      description:
        "Share passes directly, in bulk, or via printed QR codes, tailored to your event's unique needs.",
    },
  ];

  const everySaysList = [
    {
      comments:
        'Hosting my tech event in Ahmedabad was effortless with Mepass. The platform gave me full control to manage tickets and updates seamlessly!',
      profile_image: bethWilson,
      user_name: 'M. Patel, Tech Event Co, Ahmedabad',
    },
    {
      comments:
        'Mepass has been a game-changer for my event management business. Editing and tracking tickets for my Surat events was incredibly smooth!',
      profile_image: bethWilson,
      user_name: 'Dev Baraiya, The D Management, Surat',
    },
    {
      comments:
        "Max Entertainment's Surat events have never been easier to manage! Mepass helped us handle ticketing and scanners like pros.",
      profile_image: bethWilson,
      user_name: 'Badal Rajput, Max Entertainment, Surat',
    },
    {
      comments:
        'Organizing events for EPIC Multispecialty Hospital became hassle-free with Mepass. It’s intuitive and saved us a lot of time!',
      profile_image: bethWilson,
      user_name: 'Ketan Desai, EPIC Multispecialty Hospital, Ahmedabad',
    },
    {
      comments:
        'Mepass was a perfect partner for our charitable trust’s event. It helped us manage everything in Gondal without any technical hiccups.',
      profile_image: bethWilson,
      user_name:
        'Prithvi Singh, Jai Shree Ram Educational Charitable Trust, Gondal',
    },
    {
      comments:
        'Our tech fest at DAIICT was a huge success, thanks to Mepass. Managing ticketing and real-time updates was easier than ever.',
      profile_image: bethWilson,
      user_name: 'Vivek Chaudhari, DAIICT, Gandhinagar',
    },
    {
      comments:
        "For Weone Entertainment's Surat events, Mepass made all the difference. Simple, efficient, and reliable—perfect for organizers!",
      profile_image: bethWilson,
      user_name: 'Dhaval Munjani, Weone Entertainment, Surat',
    },
    {
      comments:
        'Mepass made managing Uday News Jamnagar’s events a breeze. Real-time edits and seamless ticketing made our job so much easier!',
      profile_image: bethWilson,
      user_name: 'Brijesh Parmar, Uday News Jamnagar, Jamnagar',
    },
    {
      comments:
        'I tested Mepass for my demo event, and I was amazed by how intuitive and efficient it was. It’s the perfect tool for any organizer!',
      profile_image: bethWilson,
      user_name: 'Ankur Maheta, Baroda Mirror, Vadodara',
    },
    {
      comments:
        'From ticketing to updates, Mepass simplified event management for our Maharashtra events. Truly a reliable partner!',
      profile_image: bethWilson,
      user_name: 'Pradeep Waghmare, Three Om Entertainment, Thane',
    },
  ];

  //Count Data
  const dispatch = useDispatch();
  const [isApiCalled, setIsApiCalled] = useState(0);
  const [countData, setCountData] = useState('');

  useEffect(() => {
    if (isApiCalled == 0) {
      setIsApiCalled(true);
      const requestData = {};
      dispatch(
        getData(requestData, props.router.navigate, api.BUSINESS_PAGE_COUNT),
      );
    }
  }, [dispatch, isApiCalled]);

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(data.bussiness_count) &&
      isEmpty(countData)
    ) {
      setCountData(data.bussiness_count);
    }
  });

  return (
    <>
      <SeoDetails title="mepass | Business" description="mepass | Business" />
      <section className="text-center bg-white pt-14 pb-14">
        <div className="container">
          <h1 className="text-xl md:text-4xl text-[#101010] font-bold">
            Our Business
          </h1>
          <p className="mt-2 text-sm md:text-xl text-[#101010] font-normal">
            Mepass is an Indian event ticketing platform that offers seamless
            access to exclusive deals and events, enhancing your entertainment
            experience
          </p>
        </div>
      </section>
      <section className="text-center bg-white pb-14">
        <div className="container">
          <Swiper
            cssMode={true}
            mousewheel={true}
            keyboard={true}
            slidesPerView={1.1}
            centeredSlides={false}
            spaceBetween={40}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1.1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1.1,
                spaceBetween: 40,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={sliderImg}
                className="rounded-xl aspect-[99/40] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={sliderImg1}
                className="rounded-xl aspect-[99/40] w-full"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={sliderImg2}
                className="rounded-xl aspect-[99/40] w-full"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="bg-white pb-14">
        <h2 className="text-xl md:text-4xl text-[#101010] font-semibold text-center mb-8">
          Know our Partners
        </h2>
        <Swiper
          slidesPerView={5}
          spaceBetween={70}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 70,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {logos.map((logo, index) => (
            <SwiperSlide key={index}>
              <div className="text-center flex justify-center items-center">
                <img src={logo} alt="img" className="h-[40px] md:h-[90px]" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section className=" bg-white pb-14">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
            <div className="text-center lg:text-left">
              <h2 className="text-xl md:text-2xl lg:text-4xl text-[#101010] font-semibold">
                Why Choose
                <span className="text-xl md:text-2xl lg:text-6xl font-extrabold menu-text-liner-gradient ml-2 lg:ml-0">
                  MEPASS?
                </span>
              </h2>
              <p className="text-sm md:text-base lg:text-xl font-normal text-[#101010] pt-5">
                Unlock seamless event management with Mepass. Boost ticket sales
                and simplify attendee management effortlessly
              </p>
              <button
                onClick={() =>
                  window.open(process.env.REACT_APP_ORGANIZER_URL, '_blank')
                }
                className="btn-liner-gradient text-white text-sm md:text-base text-center font-medium py-[10px] px-3 md:px-7 rounded-lg duration-1000 ease-in-out mt-10 "
              >
                Join as organiser
              </button>
            </div>
            <div className="col-span-1 md:col-span-2">
              <div className="flex gap-3 lg:gap-5">
                {cards.map((card, index) => (
                  <div
                    key={index}
                    className="relative"
                    onClick={() => handleCardClick(index)}
                  >
                    <img
                      src={card.img}
                      className={`rounded-lg object-cover transition-all duration-500 ${
                        activeCard === index
                          ? 'w-[533px] md:w-[500px] lg:w-[533px] h-[200px] md:h-[480px]'
                          : 'w-[100px] md:w-[80px] h-[200px] md:h-[480px]'
                      }`}
                      alt={`Card ${index + 1}`}
                    />
                    <div
                      className="absolute top-0 left-0 right-0 bottom-0 w-full h-full z-10"
                      style={{
                        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)`,
                      }}
                    ></div>
                    <div className="absolute left-0 bottom-0 px-2 md:px-5 py-5 duration-1000 ease-in-out z-20">
                      <div
                        className={`bg-white rounded-full w-[25px] md:w-[50px] xl:w-[64px] h-[25px] md:h-[50px] xl:h-[64px] flex items-center justify-center p-1 md:p-2 overflow-hidden selection:${
                          activeCard === index
                            ? 'translate-y-0 opacity-100'
                            : '-translate-y-5 opacity-100 absolute left-[35%] md:left-[5px] xl:left-[8px] bottom-[12px] right-0 text-nowrap'
                        }`}
                      >
                        <img
                          src={card.icon}
                          className={`inline-block rounded-full duration-1000 ease-in-out ${
                            activeCard === index
                              ? 'rotate-[0deg]'
                              : 'rotate-[0deg]'
                          }`}
                          alt="KindPlatform"
                        />
                      </div>
                      <h4
                        className={`text-white font-semibold transition-all duration-500 ease-in-out ${
                          activeCard === index
                            ? 'translate-y-0 opacity-100 pt-2.5 md:pt-4 text-[12px] md:text-3xl'
                            : '-translate-y-5 opacity-100 absolute left-[0px] translate-x-1/2 lg:translate-x-[30%] 2xl:translate-x-1/2 bottom-[23px] md:bottom-[75px] right-0 text-nowrap -rotate-[90deg] text-[10px] md:text-2xl'
                        }`}
                      >
                        {card.title}
                      </h4>
                      <p
                        className={`text-[8px] md:text-xl text-white font-normal pt-2 md:pt-4 transition-opacity duration-500 ${
                          activeCard === index ? 'block' : 'hidden'
                        }`}
                      >
                        {card.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white pb-14">
        <div className="container">
          <div className="text-center mb-8">
            <h3 className="text-xl md:text-4xl text-[#101010] font-semibold">
              Our journey, by the numbers
            </h3>
            <p className="text-sm md:text-xl text-[#101010] font-normal mt-2">
              Mepass is an Indian event ticketing platform that offers seamless
              access to exclusive deals and events, enhancing your entertainment
              experience
            </p>
          </div>
          <div
            ref={sectionRef}
            className="grid grid-cols-1 md:grid-cols-2 gap-10 w-full xl:w-[840px] m-auto"
          >
            {startCount && (
              <>
                <div className="bg-white md:bg-[#F9F9F9] border border-[#E7E7E7] p-[13px] md:p-6 rounded-[4px] md:rounded-[16px] text-center h-[130px] md:h-[200px] w-[200px] md:w-full flex flex-col justify-center items-center m-auto">
                  <CountUp
                    start={0}
                    end={countData?.organizers}
                    delay={0}
                    duration={5}
                    enableScrollSpy
                  >
                    {({ countUpRef }) => (
                      <h4 className="text-3xl md:text-6xl font-bold menu-text-liner-gradient">
                        <span ref={countUpRef}></span>+
                      </h4>
                    )}
                  </CountUp>

                  <p className="text-sm md:text-xl font-medium text-[#101010] mt-2">
                    Number of Organiser
                  </p>
                </div>
                <div className="bg-white md:bg-[#F9F9F9] border border-[#E7E7E7] p-[13px] md:p-6 rounded-[4px] md:rounded-[16px] text-center h-[130px] md:h-[200px] w-[250px] md:w-full flex flex-col justify-center items-center m-auto">
                  <CountUp
                    start={0}
                    end={countData?.events}
                    delay={0}
                    duration={5}
                    enableScrollSpy
                  >
                    {({ countUpRef }) => (
                      <h4 className="text-3xl md:text-6xl font-bold menu-text-liner-gradient">
                        <span ref={countUpRef}></span>+
                      </h4>
                    )}
                  </CountUp>

                  <p className="text-sm md:text-xl font-medium text-[#101010] mt-2">
                    Number of Events
                  </p>
                </div>
                <div className="col-span-1 md:col-span-2 ">
                  <div className="bg-white md:bg-[#F9F9F9] border border-[#E7E7E7] p-[13px] md:p-6 rounded-[4px] md:rounded-[16px] text-center h-[130px] md:h-[200px]  flex flex-col justify-center items-center m-auto">
                    <CountUp
                      start={0}
                      end={countData?.footfall}
                      delay={0}
                      duration={5}
                      enableScrollSpy
                    >
                      {({ countUpRef }) => (
                        <h4 className="text-3xl md:text-6xl font-bold menu-text-liner-gradient">
                          <span ref={countUpRef}></span>+
                        </h4>
                      )}
                    </CountUp>

                    <p className="text-sm md:text-xl font-medium text-[#101010] mt-2">
                      Footfall
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="bg-white pb-14">
        <div className="container">
          <div className="text-center mb-8">
            <h3 className="text-xl md:text-4xl text-[#101010] font-semibold">
              The answers you’re looking for
            </h3>
            <p className="text-sm md:text-xl text-[#101010] font-normal mt-2">
              Mepass is an Indian event ticketing platform that offers seamless
              access to exclusive deals and events, enhancing your entertainment
              experience
            </p>
          </div>
          <div>
            <Swiper
              cssMode={true}
              mousewheel={true}
              keyboard={true}
              slidesPerView={3}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
              className="mySwiper gallary-slider !pb-10"
            >
              <SwiperSlide>
                <div
                  className="bg-no-repeat bg-cover h-[300px] md:h-[600px] rounded-lg overflow-hidden relative answer-card"
                  style={{
                    backgroundImage: `url(${bgColorFull})`,
                  }}
                >
                  <div className="p-1 md:p-10">
                    <h4 className="text-base md:text-3xl text-[#101010] font-bold text-center event-hosting-title">
                      Event Hosting
                    </h4>
                  </div>
                  <div className="flex md:items-center justify-center h-full md:w-[90%] xl:w-[70%] m-auto mt-[12px]">
                    <img
                      src={phoneImage1}
                      alt="img"
                      className="relative event-phone-image"
                    />
                  </div>
                  <div className="flex flex-col justify-center absolute top-1/2 left-3 md:left-5 -translate-y-1/2 text-effect-custom-box">
                    <ul>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={effortlessEvent}
                            className="inline-block rounded-full"
                            alt="effortlessEvent"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Effortless event
                        </h5>
                      </li>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={hosting}
                            className="inline-block rounded-full"
                            alt="hosting"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Hosting
                        </h5>
                      </li>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={quickEntryScan}
                            className="inline-block rounded-full"
                            alt="quickEntryScan"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Quick entry scan
                        </h5>
                      </li>
                      <li className="flex items-center">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={easyBooking}
                            className="inline-block rounded-full"
                            alt="easyBooking"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Easy Booking
                        </h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bg-no-repeat bg-cover h-[300px] md:h-[600px] rounded-lg overflow-hidden relative answer-card answer-card2"
                  style={{
                    backgroundImage: `url(${bgColorFull})`,
                  }}
                >
                  <div className="flex items-center justify-center -mt-[42px] md:-mt-[72px] xl:-mt-[152px] w-[70%] m-auto">
                    <img
                      src={phoneImage1}
                      alt="img"
                      className="relative event-phone-image new-phone"
                    />
                  </div>
                  <div className="p-1 md:p-5 mt-2">
                    <h4 className="text-base md:text-3xl text-[#101010] font-bold text-center event-hosting-title">
                      Ticket Management Panel
                    </h4>
                  </div>

                  <div className="flex flex-col justify-center absolute top-1/2 left-3 md:left-5 -translate-y-1/2 text-effect-custom-box">
                    <ul>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={versatilePanel}
                            className="inline-block rounded-full"
                            alt="versatilePanel"
                          />
                        </div>
                        <h5 className="text-white font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Versatile panel
                        </h5>
                      </li>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={liveTracking}
                            className="inline-block rounded-full"
                            alt="liveTracking"
                          />
                        </div>
                        <h5 className="text-white font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Live tracking
                        </h5>
                      </li>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={streamline}
                            className="inline-block rounded-full"
                            alt="streamline"
                          />
                        </div>
                        <h5 className="text-white font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Streamline digital passes
                        </h5>
                      </li>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={management}
                            className="inline-block rounded-full"
                            alt="management"
                          />
                        </div>
                        <h5 className="text-white font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Management
                        </h5>
                      </li>
                      <li className="flex items-center">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={effectSales}
                            className="inline-block rounded-full"
                            alt="effectSales"
                          />
                        </div>
                        <h5 className="text-white font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Efficient sales
                        </h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="bg-no-repeat bg-cover h-[300px] md:h-[600px] rounded-lg overflow-hidden relative answer-card answer-card3"
                  style={{
                    backgroundImage: `url(${bgColorFull})`,
                  }}
                >
                  <div className="p-1 md:p-10">
                    <h4 className="text-base md:text-3xl text-[#101010] font-bold text-center event-hosting-title">
                      Advertising & Promotions
                    </h4>
                  </div>
                  <div className="flex md:items-center justify-center h-full md:w-[90%] xl:w-[70%] m-auto mt-[12px]">
                    <img
                      src={phoneImage1}
                      alt="img"
                      className="relative event-phone-image "
                    />
                  </div>
                  <div className="flex flex-col justify-center absolute top-1/2 left-3 md:left-5 -translate-y-1/2 text-effect-custom-box">
                    <ul>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={bannerAds}
                            className="inline-block rounded-full"
                            alt="bannerAds"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Popup ADS for sponsors
                        </h5>
                      </li>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={calendarPromotion}
                            className="inline-block rounded-full"
                            alt="calendarPromotion"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Event lineup promotions
                        </h5>
                      </li>
                      <li className="flex items-center mb-4">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={mentor}
                            className="inline-block rounded-full"
                            alt="mentor"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          WhatsApp campaigns
                        </h5>
                      </li>
                      <li className="flex items-center">
                        <div className="bg-white rounded-full w-[30px] md:w-[45px] h-[30px] md:h-[45px] flex items-center justify-center p-2 overflow-hidden selection:translate-y-0 opacity-100">
                          <img
                            src={analytics}
                            className="inline-block rounded-full"
                            alt="analytics"
                          />
                        </div>
                        <h5 className="text-[#101010] font-semibold text-[12px] md:text-2xl ml-2 md:ml-3">
                          Boost sales effectively
                        </h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <section className="bg-white pb-14">
        <div className="container">
          <h4 className="text-xl md:text-4xl font-semibold text-[#101010] text-center mb-8">
            What everyone says about mepass
          </h4>
          <Swiper
            cssMode={true}
            mousewheel={true}
            keyboard={true}
            slidesPerView={3}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
            className="mySwiper gallary-slider !pb-10"
          >
            {everySaysList.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="bg-white border border-[#EFEFEF] hover:bg-[#FFF1F233] hover:border-[#CB2129] rounded-lg md:rounded-[16px] p-3 md:p-6 duration-1000 ease-in-out">
                  <p className="text-[#101010] font-normal text-[8px] md:text-lg">
                    {item.comments}
                  </p>
                  <div className="flex items-center mt-4">
                    {/* <div className="flex-none">
                      {item.profile_image ? (
                        <div className="w-[20px] md:w-[40px] h-[20px] md:h-[40px] rounded-full overflow-hidden">
                          <img
                            src={item.profile_image}
                            alt=""
                            className="aspect-[1/1] rounded-full object-cover"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>*/}
                    {/*<div className="flex-1 ml-3">*/}
                    <h4 className="text-[#101010] font-semibold text-[10px] md:text-xl">
                      {item.user_name}
                    </h4>
                    {/*</div>*/}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <section className="bg-white pb-14">
        <div className="container">
          <div className="text-center mb-8">
            <h5 className="text-xl md:text-4xl text-[#101010] font-semibold ">
              FAQs
            </h5>
            <p className="text-sm md:text-xl text-[#101010] font-normal mt-2">
              Find answers to common queries or contact our support team for
              quick assistance. We're here to make your Mepass experience
              seamless!
            </p>
          </div>
          <div className="accordion-wrapper space-y-5">
            {faqData.map((item, index) => (
              <div
                key={item.id}
                className="bg-white md:bg-[#F9F9F9] border border-[#E7E7E7] rounded-[4px] md:rounded-lg px-3 md:px-4 py-3"
              >
                <div
                  className="accordion-header flex gap-2 cursor-pointer"
                  onClick={() => handleToggle(index)}
                >
                  <h3 className="text-sm md:text-xl font-semibold text-[#101010] flex-1">
                    {item.question}
                  </h3>
                  <div className="flex-none">
                    <button className="w-6 h-6 flex items-center justify-center text-black">
                      <i
                        className={`fas ${
                          openIndex === index
                            ? 'fa-chevron-up'
                            : 'fa-chevron-down'
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
                {openIndex === index && (
                  <div className="mt-3 text-[12px] md:text-xl text-[#101010] font-normal">
                    {item.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-white pb-14">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-24">
            <div className="flex flex-col gap-6 md:gap-10">
              <div className="flex flex-col gap-3">
                <h4 className="text-2xl lg:text-5xl font-semibold text-[#212121] lg:leading-[66px]">
                  Download India’s First Event Organiser APP
                </h4>
                <p className="text-base lg:text-2xl font-normal text-[#212121] mt-2">
                  Download the Mepass Organiser APP and experience effortless
                  event hosting, managing & ticketing on one place
                </p>
              </div>
              <div className="flex items-center gap-4 lg:gap-10">
                <div className="flex flex-col gap-6">
                  {process.env.REACT_APP_PLAY_STORE_URL ? (
                    <Link
                      to={process.env.REACT_APP_PLAY_STORE_URL}
                      target="_blank"
                    >
                      <img src={googlePlay} alt="img" />
                    </Link>
                  ) : (
                    <img src={googlePlay} alt="img" />
                  )}
                  {process.env.REACT_APP_APP_STORE_URL ? (
                    <Link
                      to={process.env.REACT_APP_APP_STORE_URL}
                      target="_blank"
                    >
                      <img src={appStore} alt="img" />
                    </Link>
                  ) : (
                    <img src={appStore} alt="img" />
                  )}
                </div>
                <p className="text-2xl font-semibold text-[#212121]">OR</p>
                <div className="border-2 border-[#101010] rounded-[10px] p-2 w-[170px]">
                  <img src={qrImage} alt="img" className="aspect-[1/1]" />
                </div>
              </div>
            </div>
            <div className="flex justify-center md:justify-end">
              <img src={mobileimg} alt="img" className="px-10 md:px-0" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default withRouter(Business);
